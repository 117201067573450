<template>
    <header>
        <div id="app-header">
            <div id="top" class="header-row">
                <div id="brand" class="base m_span md:w-auto w-full">
                    <a class="fill cursor-pointer" @click="showWarning();" id="Logo - Home">
                        <div class="m_margin_align_c">
                            <div class="base">
                                <img title="LAC-Logo" alt="LA County Seal" :src="require('@/assets/img/county_seal.png')" width="60" height="60" />
                            </div>
                            <div id="txt" class="base margin_l single">
                                <p class="text-sm md:text-lg">{{ $t('Template.BrandLocation') }}</p>
                                <p class="text-sm md:text-lg"><strong> {{ $t('Template.BrandTitle') }}</strong></p>
                            </div>
                        </div>
                    </a>
                </div>

                <!-- Responsive -->
                <div id="primary_nav" class="right md:block hidden">
                    <ul>
                        <li class="primary"><a id="navigate-to-app-home" @click="showWarning();" class="cursor-pointer">{{ $t('Template.PrimaryNavigation.Home') }}</a></li>
                        <li class="primary"><a id="navigate-to-lavote-home" href="https://lavote.gov/">LA Vote</a></li>
                        <li class="primary"><a id="navigate-to-contact" href="https://www.lavote.gov/contact-us/contact-us">{{ $t('Template.PrimaryNavigation.ContactUs') }}</a></li>
                        <li id="button_language" class="image_nav"><a><img class="swap" :src="require('@/assets/img/icon_language.png')" alt="Icon - Language" title="Language" width="30" height="30">
                        <img class="hide hover" :src="require('@/assets/img/icon_language_hover.png')" alt="Icon - Language" title="Language" width="30" height="30"><span class="current_language">{{ $t('Template.LanguageName') }}</span></a></li>
                    </ul>
                    <LanguageSelectionMenu :enableTranslation="enableTranslation" @select-language="selectLanguage"/>
                </div>
                <div id="m_primary_nav" class="row block md:hidden">
                    <div id="m_primary_nav_btn" class="table w-full">
                        <div class="base btn m_margin cursor-pointer" @click="Toggle('m_primary_nav_items')">
                            <img :src="require('@/assets/img/icon_nav_primary.png')" alt="Icon - Mobile Primary Navigation" title="Mobile Primary Navigation"/>
                        </div>
                        <div id="m_button_language" class="image_nav right"><a aria-label="Language">
                            <img class="swap" :src="require('@/assets/img/icon_language.png')" alt="Icon - Language" title="Language" width="30" height="30">
                            <img class="hide hover" :src="require('@/assets/img/icon_language_hover.png')" alt="Icon - Language" title="Language" width="30" height="30"><span class="current_language">{{ $t('Template.LanguageName') }}</span></a>
                        </div>
                    </div>
                    <div id="m_primary_nav_items" class="w-full hidden">
                        <ul>
                            <li><a @click="showWarning();" id="navigate-to-app-home-mobile" class="cursor-pointer">{{ $t('Template.PrimaryNavigation.Home') }}</a></li>
                            <li><a class="font-bold block" id="navigate-to-lavote-home-mobile" href="https://lavote.gov/">LA Vote</a></li>
                            <li><a class="font-bold block" id="navigate-to-contact-mobile" href="https://www.lavote.gov/contact-us/contact-us">{{ $t('Template.PrimaryNavigation.ContactUs') }}</a></li>
                        </ul>
                    </div>
                    <LanguageSelectionMenu :enableTranslation="enableTranslation" @select-language="selectLanguage"/>
                </div>

            </div>
            <div class="darkblue pointer-events-none yellowborder">
                <div class="header-center">
                    <div class="pt-1 pb-1">
                        <div class="inline-block">
                            <span class="block text-white text-sm text-left md:text-5xl m_margin pt-2 pb-2" v-html="getHeader"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto pb-4 text-left max-w-screen-xl" v-if="modal.show">
                <div class="z-10 inset-0 overflow-y-auto" aria-label="timeout-modal" role="dialog" aria-modal="true">
                    <div class="top-0 right-0 left-0 absolute flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                        <!-- centering the modal contents. -->
                        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all modalwidth50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div class="text-left max-w-screen-xl">
                                        <div>
                                            <div class="text-sm" id="warn">
                                                <div class="text-xl text-red-600">{{ modal.message }}</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="bg-gray-100 px-4 py-3 text-left sm:px-6 sm:flex">
                                <button @click="cancel();" type="button" id="cancel-button" class="mt-3 w-full inline-flex justify-center rounded-md border text-white shadow-sm px-4 py-2 text-base font-medium bg-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" v-if="modal.showCancel">
                                {{ $t('Template.Buttons.Cancel') }}
                                </button>
                                <button @click="proceed();" type="button" id="save-button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 darkblue text-base font-medium text-white darkblue:hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ modal.buttonOkay }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="platform">
                <div id="desktop" class="sm:block hidden"></div>
                <div id="mobile" class="sm:hidden block"></div>
            </div>
        </div>
    </header>
</template>
<script>
import { MonitorActivity, LanguageSelection, Platform } from '../../assets/constants'
import { EnableTranslation } from '../../assets/config'
import LanguageSelectionMenu from '../common/LanguageSelectionMenu.vue'
export default {
    props: { headerText: String },
    data: () => ({
        modal: {
            show: false,
            message: '',
            buttonOkay: '',
            showCancel: false
        },
        language: localStorage.getItem('language') || 'en',
        enableTranslation: EnableTranslation
    }),
    components: {
        LanguageSelectionMenu
    },
    computed: {
        getHeader(){
            if(this.$router.currentRoute.value.fullPath === '/public-agencies-search' || this.$router.currentRoute.value.fullPath === '/public-agencies'){
                return this.$t('Template.PublicAgencyHeader');
            }else{
                return this.$t('Template.BFRHeader');
            }
        }
    },
    methods: {
        Toggle: function(element) {
            element = document.getElementById(element);
            element.style.display = element.style.display === 'none' || element.style.display === '' ? 'block' : 'none';
        },
        showWarning() {
            if(this.$router.currentRoute.value.fullPath === '/public-agencies-search' || this.$router.currentRoute.value.fullPath === '/public-agencies'){
                this.modal.show = false;
                this.$store.dispatch('clearAllState');
                this.$router.push('/public-agencies');
            }else{
                this.modal = {
                    show: true,
                    message: this.$t('Template.Modals.ReturnHome'),
                    buttonOkay: this.$t('Template.Buttons.Proceed'),
                    showCancel: true
                }
                window.scrollTo(0,0);
            }
        },
        showSessionActivityModalWarning() {
            this.$store.dispatch('clearAllState');
            this.modal = {
                show: true,
                message: this.$t('Template.Modals.SessionTimeout'),
                buttonOkay: 'Okay',
                showCancel: false
            }
        },
        cancel() {
            this.modal.show = false;
        },
        proceed() {
            this.modal.show = false;
            this.$store.dispatch('clearAllState');
            this.$router.push('/');
        },
        selectLanguage(event) {
            localStorage.setItem('language', event.target.id);
            window.location.reload();
        }
    },
    mounted() {
        Platform.Construct();
        MonitorActivity.Construct(this);
        LanguageSelection.Construct();
    }
}
</script>
<style>
body {
    font-family: lato, arial, san-serif !important;
    font-size: 20px;
    margin: 0px;
    color: #6d6d6d;
}
.single,
.single p {
    margin: 0px;
    padding: 0px;
}
.header-row {
    display: table;
    width: 100%;
}
.header-row .base {
    float: left;
}
.header-row .right {
    float: right;
}
#app-header {
    overflow: hidden;
}
#app-header #top {
    border-bottom: 10px solid #ffc527;
    background-color: #fff;
}
#app-header #brand {
    font-size: 15px;
    background-color: #ffc527;
    padding: 5px 35px 1px 20px;
    line-height: 1em;
    color: #000;
}
#app-header #brand #txt {
    margin-top: 14px;
}
#app-header #brand p:first-child {
    line-height: 1em !important;
}
#app-header #brand p:last-child {
    line-height: 1em !important;
}
#app-header #brand a {
    color: #000;
}
#primary_nav li {
    display: inline-block;
}
    #primary_nav li a {
        display: block;
        font-size: 21px;
        color: #003471;
        padding: 18px 25px 15px 25px;
        font-weight: bold;
        text-decoration: none;
        letter-spacing: .05em;
    }
    #primary_nav a:hover {
        background-color: #ffc527;
        color: #fff;
    }
    #primary_nav li:last-child {
        border-left: 1px solid #ccc;
    }
    #primary_nav .primary {
        position: relative;
    }
    #primary_nav .swap,
    #m_primary_nav .swap {
        display: inline-block;
    }
.darkblue{
    background-color: #003471;
}
.yellow{
    color:#ffc527;
}
.yellowborder{
    border-bottom: solid #13b5ea .5rem;
}
.header-center{
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}
.margin_l {
    margin-left: 10px;
}
.m_pad {
    padding: 10px;
}
.sub-content {
    font-size: 16px;
}
.item-row {
    border-bottom: 1px solid #ccc;
    padding: 5px 0 5px 0;
}
.item-row-content {
    margin: 5px 0 5px 5px;
}
.hide {
    display: none;
}
#language_selection,
#m_language_selection {
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 101;
    background-color: #fff;
    width: 200px;
    border-bottom: 10px solid #ffc527;
}
    #language_selection a,
    #m_language_selection a {
        color: #003471 !important;
        text-decoration: none !important;
        display: block !important;
        padding: 5px 10px 5px 10px !important;
    }
    #language_selection a:hover,
    #m_language_selection a:hover {
        background-color: #003471 !important;
        color: #fff !important;
    }
.row_item {
    display: table;
    width: 100%;
}
.row_span {
    width: 100%;
}
.btn {
    cursor: pointer;
}

/* Mobile */
#m_primary_nav a {
    font-size: 30px;
    color: #003471;
    letter-spacing: .05em;
    padding: 15px 10px 20px 20px;
}
    #m_primary_nav a:hover {
        background-color: #ffc527;
        color: #fff;
    }
#m_primary_nav_btn {
    border-bottom: 1px solid #ccc;
}
.m_margin {
    margin: 0 20px 0 20px;
}

</style>