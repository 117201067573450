<template>
    <div>
        <div>
            <UploadProof />
        </div>    
    </div>
    
</template>

<script>
import UploadProof from '../components/Reg/UploadProof.vue'
export default {
    components: {
        UploadProof
    }
}
</script>