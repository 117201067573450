const isValidFilingNumber = (filingNumber) => {
    return /^[0-9]*$/.test(filingNumber);
}

const isValidOnlineCopyNumber = (onlineCopyNumber) => {
    return /^\d{1,20}$/.test(onlineCopyNumber);
}

const isValidBusinessName = (buisinessName) => {
    let isValid = true;
    const invalidCharacters = ['*','^','<'];
    invalidCharacters.forEach(c => {
        if (buisinessName.indexOf(c) > -1) {
            isValid = false;
        }
    })
    return isValid;
}

const isValidOwnerName = (ownerName) => {
    return /[a-zA-Z]/.test(ownerName)
}

const isValidAddress = (address) => {
    return /^[#.0-9a-zA-Z/\s,-]+$/.test(address)
}

const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

const isValidPhone = (phone) => {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone);
}

const isValidZipCode = (zipCode) => {
    return /^\d{5}(?:[-\s]\d{4})?$/.test(zipCode);
}

const isValidString = (string) => {
    return /^[A-Za-z0-9 -'&#,]*[A-Za-z0-9 '-][A-Za-z0-9 '#&.]*$/.test(string);
}

const isValidLastNameOrCompanyName = (name) => {
    return /^[A-Za-z0-9 '"&$#,!Ñ(:)?~.\\·@;’|=\][_•/¥ŸŒ°{}%`²³®+]*[A-Za-z0-9 '$"~!·()+Ñ’Ÿ;®`:@¥_|²³°\\/\][{}Œ%•=?-][A-Za-z0-9 '$"#~&!\\.:()\][@_`Ÿ{²³,°}•®’Ñ%·¥;=|/Œ?+]*$/.test(name);
}

const isLetters = (string) => {
    return /^[a-zA-Z -]+$/.test(string);
}

const isValidInput = (input) => {
    return /^[A-Za-z0-9 '&#,]*[A-Za-z0-9 '-][A-Za-z0-9 '#&.]*$/.test(input)
}

const isValidDropdown = (dropdownValue) => {
    return /^[A-Za-zÅéçô0-9 -'&#,]*[A-Za-z0-9 )('-][A-Za-z0-9 ()'#&.]*$/.test(dropdownValue)
}

const isValidArticlesOfIncorporation = (articles) => {
    return /^[A-Za-z]*[0-9]*$/.test(articles)
}
// String Comparisons
function CompareStrings(string1, string2) {
    let stringsAreEqual = false
    if (string1.toUpperCase() === string2.toUpperCase()) {
        stringsAreEqual = true;
    }
    return stringsAreEqual
}
// Verify signer exists in the list of registered owners
function verifySignerExists(signerNames, signer) {
    if (signerNames.includes(signer)) {
        return true
    } else {
        return false
    }
}

module.exports = {
    isValidFilingNumber,
    isValidBusinessName,
    isValidOwnerName,
    isValidAddress,
    isValidDropdown,
    isValidEmail,
    isValidInput,
    isValidPhone, 
    isValidZipCode,
    isValidString,
    isLetters,
    isValidLastNameOrCompanyName,
    isValidOnlineCopyNumber,
    isValidArticlesOfIncorporation,
    CompareStrings,
    verifySignerExists
}