<template>
    <div></div>
</template>
<script>
import { FilingActionType } from '../../assets/constants'
export default {
    computed: {
        getFbnFiling () {
            return this.$store.getters.getFbnFiling
        }
    },
    data: () => ({
        FbnFiling: {},
        // Remove when refactoring registered owner page
        temporaryFilingAction: []
    }),
    methods: {
        //#region Filing Actions
        createFilingAction (isUnique, filingActionTypeCode, filingActionName, filingActionNameId, filingActionDelta, edit) {
            const filingAction = {
                isUnique: isUnique,
                filingActionTypeCode: filingActionTypeCode,
                name: filingActionName,
                nameId: filingActionNameId,
                delta: filingActionDelta
            }
            // Registered owner page sets filing actions individually to store, address this in refactor of registered owner page
            // Remove when refactoring registered owner page
            if (!edit) {
                this.setFilingAction (filingAction) 
            } else {
                this.temporaryFilingAction.push(filingAction)
            }           
        },
        // Remove when refactoring registered owner page
        addTemporaryFilingActions () {
            for(let i = 0; i < this.temporaryFilingAction.length; i++) {
                this.setFilingAction(this.temporaryFilingAction[i])
            }
        },
        // Remove when refactoring registered owner page
        clearTemporaryFilingActions () {
            this.temporaryFilingAction = []
        },
        clearAbandonmentAddBusinessNamesWithdrawalFilingActions () {
            this.FbnFiling = this.getFbnFiling
            if (!this.FbnFiling.fbnFilingActions.length > 0) return
            const filingActions = []
            this.FbnFiling.fbnFilingActions.forEach(filingAction => {
                if (filingAction.filingActionTypeCode !== FilingActionType.AbandonBusinessName &&
                    filingAction.filingActionTypeCode !== FilingActionType.WithdrawOwner &&
                    filingAction.filingActionTypeCode !== FilingActionType.AddBusinessName) {
                    filingActions.push(filingAction)
                }
            })
            this.FbnFiling.fbnFilingActions = filingActions
            this.$store.dispatch('setFbnFiling', this.FbnFiling) 
        },
        // Remove when refactoring registered owner page
        removeFilingActionByNameId (id, edit) {
            this.FbnFiling = this.getFbnFiling
            for (let i = 0; i < this.FbnFiling.fbnFilingActions.length; i++) {
                if (id === this.FbnFiling.fbnFilingActions[i].nameId) {
                    this.FbnFiling.fbnFilingActions.splice(i, 1)
                }
            }
        },
        setFilingAction (filingAction) {
            this.FbnFiling = this.getFbnFiling
            if (this.FbnFiling.fbnFilingActions.length <= 0) {
                this.FbnFiling.fbnFilingActions.push(filingAction)
            } else {
                // Check the filing action array for matches and remove the filing action if found and replace with new action.
                for (let i = 0; i < this.FbnFiling.fbnFilingActions.length; i++) {
                    // A unique filing action can only occur once per filing. Compare the type codes 
                    if (filingAction.isUnique) {
                        if (this.FbnFiling.fbnFilingActions[i].filingActionTypeCode === filingAction.filingActionTypeCode) {
                            this.FbnFiling.fbnFilingActions.splice(i, 1)
                        }
                    // Registered Owner and Business Name Filing Actions
                    } else {
                        if (filingAction.filingActionTypeCode !== FilingActionType.AddRegisteredOwner && 
                        filingAction.filingActionTypeCode !== FilingActionType.AddBusinessName) {
                            if (this.FbnFiling.fbnFilingActions[i].nameId === filingAction.nameId) {
                                this.FbnFiling.fbnFilingActions.splice(i, 1)
                            }
                        }
                    }
                }
                this.FbnFiling.fbnFilingActions.push(filingAction)
            }
            this.$store.dispatch('setFbnFiling', this.FbnFiling)
        }
        //#endregion
    }
}
</script>