<template>
    <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto" id="breadcrumb">{{ getBreadcrumbText }}</div>
</template>

<script>

export default {
    computed: {
        getBreadcrumbText () {
            const breadcrumbText = this.$t('Template.Breadcrumb', { breadcrumb: this.$store.getters.getSubheader })
            return breadcrumbText
        }
    }
}

</script>