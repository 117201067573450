<template>
  <Header :headerText="headerText" />
  <router-view @header-text="HeaderText($event)"/>
  <footer>
    <p class='admin-report'>To report website issues or website related inquiries email: <a href="mailto:webadmin@rrcc.lacounty.gov">webadmin@rrcc.lacounty.gov</a></p>  
  </footer>
</template>

<script>
import Header from './components/sitecss/Header.vue'
export default {
  name: 'App',
  components: {
    Header
  },
  data: () => ({ headerText: '' }),
  methods: {
    HeaderText(text) {
      this.headerText = text === null ? this.$t('Template.AppTitle') : text;
    }
  }
}
</script>
