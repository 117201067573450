<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.AdditionalFiling.Heading') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="mx-auto pt-4 pb-8 text-left text-base max-w-screen-xl">{{ $t('Reg.AdditionalFiling.Intro') }}</div>
        <div class="max-w-screen-xl mx-auto">
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    #
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    {{ $t('Reg.AdditionalFiling.Results.Columns.FBNFilingMethod') }}
                                </th>                                
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    {{ $t('Reg.AdditionalFiling.Results.Columns.FBNName') }}
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    {{ $t('Reg.AdditionalFiling.Results.Columns.BusinessFilingType') }}
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    {{ $t('Reg.AdditionalFiling.Results.Columns.NumberOfFBNNames') }}
                                </th>
                                <th scope="col" class="px-6 py-3 text-right text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    {{ $t('Template.Edit') }}
                                    <span class="sr-only">{{ $t('Template.Edit') }}</span>
                                </th>
                                <th scope="col" class="px-6 py-3 text-right text-sm font-medium text-gray-500 uppercase tracking-wider">
                                    {{ $t('Template.Remove') }}
                                    <span class="sr-only">{{ $t('Template.Remove') }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(filing, index) in FbnFilingList" :key="index">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-base text-gray-900">
                                        {{index + 1}}
                                    </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-base text-gray-900">{{filing.filingSelectionType}} </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-base text-gray-900">{{filing.fbnNames[0]}} </div>
                                </td>
                                 <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-base text-gray-900">{{filing.businessType}} </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    <div class="text-base text-gray-900">{{filing.fbnNames.length}} </div>                                
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
                                    <button @click="EditClickHandler(index)" class="text-indigo-600 hover:text-indigo-900">{{ $t('Template.Edit') }}</button>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-base font-medium">
                                    <button @click="DeleteClickHandler(index)" class="text-indigo-600 hover:text-indigo-900">{{ $t('Template.Remove') }}</button>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="max-w-screen-xl mx-auto py-3 text-center">
            <!-- <button class="text-white rounded darkblue font-semibold shadow-lg focus:outline-none py-2 px-7" id="add-new-filing-button" @click="AddNewFilingClickHandler()" type="submit">{{ $t('Reg.AdditionalFiling.UserInput.AddNewFiling') }}</button> -->
        </div>
        <div class="mx-auto max-w-screen-xl py-4" v-if="this.enableRecaptcha">
            <recaptcha ref="recaptcha" @verify="submit"></recaptcha>
        </div>
        <div class="mx-auto max-w-screen-xl py-4 main-controls">
            <router-link class="text-white px-4 py-4 font-semibold bg-gray-700 hover:bg-gray-500 text-center rounded inline-block" id="back-button" to="/fbnreview">{{ $t('Template.Buttons.PageNavigation.Back') }}</router-link>
            <button :disabled="isSubmitButtonDisabled" :class="`text-white px-4 py-4 font-semibold darkblue text-center rounded inline-block ml-2 ${submitButtonStyle}`" id="submit-button" @click="nextPage">{{ $t('Template.SubmitOrder') }}</button>
        </div>
    </div>
</template>

<script>
import ClerkRepository from '../../repositories/ClerkRepository';
import Recaptcha from '../common/Recaptcha.vue';
import { EnableRecaptcha } from '../../assets/config';
import { AppHasData } from '../../assets/constants';
export default {
    components: {
        Recaptcha
    },
    name: 'Additional Filings',
    computed: {
        isSubmitButtonDisabled() {
            return this.disableSubmitButton;
        },
        submitButtonStyle() {
            return this.disableSubmitButton ? 'opacity-50 cursor-not-allowed' : '';
        }
    },
    data: () => ({
        nav: '',
        FbnFilingList: [],
        errors: [], 
        passRecaptcha: false,
        enableRecaptcha: EnableRecaptcha,
        workflow: {},
        disableSubmitButton: false
    }),
    methods: {
        submit(response) {
            ClerkRepository.validate(response)
                .then( response => {
                    if (response.data.success === true) {
                        this.passRecaptcha = true;
                    }
                })
                .catch( error => console.log('Recaptch: ', error))
        },
        AddNewFilingClickHandler: function() {
            const currentErrors = [];
            if (this.FbnFilingList.length >= 3) {
                currentErrors.push(this.$t('Reg.AdditionalFiling.UserInput.Errors.FilingsExceeded'));
                this.errors = currentErrors;
                return;
            }
            this.$store.dispatch('createAdditionalFiling');
            this.$store.dispatch('setFilingApproach', 'In-Person');
            this.$router.push('filingtype');
        },
        EditClickHandler: function(index) {
            if (index < 0 || index + 1 > this.FbnFilingList.length) {
                this.errors.push(this.$t('Reg.AdditionalFiling.UserInput.Errors.EditFileNotValid'));
                return;                
            }
            this.$store.dispatch('setCurrentFilingIndex', index + 1);
            const filing = this.$store.getters.getCurrentFiling;
            const subheader = filing.filingSelectionChoice + ': ' + filing.filingSelectionType;
            this.$store.dispatch('setSubheader', subheader);
            this.$store.dispatch('setWorkflow', filing.filingSelectionType); 
            this.$router.push('fbnreview');
        },
        DeleteClickHandler: function(index) {
            if (this.FbnFilingList.length === 1) {
                this.errors.push(this.$t('Reg.AdditionalFiling.UserInput.Errors.FilingRequired'));
                return;
            }
            this.$store.dispatch('deleteFbnFiling', index)
            this.FbnFilingList = [...this.$store.getters.getFilings]
        },
        nextPage() {
            this.disableSubmitButton = true;
            if (this.enableRecaptcha && !this.passRecaptcha) {
                this.errors.push(this.$t('Template.VerifyRecaptcha'));
                this.disableSubmitButton = false;
                return;
            }
            var filingType = this.$store.getters.getFilingApproach;
            var currentWorkflow = this.$store.getters.getWorkflow;
            var payload = [...this.$store.getters.getFilings];
            switch(currentWorkflow) {
                default:
                    if(filingType === 'In-Person') {
                        ClerkRepository.post(payload, filingType, this.workflow)
                            .then( response => {
                                this.$store.dispatch('setOrderNumber', response.data.trackingNumber)
                                this.$router.push('fbnorderconfirmation');    
                            })
                            .catch( error => console.log('Submit Error: ', error))    
                    }
                    break;
            } 
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.FbnFilingList = [...this.$store.getters.getFilings];
        this.nav = this.$store.getters.getSubheader;
        this.workflow = {
            Approach: this.$store.getters.getFilingApproach,
            Type: this.$store.getters.getWorkflow
        }
        window.scrollTo(0,0);
    }
}
</script>
