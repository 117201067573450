<template>
    <div class="m_pad">
        <div>
            <PublicAgencies @header-text="$emit('header-text', $event)"/>
        </div>
    </div>
</template>

<script>
import PublicAgencies from '../components/Reg/PublicAgencies.vue'
export default {
    components: {
        PublicAgencies
    }
}
</script>
