<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto py-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Home.LandingPage.Heading') }}</h1>
        <div class="mx-auto max-w-screen-xl">
            <div>
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="file-a-fictitious-business-name-button"
                    aria-label="file-a-fictitious-business-name-button"
                    @click="FilingSelection(LandingPageFilingSelection.FileFictitiousBusinessName)">
                    <div class="button-header">{{ $t(`Home.LandingPage.FileFBNButton`) }}</div>
                    <div class="button-info">{{ $t(`Home.LandingPage.FileFBNButtonDescription`) }}</div>
                </div>
            </div>
            <div class="py-4">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="provide-proof-of-publication-button"
                    aria-label="provide-proof-of-publication-button"
                    @click="FilingSelection(LandingPageFilingSelection.ProofOfPublication)">
                    <div class="button-header">{{ $t(`Home.LandingPage.ProofOfPublicationButton`) }}</div>
                </div>
            </div>
            <div>
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="online-copy-number-lookup-button"
                    aria-label="online-copy-number-lookup-button"
                    @click="FilingSelection(LandingPageFilingSelection.OnlineCopyNumberLookup)">
                    <div class="button-header">{{ $t(`Home.LandingPage.CertifiedCopyVerificationButton`) }}</div>
                </div>    
            </div>          
        </div>
        <div class="py-1">
            <div class="mx-auto py-3 text-left text-2xl font-bold max-w-screen-xl" id="small-business-info">{{ $t('Home.LandingPage.Copy1.Heading1') }}</div>
            <div class="mx-auto py-1 text-left text-lg max-w-screen-xl" v-html="$t('Home.LandingPage.Copy1.Line1')"></div>
        </div>
        <div class="py-1">
            <div class="mx-auto py-3 text-left text-2xl font-bold max-w-screen-xl" id="who-should-file">{{ $t('Home.LandingPage.Copy2.Heading1') }}</div>
            <div class="mx-auto py-1 text-left text-lg max-w-screen-xl">{{ $t('Home.LandingPage.Copy2.Line1') }}</div>
            <div class="mx-auto py-1 text-left text-lg max-w-screen-xl">{{ $t('Home.LandingPage.Copy2.Line2') }}</div>
            <div class="mx-auto py-1 text-left text-lg max-w-screen-xl">{{ $t('Home.LandingPage.Copy2.Line3') }}</div>
            <div class="mx-auto py-1 text-left text-lg max-w-screen-xl text-indigo-600 hover:text-indigo-900"><a href="https://www.lavote.gov/home/county-clerk/fictitious-business-names/filing/requirements" target="_blank">{{ $t('Home.LandingPage.Copy2.Line4') }}</a></div>
        </div>
    </div>
</template>

<script>
import { LandingPageFilingSelection } from '../../assets/constants.js'
export default {
    name: 'LandingPage',
    data: () => ({
        fbnFiling: {},
        LandingPageFilingSelection: LandingPageFilingSelection
    }),
    methods: {
        FilingSelection (selection) {
            if (selection === LandingPageFilingSelection.FileFictitiousBusinessName) {
                this.$router.push('fbnfilingapproach')
            } else {
                if (selection === LandingPageFilingSelection.ProofOfPublication) {
                    this.fbnFiling.isProofOfPublication = true
                    this.fbnFiling.workflow = selection
                } else {
                    this.fbnFiling.isOnlineCopyNumberLookup = true
                }
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$store.dispatch('setWorkflow', selection)
                this.$router.push('fbnlookup')
            }
            this.$store.dispatch('setAppHasData', true)
        },
        initializeFbnFiling () {
            this.fbnFiling = { 
                // Variables
                approach: '',
                inProgress: false,
                fbnFilingActions: [],
                reviewPageEdit: false,
                workflow: '',
                isProofOfPublication: false,
                isOnlineCopyNumberLookup: false,
                // Page Objects
                RegisteredOwnerPage: {
                    businessType: '',
                    filingActions: [],
                    registeredOwners: [],
                    registeredOwnerTempId: 0,
                    sosFile: []
                },
                BusinessNamePage: {
                    fbnNames: [],
                    originalFbnNames: [],
                    filingActions: [],
                    businessNameId: {}
                },
                BusinessAddressPage: {
                    filingActions: [],
                    internationalBusiness: false,
                    BusinessAddress: {
                        address: '',
                        additionalAddress: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        county: '',
                        country: 'United States',
                        articles: ''
                    },
                    OriginalBusinessAddress: {
                        address: '',
                        additionalAddress: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        county: '',
                        country: 'United States',
                        articles: '',
                    },                 
                },
                RequestorInformationPage: {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    address: '',
                    addAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: '',
                    email: '',
                    phone: '',
                    dateRegistrantCommenced: '',
                    additionalCopies: 1
                },
                OriginalRequestorInformation: {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    address: '',
                    addAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: '',
                    email: '',
                    phone: '',
                    dateRegistrantCommenced: '',
                    additionalCopies: 1
                },
                VitalChekPage: {
                    filingActions: []
                }
            }
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
        }
    },
    mounted () {
        this.$store.dispatch('clearAllState')
        this.initializeFbnFiling()
        window.scrollTo(0,0)
    }
};
</script>