<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.UploadProof.Heading') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ currentWorkflow }}</div>
        <div class="mx-auto py-2 max-w-screen-xl">
            <div class="text-md pb-2">
                {{ $t('Reg.UploadProof.Intro1') }}    
            </div>
            <!-- <upload :cachedFiles="cachedFiles" :title="'Please upload SOS File'" :subTitle="'PDF, JPEG, and TIFF only. File must be 2 MB or less.'" @onErrorHandler="onErrorHandler" @fileRemoveHandler="deleteSOSFile" @fileChangeHandler="setSOSFile"></upload> -->
            <div class="text-md pb-2">
                {{ $t('Reg.UploadProof.Intro2') }}
            </div>
            <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
                <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
                <ul>
                    <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
            <div class="max-w-screen-xl mx-auto" v-if="filingList.length > 0">
                <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                #
                                            </th>
                                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                 {{ $t('Reg.UploadProof.TableHeadings.FilingNumber') }}
                                            </th>
                                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                {{ $t('Reg.UploadProof.TableHeadings.File') }}
                                            </th>
                                            <th scope="col" class="w-1/12 px-6 py-3 text-right text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                {{ $t('Reg.UploadProof.TableHeadings.Remove') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="(filingNumber,index) in filingObjects" :key="index">
                                            <td class="px-6 py-4 whitespace-nowrap">
                                                <div class="text-sm text-gray-900">
                                                    {{ index + 1}}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap">
                                                <div class="text-base text-gray-900">{{ filingNumber.jediFilingNumber }}</div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap">
                                                <upload :cachedFiles="getCachedFileByFilingNumber(filingNumber.jediFilingNumber)" @onErrorHandler="onErrorHandler" @fileRemoveHandler="deleteSOSFile(filingNumber.jediFilingNumber)" @fileChangeHandler="setSOSFile($event, filingNumber.jediFilingNumber)"></upload>
                                            </td>
                                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <!-- Responsive -->
                                                <div class="hidden md:block">
                                                    <button @click="removeFiling(index)" class="text-indigo-600 hover:text-indigo-900">Remove</button>
                                                </div>
                                                <div class="md:hidden">
                                                    <button @click="removeFiling(index)" class="text-indigo-600 hover:text-indigo-900"><img :src="require('@/assets/img/m/icon_delete.png')" alt="Icon - Delete" title="Delete" width="40" height="40"/></button>
                                                </div>                                                        
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="max-w-screen-xl mx-auto py-3 text-center">
            <button class="text-white rounded darkblue font-semibold shadow-lg focus:outline-none py-3 px-7" id="add-new-filing-button" @click="addFiling()">{{ $t('Template.Buttons.AddNewProof') }}</button>
        </div>    
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="1"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>
    </div>
</template>

<script>
import Upload from '../common/Upload.vue';
import Help from '../common/Help.vue';
import { ConvertFileToBase64 } from '../../assets/common';
import ClerkRepository from '../../repositories/ClerkRepository';
import { AppHasData } from '../../assets/constants';
import NavigationControls from '../common/NavigationControls.vue'
export default {
    name: 'Upload Proof',
    data: () => ({
        errors: [],
        cachedFiles: [],
        currentSOSFile: [],
        isHelpVisible: false,
        currentFileCount: 0,
        filingList: [],
        filingObjects: [],
        currentPublicationFilesList: [],
        currentWorkflow: ''
    }),
    components: {
        Help,
        NavigationControls,
        Upload
    },
    methods: {
        async setSOSFile(event, filingNumber) {
            if (this.checkForUploadErrors(filingNumber)) return;
            for(let i = 0; i < this.filingObjects.length; i++) {
                if(filingNumber === this.filingObjects[i].jediFilingNumber) {
                    if(this.filingObjects[i].sosFilePublications.length > 0) {
                        this.errors.push(this.$t('Reg.UploadProof.UserInput.Errors.FileAlreadyAddedMessage'), {field: filingNumber});
                    }
                }
            }
            if(this.errors.length <= 0) {
                this.$store.dispatch('setSOSFilePublications', {
                    filingNumber: filingNumber,
                    file: event.target.files[0]
                });    
                const payload = {
                    FileBase64: await ConvertFileToBase64(event.target.files[0]),
                    FilingNumber: filingNumber
                };
                ClerkRepository.uploadFile(payload)
                    .then(() => {
                        this.currentPublicationFilesList = this.$store.getters.getCurrentPublicationFilesList;
                    })
                    .catch(error => {
                        const currentErrors = [];
                        currentErrors.push(this.$t('Reg.UploadProof.UserInput.Errors.FileUploadExceptionMessage'), {field: filingNumber});
                        this.errors = currentErrors; 
                        console.log(`[FileUpload] ${error}`);
                    })
            }                              
        },
        onErrorHandler(error) {
            const currentErrors = [];
            currentErrors.push(error);
            this.errors = currentErrors;
        },
        deleteSOSFile(filingNumber) {
            this.$store.dispatch('deleteSOSFilePublications', filingNumber);
            this.currentPublicationFilesList = this.currentPublicationFilesList.filter(file => file.filingNumber !== filingNumber);
            this.filingObjects = this.$store.getters.getFilingPublicationObjects;
        },
        checkForUploadErrors(filingNumber) {
            const currentErrors = [];
            for(let i = 0; i < this.filingObjects.length; i++) {
                if(filingNumber === this.filingObjects[i].jediFilingNumber) {
                    if(this.filingObjects[i].sosFilePublications.length > 0) {
                        currentErrors.push(this.$t('Reg.UploadProof.UserInput.Errors.FileAlreadyAddedMessage'), {field: filingNumber});
                    }
                }
            }
            this.errors = currentErrors;
            return currentErrors.length > 0;
        },
        openHelp() {
            this.isHelpVisible = true;
        },
        closeHelp() {
            this.isHelpVisible = false;
        },
        getCachedFileByFilingNumber(filingNumber) {
            return this.currentPublicationFilesList.filter(file => file.filingNumber === filingNumber);
        },
        addFiling() {
            if(this.$store.getters.getFilings.length >= 10) {
                this.errors.push(this.$t('Reg.UploadProof.UserInput.Errors.FileLimitReachedMessage'));
            } else {
                this.$store.dispatch('createAdditionalFiling');  
                this.$router.push('fbnlookup');
            }
        },
        removeFiling(key) {           
            const filingNumberToRemove = this.filingList[key];
            this.filingList.splice(key, 1);            
            this.currentPublicationFilesList = this.$store.getters.getCurrentPublicationFilesList;            
            this.deleteSOSFile(filingNumberToRemove);
            this.$store.dispatch('removeFilingPublicationObject', filingNumberToRemove);            
        },
        doesPageContainErrors: function () {
            const currentErrors = [];
            for(let i = 0; i < this.filingObjects.length; i++) {
                if(this.filingObjects[i].sosFilePublications.length <= 0) {
                    currentErrors.push(this.$t('Reg.UploadProof.UserInput.Errors.NoFileAddedMessage', {field: this.filingObjects[i].jediFilingNumber}));
                }
            }          
            this.errors = currentErrors;
            return currentErrors.length > 0;
        },
        submit(response) {
            ClerkRepository.validate(response)
                .then( response => {
                    if (response.data.success === true) {
                        this.passRecaptcha = true;
                    }
                })
                .catch( error => console.log('Recaptch: ', error))
        },
        proceed () {
            if (this.doesPageContainErrors()) return;
            this.$router.push('fbnadditionalinformation');
        },
        previousPage() {
            this.$router.push('fbnpublishinginfo')
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.cachedFiles = this.$store.getters.getSOSFilePublications || [];
        this.filingObjects = this.$store.getters.getFilingPublicationObjects;
        for(let i = 0; i < this.filingObjects.length; i++) {
            this.filingList.push(this.filingObjects[i].jediFilingNumber);
        }
        this.currentFileCount = this.filingList.length;
        this.currentPublicationFilesList = this.$store.getters.getCurrentPublicationFilesList;
        this.currentWorkflow = this.$store.getters.getWorkflow;
    }
}
</script>

<style>
    .lightblue{
        background-color: #13b5ea;
    }
    .lightblue:hover{
        background-color: #59cbf0;
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
    }
</style>