<template>
    <div>
        <div>
            <RegisterName />
        </div>    
    </div>
    
</template>

<script>
import RegisterName from '../components/Reg/RegisterName.vue'
export default {
    components: {
        RegisterName
    }
}
</script>