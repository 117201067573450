<template>
  <div
  aria-label="g-recaptcha" 
  id="g-recaptcha"
  class="g-recaptcha"
  :data-sitekey="sitekey">
  </div>
</template>

<script>
import { RecaptchaSiteKey } from '../../assets/config';
export default {
  data () {
    return {
      sitekey: RecaptchaSiteKey,
      widgetId: 0
    }
  },
  methods: {
    execute () {
      window.grecaptcha.execute(this.widgetId)
    },
    reset () {
      window.grecaptcha.reset()
    },
    render () {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey,
          callback: (response) => {
            this.$emit('verify', response)
          }
        })
      }
    }
  },
  mounted () {
    this.render()
    this.reset();
  }
}
</script>