<template>
    <div class="m_pad" role="main">
        <div id=public_agency>
            <p>{{ $t('Reg.PublicAgenciesSearch.StartEndDateInstructions') }}</p>
            <br>
            <div id="secondary-nav">
                <div class="base btn-blue" tabindex="-1">
                    <SubNavigationItem
                    id="statement-filing"
                    :name="$t('Reg.PublicAgenciesSearch.StatementFileDateRange')"
                    :default="true"
                    @navigate="ToggleNavigation($event)"
                    @keyup.enter="ToggleNavigation({ event: $event, id: 'statement-filing' })"
                />
                </div>
                <div class="base btn-blue" tabindex="-1">
                    <SubNavigationItem
                    id="original-filing"
                    :name="$t('Reg.PublicAgenciesSearch.OriginalFilingDateRange')"
                    :default="false"
                    @navigate="ToggleNavigation($event)"
                    @keyup.enter="ToggleNavigation({ event: $event, id: 'original-filing' })"
                />
                </div>
                <a tabindex="0" aria-labelledby="public-agencies-home" id="back-hyperlink" class="text-white px-1 py-1 darkblue text-center rounded inline-block" href="#public-agencies">{{ $t('Template.Buttons.PageNavigation.Back') }}</a>
            </div>
            <div id="main-content">
                <div id="statement-filing" class="hide main-content-area">
                    <br>
                    <litepie-datepicker
                    as-single
                    use-range
                    :formatter="formatter"
                    v-model="statementFileDateRange"
                    separator=" - "
                    :placeholder="$t('Reg.PublicAgenciesSearch.SelectStatementFileDateRange')"
                    trigger="open-datepicker"
                    :i18n="$t('Template.Language')"
                    ></litepie-datepicker>
                    <button :tabindex= this.displayStatementFileDateRange aria-labelledby="statement-file-date-search" class="text-white px-1 py-2 darkblue text-center rounded inline-block top-spacing" :class="{'button-disabled': disableSearchButton()}" @click="populateData">{{ $t('Template.Buttons.Search') }}</button>
                </div>
                <div id="original-filing" class="hide main-content-area">
                    <br>
                    <litepie-datepicker
                    as-single
                    use-range
                    :formatter="formatter"
                    v-model="originalFilingDateRange"
                    separator=" - "
                    :placeholder="$t('Reg.PublicAgenciesSearch.SelectOriginalFilingDateRange')"
                    trigger="open-datepicker"
                    :i18n="$t('Template.Language')"
                    ></litepie-datepicker>
                    <button :tabindex= this.displayOriginalFileDateRange aria-labelledby="original-filing-date-search" class="text-white px-1 py-2 darkblue text-center rounded inline-block top-spacing" :class="{'button-disabled': disableSearchButton()}" @click="populateData">{{ $t('Template.Buttons.Search') }}</button>
                </div>
            </div>
            <br>
            <div v-show="displayFilter">
                <Filter
                :clearFilterOnSearch = "this.didSearch"
                :clearFilterOnTabChange = "this.useStatementFileDateRange"
                @EventSearchChanged = "filterTable"
                />
            </div>
            <div class="text-center font-bold text-2xl py-1 text-red-600"
                id="no-search-results-found" 
                v-if="!displayTable && didSearch">  
                {{ $t('Template.NoResults') }}
            </div>
            <div v-show="displayTable">
                <br>
                <Table
                :tableDTO="tableDTODisplayed" />
                <div id="data-table-pagination" class="max-w-screen-xl mx-auto py-2">
                    <button aria-label="previous" class="inline-block pr-2 text-white px-1 py-1 darkblue text-center rounded" :class="{'button-disabled': disablePreviousButton()}" @click="previousSearchPage()">{{ left }}</button>
                    <p class="inline-block pr-2 pl-2">{{ $t('Template.Pagination', { currentPage, total: Math.ceil(totalItems / itemsPerPage) }) }}</p>
                    <button aria-label="next" class="inline-block pr-2 text-white px-1 py-1 darkblue text-center rounded" :class="{'button-disabled': disableNextButton()}" @click="nextSearchPage()">{{ right }}</button>
                </div>
                <br>
                <p>{{ $t('Reg.PublicAgenciesSearch.PurchasingInstructions') }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import JediClerkRepository from '../../repositories/JediClerkRepository';
import Table from '../common/Table.vue'
import SubNavigationItem from '../common/SubNavigationItem.vue'
import $ from 'jquery'; 
import Filter from '../common/Filter.vue'
import { ref } from 'vue';
import LitepieDatepicker from 'litepie-datepicker'

export default {
    name: 'Public Agencies Search',
    components: {
        LitepieDatepicker,
        Table,
        SubNavigationItem,
        Filter
    },
    setup() {
        // Litepie Datepicker Setup
        const statementFileDateRange = ref([]);
        const originalFilingDateRange = ref([]);
        const formatter = ref({
        date: 'MM-DD-YYYY',
        month: 'MMM'
    })
      return {
        statementFileDateRange,
        originalFilingDateRange,
        formatter
      };
    },
    data: () => ({
            useStatementFileDateRange: true,
            displayStatementFileDateRange: 0,
            displayOriginalFileDateRange: -1,
            displayTable: false,
            didSearch: false,
            displayFilter: false,
            data: {},
            filterData: {},
            tableDTODisplayed: {},
            left: '<',
            right: '>',
            currentPage: 1,
            totalItems: 0,
            itemsPerPage: 20,
            tableStartIndex: 0,
            tableEndIndex: 0,
            filterUserInput:'',
    }),
    methods: {
        disableSearchButton(){
            if(this.useStatementFileDateRange){
                if(this.statementFileDateRange[0] !== undefined && this.statementFileDateRange[1] !== undefined){
                    return false;
                }
            }else{
                if(this.originalFilingDateRange[0] !== undefined && this.originalFilingDateRange[1] !== undefined){
                    return false;
                }
            }
            this.didSearch = false;
            this.displayTable = false;
            this.displayFilter = false;
            return true;
        },
        async populateData(){
            // Remove all table elements
            this.data.tableData = [];
            this.didSearch = false;
            this.tableDTODisplayed.tableHeaders = [this.$t('Reg.PublicAgenciesSearch.TableHeadings.AgencyName'), this.$t('Reg.PublicAgenciesSearch.TableHeadings.StatementFileDate'), this.$t('Reg.PublicAgenciesSearch.TableHeadings.NewFileNumber'), this.$t('Reg.PublicAgenciesSearch.TableHeadings.FilingType'), this.$t('Reg.PublicAgenciesSearch.TableHeadings.OriginalFilingNumber'), this.$t('Reg.PublicAgenciesSearch.TableHeadings.OriginalFilingDate')];
            this.tableDTODisplayed.tableData = [];
            this.resetValues();
            this.filterUserInput = '';
            // Grabs statementFile start and end dates when useStatementFileDateRange enabled or originalFiling start and end dates when useStatementFileDateRange disabled
            var startDateSF, endDateSF, startDateOF, endDateOF;
            if(this.useStatementFileDateRange){
                if(this.statementFileDateRange[0] !== undefined || this.statementFileDateRange[1] !== undefined){
                    startDateSF = this.statementFileDateRange[0].toString();
                    endDateSF = this.statementFileDateRange[1].toString();
                    // Grabs the Public Agencies that fall within Statement Filing Dates
                    await JediClerkRepository.lookUpPublicAgenciesByStatementFilingDates(startDateSF, endDateSF)
                    .then( response => {
                        this.data.tableData = response.data;
                        this.didSearch = true;
                    })
                }
            }else{
                if(this.originalFilingDateRange[0] !== undefined || this.originalFilingDateRange[1] !== undefined){
                    startDateOF = this.originalFilingDateRange[0].toString();
                    endDateOF = this.originalFilingDateRange[1].toString();
                    // Grabs the Public Agencies that fall within Original Filing Dates
                    await JediClerkRepository.lookUpPublicAgenciesByOriginalFilingDates(startDateOF, endDateOF)
                    .then( response => {
                        this.data.tableData = response.data;
                        this.didSearch = true;
                    })
                }
            }
            for (let i = 0; i < this.data.tableData.length; i++) {
                // Formats statementFileDate if not equal to null
                if(this.data.tableData[i].statementFileDate != null){
                    const statementFileDateInPst = new Date(this.data.tableData[i].statementFileDate);
                    const statementFileDate = `${statementFileDateInPst.getMonth() + 1}/${statementFileDateInPst.getDate()}/${statementFileDateInPst.getFullYear()}`;
                    this.data.tableData[i].statementFileDate = statementFileDate;
                } 
                // Formats originalFilingDate if not equal to null
                if(this.data.tableData[i].originalFilingDate != null){
                    const originalFilingDateInPst = new Date(this.data.tableData[i].originalFilingDate);
                    const originalFilingDate = `${originalFilingDateInPst.getMonth() + 1}/${originalFilingDateInPst.getDate()}/${originalFilingDateInPst.getFullYear()}`;
                    this.data.tableData[i].originalFilingDate = originalFilingDate;
                }
                if(this.data.tableData[i].filingType === 'Original'){
                    this.data.tableData[i].filingType = this.$t('Reg.PublicAgenciesSearch.FilingType.Original');
                }else if(this.data.tableData[i].filingType === 'Amendment'){
                    this.data.tableData[i].filingType = this.$t('Reg.PublicAgenciesSearch.FilingType.Amendment');
                }      
            }
            // Updates table data information
            this.totalItems = this.data.tableData.length;
            if(this.data.tableData.length === 0){
                this.displayTable = false;
                this.displayFilter = false;
            }else{
                this.displayTable = true;
                this.displayFilter = true; 
                this.filterData.tableData = this.data.tableData;
                this.filterTable(this.filterUserInput);
            }
        },
        disablePreviousButton(){
            if(this.currentPage <= 1){
                return true;
            }
            return false;
        },
        disableNextButton(){
            if (this.currentPage > ((this.totalItems - 1) / this.itemsPerPage)){
                return true;
            }
            return false;
        },
        previousSearchPage () {
            // Pagination previous button
            if(this.currentPage <= 1) return
            this.currentPage = this.currentPage - 1
            this.updateDisplayedTable();
        },
        nextSearchPage () {
            // Pagination next button
            if (this.currentPage > ((this.totalItems - 1) / this.itemsPerPage)) return
            this.currentPage = this.currentPage + 1
            this.updateDisplayedTable();
        },
        resetValues () {
            // Resets pagination variables
            this.currentPage = 1
            this.totalItems = 0
        },
        updateDisplayedTable(){
            // Selects the start and end index of the public agency table to display
            this.tableStartIndex = (this.currentPage - 1) * this.itemsPerPage;
            this.tableEndIndex = Math.min(this.currentPage * this.itemsPerPage, this.totalItems);
            this.tableDTODisplayed.tableData = this.filterData.tableData.slice(this.tableStartIndex, this.tableEndIndex);
        },
        filterTable(filterInput){
            // Provides a filtered set of the data based on filterInput
            var idx = 0;
            this.filterData.tableData = [];
            this.filterUserInput = filterInput;
            if(this.didSearch){
                if(filterInput === ''){
                    this.filterData.tableData = this.data.tableData;
                }else{
                    for (let i = 0; i < this.data.tableData.length; i++) {
                        var agencyName = this.data.tableData[i].agencyName;
                        if(agencyName !== undefined && agencyName !== null){
                            if(agencyName.includes(filterInput)){
                                this.filterData.tableData[idx] = this.data.tableData[i];
                                idx++;
                            }
                        }
                    }
                }
                this.totalItems = this.filterData.tableData.length;
                if(this.filterData.tableData.length === 0){
                    this.displayTable = false;
                }else{
                    this.displayTable = true; 
                    this.updateDisplayedTable();
                }
                this.displayFilter = true;
            }
        },
        ToggleNavigation(target) {
            // Secondary navigation
            $('.main-content-area').css('display', 'none');
            $(`#${target.id}`).css('display', 'block');
            $('.sub-navigation-item').removeClass('active');
            $(target.event.currentTarget).addClass('active');
            this.displayTable = false;
            this.didSearch = false;
            this.displayFilter = false;
            if (target.id === 'statement-filing'){
                this.useStatementFileDateRange = true;
                this.displayStatementFileDateRange = 0;
                this.displayOriginalFileDateRange = -1;
            }
            if(target.id === 'original-filing'){
                this.useStatementFileDateRange = false;
                this.displayStatementFileDateRange = -1;
                this.displayOriginalFileDateRange = 0;
            }
        },
    },
    mounted() { this.$emit('header-text', 'Registry of Public Agency Online Search'); },
    beforeUnmount() { this.$emit('header-text', null); }
}
</script>
<style>
#secondary-nav{
    background-color: #eee;
    padding-top: 3px;
    padding-bottom: 3px;
}
#secondary-nav .active {
    background-color: #fff;
    color: #494c4e !important;
    font-weight: bold;
    border-top: 3px solid #003471;
}
#main-content .main-content-area:first-child {
    display: block;
}
.base{
    display: block;
    margin: 6px;
}
.btn-blue a{
    padding: 5px 10px 5px 10px;
    background-color: #003471;
    color: #fff !important;
    text-decoration: none;
    cursor: pointer;
    border: 0;
}
#back-hyperlink {
    padding: 8px;
    margin-left: 5px;
}
#litepie > label > span :focus{outline:2px solid black}

#litepie > label > span > button{
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.top-spacing{
    margin-top:10px;
}
/*Screen size larger than 1000px*/
@media (min-width: 1000px) { 
    .base{
        display: inline-block;
        margin: 0px;
    }
    #back-hyperlink {
        float: right; 
        position: relative;
        bottom: 1px; 
        right: 3px;    
    }
    #litepie{
        width: 500px;
        margin-right: 10px;
        display: inline-block;
    }
 }
</style>
