<template>
    <div>
        <div>
            <RegisterBusinessAddress />
        </div>    
    </div>
    
</template>

<script>
import RegisterBusinessAddress from '../components/Reg/RegisterBusinessAddress.vue'
export default {
    components: {
        RegisterBusinessAddress
    }
}
</script>