const helpData =
{
    en: [
    {
        modalTitle: 'Statement Types',
        body:
        [
            {   
                title: 'Fictitious Name Statement Original Filing',
                sectionTitle: 'Business and Professions Code 17918',
                subtitle: '',
                data: 
                [
                    'No person transacting business under a fictitious business name contrary to the provisions of this chapter, or his assignee, may maintain any action upon or on account of any contract made, or transaction had, in the fictitious business name in any court of this state until the fictitious business name statement has been executed, filed, and published as required by this chapter.',
                    'For the purposes of this section, the failure to comply with subdivision (b) of Section 17917 does not constitute transacting business contrary to the provisions of this chapter.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Purpose of Filing',
                subtitle: '',
                data: [
                    'To make available to the public the identities of those persons or business entities doing business under fictitious business names.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Who Should File',
                subtitle: '',
                data: 
                [
                    'Persons doing business for profit under a fictitious name (does not include surname in company name)',
                    'Statement must be filed in county of principal place of business, and may also be filed in other counties as long as the requirements for filing in the county of principal place of business have been met.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Fees',
                subtitle: '',
                data: 
                [
                    'The first time filing fee for the Fictitious Business Name Statement is $26.00 for one business name and one registrant. The cost to file an FBN with Additional business name or registrant on the same statement and doing business at the same location will be $5.00 per name.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Requirements',
                subtitle: '',
                data: 
                [
                    'File within 40 days of business start date.',
                    'Owner must sign (corporate officer if corporation; manager or officer if limited liability company; general partner if partnership; trustee if trust)',
                    'Street address of principal place of business and county must be listed (corporation/limited liability companies must include address as set out in its articles of incorporation on file with the Secretary of State and the state of incorporation/organization)',
                    'All owners and their residence addresses must be listed',
                    'Filed copy of statement must be published once per week for four consecutive weeks in an adjudicated newspaper in Los Angeles County. The list is included on web page and at County Clerk Office.',
                    'Publication must begin within 45 days of filing'
                ]
            },
            {
                title: 'Fictitious Name Statement Refile/Renewal Filing',
                sectionTitle: '',
                subtitle: '',
                data: 
                [
                    'A fictitious business name statement expires five years from the date it is filed in the office of the County Clerk. A renewal of a fictitious business name statement must be filed prior to the date of expiration if you intend to continue doing business under that name and if there are no changes from the original. A renewal only requires completion of the application and a $26.00 fee and $5.00 for each additional business name/registrant. A renewal does not require publication.'
                ]
            },
            {
                title: 'Fictitious Name Statement Abandonment Filing',
                sectionTitle: 'This filing will abandon one or more business names from the original fictitious name statement filed.',
                subtitle: 'Requirements',
                data: 
                [
                    'Filed copy of statement must be published once per week for four consecutive weeks in an adjudicated newspaper in Los Angeles County. The list is included on web page and at County Clerk Office.',
                    'Publication must begin within 45 days of filing.'

                ]
            },
            {
                title: '',
                sectionTitle: 'Fees',
                subtitle: '',
                data: 
                [
                    'The fee for the Fictitious Business Name Abandonment filing is $26.00.'
                ]
            },
            {
                title: 'Fictitious Name Statement Withdrawal Filing',
                sectionTitle: '',
                subtitle: '',
                data: 
                [
                    'This filing will withdraw any persons specified as general partner(s) from the partnership operating under the fictitious business name. You will need to provide the name and address of the persons being withdrawn.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Requirements',
                subtitle: '',
                data: 
                [
                    'Filed copy of statement must be published once per week for four consecutive weeks in an adjudicated newspaper in Los Angeles County. The list is included on web page and at County Clerk Office.',
                    'Publication must begin within 45 days of filing.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Fees',
                subtitle: '',
                data: 
                [
                    'The fee for the Fictitious Business Name Withdrawal filing is $26.00.'
                ]
            }
        ]
    },
    {
    modalTitle: 'Additional Information - Fictitious Business Name',
    body: 
        [
            {   
                title: '',
                sectionTitle:'Business and Professions Code Section 17915', 
                subtitle: '', 
                data: ['The fictitious business name statement shall be filed with the clerk of the county in which the registrant has his or her principal place of business in this state or, if the registrant has no place of business in this state, with the Clerk of Sacramento County. Nothing in this chapter shall preclude a person from filing a fictitious business name statement in a county other than that where the principal place of business is located, as long as the requirements of this subdivision are also met.'] 
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17917',
                subtitle: 'Publication for Original, New Filings (renewal with change in facts from previous filing), or Refile',
                data: 
                [
                    'Within 45 days after a fictitious business name statement has been filed, the registrant shall cause it to be published in a newspaper of general circulation in the county where the fictitious business name statement was filed or, if there is no such newspaper in that county, in a newspaper of general circulation in an adjoining county. If the registrant does not have a place of business in this state, the notice shall be published in a newspaper of general circulation in Sacramento County. The publication must be once a week for four successive weeks and an affidavit of publication must be filed with the county clerk where the fictitious business name statement was filed within 45 days after the completion of the publication.',
                    'If a refiling is required because the prior statement has expired, the refiling need not be published, unless there has been a change in the information required in the expired statement, provided the refiling is filed within 40 days of the date the statement expired.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17922',
                subtitle: 'Abandonment of Fictitious Business Name',
                data: ['Upon ceasing to transact business in this state under a fictitious business name that was filed in the previous five years, a person who has filed a fictitious business name statement shall file a statement of abandonment of use of fictitious business name. The statement shall be executed and published in the same manner as a fictitious business name statement and shall be filed with the county clerk of the county in which the person has filed his or her fictitious business name statement.']
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17930',
                subtitle: '',
                data: ['Any person who executes, files, or publishes any statement under this chapter, knowing that such statement is false, in whole or in part, shall be guilty of a misdemeanor and upon conviction thereof shall be punished by a fine not to exceed one thousand dollars ($1,000).']
            }
        ]
    },
    {
        modalTitle: 'Additional Information - Fictitious Business Name(s)',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17913',
                subtitle: '',
                data: 
                [
                    'Insert the fictitious business name or names',
                    'Only those businesses operated at the same address and under the same ownership may be listed on one statement',
                    'If the registrant has a place of business in this state, insert the street address and county of his or her principal place of business in this state',
                    'If the registrant has no place of business in this state, insert the street address and county of his or her principal place of business outside this state and file with the Clerk of Sacramento County (B&P 17915)',
                    'Mail Box and Post Office Box Numbers are not acceptable as a business address when used alone without a street address'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17915',
                subtitle: '',
                data: 
                [
                    'The fictitious business name statement shall be filed with the clerk of the county in which the registrant has his or her principal place of business in this state or, if the registrant has no place of business in this state, with the Clerk of Sacramento County. Nothing in this chapter shall preclude a person from filing a fictitious business name statement in a county other than that where the principal place of business is located, as long as the requirements of this subdivision are also met.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17917',
                subtitle: 'Publication for Original, New Filings (renewal with change in facts from previous filing), or Refile',
                data: 
                [
                    'Within 45 days after a fictitious business name statement has been filed, the registrant shall cause it to be published in a newspaper of general circulation in the county where the fictitious business name statement was filed or, if there is no such newspaper in that county, in a newspaper of general circulation in an adjoining county. If the registrant does not have a place of business in this state, the notice shall be published in a newspaper of general circulation in Sacramento County. The publication must be once a week for four successive weeks and an affidavit of publication must be filed with the county clerk where the fictitious business name statement was filed within 45 days after the completion of the publication.',
                    'If a refiling is required because the prior statement has expired, the refiling need not be published, unless there has been a change in the information required in the expired statement, provided the refiling is filed within 40 days of the date the statement expired.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17922',
                subtitle: 'Abandonment of Fictitious Business Name',
                data: 
                [
                    'Upon ceasing to transact business in this state under a fictitious business name that was filed in the previous five years, a person who has filed a fictitious business name statement shall file a statement of abandonment of use of fictitious business name. The statement shall be executed and published in the same manner as a fictitious business name statement and shall be filed with the county clerk of the county in which the person has filed his or her fictitious business name statement.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17930',
                subtitle: '',
                data: 
                [
                    'Any person who executes, files, or publishes any statement under this chapter, knowing that such statement is false, in whole or in part, shall be guilty of a misdemeanor and upon conviction thereof shall be punished by a fine not to exceed one thousand dollars ($1,000).'
                ]
            }
        ]
    },
    {
        modalTitle: 'Additional Information - Registrant Information',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17913',
                subtitle: '',
                data: 
                [
                    'If the registrant is an individual, insert his or her full name and residence address',
                    'If the registrants is a married couple, insert the full name and residence address of both the husband and the wife',
                    'If the registrant is a general partnership, copartnership, joint venture, limited liability partnership, or unincorporated association other than a partnership, insert the full name and residence address of each general partner',
                    'If the registrant is a limited partnership, insert the full name and residence address of each general partner',
                    'If the registrant is a limited liability company, insert the name and address of the limited liability company, as set out in its articles of organization on file with the CA Secretary of State, and the state of organization',
                    'If the registrant is a trust, insert the full name and residence address of each trustee',
                    'If the registrant is a corporation, insert the name and address of the corporation, as set out in its articles of incorporation on file with the CA Secretary of State, and the state of incorporation',
                    'If the registrants are state or local registered domestic partners, insert the full name and residence address of each domestic partner'
                ]
            },
            {   
                title: '',
                sectionTitle:'Business and Professions Code Section 17915', 
                subtitle: '', 
                data: ['The fictitious business name statement shall be filed with the clerk of the county in which the registrant has his or her principal place of business in this state or, if the registrant has no place of business in this state, with the Clerk of Sacramento County. Nothing in this chapter shall preclude a person from filing a fictitious business name statement in a county other than that where the principal place of business is located, as long as the requirements of this subdivision are also met.'] 
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17917',
                subtitle: 'Publication for Original, New Filings (renewal with change in facts from previous filing), or Refile',
                data:
                [
                    'Within 45 days after a fictitious business name statement has been filed, the registrant shall cause it to be published in a newspaper of general circulation in the county where the fictitious business name statement was filed or, if there is no such newspaper in that county, in a newspaper of general circulation in an adjoining county. If the registrant does not have a place of business in this state, the notice shall be published in a newspaper of general circulation in Sacramento County. The publication must be once a week for four successive weeks and an affidavit of publication must be filed with the county clerk where the fictitious business name statement was filed within 45 days after the completion of the publication.',
                    'If a refiling is required because the prior statement has expired, the refiling need not be published, unless there has been a change in the information required in the expired statement, provided the refiling is filed within 40 days of the date the statement expired.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17922',
                subtitle: 'Abandonment of Fictitious Business Name',
                data: ['Upon ceasing to transact business in this state under a fictitious business name that was filed in the previous five years, a person who has filed a fictitious business name statement shall file a statement of abandonment of use of fictitious business name. The statement shall be executed and published in the same manner as a fictitious business name statement and shall be filed with the county clerk of the county in which the person has filed his or her fictitious business name statement.']
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17930',
                subtitle: '',
                data: ['Any person who executes, files, or publishes any statement under this chapter, knowing that such statement is false, in whole or in part, shall be guilty of a misdemeanor and upon conviction thereof shall be punished by a fine not to exceed one thousand dollars ($1,000).']
            }
        ]
    },
    {
        modalTitle: 'Additional Information - Registrant Information',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17914',
                subtitle: 'The statement shall be signed as follows',
                data: 
                [
                    'If the registrant is an individual, by the individual',
                    'If the registrants is a married couple',
                    'If the registrant is a general partnership, limited partnership, limited liability partnership, copartnership, joint venture, or unincorporated association other than a partnership, by a general partner',
                    'If the registrant is a limited liability company, by a manager or officer',
                    'If the registrant is a trust, by a trustee',
                    'If the registrant is a corporation, by an officer',
                    'If the registrant is a state or local registered domestic partnership, by one of the domestic partners'
                ]
            },
            {   
                title: '',
                sectionTitle:'Business and Professions Code Section 17915', 
                subtitle: '', 
                data: ['The fictitious business name statement shall be filed with the clerk of the county in which the registrant has his or her principal place of business in this state or, if the registrant has no place of business in this state, with the Clerk of Sacramento County. Nothing in this chapter shall preclude a person from filing a fictitious business name statement in a county other than that where the principal place of business is located, as long as the requirements of this subdivision are also met.'] 
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17917',
                subtitle: 'Publication for Original, New Filings (renewal with change in facts from previous filing), or Refile',
                data: 
                [
                    'Within 45 days after a fictitious business name statement has been filed, the registrant shall cause it to be published in a newspaper of general circulation in the county where the fictitious business name statement was filed or, if there is no such newspaper in that county, in a newspaper of general circulation in an adjoining county. If the registrant does not have a place of business in this state, the notice shall be published in a newspaper of general circulation in Sacramento County. The publication must be once a week for four successive weeks and an affidavit of publication must be filed with the county clerk where the fictitious business name statement was filed within 45 days after the completion of the publication.',
                    'If a refiling is required because the prior statement has expired, the refiling need not be published, unless there has been a change in the information required in the expired statement, provided the refiling is filed within 40 days of the date the statement expired.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17922',
                subtitle: 'Abandonment of Fictitious Business Name',
                data: ['Upon ceasing to transact business in this state under a fictitious business name that was filed in the previous five years, a person who has filed a fictitious business name statement shall file a statement of abandonment of use of fictitious business name. The statement shall be executed and published in the same manner as a fictitious business name statement and shall be filed with the county clerk of the county in which the person has filed his or her fictitious business name statement.']
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17930',
                subtitle: '',
                data: ['Any person who executes, files, or publishes any statement under this chapter, knowing that such statement is false, in whole or in part, shall be guilty of a misdemeanor and upon conviction thereof shall be punished by a fine not to exceed one thousand dollars ($1,000).']
            }
        ]
    },
    {
        modalTitle: 'Additional Information - Business Date',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17913',
                subtitle: '',
                data: 
                [
                    'Insert the date on which the registrant first commenced to transact business under the fictitious business name or names listed, if already transacting business under that name or names',
                    'Insert N/A if you have not yet commenced to transact business under the fictitious business name or names listed'
                ]
            },
            {   
                title: '',
                sectionTitle:'Business and Professions Code Section 17915', 
                subtitle: '', 
                data: ['The fictitious business name statement shall be filed with the clerk of the county in which the registrant has his or her principal place of business in this state or, if the registrant has no place of business in this state, with the Clerk of Sacramento County. Nothing in this chapter shall preclude a person from filing a fictitious business name statement in a county other than that where the principal place of business is located, as long as the requirements of this subdivision are also met.'] 
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17917',
                subtitle: 'Publication for Original, New Filings (renewal with change in facts from previous filing), or Refile',
                data: 
                [
                    'Within 45 days after a fictitious business name statement has been filed, the registrant shall cause it to be published in a newspaper of general circulation in the county where the fictitious business name statement was filed or, if there is no such newspaper in that county, in a newspaper of general circulation in an adjoining county. If the registrant does not have a place of business in this state, the notice shall be published in a newspaper of general circulation in Sacramento County. The publication must be once a week for four successive weeks and an affidavit of publication must be filed with the county clerk where the fictitious business name statement was filed within 45 days after the completion of the publication.',
                    'If a refiling is required because the prior statement has expired, the refiling need not be published, unless there has been a change in the information required in the expired statement, provided the refiling is filed within 40 days of the date the statement expired.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17922',
                subtitle: 'Abandonment of Fictitious Business Name',
                data: ['Upon ceasing to transact business in this state under a fictitious business name that was filed in the previous five years, a person who has filed a fictitious business name statement shall file a statement of abandonment of use of fictitious business name. The statement shall be executed and published in the same manner as a fictitious business name statement and shall be filed with the county clerk of the county in which the person has filed his or her fictitious business name statement.']
            },
            {
                title: '',
                sectionTitle: 'Business and Professions Code Section 17930',
                subtitle: '',
                data: ['Any person who executes, files, or publishes any statement under this chapter, knowing that such statement is false, in whole or in part, shall be guilty of a misdemeanor and upon conviction thereof shall be punished by a fine not to exceed one thousand dollars ($1,000).']
            }
        ]
    }],
    es: [
    {
        modalTitle: 'Tipos de Declaraciones',
        body:
        [
            {   
                title: 'Registración de Declaración de Nombre Ficticio Original',
                sectionTitle: 'Código de Negocios y Profesiones 17918',
                subtitle: '',
                data: 
                [
                    'Ninguna persona que realice transacciones comerciales bajo un nombre comercial ficticio contrario a las disposiciones de este capítulo, o su cesionario, podrá mantener cualquier acción sobre o en cuenta de cualquier contrato realizado o transacción realizada con el nombre comercial ficticio en cualquier tribunal de este estado hasta que la declaración de nombre comercial ficticio se ha ejecutado, archivado y publicado según lo requiere este capítulo.',
                    'Para los propósitos de esta sección, el incumplimiento de la subdivisión (b) de la Sección 17917 no constituye transacciones comerciales contrarias a las disposiciones de este capítulo.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Propósito de Registro',
                subtitle: '',
                data: [
                    'Para poner a disposición del público las identidades de aquellas personas o entidades comerciales que hacen negocios con nombres comerciales ficticios.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Quién debería registrarse',
                subtitle: '',
                data: 
                [
                    'Personas que hacen negocios con fines de lucro con un nombre ficticio (no incluye el apellido en el nombre de la empresa)',
                    'La declaración debe registrarse en el condado del lugar principal de negocios y también puede presentarse en otros condados siempre que se cumplan los requisitos para la registración en el condado del lugar principal de negocios.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Tarifa',
                subtitle: '',
                data: 
                [
                    'La tarifa de registración por primera vez de la Declaración de Nombre Comercial Ficticio es de $26.00 por un nombre comercial y un solicitante. El costo de registro de un FBN con un nombre comercial Adicional o solicitante en la misma declaración y hacer negocios en la misma ubicación será de $5.00 por nombre.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Requirements',
                subtitle: '',
                data: 
                [
                    'Registre dentro de los 40 días posteriores a la fecha de inicio comercial.',
                    'El propietario debe firmar (funcionario corporativo si es una corporación; gerente o funcionario si es una compañía de responsabilidad limitada; socio general si es una sociedad; fiduciario si es un fideicomiso)',
                    'Se debe incluir la dirección de la calle del lugar principal de negocios y el condado (las corporaciones / compañías de responsabilidad limitada deben incluir la dirección como se establece en sus artículos de incorporación en los archivos del Secretario de Estado y el estado de incorporación / organización)',
                    'Todos los propietarios y sus direcciones de residencia deben figurar',
                    'La copia archivada de la declaración debe publicarse una vez por semana durante cuatro semanas consecutivas en un periódico adjudicado en el Condado de Los Ángeles. La lista está incluida en la página web y en la Oficina del Secretario del Condado.',
                    'La publicación debe comenzar dentro de los 45 días posteriores a la presentación.'
                ]
            },
            {
                title: 'Registración de Restitución / Renovación de Declaración de Nombre Ficticio',
                sectionTitle: '',
                subtitle: '',
                data: 
                [
                    'Una declaración de nombre comercial ficticio vence cinco años después de la fecha en que se presenta en la oficina del Secretario del Condado. Se debe presentar una renovación de una declaración de nombre comercial ficticio antes de la fecha de vencimiento si tiene la intención de continuar haciendo negocios con ese nombre y si no hay cambios con respecto al original. Una renovación solo requiere completar la solicitud y una tarifa de $26.00 y $5.00 por cada nombre comercial/registrante adicional. Una renovación no requiere publicación.'
                ]
            },
            {
                title: 'Registración de Abandono de Declaración de Nombre Ficticio',
                sectionTitle: 'Esta registración abandonará uno o más nombres comerciales de la declaración de nombre ficticio original presentada.',
                subtitle: 'Requirements',
                data: 
                [
                    'La copia archivada de la declaración debe publicarse una vez por semana durante cuatro semanas consecutivas en un periódico adjudicado en el Condado de Los Ángeles. La lista está incluida en la página web y en la Oficina del Secretario del Condado.',
                    'La publicación debe comenzar dentro de los 45 días posteriores a la presentación.'
    
                ]
            },
            {
                title: '',
                sectionTitle: 'Tarifa',
                subtitle: '',
                data: 
                [
                    'La tarifa para la Registración de Abandono de Nombre Comercial Ficticio es de $26.00.'
                ]
            },
            {
                title: 'Registración de Retiro de Declaración de Nombre Ficticio',
                sectionTitle: '',
                subtitle: '',
                data: 
                [
                    'Esta registración retirará a cualquier persona especificada como socio(s) general(es) de la sociedad que opera bajo el nombre comercial ficticio. Usted deberá proporcionar el nombre y la dirección de las personas que se retiran.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Requirements',
                subtitle: '',
                data: 
                [
                    'La copia archivada de la declaración debe publicarse una vez por semana durante cuatro semanas consecutivas en un periódico adjudicado en el Condado de Los Ángeles. La lista está incluida en la página web y en la Oficina del Secretario del Condado.',
                    'La publicación debe comenzar dentro de los 45 días posteriores a la presentación.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Tarifa',
                subtitle: '',
                data: 
                [
                    'La tarifa para la registración del Retiro de Nombre Comercial Ficticio es de $26.00.'
                ]
            }
        ]
    },
    {
    modalTitle: 'Información Adicional: Nombre Comercial Ficticio',
    body: 
        [
            {   
                title: '',
                sectionTitle:'Sección 17915 del Código de Negocios y Profesiones', 
                subtitle: '', 
                data: ['La declaración de nombre comercial ficticio se presentará ante el secretario del condado en el que el registrante tiene su lugar principal de negocios en este estado o, si el registrante no tiene un lugar de negocios en este estado, ante el Secretario del Condado de Sacramento. Nada en este capítulo impedirá que una persona presente una declaración de nombre comercial ficticio en un condado que no sea el lugar donde se encuentra el lugar principal de negocios, siempre y cuando también se cumplan los requisitos de esta subdivisión.'] 
            },
            {
                title: '',
                sectionTitle: 'Sección 17917 del Código de Negocios y Profesiones',
                subtitle: 'Publicación para Registraciones Originales, Nuevas (renovación con cambio en los datos de registración anterior) o restitución',
                data: 
                [
                    'Dentro de los 45 días posteriores a la registración de una declaración de nombre comercial ficticio, el registrante deberá hacer que se publique en un periódico de circulación general en el condado donde se presentó la declaración de nombre comercial ficticio o, si no existe tal periódico en ese condado, en un periódico de circulación general en un condado vecino. Si el solicitante no tiene un lugar de trabajo en este estado, el aviso se publicará en un periódico de circulación general en el Condado de Sacramento. La publicación debe ser una vez a la semana durante cuatro semanas consecutivas y se debe presentar una declaración jurada de publicación ante el secretario del condado donde se presentó la declaración de nombre comercial ficticio dentro de los 45 días posteriores a la finalización de la publicación.',
                    'Si se requiere una nueva registración porque la declaración anterior ha expirado, no es necesario que se publique nuevamente, a menos que haya habido un cambio en la información requerida en la declaración expirada, siempre que la nueva registración se presente dentro de los 40 días posteriores a la fecha de vencimiento de la declaración.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17922 del Código de Negocios y Profesiones',
                subtitle: 'Abandono del Nombre Comercial Ficticio',
                data: ['Al dejar de realizar transacciones comerciales en este estado con un nombre comercial ficticio que se registro en los cinco años anteriores, una persona que haya registrado una declaración de nombre comercial ficticio deberá presentar una declaración de abandono del uso del nombre comercial ficticio. La declaración se ejecutará y publicará de la misma manera que una declaración de nombre comercial ficticio y se presentará ante el secretario del condado del condado en el que la persona haya presentado su declaración de nombre comercial ficticio.']
            },
            {
                title: '',
                sectionTitle: 'Sección 17930 del Código de Negocios y Profesiones',
                subtitle: '',
                data: ['Cualquier persona que ejecute, archive o publique cualquier declaración bajo este capítulo, sabiendo que dicha declaración es falsa, en todo o en parte, será culpable de un delito menor y al ser declarado culpable será castigado con una multa que no exceda los mil dólares. ($1,000).']
            }
        ]
    },
    {
        modalTitle: 'Información Adicional: Nombres Comerciales Ficticios',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Sección 17913 del Código de Negocios y Profesiones',
                subtitle: '',
                data: 
                [
                    'Ingrese el nombre o los nombres comerciales ficticios',
                    'Solo aquellas empresas que operan en la misma dirección y bajo la misma propiedad pueden aparecer en un estado de cuenta',
                    'Si el solicitante tiene un lugar de negocios en este estado, ingrese la dirección y el condado de su lugar principal de negocios en este estado.',
                    'Si el solicitante no tiene un lugar de negocios en este estado, ingrese la dirección y el condado de su lugar principal de negocios fuera de este estado y presente la solicitud ante el Secretario del Condado de Sacramento (B&P 17915)',
                    'Los Números de Buzones y Apartados Postales no son aceptables como dirección comercial cuando se usan solos sin una dirección postal.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17915 del Código de Negocios y Profesiones',
                subtitle: '',
                data: 
                [
                    'La declaración de nombre comercial ficticio se presentará ante el secretario del condado en el que el registrante tiene su lugar principal de negocios en este estado o, si el registrante no tiene un lugar de negocios en este estado, ante el Secretario del Condado de Sacramento. Nada en este capítulo impedirá que una persona presente una declaración de nombre comercial ficticio en un condado que no sea el lugar donde se encuentra el lugar principal de negocios, siempre y cuando también se cumplan los requisitos de esta subdivisión.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17917 del Código de Negocios y Profesiones',
                subtitle: 'Publicación para Registraciones Originales, Nuevas (renovación con cambio en los datos de registración anterior) o restitución',
                data: 
                [
                    'Dentro de los 45 días posteriores a la registración de una declaración de nombre comercial ficticio, el registrante deberá hacer que se publique en un periódico de circulación general en el condado donde se presentó la declaración de nombre comercial ficticio o, si no existe tal periódico en ese condado, en un periódico de circulación general en un condado vecino. Si el solicitante no tiene un lugar de trabajo en este estado, el aviso se publicará en un periódico de circulación general en el Condado de Sacramento. La publicación debe ser una vez a la semana durante cuatro semanas consecutivas y se debe presentar una declaración jurada de publicación ante el secretario del condado donde se presentó la declaración de nombre comercial ficticio dentro de los 45 días posteriores a la finalización de la publicación.',
                    'Si se requiere una nueva registración porque la declaración anterior ha expirado, no es necesario que se publique nuevamente, a menos que haya habido un cambio en la información requerida en la declaración expirada, siempre que la nueva registración se presente dentro de los 40 días posteriores a la fecha de vencimiento de la declaración.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17922 del Código de Negocios y Profesiones',
                subtitle: 'Abandono del Nombre Comercial Ficticio',
                data: 
                [
                    'Al dejar de realizar transacciones comerciales en este estado con un nombre comercial ficticio que se registro en los cinco años anteriores, una persona que haya registrado una declaración de nombre comercial ficticio deberá presentar una declaración de abandono del uso del nombre comercial ficticio. La declaración se ejecutará y publicará de la misma manera que una declaración de nombre comercial ficticio y se presentará ante el secretario del condado del condado en el que la persona haya presentado su declaración de nombre comercial ficticio.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17930 del Código de Negocios y Profesiones',
                subtitle: '',
                data: 
                [
                    'Cualquier persona que ejecute, archive o publique cualquier declaración bajo este capítulo, sabiendo que dicha declaración es falsa, en todo o en parte, será culpable de un delito menor y al ser declarado culpable será castigado con una multa que no exceda los mil dólares. ($1,000).'
                ]
            }
        ]
    },
    {
        modalTitle: 'Información Adicional: Información del Solicitante',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Sección 17913 del Código de Negocios y Profesiones',
                subtitle: '',
                data: 
                [
                    'Si el solicitante es un individuo, ingrese su nombre completo y dirección de residencia',
                    'Si los solicitantes son una pareja casada, ingrese el nombre completo y la dirección de residencia tanto del esposo como de la esposa',
                    'Si el solicitante es una sociedad general, coparticipación, empresa conjunta, sociedad de responsabilidad limitada o asociación no incorporada que no sea una sociedad, ingrese el nombre completo y la dirección de residencia de cada socio general.',
                    'Si el solicitante es una sociedad limitada, ingrese el nombre completo y la dirección de residencia de cada socio general.',
                    'Si el solicitante es una compañía de responsabilidad limitada, ingrese el nombre y la dirección de la compañía de responsabilidad limitada, como se establece en sus artículos de organización en los archivos de la Secretaría de Estado de CA, y el estado de la organización.',
                    'Si el solicitante es un fideicomiso, ingrese el nombre completo y la dirección de residencia de cada fideicomisario',
                    'Si el solicitante es una corporación, ingrese el nombre y la dirección de la corporación, como se establece en sus artículos de incorporación en los archivos de la Secretaría de Estado de CA, y el estado de incorporación',
                    'Si los solicitantes de registro son parejas domésticas registradas en el estado o local, ingrese el nombre completo y la dirección de residencia de cada pareja doméstica.'
                ]
            },
            {   
                title: '',
                sectionTitle:'Sección 17915 del Código de Negocios y Profesiones', 
                subtitle: '', 
                data: ['La declaración de nombre comercial ficticio se presentará ante el secretario del condado en el que el registrante tiene su lugar principal de negocios en este estado o, si el registrante no tiene un lugar de negocios en este estado, ante el Secretario del Condado de Sacramento. Nada en este capítulo impedirá que una persona presente una declaración de nombre comercial ficticio en un condado que no sea el lugar donde se encuentra el lugar principal de negocios, siempre y cuando también se cumplan los requisitos de esta subdivisión.'] 
            },
            {
                title: '',
                sectionTitle: 'Sección 17917 del Código de Negocios y Profesiones',
                subtitle: 'Publicación para Registraciones Originales, Nuevas (renovación con cambio en los datos de registración anterior) o restitución',
                data:
                [
                    'Dentro de los 45 días posteriores a la registración de una declaración de nombre comercial ficticio, el registrante deberá hacer que se publique en un periódico de circulación general en el condado donde se presentó la declaración de nombre comercial ficticio o, si no existe tal periódico en ese condado, en un periódico de circulación general en un condado vecino. Si el solicitante no tiene un lugar de trabajo en este estado, el aviso se publicará en un periódico de circulación general en el Condado de Sacramento. La publicación debe ser una vez a la semana durante cuatro semanas consecutivas y se debe presentar una declaración jurada de publicación ante el secretario del condado donde se presentó la declaración de nombre comercial ficticio dentro de los 45 días posteriores a la finalización de la publicación.',
                    'Si se requiere una nueva registración porque la declaración anterior ha expirado, no es necesario que se publique nuevamente, a menos que haya habido un cambio en la información requerida en la declaración expirada, siempre que la nueva registración se presente dentro de los 40 días posteriores a la fecha de vencimiento de la declaración.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17922 del Código de Negocios y Profesiones',
                subtitle: 'Abandono del Nombre Comercial Ficticio',
                data: ['Al dejar de realizar transacciones comerciales en este estado con un nombre comercial ficticio que se registro en los cinco años anteriores, una persona que haya registrado una declaración de nombre comercial ficticio deberá presentar una declaración de abandono del uso del nombre comercial ficticio. La declaración se ejecutará y publicará de la misma manera que una declaración de nombre comercial ficticio y se presentará ante el secretario del condado del condado en el que la persona haya presentado su declaración de nombre comercial ficticio.']
            },
            {
                title: '',
                sectionTitle: 'Sección 17930 del Código de Negocios y Profesiones',
                subtitle: '',
                data: ['Cualquier persona que ejecute, archive o publique cualquier declaración bajo este capítulo, sabiendo que dicha declaración es falsa, en todo o en parte, será culpable de un delito menor y al ser declarado culpable será castigado con una multa que no exceda los mil dólares. ($1,000).']
            }
        ]
    },
    {
        modalTitle: 'Información Adicional: Información del Solicitante',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Sección 17914 del Código de Negocios y Profesiones',
                subtitle: 'La declaración se firmará de la siguiente manera',
                data: 
                [
                    'Si el solicitante es un individuo, por el individuo',
                    'Si los inscritos son una pareja casada',
                    'Si el solicitante es una sociedad general, sociedad limitada, sociedad de responsabilidad limitada, coparticipación, empresa conjunta o asociación no incorporada que no sea una sociedad, por un socio general',
                    'Si el solicitante es una sociedad de responsabilidad limitada, por un gerente o funcionario',
                    'Si el solicitante es un fideicomiso, por un fiduciario',
                    'Si el solicitante es una corporación, por un oficial',
                    'Si el solicitante es una pareja doméstica registrada estatal o local, por una de las parejas domésticas'
                ]
            },
            {   
                title: '',
                sectionTitle:'Sección 17915 del Código de Negocios y Profesiones', 
                subtitle: '', 
                data: ['La declaración de nombre comercial ficticio se presentará ante el secretario del condado en el que el registrante tiene su lugar principal de negocios en este estado o, si el registrante no tiene un lugar de negocios en este estado, ante el Secretario del Condado de Sacramento. Nada en este capítulo impedirá que una persona presente una declaración de nombre comercial ficticio en un condado que no sea el lugar donde se encuentra el lugar principal de negocios, siempre y cuando también se cumplan los requisitos de esta subdivisión.'] 
            },
            {
                title: '',
                sectionTitle: 'Sección 17917 del Código de Negocios y Profesiones',
                subtitle: 'Publicación para Registraciones Originales, Nuevas (renovación con cambio en los datos de registración anterior) o restitución',
                data: 
                [
                    'Dentro de los 45 días posteriores a la registración de una declaración de nombre comercial ficticio, el registrante deberá hacer que se publique en un periódico de circulación general en el condado donde se presentó la declaración de nombre comercial ficticio o, si no existe tal periódico en ese condado, en un periódico de circulación general en un condado vecino. Si el solicitante no tiene un lugar de trabajo en este estado, el aviso se publicará en un periódico de circulación general en el Condado de Sacramento. La publicación debe ser una vez a la semana durante cuatro semanas consecutivas y se debe presentar una declaración jurada de publicación ante el secretario del condado donde se presentó la declaración de nombre comercial ficticio dentro de los 45 días posteriores a la finalización de la publicación.',
                    'Si se requiere una nueva registración porque la declaración anterior ha expirado, no es necesario que se publique nuevamente, a menos que haya habido un cambio en la información requerida en la declaración expirada, siempre que la nueva registración se presente dentro de los 40 días posteriores a la fecha de vencimiento de la declaración.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17922 del Código de Negocios y Profesiones',
                subtitle: 'Abandono del Nombre Comercial Ficticio',
                data: ['Al dejar de realizar transacciones comerciales en este estado con un nombre comercial ficticio que se registro en los cinco años anteriores, una persona que haya registrado una declaración de nombre comercial ficticio deberá presentar una declaración de abandono del uso del nombre comercial ficticio. La declaración se ejecutará y publicará de la misma manera que una declaración de nombre comercial ficticio y se presentará ante el secretario del condado del condado en el que la persona haya presentado su declaración de nombre comercial ficticio.']
            },
            {
                title: '',
                sectionTitle: 'Sección 17930 del Código de Negocios y Profesiones',
                subtitle: '',
                data: ['Cualquier persona que ejecute, archive o publique cualquier declaración bajo este capítulo, sabiendo que dicha declaración es falsa, en todo o en parte, será culpable de un delito menor y al ser declarado culpable será castigado con una multa que no exceda los mil dólares. ($1,000).']
            }
        ]
    },
    {
        modalTitle: 'Información Adicional - Fecha Comercial',
        body: 
        [
            {
                title: '',
                sectionTitle: 'Sección 17913 del Código de Negocios y Profesiones',
                subtitle: '',
                data: 
                [
                    'Ingrese la fecha en la que el solicitante comenzó a realizar transacciones comerciales con el nombre o los nombres comerciales ficticios enumerados, si ya realiza transacciones comerciales con ese nombre o nombres.',
                    'Ingrese N/A si aún no ha comenzado a realizar transacciones comerciales con el nombre comercial ficticio o los nombres enumerados'
                ]
            },
            {   
                title: '',
                sectionTitle:'Sección 17915 del Código de Negocios y Profesiones', 
                subtitle: '', 
                data: ['La declaración de nombre comercial ficticio se presentará ante el secretario del condado en el que el registrante tiene su lugar principal de negocios en este estado o, si el registrante no tiene un lugar de negocios en este estado, ante el Secretario del Condado de Sacramento. Nada en este capítulo impedirá que una persona presente una declaración de nombre comercial ficticio en un condado que no sea el lugar donde se encuentra el lugar principal de negocios, siempre y cuando también se cumplan los requisitos de esta subdivisión.'] 
            },
            {
                title: '',
                sectionTitle: 'Sección 17917 del Código de Negocios y Profesiones',
                subtitle: 'Publicación para Registraciones Originales, Nuevas (renovación con cambio en los datos de registración anterior) o restitución',
                data: 
                [
                    'Dentro de los 45 días posteriores a la registración de una declaración de nombre comercial ficticio, el registrante deberá hacer que se publique en un periódico de circulación general en el condado donde se presentó la declaración de nombre comercial ficticio o, si no existe tal periódico en ese condado, en un periódico de circulación general en un condado vecino. Si el solicitante no tiene un lugar de trabajo en este estado, el aviso se publicará en un periódico de circulación general en el Condado de Sacramento. La publicación debe ser una vez a la semana durante cuatro semanas consecutivas y se debe presentar una declaración jurada de publicación ante el secretario del condado donde se presentó la declaración de nombre comercial ficticio dentro de los 45 días posteriores a la finalización de la publicación.',
                    'Si se requiere una nueva registración porque la declaración anterior ha expirado, no es necesario que se publique nuevamente, a menos que haya habido un cambio en la información requerida en la declaración expirada, siempre que la nueva registración se presente dentro de los 40 días posteriores a la fecha de vencimiento de la declaración.'
                ]
            },
            {
                title: '',
                sectionTitle: 'Sección 17922 del Código de Negocios y Profesiones',
                subtitle: 'Abandono del Nombre Comercial Ficticio',
                data: ['Al dejar de realizar transacciones comerciales en este estado con un nombre comercial ficticio que se registro en los cinco años anteriores, una persona que haya registrado una declaración de nombre comercial ficticio deberá presentar una declaración de abandono del uso del nombre comercial ficticio. La declaración se ejecutará y publicará de la misma manera que una declaración de nombre comercial ficticio y se presentará ante el secretario del condado del condado en el que la persona haya presentado su declaración de nombre comercial ficticio.']
            },
            {
                title: '',
                sectionTitle: 'Sección 17930 del Código de Negocios y Profesiones',
                subtitle: '',
                data: ['Cualquier persona que ejecute, archive o publique cualquier declaración bajo este capítulo, sabiendo que dicha declaración es falsa, en todo o en parte, será culpable de un delito menor y al ser declarado culpable será castigado con una multa que no exceda los mil dólares. ($1,000).']
            }
        ]
    }]
}

module.exports = {
    helpData
}