<template>
    <div class="m_pad" role="main">
        <h1 class="pt-4 mx-auto text-left text-4xl font-bold max-w-screen-xl">{{ $t('Home.FilingApproach.Heading') }}</h1>
        <div class="text-xl py-2 mx-auto max-w-screen-xl text-left" id="filing-approach-info-block">{{ $t('Home.FilingApproach.Intro') }}</div>
        <div class="mx-auto py-2 max-w-screen-xl">
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="online-approach-button"
                    aria-label="online-approach-button"
                    @click="filingApproachSelection(FilingApproach.Online)">
                    <div class="button-header">{{ $t(`Home.FilingApproach.OnlineApproachButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingApproach.OnlineApproachButton.Description`) }}</div>
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="in-person-approach-button"
                    aria-label="in-person-approach-button"
                    @click="filingApproachSelection(FilingApproach.InPerson)">
                    <div class="button-header">{{ $t(`Home.FilingApproach.InPersonApproachButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingApproach.InPersonApproachButton.Description`) }}</div>
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="mail-in-approach-button"
                    aria-label="mail-in-approach-button"
                    @click="filingApproachSelection(FilingApproach.MailIn)">
                    <div class="button-header">{{ $t(`Home.FilingApproach.MailInApproachButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingApproach.MailInApproachButton.Description`) }}</div>
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="third-party-approach-button"
                    aria-label="third-party-approach-button"
                    @click="filingApproachSelection(FilingApproach.ThirdParty)">
                    <div class="button-header">{{ $t(`Home.FilingApproach.ThirdPartyApproachButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingApproach.ThirdPartyApproachButton.Description`) }}</div>
                </div>    
            </div>          
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showHelpButton="true" 
                @openHelp="openHelp()" 
                @previousPage="previousPage()"/>
            <Help 
                :page="0" 
                v-show="isHelpVisible" 
                @closeHelp="closeHelp()"/>
        </div>          
    </div>
</template>

<script>
import { AppHasData, FilingApproach } from '../../assets/constants.js'
import NavigationControls from '../common/NavigationControls.vue'
import Help from '../common/Help.vue'
export default {
    name: 'FilingApproach',
    components: {
        NavigationControls,
        Help
    },
    data: () => ({
        FilingApproach: FilingApproach,
        fbnFiling: {},
        isHelpVisible: false
    }),
    methods: {
        filingApproachSelection (approach) {
            this.fbnFiling = this.$store.getters.getFbnFiling
            this.fbnFiling.approach = approach
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
            this.$store.dispatch('setFilingApproach', approach)
            this.$store.dispatch('setSubheader', approach)
            this.$router.push('fbnfilingworkflow')
        },
        openHelp () {
            this.isHelpVisible = true
        },
        closeHelp () {
            this.isHelpVisible = false
        },
        previousPage () {
            this.$router.push('/')
        }
    },
    beforeMount () {
        AppHasData(this.$store.getters.getAppHasData, this)
        window.scrollTo(0,0)
    }
}
</script>