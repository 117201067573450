<template>
    <div class="w-full text-left py-2" id="fbn-search-results-table">
        <div class="max-w-screen-xl py-4 mx-auto">
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" id="data-table">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200 table-auto">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th v-for="(tableHeader, tableHeaderIndex) in tableDTO.tableHeaders" 
                                        :key="tableHeaderIndex" 
                                        :id="'table-heading-' + tableHeaderIndex" 
                                        scope="col" 
                                        role="columnheader" 
                                        class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                        {{ tableHeader }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200" id="table-data">
                                <template v-for="(dataRow, dataRowIndex) in tableDTO.tableData" :key="dataRowIndex" :class="'data-row-' + dataRowIndex">
                                    <tr>
                                        <td v-for="(rowItem, rowItemIndex) in Object.values(dataRow)" 
                                            :key="rowItemIndex"
                                            :id="'table-row-' + dataRowIndex" 
                                            class="text-base text-gray-900 px-6 py-4 whitespace-normal">
                                            {{ rowItem }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        tableDTO: Object
    }
}
</script>