<template>
    <div class="m_pad" role="main">
        <!-- Page Headings and breadcrumb -->
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" id="fbn-search-page-header">{{ $t('Reg.Search.Heading') }}</h1>
        <Breadcrumb v-if="!standAlone"/>
        <div class="mx-auto pt-4 text-left text-2xl font-bold max-w-screen-xl">{{ $t('Reg.Search.Intro') }}</div>
        <!-- Error Messages -->
        <ErrorHandler
            ref="errorHandler" 
            @fbnSearchValidation="fbnSearchValidation"
            @fbnSearchSubmitValidation="fbnSearchSubmitValidation"
            @receivePageErrorObject="receivePageErrorObject" />
        <div class="max-w-screen-xl mx-auto">
            <div class="text-center py-2">
                <!-- Search field and Submit Button -->
                <input
                    type="text" 
                    name="business-name-search" 
                    id="business-name-search" 
                    class="appearance-none w-11/12 md:w-11/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12" 
                    v-bind:class="[ PageErrors.FbnLookupError ? 'outline-red' : 'outline-none' ]" 
                    v-model="fbnName" 
                    :placeholder="$t('Template.UserInput.FBNFieldPlaceHolder')"/>
                <div class="w-1/12 pt-2 pb-2 pl-2 inline-block">
                    <button class="text-white px-2 py-2 font-semibold darkblue text-center rounded inline-block" 
                        id="fbn-lookup-button"
                        aria-label="fbn-lookup-button"
                        @click="fbnLookup(fbnName)">
                        {{ $t('Template.Buttons.Search') }}
                    </button>
                </div>
                <!-- Display fbn search results in a table if the users fbn matches names in the db -->
                <div class="text-center font-bold text-2xl py-1 text-red-600"
                    id="no-search-results-found" 
                    v-if="!displayTable && didSearch">    
                    {{ $t('Template.NoResults') }}
                </div>
                <div v-show="displayTable">
                    <Table
                        ref="table"
                        :tableDTO="tableDTO" />
                    <Pagination
                        ref="pagination"
                        :searchString="fbnName"
                        :itemsPerPage="itemsPerPage"
                        :currentPage="1"
                        @apiSearch="apiSearch"
                        @resetValues="resetValues"
                        @setTableDTO="setTableDTO"
                        @hideTable="hideTable" />
                </div>              
            </div>
        </div>         
        <div class="max-w-screen-xl mx-auto py-2" id="search-disclaimer-and-agreement-checkbox">
            <!-- Search disclaimer text -->
            <div class="text-lg" v-html="$t('Reg.Search.Copy.Line1')"></div>
            <br>
            <div class="text-lg" v-html="$t('Reg.Search.Copy.Line2')"></div>
            <br>
            <!-- Agreement Checkbox -->
            <Checkbox 
                v-if="!standAlone"
                ref="checkbox"
                :checkboxLabel="$t('Reg.Search.UserInput.Checkbox')"
                :checkboxError="PageErrors.CheckboxError"
                @onCheckboxSelectedHandler="onCheckboxSelectedHandler" />
        </div>
        <br>
        <!-- Page Navigation -->
        <div class="mx-auto max-w-screen-xl py-2 main-controls" v-if="!standAlone">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true" 
                @openHelp="openHelp" 
                @previousPage="previousPage"
                @proceed="proceed" />
            <Help 
                :page="1" 
                v-show="isHelpVisible" 
                @closeHelp="closeHelp" />
        </div>       
    </div>
</template>

<script>
import Breadcrumb from '../common/Breadcrumb.vue'
import Checkbox from '../common/Checkbox.vue'
import ErrorHandler from '../common/ErrorHandler.vue'
import Help from '../common/Help.vue'
import NavigationControls from '../common/NavigationControls.vue'
import Pagination from '../common/Pagination.vue'
import Table from '../common/Table.vue'
import { AppHasData, FilingApproach } from '../../assets/constants'

export default {
    components: {
        Breadcrumb,
        Checkbox,
        ErrorHandler,
        Help,
        NavigationControls,
        Pagination,
        Table
    },
    props: { standAlone: Boolean },
    data: () => ({
        fbnName: '',
        fbnLookupError: false,
        checkbox: false,
        checkboxError: false,
        isHelpVisible: false,
        isLoading: true,
        tableDTO: {},
        itemsPerPage: 20,
        displayTable: false,
        didSearch: false,
        PageErrors: {
            FbnLookupError: false,
            CheckboxError: false
        }
    }),
    methods:{
        // #region Page Actions
        fbnLookup (fbnName) {
            if (!this.$refs.errorHandler.fbnSearchValidation(fbnName)) {
                this.$refs.pagination.resetValues()
                this.$refs.pagination.apiSearch()
            }
        },
        previousPage () {
            this.$router.push('fbnfilingworkflow')
        },
        proceed () {
            const payload = {
                fbnName: this.fbnName,
                checkbox: this.checkbox
            }
            this.$refs.errorHandler.fbnSearchSubmitValidation(payload)
            if(!this.PageErrors.FbnLookupError && !this.PageErrors.CheckboxError) {
                if (this.$store.getters.getFilingApproach === FilingApproach.Online) {
                    this.$router.push('fbnvitalchekinfo')
                } else {
                    this.$router.push('addregisteredowner')
                }
            }    
        },
        // #endregion

        // #region Helper Methods
        onCheckboxSelectedHandler (checkboxSelected) {
            this.checkbox = checkboxSelected
            this.$refs.errorHandler.checkboxValidation(this.checkbox)
        },
        openHelp () {
            this.isHelpVisible = true
        },
        closeHelp () {
            this.isHelpVisible = false
        },
        receivePageErrorObject (errors) {
            this.PageErrors.FbnLookupError = errors.FbnSearchPage.FbnLookupError.Error
            this.PageErrors.CheckboxError = errors.SharedPageErrors.CheckboxError.Error
        },
        setTableDTO (searchResults) {
            this.tableDTO = searchResults
            this.displayTable = true
        },
        hideTable (hideTable) {
            this.displayTable = hideTable
            this.didSearch = true
        }
        // #endregion
    },
    beforeMount () {
        if (!this.standAlone) {
            AppHasData(this.$store.getters.getAppHasData, this)
        }
        window.scrollTo(0,0)
    }
}
</script>