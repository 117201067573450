<template>
    <div>
        <div>
            <Lookup />
        </div>    
    </div>
    
</template>

<script>
import Lookup from '../components/Reg/Lookup.vue'
export default {
    components: {
        Lookup
    }
}
</script>