<template>
    <div>
        <div>
            <Review />
        </div>    
    </div>
    
</template>

<script>
import Review from '../components/Reg/Review.vue'
export default {
    components: {
        Review
    }
}
</script>