<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Template.AdditionalInformation') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto" v-if="fbnFiling.workflow !== 'Provide Proof of Publication'">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto" v-else>{{ fbnFiling.workflow }}</div>
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="max-w-screen-xl mx-auto">
            <div class="text-center py-2">
                <div class="text-1xl md:w-4/12 w-full py-2 mt-4 md:inline-block block text-left" id="first-name-label">{{ $t('Template.FirstName') }}</div>
                <input
                    aria-label="first-name-field"
                    aria-labelledby="first-name-label" 
                    maxlength="50"
                    type="text" 
                    name="first-name" 
                    id="first-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.firstName ? 'outlineRed': 'outline-none' ]" 
                    :placeholder="$t('Template.Optional')" 
                    v-model="requestorInformationPage.firstName"/>
                <div class="text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="middle-name-label">{{ $t('Template.MiddleName') }}</div>
                <input
                    aria-label="middle-name-field"
                    aria-labelledby="middle-name-label"
                    maxlength="50"
                    type="text" 
                    name="middle-name" 
                    id="middle-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.middleName ? 'outlineRed': 'outline-none' ]" 
                    :placeholder="$t('Template.Optional')" 
                    v-model="requestorInformationPage.middleName"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="last-or-company-name-label">{{ $t('Reg.AdditionalInformation.UserInput.Labels.LastNameOrCompanyName') }}</div>
                <input
                    aria-label="last-or-company-name-field"
                    aria-labelledby="last-or-company-name-label" 
                    maxlength="400"
                    type="text" 
                    name="last-name" 
                    id="last-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.lastName ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.lastName"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="address-label">{{ $t('Template.Address') }}</div>
                <input
                    aria-label="address-field"
                    aria-labelledby="address-label"    
                    maxlength="40"
                    type="text" 
                    name="address" 
                    id="address" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.address ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.address"/>
                <div id="vc-address-info">
                    <div class="text-base md:w-4/12 py-2 inline-block text-left w-full" id="address-information-label">{{ $t('Template.AddressInformation') }}</div>
                </div>
                <div class="text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="additional-address-label">{{ $t('Template.AddressAdditional') }}</div>
                <input
                    aria-label="additional-address-field"
                    aria-labelledby="additional-address-label" 
                    maxlength="40"
                    type="text" 
                    name="additional-address" 
                    id="additional-address" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.addAddress ? 'outlineRed': 'outline-none' ]" 
                    :placeholder="$t('Template.Optional')" 
                    v-model="requestorInformationPage.addAddress"/>
                <div id="vc-additional-address-info">
                    <div class="text-base md:w-4/12 py-2 inline-block text-left w-full" id="vc-additional-address-information-label">{{ $t('Template.AddressAdditionalInformation') }}</div>
                </div>
                <div for="city" class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="city-label">{{ $t('Template.City') }}</div>
                <input 
                    aria-label="city-field"
                    aria-labelledby="city-label"
                    maxlength="30"
                    list="cities" 
                    type="text" 
                    min="0" 
                    name="city" 
                    id="city" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.city ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.city"/>
                <datalist id="cities">
                    <option v-for="city in cities" v-bind:value="city" :key="city"/>
                </datalist>   
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="state-label">{{ $t('Template.State') }}</div>
                <select
                    aria-label="state-select"
                    aria-labelledby="state-label" 
                    maxlength="30"
                    class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                    name="state" 
                    id="state" 
                    v-bind:class="[ showInputErrorWarning.state ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.state">
                    <option v-for="state in states" :key="state" :selected="requestorInformationPage.state === state">
                        {{ state }}
                    </option>
                </select>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="zipcode-label">{{ $t('Template.ZipCode') }}</div>
                <input
                    aria-label="zipcode-field"
                    aria-labelledby="zipcode-label" 
                    maxlength="10"
                    type="text" 
                    name="zip-code" 
                    id="zip-code" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.zipCode ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.zipCode"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="country-label">{{ $t('Template.Country') }}</div>
                <select
                    aria-label="country-select"
                    aria-labelledby="country-label" 
                    maxlength="30"
                    class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                    name="country" 
                    id="country" v-bind:class="[ showInputErrorWarning.country ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.country">
                    <option v-for="country in countries" :key="country" :selected="requestorInformationPage.country === country">
                        {{ country }}
                    </option>
                </select>
                <div v-show="fbnFiling.approach !== filingApproach.Online" class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="email-label">{{ $t('Template.Email') }}</div>
                <input 
                    ref="email"
                    v-show="fbnFiling.approach !== filingApproach.Online"
                    aria-label="email-field"
                    aria-labelledby="email-label"
                    maxlength="100"
                    type="email" 
                    name="email" 
                    id="email" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.email ? 'outlineRed': 'outline-none' ]" 
                    v-model="requestorInformationPage.email"/>
                <div v-show="fbnFiling.approach !== filingApproach.Online" class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="confirm-email-label">{{ $t('Reg.AdditionalInformation.UserInput.Labels.ConfirmEmail') }}</div>
                <input
                    ref="cemail"
                    v-show="fbnFiling.approach !== filingApproach.Online"
                    aria-label="confirm-email-field"
                    aria-labelledby="confirm-email-label" 
                    maxlength="100"
                    type="confirm-email" 
                    name="confirm-email" 
                    id="confirm-email" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.confirmEmail ? 'outlineRed': 'outline-none' ]" 
                    v-model="confirmEmail"/>                        
                <div 
                    v-show="fbnFiling.approach !== filingApproach.Online"
                    class="text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full"
                    id="phone-label">{{ $t('Template.Phone') }}
                </div>
                <input
                    ref="phone"
                    v-show="fbnFiling.approach !== filingApproach.Online"
                    aria-label="phone-field"
                    aria-labelledby="phone-label" 
                    name="phone-number" 
                    id="phone-number" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.phone ? 'outlineRed': 'outline-none' ]" 
                    @keydown="onPhoneNumberKeyDownHandler($event)"
                    @keyup="onPhoneNumberKeyUpHandler($event)"
                    maxlength="14"
                    :placeholder="$t('Template.Optional')" 
                    v-model="requestorInformationPage.phone"/>
                <br>                     
            </div>
        </div>
        <div v-if="fbnFiling.workflow !== filingWorkflow.ProvideProofOfPublication">
            <div class="mx-auto py-4 text-left text-xl font-bold max-w-screen-xl">{{ $t('Template.AdditionalInformation') }}</div>
            <div class="max-w-screen-xl mx-auto">
                <div class="text-center py-2 items-end">
                    <div class="required text-xl md:w-6/12 py-2 inline-block text-left w-full" id="commence-date-label">{{ $t('Reg.AdditionalInformation.UserInput.Labels.TransactionCommenceDate') }}</div>
                    <input
                        aria-label="commence-date-field"
                        aria-labelledby="commence-date-label"
                        type="text" 
                        maxlength="7" 
                        name="date-registrant-commenced" 
                        id="date-registrant-commenced" 
                        v-bind:class="[ showInputErrorWarning.dateRegistrantCommenced ? `outlineRed ${inputBoxStyle} `: `outline-none ${inputBoxStyle}` ]"
                        placeholder="MM/YYYY" v-model="requestorInformationPage.dateRegistrantCommenced"/>
                </div>
                <div v-if="fbnFiling.approach !== filingApproach.Online" class="text-center py-2 items-end">
                    <div class="required text-xl md:w-6/12 py-2 inline-block text-left w-full" id="number-of-copies-label">{{ $t('Reg.AdditionalInformation.UserInput.Labels.NumberOfCopies') }}</div>
                    <input
                        aria-label="number-of-copies-field"
                        aria-labelledby="number-of-copies-label" 
                        type="number" 
                        min="1" 
                        max="99" 
                        name="number-of-copies" 
                        id="number-of-copies" 
                        v-bind:class="[ showInputErrorWarning.additionalCopies ? `outlineRed ${inputBoxStyle} `: `outline-none ${inputBoxStyle}` ]"
                        placeholder="1-99" 
                        v-model="requestorInformationPage.additionalCopies"
			            @keydown="checkForNumber"/>
                </div>         
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="showProceedButton"
                :showSubmitButton="showSubmitButton"
                :showHelpButton="true"
                :isSubmitDisabled="isSubmitButtonDisabled"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="5"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>
        <FbnFiling 
            ref="fbnFiling"
            v-show="false" />
    </div>
</template>

<script>
import { states, countries, cities, AppHasData, FilingActionType, FilingApproach, FilingWorkflow } from '../../assets/constants'
import ClerkRepository from '../../repositories/ClerkRepository';
import { isValidAddress, isValidBusinessName } from '../../assets/constant/validation';
import { EnableRecaptcha } from '../../assets/config';
import Help from '../common/Help.vue';
import FbnFiling from '../common/FbnFiling.vue'
import NavigationControls from '../common/NavigationControls.vue'
export default {
    name: 'Additional Information',
    components: {
        FbnFiling,
        Help,
        NavigationControls
    },
    computed: {
        isSubmitButtonDisabled () {
            return this.disableSubmitButton;
        }
    },
    data: () => ({
        enableRecaptcha: EnableRecaptcha,
        disableSubmitButton: false,
        states: states,
        countries: countries,
        cities: cities,
        confirmEmail: '',
        options: [
            'Corporation', 'General Partnership', 'Joint Venture', 'Limited Liability Company (LLC)', 'Limited Liability Partnership', 
            'Limited Partnership', 'Married Couple', 'State or Local Registered Domestic Partnership',
            'Trust', 'Individual', 'Unincorporated Association', 'Co-partners'
        ],
        errors: [],
        inputBoxStyle: 'appearance-none md:w-6/12 mx-auto border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full',
        showInputErrorWarning: {
            firstName: false,
            middleName: false,
            lastName: false,
            address: false,
            addAddress: false,
            city: false,
            state: false,
            zipCode: false,
            country: false,
            email: false,
            phone: false,
            dateRegistrantCommenced: false,
            additionalCopies: false,
            confirmEmail: false
        },
        requestorInformationPage: { 
            firstName: '',
            middleName: '',
            lastName: '',
            address: '',
            addAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            email: '',
            phone: '',
            dateRegistrantCommenced: '',
            additionalCopies: 1,   
        },
        nav: '',
        isHelpVisible: false,
        fbnFiling: {},
        showProceedButton: false,
        showSubmitButton: false,
        filingApproach: FilingApproach,
        filingWorkflow: FilingWorkflow
    }),
    methods: {
         submitRecaptcha(response) {
            ClerkRepository.validate(response)
                .then( response => {
                    if (response.data.success === true) {
                        this.passRecaptcha = true;
                    }
                })
                .catch( error => console.log('Recaptch: ', error))
        },
        onPhoneNumberKeyUpHandler(event) {
            if (this.requestorInformationPage.phone.length > 0 && this.requestorInformationPage.phone[0] !== '(') {
                this.requestorInformationPage.phone = '(' + this.requestorInformationPage.phone;
            }
            if (this.requestorInformationPage.phone.length > 4 && this.requestorInformationPage.phone[4] !== ')') {
                this.requestorInformationPage.phone = this.requestorInformationPage.phone.substring(0, 4) + ') ' + this.requestorInformationPage.phone.substring(4, this.requestorInformationPage.phone.length);
            }
            if (this.requestorInformationPage.phone.length > 9 && this.requestorInformationPage.phone[9] !== '-') {
                this.requestorInformationPage.phone = this.requestorInformationPage.phone.substring(0, 9) + '-' + this.requestorInformationPage.phone.substring(9, this.requestorInformationPage.phone.length);
            }
        },
        onPhoneNumberKeyDownHandler(event) {
            if (event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'Tab') {
                const isNumber = /^[0-9]$/i.test(event.key);
                if (event.repeat || this.requestorInformationPage.phone.length > 14 || !isNumber) {
                    event.preventDefault();
                    return;
                };
                if (this.requestorInformationPage.phone.length > 1 && this.requestorInformationPage.phone[0] !== '(') {
                    this.requestorInformationPage.phone = '(' + this.requestorInformationPage.phone;
                }
            }
        },
        validateField(type, input) {
            let re = null;
            switch (type) {
                case 'country': 
                    re = /^[A-Za-zÅéçô0-9 -'&#,]*[A-Za-z0-9 )('-][A-Za-z0-9 ()'#&.]*$/;
                    break;
                case 'zipCode': 
                    re = /^\d{5}(?:[-\s]\d{4})?$/;
                    break;
                case 'email': 
                    re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    break;
                case 'phone': 
                    re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
                    break;
                case 'dateRegistrantCommenced': {
                        re = /^(0[123456789]|10|11|12)([/])([1-2][0-9][0-9][0-9])$/;
                        return re.test(input) || input.trim().toUpperCase() === 'N/A';
                    }
                case 'additionalCopies': {
                        if (typeof input === 'string' && input === '1') return true;
                        if (typeof input === 'number' && input === 1) return true;
                        const removedLeadingZeros = input.replace(/^0+/, '');
                        const re = /^[1-9]\d*$/;
                        return re.test(removedLeadingZeros);
                    }
                case 'lastName': 
                    re = /^[A-Za-z0-9 '"&$#,!Ñ(:)?~.\\·@;’|=\][_•/¥ŸŒ°{}%`²³®+]*[A-Za-z0-9 '$"~!·()+Ñ’Ÿ;®`:@¥_|²³°\\/\][{}Œ%•=?-][A-Za-z0-9 '$"#~&!\\.:()\][@_`Ÿ{²³,°}•®’Ñ%·¥;=|/Œ?+]*$/;
                    break;
                default:
                    re = /^[A-Za-z0-9 -'&#,]*[A-Za-z0-9 '-][A-Za-z0-9 '#&.]*$/;
                    break;
            }
            return re.test(input);
        },
        variableNameToLabel(name) {
            const result = name.split(/(?=[A-Z])/).join(' ');        
            return result.charAt(0).toUpperCase() + result.slice(1);
        },
        resetInputErrorWarnings() {
            const copyShowInputErrorWarning = {...this.showInputErrorWarning};
            for (const [k] of Object.entries(copyShowInputErrorWarning)) {
                copyShowInputErrorWarning[k] = false;
            }
            this.showInputErrorWarning = copyShowInputErrorWarning;
        },
        doesPageContainErrors() {
            let optionalInput = [];
            if (this.fbnFiling.approach === FilingApproach.Online) {
                optionalInput = ['firstName','middleName', 'addAddress', 'email', 'phone']
            } else {
                optionalInput = ['firstName', 'middleName', 'addAddress', 'phone'];
            }
            if (this.fbnFiling.workflow === 'Provide Proof of Publication') {
                optionalInput.push('dateRegistrantCommenced');
            }
            const currentErrors = [];
            this.resetInputErrorWarnings();
            var showErrors = {...this.showInputErrorWarning};
            for (const [k, v] of Object.entries(this.requestorInformationPage)) {
                if (typeof v === 'string') {
                    var value = null;
                    if (v != null){
                        value = v.trim();
                    } else {
                        value = '';
                    }
                    if (value.length === 0) {
                        if (!optionalInput.includes(k)) {
                            currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorFieldRequired', { field: this.variableNameToLabel(k) }));
                            showErrors[k] = true;
                        }
                    }
                    if (value.length !== 0) {
                        switch (k) {
                            case 'email': 
                                if (this.fbnFiling.approach !== FilingApproach.Online) {
                                    if (!this.validateField(k, value)) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorFieldInvalid', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                    }
                                    if (this.confirmEmail !== value) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorFieldsDoNotMatch', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                        showErrors.confirmEmail = true;
                                    }
                                }
                                break;
                            case 'address':
                            case 'addAddress': 
                                if (!isValidAddress(value)) {
                                    currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.variableNameToLabel(k) }));
                                    showErrors[k] = true;
                                }
                                break;
                            case 'phone': 
                                if (this.fbnFiling.approach !== FilingApproach.Online) {
                                    if (!this.validateField(k, value)) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorPhoneInvalid', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                    }
                                }
                                break;
                            case 'zipCode':
                                if (!this.validateField(k, value)) {
                                    currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorFieldInvalid', { field: this.variableNameToLabel(k) }));
                                    showErrors[k] = true;
                                }    
                                break;
                            case 'country':
                                if (!this.validateField(k, value)) {
                                    currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorFieldInvalidWithDefinition', { field: this.variableNameToLabel(k) }))
                                    showErrors[k] = true;
                                }    
                                break;
                            case 'lastName': 
                                if (!isValidBusinessName(value)) {
                                    currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.variableNameToLabel(k) }));
                                    showErrors[k] = true;
                                }
                                break;
                            default: 
                                if (!this.validateField(k, value)) {
                                    currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.ReqeustorFieldInvalid', { field: this.variableNameToLabel(k) }));
                                    showErrors[k] = true;
                                }
                        }
                    }
                }
            }
            if (this.fbnFiling.workflow !== 'Provide Proof of Publication') {
                for (const [k, v] of Object.entries(this.requestorInformationPage)) {
                    if (typeof v === 'string') {
                        if (v != null){
                        value = v.trim();
                        } else {
                        value = '';
                        }
                        if (!optionalInput.includes(k) && value.length === 0) {
                            currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.RequestorFieldRequired', { field: this.variableNameToLabel(k) }));
                            showErrors[k] = true;
                        }
                        if (value.length !== 0) {
                            switch(k) {
                                case 'dateRegistrantCommenced': 
                                    if (!this.validateField(k, value)) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.InvalidDateFormat', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                    }
                                    var commencedMonth = value.split('/')[0];
                                    var commencedYear = value.split('/')[1];
                                    if (commencedYear !== null && commencedYear < 1753) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.InvalidDateYear', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                    }
                                    var today = new Date();
                                    var commenceDate = new Date(commencedYear, (+commencedMonth - 1));
                                    if (commencedMonth >= 1 && commencedMonth <= 12 && commenceDate > today) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.InvalidDateFuture', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                    }
                                    break;
                                case 'additionalCopies': 
                                    if (!this.validateField(k, value) || (parseInt(value) < 1 || parseInt(value) > 99)) {
                                        currentErrors.push(this.$t('Template.ErrorHandling.NumberRange', {
                                            field: this.variableNameToLabel(k),
                                            min: 1,
                                            max: 99
                                        }));
                                        showErrors[k] = true;
                                    }
                                    break;
                                default: 
                                    if (!this.validateField(k, value)) {
                                        currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.InvalidCharacters', { field: this.variableNameToLabel(k) }));
                                        showErrors[k] = true;
                                    }
                            }               
                        }   
                    }
                    if (typeof v === 'number') {
                        const value = v;
                        if (!optionalInput.includes(k) && value.length === 0) {
                            currentErrors.push(this.$t('Reg.AdditionalInformation.UserInput.Errors.AdditionalInformationFieldRequired', { field: this.variableNameToLabel(k) }));
                            showErrors[k] = true;
                        }
                        if (k === 'additionalCopies' && value < 1) {
                            currentErrors.push(this.$t('Template.ErrorHandling.NumberRange', {
                                field: this.variableNameToLabel(k),
                                min: 1,
                                max: 99
                            }));
                            showErrors[k] = true;
                        } else if (k === 'additionalCopies' && value > 99) {
                            currentErrors.push(this.$t('Template.ErrorHandling.NumberRange', {
                                field: this.variableNameToLabel(k),
                                min: 1,
                                max: 99
                            }));
                            showErrors[k] = true;
                        }
                    }            
                }    
            }
            
            this.showInputErrorWarning = showErrors;
            this.errors = currentErrors;
            return currentErrors.length > 0;
        },
        proceed () {
            this.disableSubmitButton = true;
            if (this.doesPageContainErrors()) {
                this.disableSubmitButton = false;
                return;
            };
            switch(this.fbnFiling.workflow){
                case 'Fictitious Business Name Statement':
                    if(this.fbnFiling.approach === FilingApproach.MailIn || this.fbnFiling.approach === FilingApproach.ThirdParty) {
                        if(this.requestorInformationPage.additionalCopies !== 
                            this.fbnFiling.RequestorInformationPage.additionalCopies) {
                            this.$refs.fbnFiling.createFilingAction (
                                true, 
                                FilingActionType.AddAdditionalCopies, 
                                '', 
                                0, 
                                [{field: 'NumberOfCopies', value: this.requestorInformationPage.additionalCopies.toString()}],
                                false)
                        }
                    }
                    this.setFbnFiling()
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Amendment':
                    if(this.requestorInformationPage.additionalCopies !== 
                        this.fbnFiling.RequestorInformationPage.additionalCopies) {
                        this.$refs.fbnFiling.createFilingAction (
                            true, 
                            FilingActionType.AddAdditionalCopies, 
                            '', 
                            0, 
                            [{field: 'NumberOfCopies', value: this.requestorInformationPage.additionalCopies.toString()}], 
                            false)
                    }
                    this.setFbnFiling()
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Abandonment':
                    if(this.requestorInformationPage.additionalCopies !== 
                        this.fbnFiling.RequestorInformationPage.additionalCopies) {
                        this.$refs.fbnFiling.createFilingAction (
                            true, 
                            FilingActionType.AddAdditionalCopies, 
                            '', 
                            0, 
                            [{field: 'NumberOfCopies', value: this.requestorInformationPage.additionalCopies.toString()}], 
                            false)
                    }
                    this.setFbnFiling()
                    this.$router.push('fbnreviewbusinessnames');
                    break;
                case 'Fictitious Business Name Statement Withdrawal':
                    if(this.requestorInformationPage.additionalCopies !== 
                        this.fbnFiling.RequestorInformationPage.additionalCopies) {
                        this.$refs.fbnFiling.createFilingAction (
                            true, 
                            FilingActionType.AddAdditionalCopies, 
                            '', 
                            0, 
                            [{field: 'NumberOfCopies', value: this.requestorInformationPage.additionalCopies.toString()}], 
                            false)
                    }
                    this.setFbnFiling()
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Renewal':
                    if(this.requestorInformationPage.additionalCopies !== 
                        this.fbnFiling.RequestorInformationPage.additionalCopies) {
                        this.$refs.fbnFiling.createFilingAction (
                            true, 
                            FilingActionType.AddAdditionalCopies, 
                            '', 
                            0, 
                            [{field: 'NumberOfCopies', value: this.requestorInformationPage.additionalCopies.toString()}], 
                            false)
                    }
                    this.setFbnFiling()
                    this.$router.push('fbnreview');
                    break;
                case FilingWorkflow.ProvideProofOfPublication: {
                    if(this.enableRecaptcha) {
                        if(!this.recaptcha()) {
                            this.errors.push(this.$t('Template.VerifyRecaptcha'));
                        } else {
                            const payload = [...this.$store.getters.getFilings]
                            payload.forEach(file => { 
                                file.filingSelectionType = 'FBN Proof of Publication';
                                file.RequestorInformationPage = {...this.requestorInformationPage};
                            });
                            const currentWorkflow = this.$store.getters.getWorkflow;
                            const workflow = {
                                Approach: 'In-Person',
                                Type: currentWorkflow
                            }
                            ClerkRepository.post(payload, this.$store.getters.getFilingApproach, workflow)
                                .then( response => {
                                    this.$store.dispatch('setOrderNumber', response.data.trackingNumber);          
                                    this.$router.push('fbnorderconfirmation');    
                                })
                                .catch( error => console.log(error))
                        } 
                    }
                    break; 
                }
            }
        },
        previousPage() {
            this.fbnFiling.RequestorInformationPage = {...this.fbnFiling.OriginalRequestorInformation}
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
            if (this.fbnFiling.reviewPageEdit) {
                this.$router.push('fbnreview')
            } else {
                switch(this.fbnFiling.workflow){
                    case 'Fictitious Business Name Statement':
                        this.$router.push('registerbusinessaddress');
                        break;
                    case 'Fictitious Business Name Statement Amendment':
                        this.$router.push('registerbusinessaddress');
                        break;
                    case 'Fictitious Business Name Statement Abandonment':
                        this.$router.push('fbnsigner');
                        break;
                    case 'Fictitious Business Name Statement Withdrawal':
                        this.$router.push('addregisteredowner');
                        break;
                    case 'Fictitious Business Name Statement Renewal':
                        this.$router.push('fbnsigner');
                        break;
                    case 'Provide Proof of Publication':
                        this.$router.push('fbnuploadproof');
                        break; 
                }    
            }         
        },
        openHelp(){
            this.isHelpVisible = true;
        },
        closeHelp(){
            this.isHelpVisible = false;
        },
        async recaptcha() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('submit')
            await ClerkRepository.validate(token)
                .then( response => {
                    if (response.data.success === true) {
                        if(response.data.score >= 0.5){
                            return true;
                        }
                    }
                })
                .catch( error => console.log('Recaptcha: ', error))
        },
        showProceedOrSubmitButton () {
            if (this.fbnFiling.isProofOfPublication) {
                this.showProceedButton = false
                this.showSubmitButton = true
            } else {
                this.showProceedButton = true
                this.showSubmitButton = false
            }
        },
	    checkForNumber (event) {
            if (isNaN(Number(event.key))) {
                event.preventDefault()
            }
        },
        setFbnFiling () {
            this.fbnFiling.RequestorInformationPage = {...this.requestorInformationPage}
            this.fbnFiling.OriginalRequestorInformation = {...this.requestorInformationPage}
            this.fbnFiling.reviewPageEdit = false
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.nav = this.$store.getters.getSubheader;
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        this.requestorInformationPage = {...this.fbnFiling.RequestorInformationPage}
        this.showProceedOrSubmitButton()
        window.scrollTo(0,0);
    }
}
</script>
<style>
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
        border-width: 2px;
    }
    .outlineRed{
        outline: 2px solid red;
    }
    .required::after{
        content: " *";
        color:red;
    }
</style>