<template>
    <div aria-label="registered-owner-color-action-legend" id="registered-owner-color-action-legend" class="pt-2">
        <div class="py-1 text-left text-lg font-bold ">{{ this.$t('Template.ColorLegend.Label') }}</div>
        <div id="action-legend" class="flex-row-container">
            <div aria-label="add-owner-color" class="flex-row-item">
                <div class="add action-color inline-grid"></div>
                <div class="text-xs font-medium">{{ this.$t('Template.ColorLegend.AddOwner') }}</div>
            </div>
            <div aria-label="edit-owner-color" class="flex-row-item">
                <div class="edit action-color inline-grid"></div>
                <div class="text-xs font-medium">{{ this.$t('Template.ColorLegend.EditOwner') }}</div>
            </div>
            <div aria-label="remove-owner-color" class="flex-row-item">
                <div class="remove action-color inline-grid"></div>
                <div class="text-xs font-medium">{{ this.$t('Template.ColorLegend.RemoveOwner') }}</div>
            </div>
            <div aria-label="missing-data-color" class="flex-row-item">
                <div class="missing-data action-color inline-grid"></div>
                <div class="text-xs font-medium">{{ this.$t('Template.ColorLegend.MissingData') }}</div>
            </div>
        </div>
    </div>
</template>

<style>
    #action-legend {
        border: .1rem solid lightgray;
        width: 40%;
    }

    #action-legend .action-color {
        width: 1rem;
        height: 1rem;
    }

    #action-legend .add {
        background-color: rgb(74, 222, 128);
    }

    #action-legend .edit {
        background-color: rgb(253, 186, 116);
    }

    #action-legend .remove {
        background-color: rgba(168, 162, 158);
    }

    #action-legend .missing-data {
        background-color: rgb(252, 165, 165);
    }

    .flex-row-container {
        display: flex;
        flex-wrap: wrap;
    }

    .flex-row-container > .flex-row-item {
        flex: 1 1 auto;
        padding: .4rem;
        text-align: center;
    }
</style>
