import { createApp } from 'vue'
import App from './App.vue'
import i18n from './assets/language/i18n'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import './assets/css/application.css'
import VueGTag from 'vue-gtag'
import { RecaptchaSiteKey } from './assets/config';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import LitepieDatepicker from 'litepie-datepicker';

createApp(App).use(store).use(router).use(i18n).use(LitepieDatepicker).use(VueReCaptcha, { siteKey: RecaptchaSiteKey }).use(VueGTag, { config: { id: 'G-FBC6W6LT7W' }, }, router).mount('#app')