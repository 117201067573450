<template>
    <div>
        <div>
            <ReviewBusinessNames />
        </div>    
    </div>
    
</template>

<script>
import ReviewBusinessNames from '../components/Reg/ReviewBusinessNames.vue'
export default {
    components: {
        ReviewBusinessNames
    }
}
</script>