<template>
    <div>
        <div>
            <FilingApproach />
        </div>    
    </div>
    
</template>

<script>
import FilingApproach from '../components/Home/FilingApproach.vue'
export default {
    components: {
        FilingApproach
    }
}
</script>