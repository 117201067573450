<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" v-if="currentWorkflow !== 'Online Copy Number Lookup'">{{ $t('Reg.Lookup.Heading') }}</h1>
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" v-else>{{ $t('Reg.Lookup.HeadingCopyNumberLookup') }}</h1>
        <div class="py-3 mx-auto text-left text-lg max-w-screen-xl" v-if="currentWorkflow === 'Online Copy Number Lookup'" v-html="$t('Reg.Lookup.TextIntro')"></div>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ nav }}</div>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto" v-if="currentWorkflow === 'Provide Proof of Publication'">{{ currentWorkflow }}</div>
        <div class="mx-auto max-w-screen-xl py-2" v-if="!this.$store.getters.getEdit">
        <div class="mx-auto text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-400" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div v-if="currentWorkflow === 'Online Copy Number Lookup' ">
            <div class="max-w-screen-xl mx-auto mt-4 mb-4">
                <div class="text-center py-2">
                    <input 
                        aria-label="search-text-field"
                        aria-labelledby="search-text-field-label"
                        maxlength="20"
                        type="text" 
                        name="search-field-text-input" 
                        id="search-field-text-input"
                        :placeholder="$t('Reg.Lookup.PlaceholderText.OnlineCopyNumber')"
                        class="appearance-none md:w-12/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                        @keydown="onKeyDownHandler($event)"
                        v-bind:class="[ showInputErrorWarning.searchText ? 'outlineRed': 'outline-none' ]" 
                        v-model="searchInput">
                </div>
            </div>  
            <div class="py-3 mx-auto text-left text-lg max-w-screen-xl" v-html="$t('Reg.Lookup.Disclaimer')"></div>
        </div>
        <div v-else>
            <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto" v-html="$t('Reg.Lookup.UseDropdownText')"></div>
            <select class="w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded 
                    shadow leading-tight focus:outline-none focus:border-lightblue pt-2" 
                    id="business-type-select"
                    v-model="selectedOption" 
                    @change="onSearchTypeHandler(selectedOption);">
                <option value="" disabled selected hidden>{{$t('Reg.Lookup.DropdownDefaultText')}}</option>
                <option v-for="searchTypeSelection in searchTypes" :key="searchTypeSelection" :selected="selectedOption === searchTypeSelection">{{ searchTypeSelection }}</option>
            </select>     
            <div v-if="selectedOption.length > 0" class="max-w-screen-xl mx-auto mt-4 mb-4">
                <div class="text-center py-2">
                    <input 
                        aria-label="search-text-field"
                        aria-labelledby="search-text-field-label"
                        :maxlength="searchInputLength"
                        type="text" 
                        name="search-field-text-input" 
                        id="search-field-text-input"
                        :placeholder="placeholderText"
                        class="appearance-none md:w-12/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                        @keydown="onKeyDownHandler($event)"
                        v-bind:class="[ showInputErrorWarning.searchText ? 'outlineRed': 'outline-none' ]" 
                        v-model="searchInput">
                </div>
            </div>
        </div>
        <div class="text-center mt-5">
            <button ref="search" class="text-white px-4 py-4 font-semibold darkblue rounded ml-2" id="search-button" @click="onSearchClickHandler">{{ $t('Template.Buttons.Search') }}</button>
        </div>
        <div class="text-2xl font-medium" v-if="results.length > 0" v-html="$t('Reg.Lookup.Results.Stats', { maxRecords, searchedKeyword })"></div>
        <div v-for="(item, index) in results" :key="item.filingNumber" class="my-5 p-5 rounded uppercase bg-gray-100 active:bg-gray-300 text-lg">
            <!-- Responsive -->
            <div class="md:block hidden">
                <div class="w-full">
                    <button v-if="selectedOption !== $t('Reg.Lookup.DropdownOptions.CopyNumber')" class="text-white px-2 py-1 font-semibold darkblue rounded mb-2" @click="OnBusinessClickedHandler($event, index)">{{ $t('Template.Buttons.Select') }}</button>
                </div>
                <div class="flex flex-col">
                    <div class="flex flex-row justify-between mb-4">
                        <span><b>{{ $t('Template.FilingNumber') }}:</b> {{`${item.filingNumber}`}}</span>
                        <span><b>{{ $t('Template.FiledTS') }}:</b> {{`${item.filedTs}`}}</span>
                        <span v-if="selectedOption !== $t('Reg.Lookup.DropdownOptions.CopyNumber')"><b>{{ $t('Template.ExpireTS') }}:</b> {{`${item.filedTs.split('/')[0]}/${item.filedTs.split('/')[1]}/${parseInt(item.filedTs.split('/')[2])+5}`}}</span>
                    </div>
                    <div class="mb-4">
                        <b>{{ $t('Template.BusinessAddress') }}:</b> {{`${item.businessAddress.address || ""} ${item.businessAddress.addressAdditional || ""} ${item.businessAddress.city || ""} ${item.businessAddress.state || ""} ${item.businessAddress.zipCode || ""}`}}
                    </div> 
                    <table class="table-auto mb-4">
                        <caption class="text-left"><b>{{ $t('Reg.Lookup.Results.Properties.BusinessNames') }}</b></caption>
                        <tr v-for="businessName in item.businessName" :key="businessName.businessNameId">
                            {{businessName.businessName}}
                        </tr>
                    </table>
                    <table class="table-auto mb-4">
                        <thead v-if="!fbnFiling.isOnlineCopyNumberLookup">
                            <tr class="text-left">
                                <th>{{ $t('Template.OwnerName') }}</th>
                                <th>{{ $t('Template.Address') }}</th>
                                <th>{{ $t('Template.AddressAdditional') }}</th>
                                <th>{{ $t('Reg.Lookup.Results.Properties.CityStateZipCode') }}</th>
                                <th>{{ $t('Template.Country') }}</th>
                            </tr>
                        </thead>
                        <thead v-else>
                            <tr class="text-left">
                                <th>{{ $t('Template.OwnerName') }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="!fbnFiling.isOnlineCopyNumberLookup">
                            <tr class="text-left" v-for="owner in item.registeredOwner" :key="owner.registeredOwnerName">
                                <td>{{owner.registeredOwnerName}}</td>
                                <td>{{owner.address}}</td>
                                <td>{{owner.addressAdditional}}</td>
                                <td>{{owner.city}}, {{owner.state}} {{owner.zipCode}}</td>
                                <td>{{owner.country}}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr class="text-left" v-for="owner in item.registeredOwner" :key="owner.registeredOwnerName">
                                <td>{{owner.registeredOwnerName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="md:hidden block">
                <div @click="expandContent($event, 'business' + (index + 1))" class="cursor-pointer item-row table w-full">
                    <div class="w-full">
                        <button v-if="selectedOption !== $t('Reg.Lookup.DropdownOptions.CopyNumber')" class="text-white px-2 py-1 font-semibold darkblue rounded mb-2" @click="OnBusinessClickedHandler($event, index)">{{ $t('Template.Buttons.Select') }}</button>
                    </div>
                    <div class="float-left">
                        <p><b>{{ $t('Reg.Lookup.Results.Properties.BusinessNames') }}</b></p>
                        <div v-for="businessName in item.businessName" :key="businessName.businessNameId">
                            {{businessName.businessName}}
                        </div>
                    </div>
                    <div class="float-right">
                        <img class="expand" :src="require('@/assets/img/m/icon_plus.png')" alt="Icon - Expand" title="Expand" width="25" height="25"/>
                        <img class="collapse hidden" :src="require('@/assets/img/m/icon_minus.png')" alt="Icon - Collapse" title="Collapse" width="25" height="25"/>
                    </div>
                </div>
                <div :id="'business' + (index + 1)" class="business-details hidden w-full">
                    <div class="w-full">
                        <p><b>{{ $t('Template.FilingNumber') }}:</b> {{`${item.filingNumber}`}}</p>
                        <p><b>{{ $t('Template.FiledTS') }}:</b> {{`${item.filedTs}`}}</p>
                        <p v-if="selectedOption !== $t('Reg.Lookup.DropdownOptions.CopyNumber')"><b>{{ $t('Template.ExpireTS') }}:</b> {{`${item.filedTs.split('/')[0]}/${item.filedTs.split('/')[1]}/${parseInt(item.filedTs.split('/')[2])+5}`}}</p>
                        <div class="group">
                            <p><b>{{ $t('Template.OwnerName') }}</b></p>
                            <div class="text-left" v-for="owner in item.registeredOwner" :key="owner.registeredOwnerName">
                                <p>{{owner.registeredOwnerName}}</p>
                                <div class="indent">
                                    <p><b>{{ $t('Template.Address') }}:</b> {{owner.address}} {{owner.city}}, {{owner.state}} {{owner.zipCode}}, {{owner.country}}</p>
                                    <p><b>{{ $t('Template.AddressAdditional') }}:</b> {{owner.addressAdditional}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
        </div>
            <div class="flex py-4 justify-center" v-if="!isLoading && results.length > 0 && maxRecords !== 1">
                <button class="text-white mx-5 px-4 py-4 font-semibold darkblue rounded" type="button" v-if="currentPage !== 1" @click="OnSearchResultPreviousPageClickHandler">{{ $t('Template.Buttons.Previous') }}</button>
                <button class="text-white mx-5 px-4 py-4 font-semibold darkblue rounded" type="button" v-if="ShowNextButton" @click="OnSearchResultNextPageClickHandler">{{ $t('Template.Buttons.Next') }}</button>
            </div>
            <div id="loading-message" class="my-10 text-center max-w-screen-xl text-lg font-semibold" v-if="isLoading">
                {{ $t('Reg.Lookup.Loading') }}
            </div>
            <div id="no-results-message" class="my-10 text-center max-w-screen-xl text-lg font-semibold" v-if="!isLoading && searched && results.length === 0">
                {{ $t('Template.NoResults') }}
            </div>
            <div class="mx-auto max-w-screen-xl py-2 main-controls">
                <NavigationControls 
                    :showBackButton="true"
                    :showHelpButton="true"  
                    @previousPage="previousPage"
                    @openHelp="openHelp"/>
                <Help 
                    :page="1"
                    v-show="isHelpVisible"
                    @closeHelp="closeHelp"/>
            </div>
        </div>
    </div>
</template>

<script>
import JediClerkRepository from '../../repositories/JediClerkRepository';
import { isValidOwnerName, isValidBusinessName, isValidFilingNumber, isValidOnlineCopyNumber } from '../../assets/constant/validation';
import { MapBusinessTypeIntToString, StateMapToAbbreviatedState, CountryAbbrevNameToCountryNameMap, AppHasData, SearchBy, FilingWorkflow} from '../../assets/constants'
import Help from '../common/Help.vue';
import $ from 'jquery';
import NavigationControls from '../common/NavigationControls.vue'
export default {
    name: 'Lookup',
    components: {
        Help,
        NavigationControls
    },
    data: () => ({
        nav: '',
        errors: [],
        showInputErrorWarning: {
            searchText: false,
            copyNumber: false
        },
        searchTypes: [],
        selectedOption: '',
        searchInput: '',
        searchInputLength: 0,
        placeholderText: '',
        ownerName: '',
        filingNumber: '',
        businessName: '',
        copyNumber: '',
        results: [],
        data: [],
        currentWorkflow: '',
        isHelpVisible: false,
        isLoading: false,
        currentPage: 1,
        maxRecords: 1,
        searchedKeyword: '',
        previousKeyword: '',
        responseData: null,
        searched: false,
        ShowNextButton: false,
        isProofOfPublication: false
    }),
    methods: {
        OnSearchResultNextPageClickHandler() {
            this.currentPage++;
            this.onSearchClickHandler();
        },
        OnSearchResultPreviousPageClickHandler() {
            if (this.currentPage === 1) {
                return;
            }
            this.currentPage--;
            this.onSearchClickHandler();
        },
        onKeyDownHandler(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.onSearchClickHandler();
            }
        },
        onSearchTypeHandler(selectedOption) {
            switch(selectedOption) {
                case this.$t('Reg.Lookup.DropdownOptions.OwnerName'):
                    this.searchInput = '';
                    this.searchInputLength = 400;
                    this.placeholderText = this.$t('Reg.Lookup.PlaceholderText.OwnerName');
                    break;
                case this.$t('Reg.Lookup.DropdownOptions.FilingNumber'):
                    this.searchInput = '';
                    this.searchInputLength = 10;
                    this.placeholderText = this.$t('Reg.Lookup.PlaceholderText.FilingNumber');
                    break;
                case this.$t('Reg.Lookup.DropdownOptions.BusinessName'):
                    this.searchInput = '';
                    this.searchInputLength = 400;
                    this.placeholderText = this.$t('Reg.Lookup.PlaceholderText.BusinessName');
                    break;
            }
        },
        doesPageContainErrors(searchInput) {
            const currentErrors = [];
            if (searchInput.length === 0) {
                if(this.currentWorkflow === 'Online Copy Number Lookup') {
                    currentErrors.push(this.$t('Reg.Lookup.UserInput.Errors.EnterSearchOnlineCopyNumber'))
                } else {                
                    currentErrors.push(this.$t('Reg.Lookup.UserInput.Errors.EnterSearch'))                     
                }                      
            } else {              
                if(this.selectedOption === this.$t('Reg.Lookup.DropdownOptions.FilingNumber')) {
                    if (!isValidFilingNumber(searchInput)) {
                        currentErrors.push(this.$t('Reg.Lookup.UserInput.Errors.InvalidFilingNumber'))
                    }
                }
                if(this.selectedOption === this.$t('Reg.Lookup.DropdownOptions.BusinessName')) {
                    if (!isValidBusinessName(searchInput)) {
                        currentErrors.push(this.$t('Reg.Lookup.UserInput.Errors.InvalidBusinessName')) 
                    }
                }
                if(this.selectedOption === this.$t('Reg.Lookup.DropdownOptions.OwnerName')) {
                    if (!isValidOwnerName(searchInput)) {
                        currentErrors.push(this.$t('Reg.Lookup.UserInput.Errors.InvalidOwnerName')) 
                    } 
                }
                if(this.selectedOption === this.$t('Reg.Lookup.DropdownOptions.CopyNumber')) {
                    if (!isValidOnlineCopyNumber(searchInput)) {
                        currentErrors.push(this.$t('Reg.Lookup.UserInput.Errors.InvalidOnlineCopyNumber')) 
                    } 
                }
            }
            this.errors = currentErrors;
            return currentErrors.length > 0;
        },
        mapSearchType(searchOption) {
            switch(searchOption) {
                case this.$t('Reg.Lookup.DropdownOptions.FilingNumber'): 
                    return 1;
                case this.$t('Reg.Lookup.DropdownOptions.BusinessName'):
                    return 2;
                case this.$t('Reg.Lookup.DropdownOptions.OwnerName'):
                    return 3;
                case this.$t('Reg.Lookup.DropdownOptions.CopyNumber'):
                    return 4;
            }
        },
        onSearchClickHandler() {
            this.results = [];
            this.searchedKeyword = this.searchInput;
            if(this.selectedOption === '') {
                this.selectedOption = this.$t('Reg.Lookup.DropdownOptions.CopyNumber');
            }
            if (this.doesPageContainErrors(this.searchInput)) {
                return;
            };
            this.isLoading = true;
            this.searched = true;
            if (this.searchInput.length > 0 ) {
                const searchCode = this.mapSearchType(this.selectedOption);
                this.searchInput = this.searchInput.trim()
                JediClerkRepository.lookupSearch(this.searchInput, searchCode, this.currentPage, this.isProofOfPublication)
                .then( response => {
                    if (response.data.length === 0) {
                        this.isLoading = false;                     
                        return;
                    }
                    if(searchCode === SearchBy.FilingNumber || searchCode === SearchBy.CopyNumber) {
                        this.data = [];
                        this.data.push(response.data);
                        var singleData = response.data;
                        const singleResult = [];
                        const date = new Date(singleData.filedTS);
                        const filedTS = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                        singleResult.push({
                            filingNumber: singleData.filingNumber,
                            filedTs: filedTS,
                            businessAddress: {
                                address: singleData.businessAddress,
                                addressAdditional: singleData.businessAddressAdditional,
                                city: singleData.businessCity,
                                state: singleData.businessState,
                                zipCode: singleData.businessZipCode
                            },
                            businessName: singleData.fbnBusinessNames,
                            registeredOwner: singleData.fbnRegisteredOwnerNames
                        });
                        this.results = singleResult;
                        this.isLoading = false;
                        this.maxRecords = 1;
                    } else {
                        this.data = response.data;
                        var data = response.data;
                        const dataResults = [];
                        for (let i = 0; i < data.length; i++) {
                            const filedTsInPst = new Date(data[i].filedTS + 'Z');
                            const filedTS = `${filedTsInPst.getMonth() + 1}/${filedTsInPst.getDate()}/${filedTsInPst.getFullYear()}`;
                            dataResults.push({
                                filingNumber: data[i].filingNumber,
                                filedTs: filedTS,
                                businessAddress: {
                                    address: data[i].businessAddress,
                                    addressAdditional: data[i].businessAddressAdditional,
                                    city: data[i].businessCity,
                                    state: data[i].businessState,
                                    zipCode: data[i].businessZipCode
                                },
                                businessName: data[i].fbnBusinessNames,
                                registeredOwner: data[i].fbnRegisteredOwnerNames
                            });
                        }
                        this.isLoading = false;
                        this.results = dataResults;     
                    }
                    if (searchCode === SearchBy.OwnerName || searchCode === SearchBy.BusinessName) {
                        JediClerkRepository.lookUpSearchMaxNumOfPages(this.searchInput, searchCode, this.isProofOfPublication) 
                        .then( response => {
                            this.maxRecords = response.data;
                            if(this.currentPage * 5 >= this.maxRecords) {
                                this.ShowNextButton = false;
                            } else {
                                this.ShowNextButton = true;
                            }
                        })
                    }
                    this.responseData = response.data;
                })
                .catch ( error => {
                    console.log(`[API Error]: ${error}`);
                    this.isLoading = false;
                })
            }
        },
        OnBusinessClickedHandler($event, index) {
            this.errors = [];
            var filing = this.data[index];
            // Business Name
            var fbnNames = [];
            var MapBusinessNameToBusinessNameId = {};
            filing.fbnBusinessNames.forEach(business => {
                fbnNames.push(business.businessName);
                MapBusinessNameToBusinessNameId[business.businessName] = business.businessNameId
            });
            // Registered owner
            var MapRegisteredOwnerNameToRegisteredOwnerId = {};
            filing.fbnRegisteredOwnerNames.forEach(owner => { 
                MapRegisteredOwnerNameToRegisteredOwnerId[owner.registeredOwnerName] = owner.registeredOwnerNameId 
            });
            var owners = [];
            this.$store.dispatch('setBusinessId', MapBusinessNameToBusinessNameId);
            this.$store.dispatch('setRegisteredOwnerId', MapRegisteredOwnerNameToRegisteredOwnerId);
            // Convert state abbreviation to state name
            const mapStateAbbreviationsToStateName = (value, states) => Object.keys(states).filter((key) => states[key] === value)[0];
            // FBN group
            filing.fbnRegisteredOwnerNames.forEach(ro => owners.push({
                id: ro.registeredOwnerNameId,
                name: ro.registeredOwnerName || '',
                address: ro.address || '',
                addressAdditional: ro.addressAdditional || '',
                city: ro.city || '',
                state: mapStateAbbreviationsToStateName(ro.state, StateMapToAbbreviatedState) || '',
                zipCode: ro.zipCode ? (ro.zipCode.trim().indexOf('-') !== -1 ? ro.zipCode.trim().split('-')[0] : ro.zipCode.trim()) : '',
                country: ro.country && ro.country.length > 0 ? CountryAbbrevNameToCountryNameMap[ro.country] : '',
                stateOfOrganization: mapStateAbbreviationsToStateName(ro.stateOfOrganization, StateMapToAbbreviatedState) || '',
                removed: false,
                hasMissingData: false,
                backgroundColor: ''
            }));
            var commencementDate = filing.commenceTS ? filing.commenceTS.split('T')[0].split('-') : null;
            var businessType = MapBusinessTypeIntToString(filing.businessTypeCode);
            var filingType = this.$store.getters.getFilingSelectionType;
            var filingSelectionChoice = this.$store.getters.getFilingApproach;
            var businessZipCode = filing.businessZipCode ? (filing.businessZipCode.trim().indexOf('-') !== -1 ? filing.businessZipCode.trim().split('-')[0] : filing.businessZipCode.trim()) : '';
            var filingObject = {
                agreedFbnIsAvaliable: false,
                filingSelectionType: filingType,
                filingSelectionChoice: filingSelectionChoice,
                sosFile: [],
                filingActions: [],
                sosFilePublications: [],
                businessType: businessType,
                fbnNames: fbnNames,
                BusinessAddress: {
                    address: filing.businessAddress || '',
                    additionalAddress: filing.businessAddressAdditional || '',
                    city: filing.businessCity || '',
                    state: mapStateAbbreviationsToStateName(filing.businessState, StateMapToAbbreviatedState) || '',
                    zipCode: businessZipCode ? (businessZipCode.trim().indexOf('-') !== -1 ? businessZipCode.trim().split('-')[0] : businessZipCode.trim()) : '',
                    county: filing.businessCounty || '',
                    country: CountryAbbrevNameToCountryNameMap[filing.businessCountry] || '',
                    articles: filing.articlesIncorpNumber || ''
                },
                internationalToggle: this.$store.getters.getInternationalToggle,
                businessInternational: this.$store.getters.getInternationalBusiness,
                RequestorInformationPage: {
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    address: '',
                    addAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: '',
                    email: '',
                    phone: '',
                    dateRegistrantCommenced: commencementDate ? `${commencementDate[1]}/${commencementDate[0]}` : 'N/A',
                    additionalCopies: 1                  
                },
                OriginalRequestorInformation: {
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    address: '',
                    addAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: '',
                    email: '',
                    phone: '',
                    dateRegistrantCommenced: commencementDate ? `${commencementDate[1]}/${commencementDate[0]}` : 'N/A',
                    additionalCopies: 1                  
                },
                signatureInfo: {
                    FullName: filing.registrantFullName || '',
                    SignatoryName: filing.signatoryName || '',
                    SignatoryTitle: filing.signatoryTitle || ''
                },
                registeredOwners: owners,
                signature: '',
                orderNumber: 0,
                jediFilingNumber: filing.filingNumber,
                businessMap: MapBusinessNameToBusinessNameId,
                RegisteredOwnerMap: MapRegisteredOwnerNameToRegisteredOwnerId,
                fbnGroupNumber: filing.fbnGroupNumber,
                filedTS: filing.filedTS,
                businessNameCount: filing.businessNameCount,
                ownerNameCount: filing.ownerNameCount,
                addressBackup: {},
                didSetAddressBackup: false,
                registeredOwnersBackup: [],
                isBackup: false,
                backup: [],
                businessId: this.$store.getters.getBusinessId,
                registeredOwnerIds: this.$store.getters.getRegisteredOwnerIds,
                newFbnNames: [],
                additionalCopiesBackup: 0,
                registeredOwnerTempId: 1
            }
            if(filingObject.RequestorInformationPage.additionalCopies < 1) {
                filingObject.RequestorInformationPage.additionalCopies = 1;
            }
            if (this.currentWorkflow === 'Provide Proof of Publication') {
                filingObject.RequestorInformationPage.additionalCopies = 0;
            }
            // new filing object
            this.fbnFiling.RegisteredOwnerPage.businessType = filingObject.businessType
            this.fbnFiling.RegisteredOwnerPage.registeredOwners = filingObject.registeredOwners
            this.fbnFiling.RegisteredOwnerPage.sosFile = filingObject.sosFile
            this.fbnFiling.BusinessNamePage.businessNameId = MapBusinessNameToBusinessNameId
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment || this.fbnFiling.workflow === FilingWorkflow.Abandonment) {
                this.fbnFiling.BusinessNamePage.originalFbnNames = filingObject.fbnNames  
            } else {
                this.fbnFiling.BusinessNamePage.fbnNames = filingObject.fbnNames
            }
            this.fbnFiling.BusinessAddressPage.BusinessAddress = {...filingObject.BusinessAddress}
            this.fbnFiling.BusinessAddressPage.OriginalBusinessAddress = {...filingObject.BusinessAddress}
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
            // old filing object, still in use until refactor is complete
            this.$store.dispatch('setFiling', filingObject)
            let proceed = true;
            const proofFilingObjects = this.$store.getters.getFilingPublicationObjects;
            switch(this.currentWorkflow){
                case 'Provide Proof of Publication':
                    for(let i = 0; i < proofFilingObjects.length; i++) {
                        if(filingObject.jediFilingNumber === proofFilingObjects[i].jediFilingNumber) {
                            this.errors.push('The currently selected filing is already in the proof of publications list.');
                            proceed = false;
                        }
                    }
                    if (proceed === true) {
                        this.$store.dispatch('setFilingPublicationObjects', filingObject);
                        this.$router.push('fbnpublishinginfo');    
                    }
                    break;
                case 'Fictitious Business Name Statement Renewal':
                    this.$store.dispatch('setIsBackup', false);
                    if (this.$store.getters.getFilingApproach === 'Online') {
                        this.$router.push('fbnvitalchekinfo');
                    } else {
                        this.$router.push('fbnreviewbusinessnames');
                    }
                    break;
                case 'Fictitious Business Name Statement Abandonment':
                    this.$store.dispatch('setIsBackup', false);
                    this.$store.dispatch('setBackup');
                    if (this.$store.getters.getFilingApproach === 'Online') {
                        this.$router.push('fbnvitalchekinfo');
                    } else {
                        this.$router.push('fbnsigner');
                    }
                    break;
                case 'Fictitious Business Name Statement Withdrawal':
                    this.$store.dispatch('setIsBackup', false);
                    this.$store.dispatch('setBackupOwners');
                    if (this.$store.getters.getFilingApproach === 'Online') {
                        this.$router.push('fbnvitalchekinfo');
                    } else {
                        this.$router.push('addregisteredowner');
                    }
                    break;
                case 'Fictitious Business Name Statement Amendment':
                    this.$store.dispatch('setIsBackup', false);
                    this.$store.dispatch('resetBackup');
                    this.$store.dispatch('removeNewNames');
                    this.$store.dispatch('setBackup');
                    this.$store.dispatch('didSetAddressBackup', false);
                    this.$store.dispatch('setRegisteredOwnersBackup', this.$store.getters.getRegisteredOwners);
                    if (this.$store.getters.getFilingApproach === 'Online') {
                        this.$router.push('fbnvitalchekinfo');
                    } else {
                        this.$router.push('addregisteredowner');
                    }
                    break;  
            }
        },
        openHelp() {
            this.isHelpVisible = true;
        },
        closeHelp() {
            this.isHelpVisible = false;
        },
        clearState() {
            this.$store.dispatch('clearAllState');
        },
        expandContent(event, element) {
            element = document.getElementById(element);
            const expandableContentHidden = element.style.display === 'none' || element.style.display === '';
            element.style.display = expandableContentHidden ? 'table' : 'none';
            $(event.target).find('.expand').css('display', expandableContentHidden ? 'none' : 'block');
            $(event.target).find('.collapse').css('display', expandableContentHidden ? 'block' : 'none');
        },
        previousPage() {
            if (this.fbnFiling.isOnlineCopyNumberLookup === true || this.fbnFiling.isProofOfPublication === true) {
                this.$router.push('/')
            } else {
                this.$router.push('fbnfilingworkflow')
            }
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.nav = this.$store.getters.getSubheader;
        this.currentWorkflow = this.$store.getters.getWorkflow;
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        if(this.fbnFiling.workflow === FilingWorkflow.ProvideProofOfPublication) {
            this.isProofOfPublication = true;
        }
        window.scrollTo(0,0);
    },
    mounted() {
        this.searchTypes = [this.$t('Reg.Lookup.DropdownOptions.BusinessName'), this.$t('Reg.Lookup.DropdownOptions.FilingNumber'), this.$t('Reg.Lookup.DropdownOptions.OwnerName')];
    }
}
</script>
<style>
    .cursor-pointer:active {
        background-color: #E5E7EB;
    }
    .business-details {
        padding-top: 10px;
        border-top: 1px solid #ccc;
    }
    .group {
        margin: 10px 0 10px 0;
    }
    .indent {
        margin-left: 5px;
        font-size: 15px;
        line-height: 1.4em;
    }
</style>