<template>
    <div>
        <div>
            <RegisterOwner />
        </div>    
    </div>
    
</template>

<script>
import RegisterOwner from '../components/Reg/RegisterOwner.vue'
export default {
    components: {
        RegisterOwner
    }
}
</script>