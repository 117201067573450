<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" id="page-header">{{ $t('Reg.ReviewBusinessNames.Heading') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto py-4 text-left text-2xl font-bold max-w-screen-xl" v-if="fbnFiling.workflow === filingWorkflow.Renewal">{{ $t('Reg.ReviewBusinessNames.Intro') }}</div>
        <div class="mx-auto py-4 text-left text-2xl font-bold max-w-screen-xl" v-else-if="fbnFiling.workflow === filingWorkflow.Abandonment">{{ $t('Reg.ReviewBusinessNames.AbandonmentIntro') }}</div>
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="max-w-screen-xl mx-auto">
            <div class="text-center py-2">
                <div class="w-full text-left py-4" v-if="getFbnNames.length > 0">
                    <div class="max-w-screen-xl mx-auto">
                        <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-200" id="fbn-names-table">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                    #
                                                </th>
                                                <th scope="col" class="w-8/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                    {{ $t('Template.FBNName') }}
                                                </th>
                                                <th scope="col" class="w-8/12 px-6 py-3 text-right text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="fbnFiling.workflow === filingWorkflow.Abandonment">
                                                    {{ $t('Reg.ReviewBusinessNames.Results.Columns.SelectFBN') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(fbn,index) in getFbnNames" :key="index">
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="text-sm text-gray-900">
                                                        {{ index + 1}}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap">
                                                    <div class="text-base text-gray-900">{{ fbn }}</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap text-right" v-if="fbnFiling.workflow === filingWorkflow.Abandonment">
                                                    <!-- Responsive -->
                                                    <div class="sm:blockk">
                                                        <input type="checkbox" aria-label="abandon-fbn-checkbox" :id="index" :value="fbn" class="h-4 w-4" v-model="fbnNamesToRemove">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                  
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="2"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>        
    </div>
</template>

<script>
import Help from '../common/Help.vue'
import NavigationControls from '../common/NavigationControls.vue'
import { AppHasData, FilingActionType, FilingApproach, FilingWorkflow } from '../../assets/constants'
export default {
    name: 'Review Business Names',
    components: {
        Help,
        NavigationControls
    },
    computed: {
        getFbnNames () {
            if (this.fbnFiling.workflow === FilingWorkflow.Abandonment) {
                return this.fbnFiling.BusinessNamePage.originalFbnNames
            } else {
                return this.fbnFiling.BusinessNamePage.fbnNames
            }
        }    
    },
    data: () => ({
        errors: [],
        fbnNamesToRemove: [],
        filingWorkflow: FilingWorkflow,
        isHelpVisible: false,
        nav: '' 
    }),
    methods: {
        //#region Interactions
        closeHelp() {
            this.isHelpVisible = false
        },
        openHelp () {
            this.isHelpVisible = true
        },
        previousPage () {
            if (this.fbnFiling.workflow === FilingWorkflow.Abandonment) {
                this.$router.push('fbnadditionalinformation')
            } else if (this.fbnFiling.workflow === FilingWorkflow.Renewal) {
                if (this.fbnFiling.approach === FilingApproach.Online) {
                    this.$router.push('fbnvitalchekinfo')
                } else {
                    this.$router.push('fbnlookup')
                }
            }
        },
        proceed () {
            if (this.doesPageContainErrors()) return
            if (this.fbnFiling.workflow === FilingWorkflow.Abandonment) {
                this.createRemoveFbnFilingAction()
                this.setFbnFilingData()
                this.$router.push('fbnreview')
            } else if (this.fbnFiling.workflow === FilingWorkflow.Renewal) {
                this.$router.push('addregisteredowner')
            }
        },
        //#endregion

        //#region Helpers

        createRemoveFbnFilingAction () {
            for(let i = 0; i < this.fbnNamesToRemove.length; i++){
                const filingAction = {
                    delta: [{ field: 'Name', value: this.fbnNamesToRemove[i] }],
                    filingActionTypeCode: FilingActionType.AbandonBusinessName,
                    name: this.fbnNamesToRemove[i],
                    nameId: this.fbnFiling.BusinessNamePage.businessNameId[this.fbnNamesToRemove[i]],
                }
                this.fbnFiling.BusinessNamePage.filingActions.push(filingAction)
            }
        },
        setFbnFilingData () {
            this.fbnFiling.BusinessNamePage.fbnNames = this.fbnNamesToRemove
            this.$store.dispatch('setFbnFiling', this.fbnFiling)             
        },

        //#endregion
        
        // #region Validation
        doesPageContainErrors () {
            if (this.fbnFiling.workflow === FilingWorkflow.Abandonment) {
                this.errors = []
                if(this.fbnNamesToRemove.length < 1) {
                    this.errors.push(this.$t('Reg.ReviewBusinessNames.UserInput.Errors.RemoveBusinessName'))
                }
                return this.errors.length > 0 
            } else {
                return false
            }
        }
        // #endregion

    },
    beforeMount () {
        AppHasData(this.$store.getters.getAppHasData, this)
        this.pageTranslated = this.language !== 'en'
        this.nav = this.$store.getters.getSubheader
        // Get Filing
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        window.scrollTo(0,0)
    }
}
</script>
<style>
    .m-owner-row {
        border-bottom: 1px solid #ccc;
    }
    .m-owner-row-details {
        font-size: 16px;
        background-color: #fff;
    }
    .m-owner-row-controls {
        margin-top: -12px;
    }
</style>