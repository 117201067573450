<template>
    <div id="search-bar" class="base-inline">
        <label class="label">{{ $t('Reg.PublicAgenciesSearch.LabelFilterResults') }}</label> 
        <input 
            aria-label="filter"
            :placeholder="this.$t('Reg.PublicAgenciesSearch.LabelFilterHint')"
            style="text-transform:uppercase" 
            type="text"
            v-model="search" 
            @keyup = "emitSearchChanged" 
            />
    </div>
</template>

<script>
export default {
    props: {
        clearFilterOnSearch: {
        type: Boolean,
        default: false,
        },
        clearFilterOnTabChange: {
        type: Boolean,
        default: false,
        },
    },
    watch:{
        clearFilterOnSearch(newVal){
            if(newVal){
                this.search = '';
            }
        },
        clearFilterOnTabChange(){
            this.search = '';
        }
    },
    methods: {
        emitSearchChanged() {
            this.search = this.search.toUpperCase();
            this.$emit('EventSearchChanged', this.search);
        }
    }
}
</script>
<style>
#search-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-size: .7em;
    padding-left: 1em;
    background-color: #003471;
    color: white;
}
#search-bar label {
    display: block;
    font-weight: bold;
}
#search-bar input {
    border: 1px solid #d2d2d2;
    flex-grow: 1;
    margin-left: 1em;
    padding: 10px;
    color: #000;
}
</style>