<template>
    <div class="m_pad" role="main">
        <h1 
            class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" 
            id="page-header">{{ $t('Reg.RegisterBusinessAddress.Heading') }}
        </h1>
        <div 
            class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">
            {{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}
        </div>
        <div 
            class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" 
            v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="max-w-screen-xl mx-auto">
            <br>
            <div class="mx-auto py-2 text-left text-2xl font-bold max-w-screen-xl">{{ $t('Reg.RegisterBusinessAddress.Intro') }}</div>
            <div class="mx-auto max-w-screen-xl pt-2">
                <label class="inline-flex items-left">
                    <input 
                        type="checkbox" 
                        id="international--business-address-checkbox" 
                        class="h-8 w-8" @click="internationalBusinessToggle" 
                        v-model="internationalBusiness">
                    <span class="ml-3 text-lg">{{ $t('Reg.RegisterBusinessAddress.UserInput.Checkbox.InternationalBusinessAddress') }}</span>
                </label>
            </div>
            <div 
                class="required text-xl md:w-5/12 py-2 mt-4 md:inline-block text-left w-full" 
                id="business-address-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessAddress') }}
            </div>
            <input 
                type="text" 
                aria-labelledby="business-address-label" 
                aria-label="business-address-field" 
                maxlength="40" 
                name="business-street-address" 
                id="business-street-address" 
                v-bind:class="[ InputErrors.address ? 'outlineRed': 'outline-none' ]" 
                class="appearance-none md:w-7/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                v-model="BusinessAddress.address"/>
            <div class="content-to-hide text-base md:w-5/12 py-1 md:inline-block text-left w-full"></div>
            <div 
                class="text-base md:w-7/12 py-1 md:inline-block text-left w-full" 
                id="business-address-info-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessAddressInformation') }}
            </div>
            <div 
                class="text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full" 
                id="additional-address-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessAddressAdditionalInformationLabel') }}
            </div>
            <input 
                type="text" 
                aria-label="additional-address-field" 
                aria-labelledby="additional-address-label" 
                maxlength="40" 
                :placeholder="$t('Template.Optional')" 
                name="business-additional-address" 
                id="business-additional-address" 
                v-bind:class="[ InputErrors.additionalAddress ? 'outlineRed': 'outline-none' ]" 
                class="appearance-none md:w-7/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                v-model="BusinessAddress.additionalAddress"/>
            <div class="content-to-hide text-base md:w-5/12 py-1 md:inline-block text-left w-full"></div>
            <div 
                class="text-base md:w-7/12 py-1 md:inline-block text-left w-full" 
                id="business-address-info-label">
                {{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessAddressAdditionalInformation') }}
            </div>
            <div 
                for="business-city-select" 
                class=" required text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full" 
                id="city-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessCity') }}
            </div>
            <input 
                list="cities" 
                aria-labelledby="city-label" 
                aria-label="city-field" 
                maxlength="30" 
                v-bind:class="[ InputErrors.city  ? 'outlineRed': 'outline-none' ]" 
                class="md:w-7/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                name="business-city-select" 
                id="business-city-select" 
                v-model="BusinessAddress.city"/>
            <datalist id="cities">
                <option v-for="city in cities" v-bind:value="city" :key="city"/>
            </datalist>
            <div 
                v-bind:class="[this.internationalBusiness ? 'text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full' : 'required text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full']" 
                id="state-select-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessState') }}
            </div>
            <select 
                v-bind:class="[ InputErrors.state  ? 'outlineRed': 'outline-none' ]" 
                aria-label="state-select" 
                aria-labelledby="state-select-label" 
                class="md:w-7/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                maxlength="30" 
                name="business-state-select" 
                id="business-state-select" 
                v-model="BusinessAddress.state">
                <option v-for="state in states" :key="state" :selected="BusinessAddress.state === state">{{ state }}</option>
            </select>
            <div 
                v-bind:class="[this.internationalBusiness ? 'text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full' : 'required text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full']" 
                id="zipcode-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessZipCode') }}
            </div>
            <input 
                type="text" 
                aria-labelledby="zipcode-label" 
                aria-label="zipcode-field" 
                maxlength="10" 
                name="business-zip-code" 
                id="business-zip-code" 
                v-bind:class="[ InputErrors.zipCode  ? 'outlineRed': 'outline-none' ]" 
                class="appearance-none md:w-7/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                v-model="BusinessAddress.zipCode"/>
            <div 
                v-bind:class="[this.internationalBusiness ? 'text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full' : 'required text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full']" 
                id="business-county-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessCounty') }}
            </div>           
            <input 
                type="text" 
                aria-label="business-county-field" 
                aria-labelledby="business-county-label" 
                maxlength="50" 
                name="business-county" 
                id="business-county" 
                class="appearance-none md:w-7/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                v-bind:class="[ InputErrors.county  ? 'outlineRed': 'outline-none' ]" 
                v-model="BusinessAddress.county"/>
            <div 
                class="required text-xl md:w-5/12 py-2 mt-4 inline-block text-left full" 
                id="country-label">{{ $t('Reg.RegisterBusinessAddress.UserInput.Labels.BusinessCountry') }}
            </div>
            <select 
                v-bind:class="[ InputErrors.country  ? 'outlineRed': 'outline-none' ]" 
                aria-labelledby="country-label" 
                aria-label="country-select" 
                class="md:w-7/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                maxlength="50" 
                name="mailing-country-select" 
                id="business-country-select" 
                v-model="BusinessAddress.country">
                <option v-for="country in countries" :key="country" :selected="BusinessAddress.country === country">{{ country }}</option>
            </select>
             <div v-if="showArticles">
                <div 
                    class="text-xl md:w-5/12 py-2 mt-4 inline-block text-left w-full" 
                    id="articles-label">
                    Articles of Incorporation # (if applicable)
                </div>
                <input 
                    type="text" 
                    aria-label="articles-field" 
                    aria-labelledby="articles-label" 
                    maxlength=30 
                    name="business-articles-of-incorporation" 
                    id="business-articles-of-incorporation" 
                    v-bind:class="[ InputErrors.articles  ? 'outlineRed': 'outline-none' ]" 
                    class="appearance-none md:w-7/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    :placeholder="$t('Template.Optional')" 
                    v-model="BusinessAddress.articles"/>
            </div>
            <br>
        </div>
        <br>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="1"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>
    </div>
</template>

<script>
import Help from '../common/Help.vue'
import NavigationControls from '../common/NavigationControls.vue'
import { AppHasData, BusinessTypeName, cities, countries, FilingActionType, FilingWorkflow, states } from '../../assets/constants'
import { isValidAddress, isValidArticlesOfIncorporation, isValidDropdown, isValidInput, isValidZipCode } from '../../assets/constant/validation'
export default {
    name: 'Register Business Address',
    components: {
        Help,
        NavigationControls
    },
    data: () => ({
        // Variables
        cities: cities,
        countries: countries,
        errors: [],
        filingActions: [],
        internationalBusiness: false,
        isHelpVisible: false,
        nav: '',
        showArticles: false,
        states: states,
        // Object variables
        BusinessAddress: {
            address:'',
            additionalAddress: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: 'United States',
            articles: ''
        },
        fbnFiling: {},
        InputErrors: {
            address: false,
            additionalAddress: false,
            city: false,
            state: false,
            zipCode: false,
            county: false,
            country: false,
            articles: false,
        }
    }),
    methods: {
        //#region Interactions
        closeHelp () {
            this.isHelpVisible = false
        },
        internationalBusinessToggle() {
            this.internationalBusiness = !this.internationalBusiness
        },
        openHelp () {
            this.isHelpVisible = true
        },
        previousPage () {
            if (this.fbnFiling.reviewPageEdit) {
                this.$router.push('fbnreview')
            } else {
                this.$router.push('addbusinessname')    
            }   
        },
        proceed () {
            if (this.validateFields()) return
            // Create filing action if this is an amendment
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                this.createFilingAction()       
            }
            // Store fbn filing data and navigate to next page
            this.setFbnFilingData()
            if (!this.isReviewPageEdit()) {
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('fbnadditionalinformation')  
            }
        },
        //#endregion

        //#region Helpers
        createFilingAction () {
            const originalBusinessAddress = JSON.parse(JSON.stringify({...this.fbnFiling.BusinessAddressPage.OriginalBusinessAddress}))
            const currentBusinessAddress = JSON.parse(JSON.stringify({...this.BusinessAddress}))
            if (JSON.stringify(currentBusinessAddress) !== JSON.stringify(originalBusinessAddress)) {
                const delta = []
                for (const [key, value] of Object.entries(currentBusinessAddress)) {
                    let data = {
                        field: '',
                        value: ''
                    }
                    if (key === 'additionalAddress') {
                        data = {
                            field: 'addressAdditional',
                            value: value
                        };
                    } else if (key === 'articles') {
                        data = {
                            field: 'articlesIncorpNumber',
                            value: value
                        };
                    } else {
                        data = {
                            field: key,
                            value: value
                        }; 
                    }    
                    delta.push(data)
                }
                this.filingActions.push({
                    delta: delta,
                    filingActionTypeCode: FilingActionType.EditBusinessAddressOrArticles,
                    name: '',
                    nameId: 0
                })
            }
        },
        isReviewPageEdit () {
            // Check for review page flag
            if (this.fbnFiling.reviewPageEdit) {
                // Return to the review page and set flag to false
                this.fbnFiling.reviewPageEdit = false
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('fbnreview')
                return true
            }
            return false
        },
        setFbnFilingData () {
            this.fbnFiling.BusinessAddressPage.BusinessAddress = this.BusinessAddress
            this.fbnFiling.BusinessAddressPage.filingActions = this.filingActions
            this.fbnFiling.BusinessAddressPage.internationalBusiness = this.internationalBusiness
        },
        variableNameToLabel (name) {
            const result = name.split(/(?=[A-Z])/).join(' ')        
            return result.charAt(0).toUpperCase() + result.slice(1)
        },
        //#endregion

        //#region Validations
        validateFields () {
            if (this.internationalBusiness) {
                this.optionalInputs = ['articles', 'additionalAddress', 'state', 'county', 'zipCode']
            } else {
                this.optionalInputs = ['articles', 'additionalAddress']
            }
            this.errors = []
            this.InputErrors = {
                address: false,
                additionalAddress: false,
                city: false,
                state: false,
                zipCode: false,
                county: false,
                country: false,
                articles: false
            }
            for (const [k, v] of Object.entries(this.BusinessAddress)) {
                const value = v.trim()
                if (value.length <= 0) {
                    if (!this.optionalInputs.includes(k)){
                        this.errors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.variableNameToLabel(k) }))
                        this.InputErrors[k] = true
                    }
                } else {
                    switch(k) {
                        case 'zipCode':
                            if (this.internationalBusiness) {
                                if (!isValidInput(value)) {
                                    this.errors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }))
                                    this.InputErrors[k] = true
                                }     
                            } else {
                                if (!isValidZipCode(value)) {
                                    this.errors.push(this.$t('Template.ErrorHandling.InvalidZipCode', { field: this.variableNameToLabel(k) }))
                                    this.InputErrors[k] = true   
                                }     
                            }
                            break;
                        case 'country':
                            if (!isValidDropdown(value)) {
                                this.errors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }))
                                this.InputErrors[k] = true
                            }
                            break;
                        case 'articles':
                            if (!isValidArticlesOfIncorporation(value)) {
                                this.errors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.variableNameToLabel(k) }));
                                this.InputErrors[k] = true
                            }
                            break;
                        case 'address':
                            if (!isValidAddress(value)) {
                                this.errors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.variableNameToLabel(k) }))
                                this.InputErrors[k] = true
                            }
                            break;
                        case 'additionalAddress': 
                            if (!isValidAddress(value)) {
                                this.errors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.variableNameToLabel(k) }))
                                this.InputErrors[k] = true
                            }
                            break;
                        default:
                            if (!isValidInput(value)) {
                                this.errors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }))
                                this.InputErrors[k] = true
                            }   
                    } 
                }
            }
            return this.errors.length > 0
        },
        //#endregion
    },
    beforeMount() {
        AppHasData(this.$store.getters.getAppHasData, this)
        this.pageTranslated = this.language !== 'en'
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        this.nav = this.$store.getters.getSubheader
        // Set original address if amendment
        if (this.fbnFiling.workflow === FilingWorkflow.Amendment && this.fbnFiling.BusinessAddressPage.address === '') {
            this.fbnFiling.BusinessAddressPage.OriginalBusinessAddress = {...this.fbnFiling.BusinessAddressPage.BusinessAddress}
        }
        // Show articles field if business type is Corporation, LLP, Limited Partnership, or LLC
        if (this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.Corporation || 
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLP || 
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LimitedPartnership || 
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLC) {
            this.showArticles = true
        } else {
            this.BusinessAddress.articles = ''
        }
        // Set business address
        this.BusinessAddress = {...this.fbnFiling.BusinessAddressPage.BusinessAddress}
        this.internationalBusiness = this.fbnFiling.BusinessAddressPage.internationalBusiness
        window.scrollTo(0,0)
    }
}
</script>

<style>
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
        border-width: 2px;
    }
    .lightblue {
        background-color: #13b5ea;
    }
    .toggle__dot {
        transition: all 0.3s ease-in-out;
    }
    input:checked ~ .toggle__dot {
        transform: translateX(100%);
        background-color: #13b5ea;
    }
    .outlineRed{
        outline: 2px solid red;
    }
    .required::after {
        content: "*";
        color:red;
    }
    .content-to-hide {
            display: none;
        }
    @media only screen and (min-width: 768px){                  
        .content-to-hide {
            display: block;
        }
    }
</style>