<template>
    <div>
        <div>
            <OrderConfirmation />
        </div>    
    </div>
    
</template>

<script>
import OrderConfirmation from '../components/Reg/OrderConfirmation.vue'
export default {
    components: {
        OrderConfirmation
    }
}
</script>
