import Repository from './Repository';

const resource = '/jediclerk';

export default {
    search(businessName, pageSize, pageNumber) {
        const endpoint = `${resource}/search/business/${businessName}/${pageSize}/${pageNumber}`;
        return Repository.get(endpoint);
    },
    async lookupSearch(payload, searchCode, page, isProofOfPublication) {
        return await Repository.get(`${resource}/lookup/${isProofOfPublication}/${searchCode}/${payload}/${page}`)
    },
    async lookUpSearchMaxNumOfPages(payload, searchCode, isProofOfPublication) {
        return await Repository.get(`${resource}/lookup/maxpage/${isProofOfPublication}/${searchCode}/${payload}`)
    },
    async lookUpPublicAgenciesByStatementFilingDates(startDate, endDate){
        return await Repository.get(`${resource}/publicagencies/statement/${startDate}/${endDate}`)
    },
    async lookUpPublicAgenciesByOriginalFilingDates(startDate, endDate){
        return await Repository.get(`${resource}/publicagencies/original/${startDate}/${endDate}`)
    },
};