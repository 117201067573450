<template>
    <div class="mx-auto">
        <label class="inline-flex items-left">
            <input type="checkbox" id="verify-business-name" class="h-8 w-8" v-bind:class="[ checkboxError ? 'outline-red' : 'outline-none' ]" @click="onCheckboxSelectedHandler" v-model="checkboxSelected">
            <span class="ml-3 text-xl" v-html="checkboxLabel"></span>
        </label>
    </div>
</template>

<script>

export default {
    props: {
        checkboxLabel: String,
        checkboxError: Boolean
    },
    data: () => ({
        checkboxSelected: false
    }),
    methods: {
        onCheckboxSelectedHandler () {
            this.checkboxSelected = !this.checkboxSelected
            this.$emit('onCheckboxSelectedHandler', this.checkboxSelected)
        }
    }
}
</script>