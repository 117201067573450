<template>
    <div>
        <div>
            <AdditionalInformation />
        </div>    
    </div>
    
</template>

<script>
import AdditionalInformation from '../components/Reg/AdditionalInformation.vue'
export default {
    components: {
        AdditionalInformation
    }
}
</script>