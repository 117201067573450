import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import FbnFilingWorkflow from '../views/FbnFilingWorkflow.vue'
import FbnSearch from '../views/FbnSearch.vue'
import TypeOfBusiness from '../views/TypeOfBusiness.vue'
import BusinessName from '../views/BusinessName.vue'
import RegisteredOwner from '../views/RegisteredOwner.vue'
import FbnLookup from '../views/FbnLookup.vue'
import BusinessAddress from '../views/BusinessAddress.vue'
import FbnSigner from '../views/FbnSigner.vue'
import FbnLandingPage from '../views/FbnLandingPage.vue'
import FbnAdditionalInformation from '../views/FbnAdditionalInformation.vue'
import FbnFilingApproach from '../views/FbnFilingApproach.vue'
import FbnAdditionalFiling from '../views/FbnAdditionalFiling.vue'
import FbnPreview from '../views/FbnPreview.vue'
import ReviewPage from '../views/ReviewPage.vue'
import Confirmation from '../views/Confirmation.vue'
import FbnPublishingInfo from '../views/FbnPublishingInfo.vue'
import FbnUploadProof from '../views/FbnUploadProof.vue'
import FbnReviewBusinessNames from '../views/FbnReviewBusinessNames.vue'
import FbnVitalChekInfo from '../views/VitalChekInfo.vue'
import PublicAgencies from '../views/PublicAgencies.vue'
import PublicAgenciesSearch from '../views/PublicAgenciesSearch.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/fbnlandingpage',
    name: 'LandingPage',
    component: FbnLandingPage
  },
  {
    path: '/fbnfilingapproach',
    name: 'FilingApproach',
    component: FbnFilingApproach
  },
  {
    path: '/fbnfilingworkflow',
    name: 'FilingWorkflow',
    component: FbnFilingWorkflow
  },
  {
    path: '/fbnsearch',
    name: 'FbnSearch',
    component: FbnSearch
  },
  {
    path: '/onlinesearch',
    name: 'OnlineSearch',
    component: FbnSearch
  },
  {
    path: '/fbnbusinesstype',
    name: 'BusinessType',
    component: TypeOfBusiness
  },
  {
    path: '/addbusinessname',
    name: 'BusinessName',
    component: BusinessName
  },
  {
    path: '/registerbusinessaddress',
    name: 'RegisterBusinessAddress',
    component: BusinessAddress
  },
  {
    path: '/addregisteredowner',
    name: 'RegisteredOwners',
    component: RegisteredOwner
  },
  {
    path: '/fbnlookup',
    name: 'Fbnlookup',
    component: FbnLookup
  },
  {
    path: '/fbnsigner',
    name: 'Signer',
    component: FbnSigner
  },
  {
    path: '/fbnadditionalinformation',
    name: 'AdditionalInformation',
    component: FbnAdditionalInformation
  },
  {
    path: '/fbnadditionalfiling',
    name: 'AdditionalFiling',
    component: FbnAdditionalFiling
  },
  {
    path: '/fbnreview',
    name: 'ReviewFbn',
    component: ReviewPage
  },
  {
    path: '/fbnpreview',
    name: 'Preview',
    component: FbnPreview
  },
  {
    path: '/fbnorderconfirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/fbnpublishingInfo',
    name: 'PublishingInfo',
    component: FbnPublishingInfo
  },
  {
    path: '/fbnuploadproof',
    name: 'UploadProof',
    component: FbnUploadProof
  },
  {
    path: '/fbnreviewbusinessnames',
    name: 'ReviewBusinessNames',
    component: FbnReviewBusinessNames
  },
  {
    path: '/fbnvitalchekinfo',
    name: 'VitalChekInfo',
    component: FbnVitalChekInfo
  },
  {
    path: '/public-agencies',
    name: 'PublicAgencies',
    component: PublicAgencies
  },
  {
    path: '/public-agencies-search',
    name: 'PublicAgenciesSearch',
    component: PublicAgenciesSearch
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router