<template>
    <div class="flex-row-container">
        <button 
            v-show="showBackButton"
            id="back-button" 
            class="flex-row-item text-white bg-gray-700 hover:bg-gray-500" 
            @click="previousPage()">
            {{ $t('Template.Buttons.PageNavigation.Back') }}
        </button>
        <button 
            v-show="showProceedButton"
            id="proceed-button" 
            class="flex-row-item text-white darkblue"
            @click="proceed()">
            {{ $t('Template.Buttons.PageNavigation.Proceed') }}
        </button>
        <button 
            v-show="showSubmitButton" 
            :disabled="isSubmitDisabled"
            id="submit-order-button" 
            :class="`flex-row-item text-white darkblue ${submitButtonStyle}`" 
            @click="proceed()">
            {{ $t('Template.SubmitOrder') }}
        </button>
        <button 
            v-show="showHomeButton"
            id="home-button" 
            class="flex-row-item text-white bg-gray-700 hover:bg-gray-500" 
            @click="homePage()">
            {{ $t('Template.Buttons.PageNavigation.Home') }}
        </button>
        <div class="flex-row-item w-64"></div>
        <button 
            v-show="showHelpButton"
            id="open-help-button" 
            class="flex-row-item text-white darkblue" 
            @click="openHelp()">
            {{ $t('Template.Buttons.PageNavigation.Help') }}
        </button>
        <button 
            v-show="showPrintButton"
            id="print-page-button" 
            class="flex-row-item text-white darkblue" 
            @click="printPage()">
            {{ $t('Template.Print') }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        isSubmitDisabled: {
            type: Boolean,
            required: false
        },
        showBackButton: {
            type: Boolean,
            required: false
        },
        showHelpButton: {
            type: Boolean,
            required: false
        },
        showHomeButton: {
            type: Boolean,
            required: false
        },
        showPrintButton: {
            type: Boolean,
            required: false
        },
        showProceedButton: {
            type: Boolean,
            required: false
        },
        showSubmitButton: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        homePage () {
            this.$emit('homePage')
        },
        openHelp () {
            this.$emit('openHelp')
        },
        previousPage () {
            this.$emit('previousPage')
        },
        printPage () {
            this.$emit('printPage')
        },
        proceed () {
            this.$emit('proceed')
        }
    },
    computed: {
        submitButtonStyle () {
            return this.isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }
    }
}
</script>
<style scoped>
.flex-row-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}
.flex-row-container > .flex-row-item {
    flex: 1 1 auto;
    border-radius: .25rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: .3rem;
    padding-right: .3rem;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    margin-left: .25rem;
    margin-right: .25rem;
}

</style>