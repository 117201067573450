<template>
    <div class="m_pad">
        <div>
            <PublicAgenciesSearch @header-text="$emit('header-text', $event)"/>
        </div>
    </div>
</template>

<script>
import PublicAgenciesSearch from '../components/Reg/PublicAgenciesSearch.vue'
export default {
    components: {
        PublicAgenciesSearch
    }
}
</script>
