<template>
        <a :tabindex ="0" :id="`sub-navigation-${id}`" :class="['sub-navigation-item', defaultState]" :aria-labelledby="`sub-navigation-${id}`" @click="$emit('navigate', { event: $event, id })" style="margin: 0; display: inline-block; width: 100%;">{{name}}</a>
</template>

<script>
export default {
    name: 'SubNavigation',
    props: {
        id: String,
        name: String,
        default: Boolean
    },
    data: () => ({ 
        defaultState: false 
    }),
    emits: ['navigate'],
    mounted() {
        this.defaultState = this.default ? 'active' : '';
    }
}
</script>