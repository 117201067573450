import {
  createStore
} from 'vuex';
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    //#region New State
    FbnFiling: {
      // Variables
      approach: '',
      inProgress: false,
      fbnFilingActions: [],
      reviewPageEdit: false,
      workflow: '',
      isProofOfPublication: false,
      isOnlineCopyNumberLookup: false,
      // Page Objects
      RegisteredOwnerPage: {
        businessType: '',
        filingActions: [],
        registeredOwners: [],
        registeredOwnerTempId: 0,
        sosFile: []
      },
      BusinessNamePage: {
        fbnNames: [],
        originalFbnNames: [],
        filingActions: [],
        businessNameId: {}
      },
      BusinessAddressPage: {
        filingActions: [],
        internationalBusiness: false,
        BusinessAddress: {
          address: '',
          additionalAddress: '',
          city: '',
          state: '',
          zipCode: '',
          county: '',
          country: 'United States',
          articles: ''
        },
        OriginalBusinessAddress: {
          address: '',
          additionalAddress: '',
          city: '',
          state: '',
          zipCode: '',
          county: '',
          country: 'United States',
          articles: '',
        }
      },
      VitalChekPage: {
        filingActions: []
      },
      RequestorInformationPage: {
        firstName: '',
        middleName: '',
        lastName: '',
        address: '',
        addAddress: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        email: '',
        phone: '',
        dateRegistrantCommenced: '',
        additionalCopies: 1
      },
      OriginalRequestorInformation: {
          firstName: '',
          middleName: '',
          lastName: '',
          address: '',
          addAddress: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          email: '',
          phone: '',
          dateRegistrantCommenced: '',
          additionalCopies: 1
      },
    },
    //#endregion

    //#region Old State
    workflow: '',
    FbnFilings: [{
      agreedFbnIsAvaliable: false,
      // Filing Type Page
      filingSelectionChoice: '',
      filingSelectionType: '',
      // SOS File 
      sosFile: [],
      // Type of Business Page
      businessType: '',
      // Register Name Page state
      fbnNames: [],
      // Register Business Address Page State
      RegisterBusinessAddress: {
        BusinessAddress: {
          address: '',
          additionalAddress: '',
          city: '',
          state: '',
          zipCode: '',
          county: '',
          country: 'United States',
          articles: ''
        }
      },
      internationalToggle: false,
      businessInternational: false,
      // Additional Information Page State
      AdditionalInformation: {
        RequestorInformation: {
          firstName: '',
          middleName: '',
          lastName: '',
          address: '',
          addAddress: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          email: '',
          phone: ''
        },
        AdditionalInformation: {
          dateRegistrantCommenced: '',
          additionalCopies: 1
        }
      },
      // VC Information
      VitalChekInformation: {
        firstName: null,
        middleName: null,
        lastName: null,
        address: null,
        additionalAddress: null,
        city: null,
        state: null,
        zipCode: null,
        email: null,
        confirmEmail: null,
        phone: null
      },
      // Signature Page State
      signatureInfo: {
        FullName: '',
        SignatoryName: '',
        SignatoryTitle: ''
      },
      // Register Owner Page state
      registeredOwners: [],
      // Preview Page State
      signature: '',
      // Confirmation Page State
      orderNumber: 0,
      // Filing Number
      filingNumber: 0,
      // Filing Actions 
      filingActions: [],
      // Proof of Publications State
      publishingData: {
        publication: '',
        publishStartDate: '',
        publishEndDate: ''
      },
      sosFilePublications: [],
      //Other Filings
      addressBackup: {},
      didSetAddressBackup: false,
      registeredOwnersBackup: [],
      isBackup: false,
      backup: [],
      businessId: {},
      registeredOwnerIds: {},
      newFbnNames: [],
      additionalCopiesBackup: 0,
      registeredOwnerTempId: 1
    }],
    // Misc State
    // Current filing number
    currentFiliingIndex: 1,
    // Nav crumbtrail text
    subheader: '',
    // Help menu component
    isHelpOpen: false,
    // edit state
    edit: false,
    // businessTypeBack: false,
    filingPublicationObjects: [],
    currentPublicationFiles: [],
    pdf: '',
    // TODO need to add logic on additional filings when in-person is enabled
    appHasData: false

    //#endregion
  },
  mutations: {
    // Mutations must be synchronous , mutations change the state directly, the components however do this by calling actions
    // which in turn modify the state
    // Payloads are the data you send to the state change 

    //#region New Mutations

    // Set Fbn Filing Object
    setFbnFiling: (state, payload) => {
      state.FbnFiling = payload
    },

    //#endregion

    //#region Old Mutations

    // Home Page Methods
    setFilingApproach: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].filingSelectionChoice = payload;
    },
    setWorkflow: (state, payload) => {
      state.workflow = payload;
    },
    // FBN Search Methods
    setAgreedFbnIsAvaliable: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].agreedFbnIsAvaliable = !state.agreedFbnIsAvaliable;
    },
    setFiling: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1] = payload;
    },
    // Filing Type Methods
    setFilingSelectionType: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].filingSelectionType = payload;
    },
    setSOSFile: (state, payload) => {
      var sosFile = {
        file: payload,
        base64: ''
      }
      var reader = new FileReader()
      reader.readAsDataURL(payload);
      reader.onload = () => {
        sosFile.base64 = reader.result;
      }
      state.FbnFilings[state.currentFiliingIndex - 1].sosFile.push(sosFile)
    },
    setSOSFilePublications: (state, payload) => {
      var sosFile = {
        file: payload.file,
        filingNumber: payload.filingNumber
      }
      state.FbnFilings.forEach(f => {
        if (f.jediFilingNumber === payload.filingNumber) {
          f.sosFilePublications.push(sosFile);
        };
      });
      state.currentPublicationFiles.push(sosFile);
    },
    deleteSOSFile: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].sosFile = [];
    },
    deleteSOSFilePublications: (state, payload) => {
      const indexOfPublicationFile = state.currentPublicationFiles.findIndex(f => f.filingNumber === payload);
      state.currentPublicationFiles.splice(indexOfPublicationFile, 1);
      let index;
      state.FbnFilings.forEach((f, i) => {
        if (f.jediFilingNumber === payload) {
          index = i;
        };
      });
      state.FbnFilings[index].sosFilePublications = [];
    },
    deleteAllSOSFiles: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].sosFile = [];
    },
    deleteAllSOSFilesPublications: (state) => {
      state.FbnFilings.forEach(f => {
        f.sosFilePublications = [];
      });
    },
    // Business Page Methods
    setBusinessType: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].businessType = payload;
    },
    // Business Address Page Methods
    setBusinessAddress: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].RegisterBusinessAddress = payload;
    },
    setInternationalToggle: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].internationalToggle = payload;
    },
    setInternationalBusiness: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].businessInternational = payload;
    },
    // Register Owner Page Methods
    setRegisteredOwners: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].registeredOwners = payload;
    },
    removeOwners: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].registeredOwners = [];
    },
    // Additional Information Page Methods 
    setAdditionalInformation: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].AdditionalInformation = payload;
    },
    // VitalChek Information
    setVitalChekInformation: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].VitalChekInformation = payload;
    },
    // Signature Page Methods
    setSignatureInformation: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].signatureInfo = payload;
    },
    // Register Name Page Methods
    setFbnNames: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].fbnNames.push(payload);
    },
    deleteFbnName: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].fbnNames = [
        ...state.FbnFilings[state.currentFiliingIndex - 1].fbnNames.slice(0, payload),
        ...state.FbnFilings[state.currentFiliingIndex - 1].fbnNames.slice(payload + 1)
      ];
    },
    removeNames: (state) => {
      // state.FbnFilings[state.currentFiliingIndex - 1].fbnNames.pop();
      state.FbnFilings[state.currentFiliingIndex - 1].fbnNames = [];
    },
    // Preview Page Methods
    setSignature: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].signature = payload;
    },
    // Order Confirmation Page Methods
    setOrderNumber: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].orderNumber = payload;
    },
    setFilingNumber: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].filingNumber = payload;
    },
    // Misc Methods
    setEdit: (state, payload) => {
      state.edit = payload;
    },
    setSubheader: (state, payload) => {
      state.subheader = payload;
    },
    setHelpMenu: (state, payload) => {
      state.isHelpOpen = payload;
    },
    setCurrentFilingIndex: (state, payload) => {
      state.currentFiliingIndex = payload;
    },
    createAdditionalFiling: (state) => {
      state.edit = false;
      // state.businessTypeBack = false;
      // Home button warning
      state.showHomeWarning = false;
      state.FbnFilings.push({
        agreedFbnIsAvaliable: false,
        // Filing Type Page
        filingSelectionChoice: '',
        filingSelectionType: '',
        // SOS File 
        sosFile: [],
        // Type of Business Page
        businessType: '',
        // Register Name Page state
        fbnNames: [],
        // Register Business Address Page State
        RegisterBusinessAddress: {
          BusinessAddress: {
            address: '',
            additionalAddress: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: 'United States',
            articles: ''
          }
        },
        mailingToggle: false,
        internationalToggle: false,
        businessInternational: false,
        // Additional Information Page State
        AdditionalInformation: {
          RequestorInformation: {
            firstName: '',
            middleName: '',
            lastName: '',
            address: '',
            addAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: 'United States',
            email: '',
            phone: ''
          },
          AdditionalInformation: {
            dateRegistrantCommenced: '',
            additionalCopies: 1,
          }
        },
        // Signature Page State
        signatureInfo: {
          FullName: '',
          SignatoryName: '',
          SignatoryTitle: ''
        },
        // Register Owner Page state
        registeredOwners: [],
        // Preview Page State
        signature: '',
        // Confirmation Page State
        orderNumber: 0,
        // Filing Action Code for abandon/renewal/withdraw
        filingActions: [],
        // Proof of Publications State
        publishingData: {
          publication: '',
          publishStartDate: '',
          publishEndDate: ''
        },
        sosFilePublications: [],
        //Other Filings
        addressBackup: {},
        didSetAddressBackup: false,
        registeredOwnersBackup: [],
        isBackup: false,
        backup: [],
        businessId: {},
        registeredOwnerIds: {},
        newFbnNames: [],
        additionalCopiesBackup: 0,
        registeredOwnerTempId: 1
      });
      state.currentFiliingIndex = state.FbnFilings.length;
    },
    deleteFbnFiling: (state, payload) => {
      state.currentFiliingIndex = state.FbnFilings.length - 1;
      state.FbnFilings = [
        ...state.FbnFilings.slice(0, payload),
        ...state.FbnFilings.slice(payload + 1)
      ]
    },
    deleteAllFbnFilings: (state) => {
      state.FbnFilings = [];
      state.edit = false;
      // state.businessTypeBack = false;
      state.filingPublicationObjects = [];
      state.currentPublicationFiles = [];
      state.FbnFilings.push({
        agreedFbnIsAvaliable: false,
        // Filing Type Page
        filingSelectionChoice: '',
        filingSelectionType: '',
        // SOS File 
        sosFile: [],
        // Type of Business Page
        businessType: '',
        // Register Name Page state
        fbnNames: [],
        // Register Business Address Page State
        RegisterBusinessAddress: {
          BusinessAddress: {
            address: '',
            additionalAddress: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: 'United States',
            articles: ''
          }
        },
        mailingToggle: false,
        internationalToggle: false,
        businessInternational: false,
        // Additional Information Page State
        AdditionalInformation: {
          RequestorInformation: {
            firstName: '',
            middleName: '',
            lastName: '',
            address: '',
            addAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: 'United States',
            email: '',
            phone: ''
          },
          AdditionalInformation: {
            dateRegistrantCommenced: '',
            additionalCopies: 1,
          }
        },
        // VC Information
        VitalChekInformation: {
          firstName: null,
          middleName: null,
          lastName: null,
          address: null,
          additionalAddress: null,
          city: null,
          state: null,
          zipCode: null,
          email: null,
          confirmEmail: null,
          phone: null
        },
        // Signature Page State
        signatureInfo: {
          FullName: '',
          SignatoryName: '',
          SignatoryTitle: ''
        },
        // Register Owner Page state
        registeredOwners: [],
        // Preview Page State
        signature: '',
        // Confirmation Page State
        orderNumber: 0,
        // Filing Action Code for abandon/renewal/withdraw
        filingActions: [],
        // Proof of Publications State
        publishingData: {
          publication: '',
          publishStartDate: '',
          publishEndDate: ''
        },
        sosFilePublications: [],
        //Other Filings
        addressBackup: {},
        didSetAddressBackup: false,
        registeredOwnersBackup: [],
        isBackup: false,
        backup: [],
        businessId: {},
        registeredOwnerIds: {},
        newFbnNames: [],
        additionalCopiesBackup: 0,
        registeredOwnerTempId: 1
      });
      state.currentFiliingIndex = 1;
    },
    setPublications: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].publishingData = payload;
    },
    setFilingPublicationObjects: (state, payload) => {
      state.filingPublicationObjects.push(payload);
    },
    removeFilingPublicationObject: (state, payload) => {
      for (let i = 0; i < state.filingPublicationObjects.length; i++) {
        if (state.filingPublicationObjects[i].jediFilingNumber === payload) {
          state.filingPublicationObjects.splice(i, 1);
        }
      }
    },
    resetFilingPublicationObjects: (state) => {
      state.filingPublicationObjects = [];
    },
    // Amendment
    setNewFbnNames: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].newFbnNames.push(payload);
    },
    // Set PDF
    setPDF: (state, payload) => {
      state.pdf = payload;
    },
    deleteNewFbnName: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].newFbnNames.splice(payload, 1);
    },
    removeNewNames: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].newFbnNames = [];
    },
    // backup
    setIsBackup: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].isBackup = payload;
    },
    setBackup: (state) => {
      // on first run through the process the backup list is false, create the backup
      if (state.FbnFilings[state.currentFiliingIndex - 1].isBackup === false) {
        const originalNames = [...state.FbnFilings[state.currentFiliingIndex - 1].fbnNames];
        state.FbnFilings[state.currentFiliingIndex - 1].backup = JSON.parse(JSON.stringify(originalNames));
      }
    },
    setBackupOwners: (state) => {
      // on first run through the process the backup list is false, create the backup
      if (state.FbnFilings[state.currentFiliingIndex - 1].isBackup === false) {
        const originalOwners = [...state.FbnFilings[state.currentFiliingIndex - 1].registeredOwners];
        state.FbnFilings[state.currentFiliingIndex - 1].backup = JSON.parse(JSON.stringify(originalOwners));
      }
    },
    resetBackup: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].backup = [];
    },
    // business id list
    setBusinessId: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].businessId = payload;
    },
    // register owner id list
    setRegisteredOwnerId: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnerIds = payload;
    },
    setAddressBackup: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].addressBackup = payload;
    },
    setRegisteredOwnersBackup: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnersBackup = payload;
    },
    setAdditionalCopiesBackup: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].additionalCopiesBackup = payload;
    },
    setRegisteredOwnerTempId: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnerTempId = payload;
    },
    subtractRegisteredOwnerTempIdByOne: (state) => {
      state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnerTempId--;
    },
    didSetAddressBackup: (state, payload) => {
      state.FbnFilings[state.currentFiliingIndex - 1].didSetAddressBackup = payload;
    },
    setAppHasData: (state, payload) => {
      state.appHasData = payload;
    },
    clearAllState: (state) => {
      //#region Clear All State

      state.FbnFiling = {
        approach: '',
        inProgress: false,
        fbnFilingActions: [],
        reviewPageEdit: false,
        workflow: '',
        isProofOfPublication: false,
        isOnlineCopyNumberLookup: false,
        RegisteredOwnerPage: {
          businessType: '',
          filingActions: [],
          registeredOwners: [],
          registeredOwnerTempId: 0,
          sosFile: []
        },
        BusinessNamePage: {
          fbnNames: [],
          originalFbnNames: [],
          filingActions: [],
          businessNameId: {}
        },
        BusinessAddressPage: {
          filingActions: [],
          internationalBusiness: false,
          BusinessAddress: {
            address: '',
            additionalAddress: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: 'United States',
            articles: ''
          },
          OriginalBusinessAddress: {
            address: '',
            additionalAddress: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: 'United States',
            articles: '',
          }
        },
        VitalChekPage: {
          filingActions: []
        },
        RequestorInformationPage: {
          firstName: '',
          middleName: '',
          lastName: '',
          address: '',
          addAddress: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          email: '',
          phone: '',
          dateRegistrantCommenced: '',
          additionalCopies: 1
        },
        OriginalRequestorInformation: {
            firstName: '',
            middleName: '',
            lastName: '',
            address: '',
            addAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            email: '',
            phone: '',
            dateRegistrantCommenced: '',
            additionalCopies: 1
        }
      }
      //#endregion

      //#region Old Clear All State (Some is still in use will remove when refactor is completed)
      state.workflow = '';
      state.fbnFilings = [];
      state.FbnFilings = [{
        agreedFbnIsAvaliable: false,
        // Filing Type Page
        filingSelectionType: '',
        filingSelectionChoice: '',
        // SOS File 
        sosFile: [],
        // Type of Business Page
        businessType: '',
        // Register Name Page state
        fbnNames: [],
        // Register Business Address Page State
        RegisterBusinessAddress: {
          BusinessAddress: {
            address: '',
            additionalAddress: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: 'United States',
            articles: ''
          }
        },
        internationalToggle: false,
        businessInternational: false,
        // Additional Information Page State
        AdditionalInformation: {
          RequestorInformation: {
            firstName: '',
            middleName: '',
            lastName: '',
            address: '',
            addAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: 'United States',
            email: '',
            phone: ''
          },
          AdditionalInformation: {
            dateRegistrantCommenced: '',
            additionalCopies: 1
          }
        },
        // Signature Page State
        signatureInfo: {
          FullName: '',
          SignatoryName: '',
          SignatoryTitle: ''
        },
        // Register Owner Page state
        registeredOwners: [],
        // Preview Page State
        signature: '',
        // Confirmation Page State
        orderNumber: 0,
        // Filing Actions 
        filingActions: [],
        // Proof of Publications State
        publishingData: {
          publication: '',
          publishStartDate: '',
          publishEndDate: ''
        },
        sosFilePublications: [],
        //Other Filings
        addressBackup: {},
        didSetAddressBackup: false,
        registeredOwnersBackup: [],
        isBackup: false,
        backup: [],
        businessId: {},
        registeredOwnerIds: {},
        newFbnNames: [],
        additionalCopiesBackup: 0,
        registeredOwnerTempId: 1
      }];
      // Misc State
      // Current filing number
      state.currentFiliingIndex = 1;
      // Nav crumbtrail text
      state.subheader = '';
      // Help menu component
      state.isHelpOpen = false;
      // edit state
      state.edit = false;
      // state.businessTypeBack = false;
      state.filingPublicationObjects = [];
      state.currentPublicationFiles = [];
      state.pdf = '';
      state.appHasData = false;
      //#endregion
    }
    //#endregion
  },
  actions: {
    // Actions can be Async, actions commit the state change to the store
    // context is the current state object with any performed mutations
    // committing changes the state that all the other components can see
    
    //#region New Actions

    // Commit Fbn Filing Object to State
    setFbnFiling: (context, payload) => {
      context.commit('setFbnFiling', payload)
    },

    //#endregion

    //#region Old Actions

    // Home Page Commits
    setFilingApproach(context, payload) {
      context.commit('setFilingApproach', payload);
    },
    setWorkflow(context, payload) {
      context.commit('setWorkflow', payload);
    },
    // FBN Search Commits 
    setAgreedFbnIsAvaliable(context) {
      context.commit('setAgreedFbnIsAvaliable');
    },
    setFiling(context, payload) {
      context.commit('setFiling', payload);
    },
    // Filing Type Commits
    setFilingSelectionType: (context, payload) => {
      context.commit('setFilingSelectionType', payload);
    },
    setSOSFile: (context, payload) => {
      context.commit('setSOSFile', payload);
    },
    setSOSFilePublications: (context, payload) => {
      context.commit('setSOSFilePublications', payload);
    },
    deleteSOSFile: (context, payload) => {
      context.commit('deleteSOSFile', payload);
    },
    deleteSOSFilePublications: (context, payload) => {
      context.commit('deleteSOSFilePublications', payload);
    },
    deleteAllSOSFiles: (context) => {
      context.commit('deleteAllSOSFiles');
    },
    deleteAllSOSFilesPublications: (context) => {
      context.commit('deleteAllSOSFilesPublications');
    },
    // Business Page Commits
    setBusinessType: (context, payload) => {
      context.commit('setBusinessType', payload);
    },
    setInternationalToggle: (context, payload) => {
      context.commit('setInternationalToggle', payload);
    },
    // Register Business Address Commits
    setBusinessAddress: (context, payload) => {
      context.commit('setBusinessAddress', payload);
    },
    setInternationalBusiness: (context, payload) => {
      context.commit('setInternationalBusiness', payload);
    },
    // Register Owner Page Commits
    setRegisteredOwners: (context, payload) => {
      context.commit('setRegisteredOwners', payload);
    },
    removeOwners: (context) => {
      context.commit('removeOwners');
    },
    // Register Name page Commits
    setFbnNames: (context, payload) => {
      context.commit('setFbnNames', payload);
    },
    deleteFbnName: (context, payload) => {
      context.commit('deleteFbnName', payload);
    },
    removeNames: (context) => {
      context.commit('removeNames');
    },
    // Additional Information Page Commits
    setAdditionalInformation: (context, payload) => {
      context.commit('setAdditionalInformation', payload);
    },
    // VitalChek Information Commit
    setVitalChekInformation: (context, payload) => {
      context.commit('setVitalChekInformation', payload);
    },
    // Signature Page Commits
    setSignatureInformation: (context, payload) => {
      context.commit('setSignatureInformation', payload);
    },
    // Preview Page Commits
    setSignature: (context, payload) => {
      context.commit('setSignature', payload);
    },
    // Order Confirmation Commits
    setOrderNumber: (context, payload) => {
      context.commit('setOrderNumber', payload);
    },
    setFilingNumber: (context, payload) => {
      context.commit('setFilingNumber', payload);
    },
    // Misc Commits
    setSubheader: (context, payload) => {
      context.commit('setSubheader', payload);
    },
    setHelpMenu: (context, payload) => {
      context.commit('setHelpMenu', payload);
    },
    setCurrentFilingIndex: (context, payload) => {
      context.commit('setCurrentFilingIndex', payload);
    },
    createAdditionalFiling: (context) => {
      context.commit('createAdditionalFiling');
    },
    deleteFbnFiling: (context, payload) => {
      context.commit('deleteFbnFiling', payload);
    },
    deleteAllFbnFilings: (context) => {
      context.commit('deleteAllFbnFilings');
    },
    setEdit: (context, payload) => {
      context.commit('setEdit', payload);
    },
    // Filing Action
    setFilingActions: (context, payload) => {
      context.commit('setFilingActions', payload);
    },
    setFilingActionsReplacement: (context, payload) => {
      context.commit('setFilingActionsReplacement', payload);
    },
    setPublications: (context, payload) => {
      context.commit('setPublications', payload);
    },
    // Amendment
    setNewFbnNames: (context, payload) => {
      context.commit('setNewFbnNames', payload);
    },
    // PDF
    setPDF: (context, payload) => {
      context.commit('setPDF', payload);
    },
    deleteNewFbnName: (context, payload) => {
      context.commit('deleteNewFbnName', payload);
    },
    removeNewNames: (context) => {
      context.commit('removeNewNames');
    },
    // backup
    setIsBackup: (context, payload) => {
      context.commit('setIsBackup', payload);
    },
    setBackup: (context, payload) => {
      context.commit('setBackup', payload);
    },
    setBackupOwners: (context, payload) => {
      context.commit('setBackupOwners', payload);
    },
    resetBackup: (context) => {
      context.commit('resetBackup');
    },
    setBusinessId: (context, payload) => {
      context.commit('setBusinessId', payload);
    },
    setRegisteredOwnerId: (context, payload) => {
      context.commit('setRegisteredOwnerId', payload);
    },
    setAddressBackup: (context, payload) => {
      context.commit('setAddressBackup', payload);
    },
    setRegisteredOwnersBackup: (context, payload) => {
      context.commit('setRegisteredOwnersBackup', payload);
    },
    setAdditionalCopiesBackup: (context, payload) => {
      context.commit('setAdditionalCopiesBackup', payload);
    },
    setRegisteredOwnerTempId: (context, payload) => {
      context.commit('setRegisteredOwnerTempId', payload);
    },
    subtractRegisteredOwnerTempIdByOne: (context) => {
      context.commit('subtractRegisteredOwnerTempIdByOne')
    },
    setFilingPublicationObjects: (context, payload) => {
      context.commit('setFilingPublicationObjects', payload);
    },
    removeFilingPublicationObject: (context, payload) => {
      context.commit('removeFilingPublicationObject', payload);
    },
    resetFilingPublicationObjects: (context) => {
      context.commit('resetFilingPublicationObjects');
    },
    didSetAddressBackup: (context, payload) => {
      context.commit('didSetAddressBackup', payload);
    },
    setAppHasData: (context, payload) => {
      context.commit('setAppHasData', payload);
    },
    clearAllState: (context) => {
      context.commit('clearAllState');
    }

    //#endregion
  },
  getters: {
    // Use these to grab state 

    //#region New Getters

    // Get Fbn Filing Object
    getFbnFiling: state => state.FbnFiling,

    //#endregion

    //#region Old Getters

    getWorkflow: state => state.workflow,
    // Filing Type Page Getters
    getFilingApproach: state => state.FbnFilings[state.currentFiliingIndex - 1].filingSelectionChoice,
    getFilingSelectionType: state => state.FbnFilings[state.currentFiliingIndex - 1].filingSelectionType,
    // FBN Search 
    getAgreedFbnIsAvaliable: state => state.FbnFilings[state.currentFiliingIndex - 1].agreedFbnIsAvaliable,
    // Fbn Business Type
    getBusinessType: state => state.FbnFilings[state.currentFiliingIndex - 1].businessType,
    getSOSFile: state => state.FbnFilings[state.currentFiliingIndex - 1].sosFile,
    // Fbn business Names
    getFbnNames: state => state.FbnFilings[state.currentFiliingIndex - 1].fbnNames,
    // Register Business Address Page
    getBusinessAddress: state => state.FbnFilings[state.currentFiliingIndex - 1].RegisterBusinessAddress,
    getArticles: state => state.FbnFilings[state.currentFiliingIndex - 1].RegisterBusinessAddress.BusinessAddress.articles,
    getInternationalToggle: state => state.FbnFilings[state.currentFiliingIndex - 1].internationalToggle,
    // Register Owner Page
    getRegisteredOwners: state => state.FbnFilings[state.currentFiliingIndex - 1].registeredOwners,
    // Additional Information Page
    getAdditionalInformation: state => state.FbnFilings[state.currentFiliingIndex - 1].AdditionalInformation,
    // VitalChek Page
    getVitalChekInformation: state => state.FbnFilings[state.currentFiliingIndex - 1].VitalChekInformation,
    // Signature Page
    getSignatureInformation: state => state.FbnFilings[state.currentFiliingIndex - 1].signatureInfo,
    // Preview Page
    getSignature: state => state.FbnFilings[state.currentFiliingIndex - 1].signature,
    // Order Confirmation Page
    getOrderConfirmationNumber: state => state.FbnFilings[state.currentFiliingIndex - 1].orderNumber,
    getFilingNumber: state => state.FbnFilings[state.currentFiliingIndex - 1].filingNumber,
    // Misc Getters
    getSubheader: state => state.subheader,
    getCurrentFilingIndex: state => state.currentFiliingIndex,
    getFilings: state => state.FbnFilings,
    getCurrentFiling: state => state.FbnFilings[state.currentFiliingIndex - 1],
    getEdit: state => state.edit,
    getPublications: state => state.FbnFilings[state.currentFiliingIndex - 1].publishingData,
    getSOSFilePublications: state => state.FbnFilings[state.currentFiliingIndex - 1].sosFilePublications,
    getNewFbnNames: state => state.FbnFilings[state.currentFiliingIndex - 1].newFbnNames,
    getIsBackup: state => state.FbnFilings[state.currentFiliingIndex - 1].isBackup,
    getBackup: state => state.FbnFilings[state.currentFiliingIndex - 1].backup,
    getBusinessId: state => state.FbnFilings[state.currentFiliingIndex - 1].businessId,
    getRegisteredOwnerIds: state => state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnerIds,
    getPDF: state => state.pdf,
    getAddressBackup: state => state.FbnFilings[state.currentFiliingIndex - 1].addressBackup,
    getDidSetAddressBackup: state => state.FbnFilings[state.currentFiliingIndex - 1].didSetAddressBackup,
    getRegisteredOwnersBackup: state => state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnersBackup,
    getAdditionalCopiesBackup: state => state.FbnFilings[state.currentFiliingIndex - 1].additionalCopiesBackup,
    getRegisteredOwnerTempId: state => state.FbnFilings[state.currentFiliingIndex - 1].registeredOwnerTempId,
    getFilingPublicationObjects: state => state.filingPublicationObjects,
    getCurrentPublicationFilesList: state => state.currentPublicationFiles,
    getInternationalBusiness: state => state.FbnFilings[state.currentFiliingIndex - 1].businessInternational,
    getAppHasData: state => state.appHasData

    //#endregion
  },
  plugins: [createPersistedState()]
})
