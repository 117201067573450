<template>
    <div id="data-table-pagination" class="max-w-screen-xl mx-auto py-2">
        <button class="inline-block pr-2 text-white px-1 py-1 font-semibold darkblue text-center rounded" @click="previousSearchPage()">{{ left }}</button>
        <p class="inline-block pr-2 pl-2">{{ $t('Template.Pagination', { currentPage, total: Math.ceil(totalItems / itemsPerPage) }) }}</p>
        <button class="inline-block pr-2 text-white px-1 py-1 font-semibold darkblue text-center rounded" @click="nextSearchPage()">{{ right }}</button>
    </div>
</template>

<script>

import JediClerkRepository from '../../repositories/JediClerkRepository'
export default {
    props: {
        searchString: String,
        itemsPerPage: Number,
    },
    data: () => ({
        totalItems: 0,
        currentPage: 1,
        left: '<',
        right: '>'
    }),
    methods: {
        apiSearch () {
            JediClerkRepository.search(this.searchString, this.itemsPerPage, this.currentPage)
                .then( data => {
                    this.totalItems = data.data.length > 0 ? data.data[0].maxRowCount : 0
                    if(this.totalItems > 0) {
                        this.formatData(data.data)
                        window.scrollTo(0,0)
                    } else {
                        this.$emit('hideTable', false)
                    }
                })
        },
        nextSearchPage () {
            if (this.currentPage > (this.totalItems / this.itemsPerPage)) return
            this.currentPage = this.currentPage + 1
            this.apiSearch()
        },
        previousSearchPage () {
            if(this.currentPage <= 1) return
            this.currentPage = this.currentPage - 1
            this.apiSearch()
        },

        // Helper Methods
        getDate (dateTime) {
            const date = dateTime.split('T')[0]
            const yearMonthDay = date.split('-')
            const year = yearMonthDay[0]
            const month = yearMonthDay[1]
            const day = yearMonthDay[2]
            return `${month}/${day}/${year}`
        },
        formatData (results) {
            const tableData = []
            const tableDTO = {}
            results.forEach( tableRow => {
                tableRow.fbnBusinessNames.forEach( business => {
                    const row = {
                        businessName: business,
                        filingNumber: tableRow.filingNumber,
                        filedTS: this.getDate(tableRow.filedTS),
                    }
                    if(this.doesBusinessNameIncludeKeyword(row.businessName)) {
                        tableData.push(row)
                    }
                })
            });
            tableDTO.tableData = tableData 
            tableDTO.tableHeaders = [ 
                this.$t('Reg.Search.Results.Columns.BusinessName'), 
                this.$t('Reg.Search.Results.Columns.DocumentNumber'), 
                this.$t('Reg.Search.Results.Columns.DateFiled')
            ]
            this.$emit('setTableDTO', tableDTO)
        },
        doesBusinessNameIncludeKeyword (businessName) {
            businessName = businessName.toLowerCase();
            return businessName.includes(this.searchString.toLowerCase().trim())
        },
        resetValues () {
            this.currentPage = 1
            this.totalItems = 0
        }
    }
}
</script>