<template>
    <div class="py-2 text-xl">
        <p><strong>{{ title }}</strong></p>
        <p>{{ subTitle }}</p>
        <br>
        <input aria-label="upload-sos-file-button" type="file" id="upload-sos-file-button" name="file" accept="image/jpeg, application/pdf, image/tiff, image/png" @change="setStoreState" />
        <br >
        <div v-if="cachedFiles.length > 0">
            <br>
            <p><strong>{{ $t('Template.UploadFiles') }}</strong></p>
            <div v-for="(file, index) in cachedFiles" :key="file.name">
                <div class="flex flex-row items-center">
                    <img :src="require('@/assets/img/icon_checkmark.png')" width="25" height="25"/>
                    {{ file.name }}
                    <button class="text-red-500 pl-4" @click="removeFile(index)" title='Remove File'>
                        Remove File 
                    </button>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib';
import { ConvertFileToBase64 } from '../../assets/common';
export default {
    props: {
        title: String,
        subTitle: String,
        cachedFiles: Array
    },
    methods: {
        async setStoreState(event) {
            if (this.cachedFiles !== undefined && this.cachedFiles.length > 0) {
                this.$emit('onErrorHandler', this.$t('Template.ErrorHandling.MaximumNumberOfFilesMessage'));
                return;
            }
            let fileSizeError = false;
            const twoMegabytesInBytes = 2097152;           
            Array.from(event.target.files).forEach( file => {
                if (file.size > twoMegabytesInBytes) fileSizeError = true;
            })
            if (fileSizeError) {
                this.$emit('onErrorHandler', this.$t('Template.ErrorHandling.FileExceeds2MBMessage'));
                return;
            }
            this.$emit('fileChangeHandler', event);
        },
        async GetPageCount(event) {
            const pdfBase64String = await ConvertFileToBase64(event.target.files[0]);
            const pdfDoc = await PDFDocument.load(pdfBase64String, { ignoreEncryption: true });
            return pdfDoc.getPageCount()
        },
        removeFile(index) {
            this.$emit('fileRemoveHandler', index)    
        }
    }
}
</script>