<template>
    <div>
        <div>
            <FilingWorkflow />          
        </div>    
    </div>
    
</template>

<script>
import FilingWorkflow from '../components/Home/FilingWorkflow.vue'
export default {
    components: {
        FilingWorkflow
    }
}
</script>
