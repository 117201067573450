const states = ['', 'Military', 'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
  'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
  'West Virginia', 'Wisconsin', 'Wyoming'
];

function MapFilingTypeIntToString(filingTypeId) {
    switch (filingTypeId) {
        case 1:
            return 'Fictitious Business Name Statement';
        case 2:
            return 'Fictitious Business Name Statement Renewal';
        case 3:
            return 'Fictitious Business Name Statement Abandonment';
        case 4:
            return 'Fictitious Business Name Statement Withdrawal';
        case 5:
            return 'Proof of Pub Filing';
        default: 
            return null;
    }
}

function ReadBusinessType(type) {
    switch (type.toLowerCase()) {
        case 'individual':
            return 1;
        case 'general partnership':
            return 2;
        case 'limited partnership':
            return 3;
        case 'limited liability company (llc)':
            return 4;
        case 'unincorporated association':
            return 5;
        case 'corporation':
            return 6;
        case 'trust':
            return 7;
        case 'co-partners':
            return 8;
        case 'married couple':
            return 9;
        case 'joint venture':
            return 10;
        case 'state or local registered domestic partnership':
            return 11;
        case 'limited liability partnership':
            return 12;
        default:
            return -1;
    }
}

function MapClerkFilingTypeIntToString(type) {
    switch (type) {
        case 'FBN Withdrawal':
        case 'Fictitious Business Name Statement Withdrawal': 
            return 5;
        case 'FBN Abandonment':
        case 'Fictitious Business Name Statement Abandonment': 
            return 6; 
        case 'FBN Proof of Publication':
            return 7;
        case 'FBN Related Form': 
            return 8;
        case 'FBN Statement':
        case 'Fictitious Business Name Statement': 
            return 9;
        case 'FBN Renewal - Original': 
            return 23;
        case 'FBN Renewal - Refile':
        case 'Fictitious Business Name Statement Renewal': 
            return 24;
        case 'FBN Renewal - New': 
            return 25;
        case 'FBN Amendment':
        case 'Fictitious Business Name Statement Amendment':
            return 37;
        default: 
            return null
    }
}

function MapBusinessTypeIntToString(businessTypeId) {
  switch (businessTypeId) {
      case 1:
          return 'Individual';
      case 2:
          return 'General Partnership';
      case 3:
          return 'Limited Partnership';
      case 4:
          return 'Limited Liability Company (LLC)';
      case 5:
          return 'Unincorporated Association';
      case 6:
          return 'Corporation';
      case 7:
          return 'Trust';
      case 8:
          return 'Co-partners';
      case 9:
          return 'Married Couple';
      case 10:
          return 'Joint Venture';
      case 11:
          return 'State or Local Registered Domestic Partnership';
      case 12:
          return 'Limited Liability Partnership';
      default:
          return null;
  }
}

function Sign(vm, signaturePad) {
    var data = signaturePad.toDataURL('image/png');
    vm.signatureData = data;
    vm.$store.dispatch('setSignature', vm.signatureData);
    if (!vm.didSign) {
        vm.didSign = true;
        vm.showSignMessage = false;
        ControlState.Set(true);
    }
}

// Platform determines desktop/mobile
var Platform = {
    _desktop: false,
    _mobile: false,
    Construct: function() {
        this._desktop = window.getComputedStyle(document.getElementById('desktop'), null).display === 'block';
        this._mobile = window.getComputedStyle(document.getElementById('mobile'), null).display === 'block';
    }
}

// Monitor state/session activity
var MonitorActivity = {
    _idleTime: 0,
    _idleInterval: null,
    _documentData: null,
    _app: null,
    Construct: function(documentData) {
        this._idleInterval = setInterval(this.Timer, 60000);
        this._app = document.getElementById('app');
        this._documentData = documentData;
    },
    Timer: function() {
        MonitorActivity._idleTime++;
        // Reduce potential expensive performance operation, only detect activity after given time, resets session timer
        if (MonitorActivity._idleTime > 14) { // 15 minutes
            MonitorActivity._app.addEventListener('mousemove', MonitorActivity.Reset);
        }
        // Idle session after given time
        if (MonitorActivity._idleTime > 29) { // 30 minutes
            MonitorActivity._idleTime = 0;
            MonitorActivity._documentData.showSessionActivityModalWarning();
            clearInterval(MonitorActivity._idleInterval);
        }
    },
    Reset: function() {
        MonitorActivity._idleTime = 0;
        MonitorActivity._app.removeEventListener('mousemove', MonitorActivity.Reset);
    }
}

// Get/set control enabled state
var ControlState = {
    _control: null,
    Construct: function(control, condition) {
        this._control = document.getElementById(control);
        condition ? this.Enable() : this.Disable();
    },
    Set: function(condition) {
        if (ControlState._control != null) {
            condition ? this.Enable() : this.Disable();
        }
    },
    Enable: function() {
        ControlState._control.classList.remove('button-disabled');
        ControlState._control.classList.add('button-enabled');
    },
    Disable: function() {
        ControlState._control.classList.remove('button-enabled');
        ControlState._control.classList.add('button-disabled');
    }
}

// Language selection toggle
var LanguageSelection = {
    _selector: null,
    Construct: function() {
        const buttonLanguage = !Platform._mobile ? 'button_language' : 'm_button_language';
        const openEvent = !Platform._mobile ? 'mouseover' : 'click';
        this._selector = document.getElementById(buttonLanguage);
        this._selector.addEventListener(openEvent, function() {
            LanguageSelection.Open();
        });
    },
    Open: function () {
        const languageSelection = !Platform._mobile
            ? document.getElementById('language_selection')
            : document.getElementById('m_language_selection');
        const hoverSource = this._selector.getElementsByClassName('hover')[0];
        this.SwapImage(hoverSource.src);
        if (Platform._mobile) {
            languageSelection.style.top = '120px';
        }

        // Toggle display of language selection
        const navigationLinks = document.getElementById('primary_nav').getElementsByClassName('primary');
        const totalNavigationLinks = navigationLinks.length;
        for (let l = 0; l < totalNavigationLinks; l++) {
            navigationLinks[l].addEventListener('mouseover', function() {
                languageSelection.style.display = 'none';
            });
        }
        languageSelection.style.display = 'block';
        languageSelection.addEventListener('mouseleave', function() {
            this.style.display = 'none';
        });
    },
    SwapImage: function (hoverSource) {
        const image = this._selector.getElementsByClassName('swap')[0];
        const originalSource = image.src;
        image.src = hoverSource;
        this._selector.addEventListener('mouseout', function() {
            image.src = originalSource;
        });
    }
}

function AppHasData (containsData, vm) {
    if (!containsData) {
        vm.$router.push('/');
    }
}

const publications = [
    'Acorn, The',
    'Acton Agua Dulce News',
    'Alhambra Post-Advocate',
    'Alhambra Press',
    'Altadena/Pasadena Weekly',
    'Angeles Mesa News Advertiser-Press',
    'American Eagle News',
    'Antelope Valley Journal, The',
    'Antelope Valley Press',
    'Arcadia Tribune, The',
    'Arcadia Weekly',
    'Argonaut, The',
    'Avalon Bay News',
    'Azusa Beacon',
    'Azusa Herald',
    'Baldwin Park Press',
    'Beach Reporter, The',
    'Bell Gardens Review',
    'Bell-Maywood Industrial Post',
    'Bellflower Herald American',
    'Belmont Beacon',
    'Belvedere Citizen, The',
    'Beverly Hills-Beverlywood Weekly, The',
    'Beverly Hills Courier, The',
    'British Weekly',
    'Bulletin, The',
    'Burbank Independent',
    'Burbank Leader',
    'California Crusader',
    'Canyon Crier',
    'Canyon News, The',
    'Catalina Islander',
    'Central News Wave',
    'Claremont Courier',
    'Covina Argus-Citizen, The',
    'Culver City News',
    'Culver City Observer',
    'Culver City Star',
    'Daily Breeze',
    'Daily Commerce',
    'Daily News',
    'Daily News, The',
    'Diamond Bar-Phillips Ranch Highlander',
    'Downey Herald American',
    'Downey Patriot, The',
    'Downtown Gazette',
    'Duarte Dispatch',
    'Duartean & Duartean Dispatch',
    'Eagle Rock Sentinel',
    'East Los Angeles Tribune',
    'Eastside Journal',
    'Easy Reader',
    'El Monte Examiner',
    'El Monte Herald',
    'El Segundo Herald',
    'El Sereno Star',
    'Epoch Times, The',
    'Gardena Valley News',
    'Gateway Guardian, The',
    'Glendale Independent',
    'Glendale News-Press',
    'Glendora Press',
    'Grunion Gazette',
    'Hawaiian Gardens Community News',
    'Hawthorne Press-Tribune',
    'Highland Park News-Herald',
    'Highlander',
    'Highlander Community News',
    'Highlander Press Courier',
    'Hollywood Independent',
    'Huntington Park Bulletin',
    'Industrial Post, The',
    'Inglewood Hawthorne Wave',
    'Inglewood Daily News',
    'Inglewood News',
    'Inglewood Today',
    'Inglewood-Hawthorne Wave',
    'Inland Valley Daily Bulletin',
    'Inland Valley News, Inc.',
    'Korea Central Daily, The',
    'La Canada Valley Sun',
    'L.A. Metropolitan Gazette',
    'L.A. Watts Times',
    'La Opinion',
    'La Puente Valley Journal',
    'Lake Los Angeles News',
    'Lawndale Tribune',
    'Lincoln Heights Bulletin-News',
    'Long Beach Press Telegram',
    'Long Beach Times',
    'Los Angeles Bay News Observer',
    'Los Angeles Bulletin',
    'Los Angeles Daily Journal, The',
    'Los Angeles Daily News',
    'Los Angeles Downtown News',
    'Los Angeles Independent-Hollywood Edition',
    'Los Angeles Metro Publications, Inc.',
    'Los Angeles Sentinel',
    'Los Angeles Times',
    'Los Angeles Watts Times',
    'Los Cerritos Community News',
    'Lynwood Press, The',
    'Malibu Surfside News, The',
    'Malibu Times',
    'Metropolitan Gazette',
    'Metropolitan News-Enterprise',
    'Monrovia News-Post',
    'Monrovia Weekly',
    'Montebello News',
    'Monterey Park Press',
    'Monterey Park Progress',
    'Mountain View News',
    'Newhall Signal, The and Saugus Enterprise',
    'Norwalk Patriot, The',
    'Observer Newspaper, The',
    'Our Weekly',
    'Pace News Publication',
    'Pacific Palisades Post',
    'Palisadian Post',
    'Palos Verdes Peninsula News',
    'Paramount Journal News',
    'Pasadena Gazette',
    'Pasadena Journal News',
    'Pasadena Press',
    'Pasadena Star-News',
    'Pasadena Weekly',
    'Pasadena/San Gabriel Valley Journal, The',
    'Rafu Shimpo, The',
    'Reporter, The',
    'Rosemead Reader',
    'Rowland Heights-Walnut Highlander',
    'San Fernando Valley Sun, The',
    'San Gabriel Sun',
    'San Gabriel Valley Daily Tribune',
    'San Gabriel Valley Examiner',
    'San Marino Tribune',
    'Santa Clarita Gazette & Free Classifieds',
    'Santa Monica Daily Press, The',
    'Santa Monica Mirror',
    'Santa Monica Observer Newspaper',
    'Signal Tribune, The',
    'South Bay Daily Breeze',
    'South El Monte, The',
    'South Gate Press',
    'South Pasadena Review',
    'Southside Journal Wave',
    'Southwest Topics-Wave',
    'Southwest Wave',
    'Temple City Arcadia Sierra Madre Highlander',
    'Temple City Tribune',
    'The Epoch Times',
    'Tolucan, The & Mangolian, The',
    'Tolucan Times and Canyon Crier, The',
    'Torrance Tribune',
    'Valley Vantage',
    'West Covina Press',
    'West Covina Tribune, The', 
]

const businessTypeInformation = {
    en: [
    {
        title: 'Corporation',
        numberOfRegisteredOwners: ['1','1'], /// [min , max] where n = unlimited. Maybe n = 200 because of constraints? 
        NumRegistrants: 'One', 
        EligibleSigner: ['President', 'Vice President', 'CEO', 'CFO', 'Treasurer', 'Secretary'], 
        Description: 'An entity (usually a business) having authority under law to act as a single person distinct from the shareholders who own it and having rights to issue stock and exist indefinitely. Requires proof of current existence and good standing from the California Secretary of State.',
        sosRequired: true
    },
    {
        title: 'General Partnership',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'A partnership in which all partners participate fully in running the business and share equally in profits and losses (though the partners\' monetary contributions may vary).',
        sosRequired: false
    },
    {
        title: 'Joint Venture',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'A business undertaking by two or more persons engaged in a single defined project.',
        sosRequired: false
    },
    {
        title: 'Limited Liability Company (LLC)',
        numberOfRegisteredOwners: ['1','1'],
        NumRegistrants: 'One',
        EligibleSigner: ['President', 'Vice President', 'CEO', 'CFO', 'Treasurer', 'Secretary', 'Manager', 'Member', 'Managing Member'], 
        Description: 'A company - statutorily authorized in certain states - that is characterized by limited liability, management by members or managers, and limitations on ownership transfer. Requires proof of current existence and good standing from the California Secretary of State.',
        sosRequired: true
    },
    {
        title: 'Limited Liability Partnership',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'A partnership in which a partner is not liable for a negligent act committed by another partner or by an employee not under the partner\'s supervision. In California, LLP\'s may only be formed by licensed persons for the practices of public accountancy, law or architecture. Requires proof of current existence and good standing from the California Secretary of State.',
        sosRequired: true
    },
    {
        title: 'Limited Partnership',
        numberOfRegisteredOwners: ['1','500'],
        NumRegistrants: 'One or More',
        EligibleSigner: ['General Partner'], 
        Description: 'A partnership composed of one or more persons who control the business and are personally liable for the partnership\'s debts (called general partners), and one or more persons who contribute capital and share profits but who cannot manage the business and are liable only for the amount of their contribution (called limited partners). In California, a limited partnership must be filed with the Secretary of State. Requires proof of current existence and good standing from the California Secretary of State.',
        sosRequired: true
    },
    {
        title: 'Married Couple',
        numberOfRegisteredOwners: ['2','2'],
        NumRegistrants: 'Two',
        EligibleSigner: ['Husband', 'Wife', 'Owner', 'Partner'], 
        Description: 'A couple that has a lawful spouse living.',
        sosRequired: false
    },
    {
        title: 'State or Local Registered Domestic Partnership',
        numberOfRegisteredOwners: ['2','2'],
        NumRegistrants: 'Two',
        EligibleSigner: ['Partner', 'Domestic Partner'], 
        Description: 'A nonmarital relationship between two persons of the same or opposite sex who live together as a couple for a significant period of time.',
        sosRequired: false
    },
    {
        title: 'Trust',
        numberOfRegisteredOwners: ['1','500'],
        NumRegistrants: 'One or More',
        EligibleSigner: ['Trustee'], 
        Description: 'The right, enforceable solely in equity, to the beneficial enjoyment of property to which another person holds the legal title; a property interest held by one person (the trustee) at the request of another (settler) for the benefit of a third party (the beneficiary). A "business trust" is a form of business organization, similar to a corporation, in which investors receive transferable certificates of beneficial interest (instead of stock shares).',
        sosRequired: false
    },
    {
        title: 'Individual',
        numberOfRegisteredOwners: ['1','1'],
        NumRegistrants: 'One',
        EligibleSigner: ['Owner'], 
        Description: 'Have or relating to a single person or thing, as opposed to a group. An individual proprietorship or sole proprietorship is a business in which one person owns all the assets, owes all the liabilities, and operates in his or her personal capacity.',
        sosRequired: false
    },
    {
        title: 'Unincorporated Association',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'An association is a gathering of people for a common purpose. An unincorporated organization that is not a legal entity separate from the persons who compose it.',
        sosRequired: false
    },
    {
        title: 'Co-partners',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'A co-partner is a member of a partnership. A partnership is a voluntary association of two or more persons who jointly own and operates a business for profit.',
        sosRequired: false
    }],
    es: [
    {
        title: 'Corporación',
        numberOfRegisteredOwners: ['1','1'], /// [min , max] where n = unlimited. Maybe n = 200 because of constraints? 
        NumRegistrants: 'One', 
        EligibleSigner: ['Presidente/a', 'Vicepresidente/a', 'CEO', 'CFO', 'Tesorero/a', 'Secretario/a'], 
        Description: 'Una entidad (generalmente una empresa) que tiene autoridad por ley para actuar como una sola persona distinta de los accionistas que la poseen y que tiene derechos para emitir acciones y existir indefinidamente. Requiere prueba de existencia actual y buena reputación del Secretario de Estado de California.',
        sosRequired: true
    },
    {
        title: 'Sociedad General',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'Una sociedad en la que todos los socios participan plenamente en el funcionamiento del negocio y comparten por igual las ganancias y las pérdidas (aunque las contribuciones monetarias de los socios pueden variar).',
        sosRequired: false
    },
    {
        title: 'Empresa Conjunta',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'Una empresa emprendida por dos o más personas involucradas en un solo proyecto definido.',
        sosRequired: false
    },
    {
        title: 'Sociedad de Responsabilidad Limitada (LLC)',
        numberOfRegisteredOwners: ['1','1'],
        NumRegistrants: 'One',
        EligibleSigner: ['Presidente/a', 'Vicepresidente/a', 'CEO', 'CFO', 'Tesorero/a', 'Secretario/a', 'Gerente', 'Miembro', 'Miembro Administrador'], 
        Description: 'Una compañía - autorizada por ley en ciertos estados - que se caracteriza por responsabilidad limitada, administración por miembros o gerentes y limitaciones en la transferencia de propiedad. Requiere prueba de existencia actual y buena reputación del Secretario de Estado de California.',
        sosRequired: true
    },
    {
        title: 'Sociedad de Responsabilidad Limitada',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'Una sociedad en la que un socio no es responsable de un acto negligente cometido por otro socio o por un empleado que no está bajo la supervisión del socio. En California, las LLP solo pueden estar formadas por personas con licencia para las prácticas de contabilidad pública, derecho o arquitectura. Requiere prueba de existencia actual y buena reputación del Secretario de Estado de California.',
        sosRequired: true
    },
    {
        title: 'Sociedad Limitada',
        numberOfRegisteredOwners: ['1','500'],
        NumRegistrants: 'One or More',
        EligibleSigner: ['General Partner'], 
        Description: 'Una sociedad compuesta por una o más personas que controlan el negocio y son personalmente responsables de las deudas de la sociedad (denominados socios generales), y una o más personas que aportan capital y comparten beneficios pero que no pueden administrar el negocio y son responsables únicamente. por el monto de su contribución (llamados socios comanditarios). En California, se debe presentar una sociedad limitada ante la Secretaría de Estado. Requiere prueba de existencia actual y buena reputación del Secretario de Estado de California.',
        sosRequired: true
    },
    {
        title: 'Pareja casada',
        numberOfRegisteredOwners: ['2','2'],
        NumRegistrants: 'Two',
        EligibleSigner: ['Husband', 'Wife', 'Owner', 'Partner'], 
        Description: 'Una pareja que tiene un cónyuge legal vivo.',
        sosRequired: false
    },
    {
        title: 'Sociedad Doméstica Registrada Estatal o Local',
        numberOfRegisteredOwners: ['2','2'],
        NumRegistrants: 'Two',
        EligibleSigner: ['Partner', 'Domestic Partner'], 
        Description: 'Una relación no matrimonial entre dos personas del mismo sexo o del sexo opuesto que viven juntas como pareja durante un período de tiempo significativo.',
        sosRequired: false
    },
    {
        title: 'Fideicomisos',
        numberOfRegisteredOwners: ['1','500'],
        NumRegistrants: 'One or More',
        EligibleSigner: ['Trustee'], 
        Description: 'El derecho, exigible únicamente en equidad, al disfrute beneficioso de la propiedad de la que otra persona tiene el título legal; un interés de propiedad en poder de una persona (el fiduciario) a solicitud de otro (mediador) en beneficio de un tercero (el beneficiario). Un "fideicomiso empresarial" es una forma de organización empresarial, similar a una corporación, en la que los inversores reciben certificados transferibles de interés beneficioso (en lugar de acciones).',
        sosRequired: false
    },
    {
        title: 'Individual',
        numberOfRegisteredOwners: ['1','1'],
        NumRegistrants: 'One',
        EligibleSigner: ['Owner'], 
        Description: 'Tener o relacionarse con una sola persona o cosa, a diferencia de un grupo. Una propiedad individual o propiedad unipersonal es un negocio en el que una persona posee todos los activos, debe todos los pasivos y opera a título personal.',
        sosRequired: false
    },
    {
        title: 'Asociación no incorporada',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'Una asociación es una reunión de personas con un propósito común. Una organización no incorporada que no es una entidad legal separada de las personas que la componen.',
        sosRequired: false
    },
    {
        title: 'Coasociados',
        numberOfRegisteredOwners: ['2','500'],
        NumRegistrants: 'Two or More',
        EligibleSigner: ['General Partner'], 
        Description: 'Un coasociados es miembro de una asociación. Una sociedad es una asociación voluntaria de dos o más personas que poseen y operan conjuntamente un negocio con fines de lucro.',
        sosRequired: false
    }],
}

const StateMapToAbbreviatedState = {
  Military: 'AE',
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Is': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  'Virgin Islands': 'VI',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  '': null,
  null: ''
}

const countries = [
    'Afghanistan', 'Åland Islands', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 
    'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia, Plurinational State of', 'Bonaire, Sint Eustatius and Saba', 'Bosnia and Herzegovina', 
    'Botswana', 'Bouvet Island', 'Brazil', 'British Indian Ocean Territory', 'Brunei Darussalam', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 'Cayman Islands', 'Central African Republic', 
    'Chad', 'Chile', 'China', 'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia', 'Comoros', 'Congo', 'Congo, the Democratic Republic of the', 'Cook Islands', 'Costa Rica', 'Côte d\'Ivoire', 'Croatia', 'Cuba', 'Curaçao', 'Cyprus', 
    'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia', 'Falkland Islands (Malvinas)', 'Faroe Islands', 'Fiji', 'Finland', 
    'France', 'French Guiana', 'French Polynesia', 'French Southern Territories', 'Gabon', 'Gambia', 'Georgia (country)', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guadeloupe', 'Guam', 'Guatemala', 'Guernsey', 
    'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Heard Island and McDonald Islands', 'Holy See (Vatican City State)', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran, Islamic Republic of', 'Iraq', 'Ireland', 
    'Isle of Man', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Korea, Democratic People\'s Republic of', 'Korea, Republic of', 'Kuwait', 'Kyrgyzstan', 'Lao People\'s Democratic Republic', 
    'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macao', 'Macedonia, the former Yugoslav Republic of', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 
    'Martinique', 'Mauritania', 'Mauritius', 'Mayotte', 'Mexico', 'Micronesia, Federated States of', 'Moldova, Republic of', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 
    'Netherlands', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'Norfolk Island', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine, State of', 'Panama', 'Papua New Guinea', 'Paraguay', 
    'Peru', 'Philippines', 'Pitcairn', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Réunion', 'Romania', 'Russian Federation', 'Rwanda', 'Saint Barthélemy', 'Saint Helena, Ascension and Tristan da Cunha', 'Saint Kitts and Nevis', 'Saint Lucia', 
    'Saint Martin (French part)', 'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 
    'Sint Maarten (Dutch part)', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Georgia and the South Sandwich Islands', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen', 'Swaziland', 
    'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan', 'Tajikistan', 'Tanzania, United Republic of', 'Thailand', 'Timor-Leste', 'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Turks and Caicos Islands', 
    'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'United States Minor Outlying Islands', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela, Bolivarian Republic of', 'Viet Nam', 'Virgin Islands, British', 
    'Virgin Islands, U.S.', 'Wallis and Futuna', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe', 
]

const CountryAbbrevNameToCountryNameMap = {
    AFG: 'Afghanistan',
    ALA: 'Åland Islands',
    ALB: 'Albania',
    DZA: 'Algeria',
    ASM: 'American Samoa',
    AND: 'Andorra',
    AGO: 'Angola',
    AIA: 'Anguilla',
    ATA: 'Antarctica',
    ATG: 'Antigua and Barbuda',
    ARG: 'Argentina',
    ARM: 'Armenia',
    ABW: 'Aruba',
    AUS: 'Australia',
    AUT: 'Austria',
    AZE: 'Azerbaijan',
    BHS: 'Bahamas',
    BHR: 'Bahrain',
    BGD: 'Bangladesh',
    BRB: 'Barbados',
    BLR: 'Belarus',
    BEL: 'Belgium',
    BLZ: 'Belize',
    BEN: 'Benin',
    BMU: 'Bermuda',
    BTN: 'Bhutan',
    BOL: 'Bolivia, Plurinational State of',
    BES: 'Bonaire, Sint Eustatius and Saba',
    BIH: 'Bosnia and Herzegovina',
    BWA: 'Botswana',
    BVT: 'Bouvet Island',
    BRA: 'Brazil',
    IOT: 'British Indian Ocean Territory',
    BRN: 'Brunei Darussalam',
    BGR: 'Bulgaria',
    BFA: 'Burkina Faso',
    BDI: 'Burundi',
    CPV: 'Cabo Verde',
    KHM: 'Cambodia',
    CMR: 'Cameroon',
    CAN: 'Canada',
    CANADA: 'Canada',
    CYM: 'Cayman Islands',
    CAF: 'Central African Republic',
    TCD: 'Chad',
    CHL: 'Chile',
    CHN: 'China',
    CXR: 'Christmas Island',
    CCK: 'Cocos (Keeling) Islands',
    COL: 'Colombia',
    COM: 'Comoros',
    COG: 'Congo',
    COD: 'Congo, the Democratic Republic of the',
    COK: 'Cook Islands',
    CRI: 'Costa Rica',
    CIV: 'Côte d\'Ivoire',
    HRV: 'Croatia',
    CUB: 'Cuba',
    CUW: 'Curaçao',
    CYP: 'Cyprus',
    CZE: 'Czech Republic',
    DNK: 'Denmark',
    DJI: 'Djibouti',
    DMA: 'Dominica',
    DOM: 'Dominican Republic',
    ECU: 'Ecuador',
    EGY: 'Egypt',
    SLV: 'El Salvador',
    GNQ: 'Equatorial Guinea',
    ERI: 'Eritrea',
    EST: 'Estonia',
    ETH: 'Ethiopia',
    FLK: 'Falkland Islands (Malvinas)',
    FRO: 'Faroe Islands',
    FJI: 'Fiji',
    FIN: 'Finland',
    FRA: 'France',
    GUF: 'French Guiana',
    PYF: 'French Polynesia',
    ATF: 'French Southern Territories',
    GAB: 'Gabon',
    GMB: 'Gambia',
    GEO: 'Georgia (country)',
    DEU: 'Germany',
    GHA: 'Ghana',
    GIB: 'Gibraltar',
    GRC: 'Greece',
    GRL: 'Greenland',
    GRD: 'Grenada',
    GLP: 'Guadeloupe',
    GUM: 'Guam',
    GTM: 'Guatemala',
    GGY: 'Guernsey',
    GIN: 'Guinea',
    GNB: 'Guinea-Bissau',
    GUY: 'Guyana',
    HTI: 'Haiti',
    HMD: 'Heard Island and McDonald Islands',
    VAT: 'Holy See (Vatican City State)',
    HND: 'Honduras',
    HKG: 'Hong Kong',
    HUN: 'Hungary',
    ISL: 'Iceland',
    IND: 'India',
    IDN: 'Indonesia',
    IRN: 'Iran, Islamic Republic of',
    IRQ: 'Iraq',
    IRL: 'Ireland',
    IMN: 'Isle of Man',
    ISR: 'Israel',
    ITA: 'Italy',
    JAM: 'Jamaica',
    JPN: 'Japan',
    JEY: 'Jersey',
    JOR: 'Jordan',
    KAZ: 'Kazakhstan',
    KEN: 'Kenya',
    KIR: 'Kiribati',
    PRK: 'Korea, Democratic People\'s Republic of',
    KOR: 'Korea, Republic of',
    KWT: 'Kuwait',
    KGZ: 'Kyrgyzstan',
    LAO: 'Lao People\'s Democratic Republic',
    LVA: 'Latvia',
    LBN: 'Lebanon',
    LSO: 'Lesotho',
    LBR: 'Liberia',
    LBY: 'Libya',
    LIE: 'Liechtenstein',
    LTU: 'Lithuania',
    LUX: 'Luxembourg',
    MAC: 'Macao',
    MKD: 'Macedonia, the former Yugoslav Republic of',
    MDG: 'Madagascar',
    MWI: 'Malawi',
    MYS: 'Malaysia',
    MDV: 'Maldives',
    MLI: 'Mali',
    MLT: 'Malta',
    MHL: 'Marshall Islands',
    MTQ: 'Martinique',
    MRT: 'Mauritania',
    MUS: 'Mauritius',
    MYT: 'Mayotte',
    MEX: 'Mexico',
    FSM: 'Micronesia, Federated States of',
    MDA: 'Moldova, Republic of',
    MCO: 'Monaco',
    MNG: 'Mongolia',
    MNE: 'Montenegro',
    MSR: 'Montserrat',
    MAR: 'Morocco',
    MOZ: 'Mozambique',
    MMR: 'Myanmar',
    NAM: 'Namibia',
    NRU: 'Nauru',
    NPL: 'Nepal',
    NLD: 'Netherlands',
    NCL: 'New Caledonia',
    NZL: 'New Zealand',
    NIC: 'Nicaragua',
    NER: 'Niger',
    NGA: 'Nigeria',
    NIU: 'Niue',
    NFK: 'Norfolk Island',
    MNP: 'Northern Mariana Islands',
    NOR: 'Norway',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PLW: 'Palau',
    PSE: 'Palestine, State of',
    PAN: 'Panama',
    PNG: 'Papua New Guinea',
    PRY: 'Paraguay',
    PER: 'Peru',
    PHL: 'Philippines',
    PCN: 'Pitcairn',
    POL: 'Poland',
    PRT: 'Portugal',
    PRI: 'Puerto Rico',
    QAT: 'Qatar',
    REU: 'Réunion',
    ROU: 'Romania',
    RUS: 'Russian Federation',
    RWA: 'Rwanda',
    BLM: 'Saint Barthélemy',
    SHN: 'Saint Helena, Ascension and Tristan da Cunha',
    KNA: 'Saint Kitts and Nevis',
    LCA: 'Saint Lucia',
    MAF: 'Saint Martin (French part)',
    SPM: 'Saint Pierre and Miquelon',
    VCT: 'Saint Vincent and the Grenadines',
    WSM: 'Samoa',
    SMR: 'San Marino',
    STP: 'Sao Tome and Principe',
    SAU: 'Saudi Arabia',
    SEN: 'Senegal',
    SRB: 'Serbia',
    SYC: 'Seychelles',
    SLE: 'Sierra Leone',
    SGP: 'Singapore',
    SXM: 'Sint Maarten (Dutch part)',
    SVK: 'Slovakia',
    SVN: 'Slovenia',
    SLB: 'Solomon Islands',
    SOM: 'Somalia',
    ZAF: 'South Africa',
    SGS: 'South Georgia and the South Sandwich Islands',
    SSD: 'South Sudan',
    ESP: 'Spain',
    LKA: 'Sri Lanka',
    SDN: 'Sudan',
    SUR: 'Suriname',
    SJM: 'Svalbard and Jan Mayen',
    SWZ: 'Swaziland',
    SWE: 'Sweden',
    CHE: 'Switzerland',
    SYR: 'Syrian Arab Republic',
    TWN: 'Taiwan',
    TJK: 'Tajikistan',
    TZA: 'Tanzania, United Republic of',
    THA: 'Thailand',
    TLS: 'Timor-Leste',
    TGO: 'Togo',
    TKL: 'Tokelau',
    TON: 'Tonga',
    TTO: 'Trinidad and Tobago',
    TUN: 'Tunisia',
    TUR: 'Turkey',
    TKM: 'Turkmenistan',
    TCA: 'Turks and Caicos Islands',
    TUV: 'Tuvalu',
    UGA: 'Uganda',
    UKR: 'Ukraine',
    ARE: 'United Arab Emirates',
    GBR: 'United Kingdom',
    'UNITED KINGDOM': 'United Kingdom',
    USA: 'United States',
    'UNITED STATES OF AMERICA': 'United States',
    'UNITED STATES': 'United States',
    UMI: 'United States Minor Outlying Islands',
    URY: 'Uruguay',
    UZB: 'Uzbekistan',
    VUT: 'Vanuatu',
    VEN: 'Venezuela, Bolivarian Republic of',
    VNM: 'Viet Nam',
    VGB: 'Virgin Islands, British',
    VIR: 'Virgin Islands, U.S.',
    WLF: 'Wallis and Futuna',
    ESH: 'Western Sahara',
    YEM: 'Yemen',
    ZMB: 'Zambia',
    ZWE: 'Zimbabwe'
}

const cities = [
  'Alhambra', 'Arcadia', 'Artesia',
  'Avalon', 'Azusa', 'Baldwin Park',
  'Bell', 'Bell Gardens', 'Bellflower',
  'Beverly Hills', 'Bradbury', 'Burbank',
  'Carson', 'Cerritos', 'Claremont',
  'Commerce', 'Compton', 'Covina',
  'Cudahy', 'Culver City', 'Downey',
  'Duarte', 'El Monte', 'El Segundo',
  'Gardena', 'Glendale', 'Glendora',
  'Hawaiian Gardens', 'Hawthorne', 'Hermosa Beach',
  'Hidden Hills', 'Huntington Park', 'Industry',
  'Inglewood', 'Irwindale', 'La Canada Flintridge',
  'Lakewood', 'La Mirada', 'La Puente',
  'La Verne', 'Lawndale', 'Lomita',
  'Long Beach', 'Los Angeles', 'Lynwood',
  'Manhattan Beach', 'Maywood', 'Monrovia',
  'Montebello', 'Monterey Park', 'Norwalk',
  'Palmdale', 'Palos Verdes Estates', 'Paramount',
  'Pasadena', 'Pico Rivera', 'Pomona',
  'Rancho Palos Verdes', 'Redondo Beach', 'Rolling Hills',
  'Rolling Hills Estates', 'Rosemead', 'San Dimas',
  'San Fernando', 'San Gabriel', 'San Marino',
  'Santa Fe Springs', 'Santa Monica', 'Sierra Madre',
  'Signal Hill', 'South El Monte', 'South Gate',
  'South Pasadena', 'Temple City', 'Torrance',
  'Vernon', 'Walnut', 'West Covina',
  'Whittier', 'Unincorporated', 'Lancaster',
  'La Habra Heights', 'Westlake Village', 'Agoura Hills',
  'West Hollywood', 'Santa Clarita', 'Diamond Bar',
  'Malibu', 'Calabasas', 'Thousand Oaks',
  'Brentwood', 'Acton', 'Agoura',
  'Agua Dulce', 'Altadena', 'Arleta',
  'Barrington', 'Bassett', 'Bell Canyon',
  'Belmont Shore', 'Bixby Knolls', 'Bouquet Canyon',
  'Box Canyon', 'Boyle Heights', 'Briggs',
  'Calabasas Hills', 'Canoga Park', 'Canyon Country',
  'Castaic', 'Catalina', 'Century City',
  'Charter Oak', 'Chatsworth', 'City Of Commerce',
  'City Of Industry', 'Dominguez', 'Eagle Rock',
  'East Long Beach', 'East Los Angeles', 'East Rancho Dominguez',
  'Echo Park', 'Edendale', 'El Sereno',
  'Encino', 'Firestone Park', 'Flintridge',
  'Fort Macarthur', 'Friendly Valley', 'Glassell Park',
  'Glendale Galleria', 'Granada Hills', 'Green Valley',
  'Hacienda Heights', 'Hansen Hills', 'Harbor City',
  'Highland Park', 'Hollywood', 'Kagel Canyon',
  'La Canada', 'La Crescenta', 'La Tuna Canyon',
  'Lake Elizabeth', 'Lake Hughes', 'Lake Los Angeles',
  'Lake View Terrace', 'Leimert Park', 'Lennox',
  'Leona Valley', 'Lincoln Heights', 'Littlerock',
  'Los Feliz', 'Los Nietos', 'Mar Vista',
  'Marina Del Rey', 'Mission Hills', 'Montrose',
  'Mount Baldy', 'Mount Wilson', 'Naples',
  'Newhall', 'North Hills', 'North Hollywood',
  'Northridge', 'Oak Park', 'Oakwood',
  'Pacific Palisades', 'Pacoima', 'Palos Verdes Peninsula',
  'Panorama City', 'Pearblossom', 'Phillips Ranch',
  'Playa Del Rey', 'Playa Vista', 'Porter Ranch',
  'Quartz Hill', 'Rancho Dominguez', 'Rancho La Tuna Canyon',
  'Rancho Park', 'Reseda', 'Rosewood',
  'Rowland Heights', 'San Pedro', 'Saratoga Hills',
  'Saugus', 'Sepulveda', 'Shadow Hills',
  'Sherman Oaks', 'Silver Lake', 'South San Gabriel',
  'Stevenson Ranch', 'Studio City', 'Sun Valley',
  'Sunland', 'Sylmar', 'Tarzana',
  'Terminal Island', 'Toluca Lake', 'Topanga',
  'Tujunga', 'Universal City', 'Val Verde',
  'Valencia', 'Valley Village', 'Van Nuys',
  'Venice', 'Verdugo City', 'Walnut Park',
  'Watts', 'West Hills', 'West Los Angeles',
  'Westchester', 'Wilmington', 'Winnetka',
  'Woodland Hills'
]

const SearchBy = {
    FilingNumber: 1,
    BusinessName: 2,
    OwnerName: 3,
    CopyNumber: 4
}

// This will be the setup for all future refactoring on Approaches and Workflows
const FilingApproach = {
    Online: 'Online',
    InPerson: 'In-Person',
    MailIn: 'Mail-In',
    ThirdParty: 'Third Party',
}

const FilingWorkflow = {
    Standard: 'Fictitious Business Name Statement',
    Amendment: 'Fictitious Business Name Statement Amendment',
    Renewal: 'Fictitious Business Name Statement Renewal',
    Withdrawal: 'Fictitious Business Name Statement Withdrawal',
    Abandonment: 'Fictitious Business Name Statement Abandonment',
    ProvideProofOfPublication: 'Provide Proof of Publication'
}

const LandingPageFilingSelection = {
    FileFictitiousBusinessName: 'File a Fictitious Business Name',
    ProofOfPublication: 'Provide Proof of Publication',
    OnlineCopyNumberLookup: 'Online Copy Number Lookup'
}

const BusinessType = [
    {
        name: 'Corporation',
        minOwnerCount: 1,
        maxOwnerCount: 1,
        sosFile: true
    },
    {
        name: 'General Partnership',
        minOwnerCount: 2,
        maxOwnerCount: 500,
        sosFile: false
    },
    {
        name: 'Joint Venture',
        minOwnerCount: 2,
        maxOwnerCount: 500,
        sosFile: false
    },
    {
        name: 'Limited Liability Company (LLC)',
        minOwnerCount: 1,
        maxOwnerCount: 1,
        sosFile: true
    },
    {
        name: 'Limited Liability Partnership',
        minOwnerCount: 2,
        maxOwnerCount: 500,
        sosFile: true
    },
    {
        name: 'Limited Partnership',
        minOwnerCount: 1,
        maxOwnerCount: 500,
        sosFile: true
    },
    {
        name: 'Married Couple',
        minOwnerCount: 2,
        maxOwnerCount: 2,
        sosFile: false
    },
    {
        name: 'State or Local Registered Domestic Partnership',
        minOwnerCount: 2,
        maxOwnerCount: 2,
        sosFile: false
    },
    {
        name: 'Trust',
        minOwnerCount: 1,
        maxOwnerCount: 500,
        sosFile: false
    },
    {
        name: 'Individual',
        minOwnerCount: 1,
        maxOwnerCount: 1,
        sosFile: false
    },
    {
        name: 'Unincorporated Association',
        minOwnerCount: 2,
        maxOwnerCount: 500,
        sosFile: false
    },
    {
        name: 'Co-partners',
        minOwnerCount: 2,
        maxOwnerCount: 500,
        sosFile: false
    },   
]

const MaxBusinessNameCount = 500

const BusinessTypeName = {
    Corporation: 'Corporation',
    GeneralPartnership: 'General Partnership',
    JointVenture: 'Joint Venture',
    LLC: 'Limited Liability Company (LLC)',
    LLP: 'Limited Liability Partnership',
    LimitedPartnership: 'Limited Partnership',
    MarriedCouple: 'Married Couple',
    StateOrLocalRegisteredDomesticPartnership: 'State or Local Registered Domestic Partnership',
    Trust: 'Trust',
    Individual: 'Individual',
    UnincorporatedAssociation: 'Unincorporated Association',
    Copartners: 'Co-partners'
}

const FilingActionType = {
    WithdrawOwner: 1,
    AbandonBusinessName: 2,
    WithdrawFromBusinessName: 3,
    EditBusinessAddressOrArticles: 4,
    EditBusinessMailAddress: 5,
    EditRegisteredOwner: 6,
    RemoveRegisteredOwner: 7,
    AddRegisteredOwner: 8,
    AddBusinessName: 9,
    RemoveBusinessName: 10,
    RegistrationRenewalName: 11,
    ImportLegacyFilingRecord: 12,
    ImportLegacyBusinessNameRecord: 13,
    AddAdditionalCopies: 14,
    RequiresManualIndexing: 15,
    SignatoryIdentityInformation: 16
}

module.exports = {
  states,
  countries,
  cities,
  publications,
  StateMapToAbbreviatedState, 
  businessTypeInformation,
  MapBusinessTypeIntToString: MapBusinessTypeIntToString,
  MapFilingTypeIntToString: MapFilingTypeIntToString,
  MapClerkFilingTypeIntToString: MapClerkFilingTypeIntToString,
  ReadBusinessType: ReadBusinessType,
  MonitorActivity: MonitorActivity,
  ControlState,
  CountryAbbrevNameToCountryNameMap: CountryAbbrevNameToCountryNameMap,
  LanguageSelection,
  Platform,
  Sign,
  AppHasData,
  SearchBy,
  FilingApproach,
  FilingWorkflow,
  LandingPageFilingSelection,
  FilingActionType,
  BusinessType,
  BusinessTypeName,
  MaxBusinessNameCount
}
