<template>
    <div>
        <div>
            <BusinessType />
        </div>    
    </div>
    
</template>

<script>
import BusinessType from '../components/Reg/BusinessType.vue'
export default {
    components: {
        BusinessType
    }
}
</script>
