<template>
    <div>
        <div>
            <AdditionalFiling />
        </div>    
    </div>
    
</template>

<script>
import AdditionalFiling from '../components/Reg/AdditionalFiling.vue'
export default {
    components: {
        AdditionalFiling
    }
}
</script>