<template>
    <div class="mx-auto pb-4 text-left max-w-screen-xl">
        <div class="fixed z-10 inset-0 overflow-y-auto" aria-label="help-modal" role="dialog" aria-modal="true">
            <div class="flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <!-- centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all modalwidth50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <button type="button" id="close-help-modal-x" class="border-none text-2xl cursor-pointer font-bold bg-transparent text-red-600 absolute top-0 right-0 p-2" @click="closeHelp" aria-label="close help modal">
                            x
                        </button>
                        <div id="help-info">
                            <div class="text-3xl font-bold py-2">
                                {{helpData[page].modalTitle}}
                            </div>
                            <div v-for="(section) in helpData[page].body" :key="section">
                                <div class="text-xl font-bold py-1">
                                    {{section.title}}
                                </div>
                                <div class="text-sm font-bold py-1">
                                    {{section.sectionTitle}}
                                </div>
                                <div class="text-sm font-bold py-1">
                                    {{section.subtitle}}
                                </div>
                                <div v-for="(data) in section.data" :key="data" class="text-sm py-1">
                                    {{data}}
                                </div>
                            </div>
                        </div>
                        <div class="py-3 text-left">
                            <button @click="closeHelp();" type="button" id="close-help-button" class="text-white px-4 py-4 font-semibold darkblue text-center rounded mr-auto">
                            {{ $t('Template.Close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {helpData} from '../../assets/constant/help';
export default {
    name: 'Help Button',
    props: {
        page: Number
    },
    data: () => ({
        helpData: helpData,
        language: localStorage.getItem('language') || 'en'
    }),
    components: {
    },
    methods: {
        closeHelp() {
            this.$emit('closeHelp');
        }
    },
    beforeMount() {
        this.helpData = helpData[this.language];
    }
}
</script>

<style>
    .backdrop {
        background-color: rgba(0, 0, 0, 0.3);
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .border-bottom{
        border-bottom: 1px solid gray;
    }
    .border-top{
        border-top: 1px solid gray;
    }
    .modalwidth50 {
        min-width: 48.5%;
    }
    .modal-fade-enter,
        .modal-fade-leave-to {
            opacity: 0;
        }

    .modal-fade-enter-active,
        .modal-fade-leave-active {
            transition: opacity .3s ease;
        }
</style>