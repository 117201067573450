<template>
    <div>
        <div>
            <FbnVitalCheckInfo />
        </div>    
    </div>
    
</template>

<script>
import FbnVitalCheckInfo from '../components/Reg/VitalChekInfo.vue'
export default {
    components: {
        FbnVitalCheckInfo
    }
}
</script>
