<template>
    <div class="m_pad" role="main">
        <div>
            <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.VitalChekInfo.Heading') }}</h1>
            <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
            <div class="mx-auto py-4 text-left text-2xl font-bold max-w-screen-xl">{{ $t('Reg.VitalChekInfo.Intro') }}</div>
            <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
                <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
                <ul>
                    <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="max-w-screen-xl mx-auto">
            <div class="text-center py-2">     
                <div class="required text-1xl md:w-4/12 w-full py-2 mt-4 md:inline-block block text-left" id="vc-full-name-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.FullName') }}</div>
                <input
                    aria-label="vc-full-name-field"
                    aria-labelledby="vc-full-name-label" 
                    maxlength="50"
                    type="text" 
                    name="vc-full-name" 
                    id="vc-full-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.fullName ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.fullName"/>
                <div class="required text-1xl md:w-4/12 w-full py-2 mt-4 md:inline-block block text-left" id="vc-first-name-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.FirstName') }}</div>
                <input
                    aria-label="vc-first-name-field"
                    aria-labelledby="vc-first-name-label" 
                    maxlength="50"
                    type="text" 
                    name="vc-first-name" 
                    id="vc-first-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.firstName ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.firstName"/>
                <div class="text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-middle-name-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.MiddleName') }}</div>
                <input
                    aria-label="vc-middle-name-field"
                    aria-labelledby="vc-middle-name-label"
                    maxlength="50"
                    type="text" 
                    name="vc-middle-name" 
                    id="vc-middle-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.middleName ? 'outlineRed': 'outline-none' ]" 
                    :placeholder="$t('Template.Optional')" 
                    v-model="RequestorInformation.middleName"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-last-name-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.LastName') }}</div>
                <input
                    aria-label="vc-last-name-field"
                    aria-labelledby="vc-last-name-label" 
                    maxlength="400"
                    type="text" 
                    name="vc-last-name" 
                    id="vc-last-name" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.lastName ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.lastName"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-address-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.Address') }}</div>
                <input
                    aria-label="vc-address-field"
                    aria-labelledby="vc-address-label" 
                    maxlength="40"
                    type="text" 
                    name="vc-address" 
                    id="vc-address" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.address ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.address"/>
                <div id="vc-address-infomation">
                    <div class="text-base md:w-4/12 py-2 inline-block text-left w-full" id="vc-address-infomation-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.AddressInformation') }}</div>
                </div>
                <div class="text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-additional-address-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.AddtionalAddress') }}</div>
                <input
                    aria-label="vc-additional-address-field"
                    aria-labelledby="vc-additional-address-label" 
                    maxlength="40"
                    type="text" 
                    name="vc-additional-address" 
                    id="vc-additional-address" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.additionalAddress ? 'outlineRed': 'outline-none' ]" 
                    :placeholder="$t('Template.Optional')" 
                    v-model="RequestorInformation.additionalAddress"/>
                <div id="vc-additional-address-information">
                    <div class="text-base md:w-4/12 py-2 inline-block text-left w-full" id="vc-additional-address-information-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.AdditionalAddressInformation') }}</div>
                </div>
                <div for="city" class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="city-label">{{ $t('Template.City') }}</div>
                <input 
                    aria-label="city-field"
                    aria-labelledby="city-label"
                    maxlength="30"
                    list="cities" 
                    type="text" 
                    min="0" 
                    name="vc-city" 
                    id="vc-city" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.city ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.city"/>
                <datalist id="cities">
                    <option v-for="city in cities" v-bind:value="city" :key="city"/>
                </datalist>   
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="state-label">{{ $t('Template.State') }}</div>
                <select
                    aria-label="state-select"
                    aria-labelledby="state-label" 
                    maxlength="30"
                    class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                    name="vc-state" 
                    id="vc-state" 
                    v-bind:class="[ inputErrors.state ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.state">
                    <option v-for="state in states" :key="state" :selected="RequestorInformation.state === state">
                        {{ state }}
                    </option>
                </select>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="zipcode-label">{{ $t('Template.ZipCode') }}</div>
                <input
                    aria-label="vc-zip-code-field"
                    aria-labelledby="vc-zip-code-label" 
                    maxlength="10"
                    type="text" 
                    name="vc-zip-code" 
                    id="vc-zip-code" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.zipCode ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.zipCode"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-email-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.Email') }}</div>
                <input 
                    aria-label="vc-email-field"
                    aria-labelledby="vc-email-label"
                    maxlength="100"
                    type="email" 
                    name="vc-email" 
                    id="vc-email" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.email ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.email"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-confirm-email-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.ConfirmEmail') }}</div>
                <input
                    aria-label="vc-confirm-email-field"
                    aria-labelledby="vc-confirm-email-label" 
                    maxlength="100"
                    type="email" 
                    name="vc-confirm-email" 
                    id="vc-confirm-email" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.confirmEmail ? 'outlineRed': 'outline-none' ]" 
                    v-model="RequestorInformation.confirmEmail"/>                        
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="vc-phone-label">{{ $t('Reg.VitalChekInfo.UserInput.Labels.Phone') }}</div>
                <input
                    aria-label="vc-phone-field"
                    aria-labelledby="vc-phone-label" 
                    name="vc-phone-number" 
                    id="vc-phone-number" 
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ inputErrors.phone ? 'outlineRed': 'outline-none' ]" 
                    maxlength="14"
                    v-model="RequestorInformation.phone"
                    @input="phoneNumberFormat"/>
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="5"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>  
    </div>         
</template>

<script>
import { states, cities, StateMapToAbbreviatedState, AppHasData, FilingActionType } from '../../assets/constants'
import Validate from '../../assets/constant/validation';
import Common from '../../assets/common'
import Help from '../common/Help.vue';
import NavigationControls from '../common/NavigationControls.vue'
export default {
    name: 'VitalChekInfo',
    components: {
        Help,
        NavigationControls
    },
    data: () => ({
        cities: cities,
        states: states,
        errors: [],
        inputErrors: {
            fullName: false,
            firstName: false,
            middleName: false,
            lastName: false,
            address: false,
            additionalAddress: false,
            city: false,
            state: false,
            zipCode: false,
            email: false,
            confirmEmail: false,
            phone: false
        },
        RequestorInformation: {
            firstName: null,
            middleName: null,
            lastName: null,
            address: null,
            additionalAddress: null,
            city: null,
            state: null,
            zipCode: null,
            email: null,
            confirmEmail: null,
            phone: null,
            fullName: null
        },
        isHelpVisible: false,
        fbnFiling: {}
    }),
    methods: {
        createFilingAction () {
            this.fbnFiling.VitalChekPage.filingActions = []
            this.fbnFiling.VitalChekPage.filingActions.push({ 
                delta: [   
                    { field: 'Name', value: this.RequestorInformation.fullName },
                    { field: 'FirstName', value: this.RequestorInformation.firstName },
                    { field: 'LastName', value: this.RequestorInformation.lastName },
                    { field: 'MiddleName', value: this.RequestorInformation.middleName },
                    { field: 'Email', value: this.RequestorInformation.email },
                    { field: 'Phone', value: this.RequestorInformation.phone },
                    { field: 'Address', value: this.RequestorInformation.address },
                    { field: 'AddressAdditional', value: this.RequestorInformation.additionalAddress },
                    { field: 'City', value: this.RequestorInformation.city },
                    { field: 'State', value: StateMapToAbbreviatedState[this.RequestorInformation.state] },
                    { field: 'ZipCode', value: this.RequestorInformation.zipCode }
                ],
                filingActionTypeCode: FilingActionType.SignatoryIdentityInformation,
                name: this.RequestorInformation.fullName,
                nameId: 0
            })
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
        },
        doesPageContainErrors() {
            var optionalInput = ['middleName', 'additionalAddress'];
            const currentErrors = [];
            this.resetInputErrorWarnings();
            var showErrors = {...this.showInputErrorWarning};
            for (const [k, v] of Object.entries(this.RequestorInformation)) {
                var value = null;
                if (v != null){
                    value = v.trim();
                } else {
                    value = '';
                }
                if (value.length === 0) {
                    if (!optionalInput.includes(k)) {
                        currentErrors.push(this.$t('Reg.VitalChekInfo.UserInput.Errors.RequestorFieldRequired', { field: Common.variableNameToLabel(k) }));
                        showErrors[k] = true;
                    }
                }
                if (value.length !== 0) {
                    switch (k) {
                        case 'email': 
                            if (!Validate.isValidEmail(value)) {
                                currentErrors.push(this.$t('Reg.VitalChekInfo.UserInput.Errors.RequestorFieldInvalid', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            if (this.RequestorInformation.confirmEmail !== value) {
                                currentErrors.push(this.$t('Reg.VitalChekInfo.UserInput.Errors.RequestorFieldsDoNotMatch', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                                showErrors.confirmEmail = true;
                            }
                            break;
                        case 'address':
                        case 'addAddress': 
                            if (!Validate.isValidAddress(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                        case 'phone': 
                            if (!Validate.isValidPhone(value)) {
                                currentErrors.push(this.$t('Reg.VitalChekInfo.UserInput.Errors.RequestorPhoneInvalid', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                         case 'zipCode':
                            if (!Validate.isValidZipCode(value)) {
                                currentErrors.push(this.$t('Reg.VitalChekInfo.UserInput.Errors.RequestorFieldInvalid', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }    
                            break;
                        case 'lastName': 
                            if (!Validate.isValidLastNameOrCompanyName(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                        case 'firstName': 
                        case 'middleName':
                            if (!Validate.isLetters(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                        default: 
                            if (!Validate.isValidString(k, value)) {
                                currentErrors.push(this.$t('Reg.VitalChekInfo.UserInput.Errors.ReqeustorFieldInvalid', { field: Common.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                    }
                }
            }
            this.inputErrors = showErrors;
            this.errors = currentErrors;
            return currentErrors.length > 0;
        },
        phoneNumberFormat () {
            const phoneNumber = this.RequestorInformation.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.RequestorInformation.phone = !phoneNumber[2] ? phoneNumber[1] : '(' + phoneNumber[1] + ') ' + phoneNumber[2] + (phoneNumber[3] ? '-' + phoneNumber[3] : '')
        },
        resetInputErrorWarnings() {
            const copyShowInputErrorWarning = {...this.inputErrors};
            for (const [k] of Object.entries(copyShowInputErrorWarning)) {
                copyShowInputErrorWarning[k] = false;
            }
            this.inputErrors = copyShowInputErrorWarning;
        },
        proceed () {
            if (!this.doesPageContainErrors()) {
                if (this.fbnFiling.reviewPageEdit) {
                    this.$store.dispatch('setVitalChekInformation',this.RequestorInformation);
                    this.createFilingAction()
                    this.$router.push('fbnreview')
                } else {
                    this.$store.dispatch('setVitalChekInformation',this.RequestorInformation);
                    this.createFilingAction()
                    switch(this.currentWorkflow) {
                        case 'Fictitious Business Name Statement Renewal':
                            this.$router.push('fbnreviewbusinessnames');
                            break;
                        case 'Fictitious Business Name Statement Abandonment': 
                            this.$router.push('fbnsigner');
                            break;
                        case 'Fictitious Business Name Statement Amendment':
                        case 'Fictitious Business Name Statement Withdrawal':
                            this.$router.push('addregisteredowner');
                            break;
                        default: 
                            this.$router.push('addregisteredowner');
                            break;
                    }    
                }              
            }
        },
        previousPage() {
            if (this.fbnFiling.reviewPageEdit) {
                this.$router.push('fbnreview')
            } else {
                switch (this.currentWorkflow) {
                    case 'Fictitious Business Name Statement': 
                        this.$router.push('fbnsearch');
                        break;
                    default: 
                        this.$router.push('fbnlookup');
                }    
            }           
        },
        openHelp(){
            this.isHelpVisible = true;
        },
        closeHelp(){
            this.isHelpVisible = false;
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        this.nav = this.$store.getters.getSubheader;
        this.currentWorkflow = this.$store.getters.getWorkflow; 
        this.filingType = this.$store.getters.getFilingApproach; 
        this.RequestorInformation = this.$store.getters.getVitalChekInformation || this.RequestorInformation;
        if(this.states[0] === '') {
            this.states.shift();
        }
        window.scrollTo(0,0);    
    }
}
</script>

<style>
   .lightblue{
        background-color: #13b5ea;
    }
    .lightblue:hover{
        background-color: #59cbf0;
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
    }
    .outlineRed{
        outline: 2px solid red;
    }
    .required::after {
        content: " *";
        color:red;
    }
</style>