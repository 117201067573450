<template>
    <div class="m_pad" role="main">
        <div id=public_agency class="mx-auto max-w-screen-xl">
            <p>
                {{ $t('Reg.PublicAgencies.Intro1') }}
                <a tabindex="0" aria-labelledby="public-agencies-search" class="text-white px-2 py-2 darkblue text-center rounded inline-block" href="#public-agencies-search">{{ $t('Reg.PublicAgencies.HERE') }}</a>.
            </p>
            <br>
            <p>
                {{ $t('Reg.PublicAgencies.Intro2') }}
                <u><a tabindex="0" aria-labelledby="government-code-53050" href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=53050.&lawCode=GOV">{{ $t('Reg.PublicAgencies.GovernmentCode53050') }}</a></u>{{ $t('Reg.PublicAgencies.Intro3') }}
                <u><a tabindex="0"  aria-labelledby="registry-of-public-agencies-form" href="https://bpd.cdn.sos.ca.gov/sf/forms/sf-405.pdf">{{ $t('Reg.PublicAgencies.here') }}</a></u>.
            </p>
            <br>
            <p>
                <u><a tabindex="0" aria-labelledby="government-code-53051" href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=GOV&sectionNum=53051">{{ $t('Reg.PublicAgencies.GovernmentCode53051') }}</a></u> 
                {{ $t('Reg.PublicAgencies.Intro4') }}
            </p>
            <ul style="list-style-type: disc; padding-left: 2em;">
                <li>{{ $t('Reg.PublicAgencies.Bullet1') }}</li>
                <li>{{ $t('Reg.PublicAgencies.Bullet2') }}</li>
                <li>{{ $t('Reg.PublicAgencies.Bullet3') }}</li>
                <li>{{ $t('Reg.PublicAgencies.Bullet4') }}</li>
            </ul>
            <br>
            <p>{{ $t('Reg.PublicAgencies.Intro5') }}</p>
            <br>
            <p>{{ $t('Reg.PublicAgencies.FilingsShouldBeMailedTo') }}</p>
            <p>{{ $t('Reg.PublicAgencies.CaliforniaSecretaryOfState') }}</p>
            <p>{{ $t('Reg.PublicAgencies.POBoxAddress1') }}</p>
            <p>{{ $t('Reg.PublicAgencies.AdditionalAddress1') }}</p>
            <br>
            <p><strong>{{ $t('Reg.PublicAgencies.AND') }}</strong></p>
            <br>
            <p>{{ $t('Reg.PublicAgencies.LosAngelesCountyBusinessFilingAndRegistration') }}</p>
            <p>{{ $t('Reg.PublicAgencies.POBoxAddress2') }}</p>
            <p>{{ $t('Reg.PublicAgencies.AdditionalAddress2') }}</p>
            <br>
            <p>{{ $t('Reg.PublicAgencies.ContactInformation') }}<u><a tabindex="0" aria-labelledby="special-filings" href="https://www.sos.ca.gov/business-programs/special-filings">{{ $t('Reg.PublicAgencies.here') }}</a></u>.</p>   
        </div>
    </div>
</template>
<script>
export default {
    name: 'Public Agencies',
    mounted() { this.$emit('header-text', 'Registry of Public Agency Online'); },
    beforeUnmount() { this.$emit('header-text', null); }
}
</script>
<style>
</style>