<template>
    <div class="m_pad">
        <div class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.BusinessType.Heading') }}</div>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto max-w-screen-xl pt-2 text-red-400 text-2xl font-bold" v-if="this.$store.getters.getEdit || this.$store.getters.getBusinessTypeBack">{{ $t('Reg.BusinessType.ChangeNotice') }}</div>
        <div class="pt-2 mx-auto text-left text-2xl font-bold max-w-screen-xl">{{ $t('Reg.BusinessType.UserInput.Heading') }}</div>
        <div class="mx-auto max-w-screen-xl">
            <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
                <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
                <ul>
                    <li class="text-red-400" v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
            <div class="relative pt-2">
                <select class="appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded 
                    shadow leading-tight focus:outline-none focus:border-lightblue pt-2" id="business-type-select" v-model="selectedOption" @change="onBusinessTypeChangeHandler();">
                    <option v-for="businessType in businessTypeInformation" :key="businessType.title" :selected="selectedOption === businessType">{{ businessType.title }}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>
        </div>  
        <div class="mx-auto py-2 max-w-screen-xl" v-if="this.sosRequired">
            <div>{{ $t('Reg.BusinessType.SOSNotice') }}</div>
            <upload :cachedFiles="cachedFiles" :title="$t('Reg.BusinessType.SOSUploadLabel')" :subTitle="'PDF, PNG, JPEG, and TIFF only. File must be 2 MB or less.'" @onErrorHandler="onErrorHandler" @fileRemoveHandler="deleteSOSFile" @fileChangeHandler="setSOSFile"></upload>
        </div>       
        <div class="mx-auto py-2 max-w-screen-xl">
            <div class="py-2 text-xl" v-if="selectedOption">
                <p><strong>{{ $t('Reg.BusinessType.PropertyLabels.NumberOfRegistrants') }}</strong><br>{{ this.businessTypeInformation[index].NumRegistrants }}</p>
                <p><strong>{{ $t('Reg.BusinessType.PropertyLabels.EligibleSigner') }}</strong><br>{{ signerString }}</p>
                <p><strong>{{ $t('Reg.BusinessType.PropertyLabels.Description') }}</strong><br>{{ this.businessTypeInformation[index].Description }}</p>
            </div>
        </div>
        <div class="mx-auto pb-4 text-left max-w-screen-xl" v-if="openAgreeModal">
            <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="edit-modal" role="dialog" aria-modal="true">
                <div class="flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                    <!-- centering the modal contents. -->
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all modalwidth50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="">
                                <div class="text-left">
                                    <div class="text-2xl leading-6 text-red-400">{{ $t('Template.Modals.BusinessTypeChange') }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-100 px-4 py-3 text-left sm:px-6 sm:flex">
                                <button @click="cancelModal();" type="button" id="cancel-button" class="mt-3 w-full inline-flex justify-center rounded-md border text-white shadow-sm px-4 py-2 text-base font-medium bg-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ $t('Template.Buttons.Cancel') }}
                                </button>
                                <button @click="proceedModal();" type="button" id="save-button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 darkblue text-base font-medium text-white darkblue:hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ $t('Template.Buttons.IUnderstand') }}
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2" v-if="this.$store.getters.getEdit">
            <router-link class="text-white px-4 py-4 font-semibold bg-gray-700 hover:bg-gray-500 text-center rounded inline-block" id="cancel-edit-button" to="/fbnreview" @click="cancelEdit();">{{ $t('Template.Buttons.Cancel') }}</router-link>
            <button class="text-white px-4 py-4 font-semibold darkblue text-center rounded inline-block ml-2" id="save-edit-button" @click="saveEdit();">{{ $t('Template.Buttons.Save') }}</button>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls" v-if="!this.$store.getters.getEdit">
            <router-link class="text-white px-4 py-4 font-semibold bg-gray-700 hover:bg-gray-500 text-center rounded inline-block" id="back-button" to="/fbnsearch">{{ $t('Template.Buttons.PageNavigation.Back') }}</router-link>
            <button class="text-white px-4 py-4 font-semibold darkblue text-center rounded inline-block ml-2 button-disabled" id="proceed-button" @click="nextPage();">{{ $t('Template.Buttons.PageNavigation.Proceed') }}</button>
        </div>
    </div>
</template>

<script>
import Upload from '../common/Upload.vue';
import { businessTypeInformation, ControlState } from '../../assets/constants';
export default {
    name: 'Business Type',
    computed: {
    },
    data: () => ({
        errors: [],
        selectedOption: '',
        nav: '',
        previousOption: '',
        filingIndex: 0,
        isBack: false,
        openAgreeModal: false,
        didAgree: false,
        cachedFiles: null,
        businessTypeInformation: businessTypeInformation,
        index: 0,
        signerString: '',
        language: localStorage.getItem('language') || 'en',
        pageTranslated: false,
        sosRequired: false
    }),
    components: {
        Upload       
    },
    methods: {
        pageContainsErrors() {
            this.errors = []; 
            if (this.sosRequired) {
                if (this.$store.getters.getSOSFile.length === 0) {
                    this.errors.push(this.$t('Template.ErrorHandling.SOSRequired'));
                }
            }
            if (this.selectedOption === null || this.selectedOption.length === 0) {
                this.errors.push(this.$t('Reg.BusinessType.UserInput.Errors.SelectBusinessType'));
            }
            return this.errors.length > 0;
        },
        setSOSFile(event) {
            this.$store.dispatch('setSOSFile', event.target.files[0]);
        },
        deleteSOSFile(index) {
            this.$store.dispatch('deleteSOSFile', index);
            this.cachedFiles = this.$store.getters.getSOSFile;
        },
        onBusinessTypeChangeHandler () {
            if(this.isBack === false || this.$store.getters.getEdit === false){
                this.$store.dispatch('deleteAllSOSFiles');
                this.cachedFiles = this.$store.getters.getSOSFile;
            }
            this.errors = [];
            for(let i = 0; i < this.businessTypeInformation.length; i++){
                if(this.selectedOption === this.businessTypeInformation[i].title){
                    this.index = i;
                }
            }
            if(this.isBack === true || this.$store.getters.getEdit === true){
                this.openAgreeModal = true;
            }
            const list = this.businessTypeInformation[this.index].EligibleSigner;
            let listString = ''
            for(let i = 0; i < list.length; i++){
                if(list.length === 1){
                    listString += list[i];
                }
                if(i < list.length && list.length > 1 && i !== list.length - 1){
                    listString += list[i] + ', ';
                }
                if(i === list.length - 1 && list.length !== 1){
                    listString += list[i];
                }
            }
            ControlState.Set(this.selectedOption !== '');
            this.sosRequired = this.businessTypeInformation[this.index].sosRequired;
            this.signerString = listString;
            return this.index;
        },
        nextPage () {
            if (this.pageContainsErrors()) return;
            // Get translated values for business type retention
            this.previousOption = this.getBusinessTypeTranslation(this.previousOption);

            if(this.isBack === true){
                this.saveEdit();
            }
            if(this.isBack === false){
                this.$store.dispatch('setBusinessType', this.selectedOption);
                this.$router.push('addbusinessname');
            }
        },
        onErrorHandler(error) {
            const currentErrors = [];
            currentErrors.push(error);
            this.errors = currentErrors;
        },
        resetState(){
            this.$store.state.FbnFilings[this.filingIndex].fbnNames = [];
            const RegisterBusinessAddress = 
            {
                BusinessAddress: {
                    address:'',
                    additionalAddress: '',
                    city: '',
                    state: 'California',
                    zipCode: '',
                    county: 'Los Angeles County',
                    country: 'United States of America',
                    articles: ''
                },
                BusinessMailingAddress: {
                    address: '',
                    additionalAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    county: '',
                    country: ''
                }
            }
            this.$store.dispatch('setMailingToggle',false);
            this.$store.dispatch('setInternationalToggle',false);
            this.$store.dispatch('setBusinessAddress',RegisterBusinessAddress);
            const additionalInformation = 
            {
                RequestorInformation: {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    address: '',
                    addAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: '',
                    email: '',
                    phone: ''
                },
                AdditionalInformation: {
                    dateRegistrantCommenced: '',
                    additionalCopies: 0
                }
            };
            this.$store.dispatch('setAdditionalInformation', additionalInformation)
            const signatureInfo = {
                FullName: '',
                SignatoryName: '',
                SignatoryTitle: ''
            };
            this.$store.dispatch('setSignatureInformation',signatureInfo);
            this.$store.dispatch('removeNames');
            this.$store.dispatch('removeOwners');
        },
        saveEdit() {
            // Get translated values for business type retention
            this.previousOption = this.getBusinessTypeTranslation(this.previousOption);

            if(this.selectedOption === this.previousOption && this.isBack === false){
                this.$store.dispatch('setEdit',false);
                this.$store.dispatch('setBusinessTypeBack', false);
                this.didAgree = false;
                this.$router.push('/fbnreview');
            }
            if(this.selectedOption === this.previousOption && this.isBack === true){
                this.$store.dispatch('setEdit',false);
                this.$store.dispatch('setBusinessTypeBack', false);
                this.didAgree = false;
                this.$router.push('/addbusinessname');
            }
            if(this.selectedOption !== this.previousOption && this.didAgree === true){
                if (this.pageContainsErrors()) return;
                this.resetState();

                // If translated, send English data through
                const enSelectedOption = businessTypeInformation.en[this.index].title;

                this.$store.dispatch('setBusinessType', enSelectedOption);
                this.$store.dispatch('setEdit',false);
                this.$store.dispatch('setBusinessTypeBack', false);
                this.didAgree = false;
                this.$router.push('addbusinessname');
            }
        },
        cancelEdit(){
            this.$store.dispatch('setEdit',false);
        },
        cancelModal(){
            this.openAgreeModal = false;
            this.didAgree = false;
            this.selectedOption = this.getBusinessTypeTranslation(this.selectedOption);
            this.cachedFiles = this.$store.getters.getSOSFile;
        },
        proceedModal(){
            this.openAgreeModal = false;
            this.didAgree = true;
        },
        buildSignerString(){
            for(let i = 0; i < this.businessTypeInformation.length; i++){
                if(this.selectedOption === this.businessTypeInformation[i].title){
                    this.index = i;
                }
            }
            const list = this.businessTypeInformation[this.index].EligibleSigner;
            let listString = ''
            for(let i = 0; i < list.length; i++){
                if(list.length === 1){
                    listString += list[i];
                }
                if(i < list.length && list.length > 1 && i !== list.length - 1){
                    listString += list[i] + ', ';
                }
                if(i === list.length - 1 && list.length !== 1){
                    listString += list[i];
                }
            }
            return listString;
        },
        getBusinessTypeTranslation(selectedOption) {
            let translatedBusinessType, t;
            translatedBusinessType = '';
            selectedOption = this.$store.getters.getBusinessType ?? 'Corporation';
            for (t = 0; t < this.businessTypeInformation.length; t++){
                if (selectedOption === businessTypeInformation.en[t].title) {
                    translatedBusinessType = businessTypeInformation[this.language][t].title;
                }
            }
            if (this.pageTranslated) {
                selectedOption = translatedBusinessType;
            }
            return selectedOption;
        }
    },
    beforeMount() {
        this.pageTranslated = this.language !== 'en';
        this.businessTypeInformation = businessTypeInformation[this.language];
        this.cachedFiles = this.$store.getters.getSOSFile;
        this.isBack = this.$store.getters.getBusinessTypeBack;

        // Get translated values for business type retention
        this.selectedOption = this.getBusinessTypeTranslation(this.selectedOption);

        this.signerString = this.buildSignerString();
        if (this.selectedOption !== '') {
            this.sosRequired = this.businessTypeInformation[this.index].sosRequired;
        }
        this.nav = this.$store.getters.getSubheader;
    },
    mounted() {
        ControlState.Construct('proceed-button', this.selectedOption !== '');
    }
}
</script>

<style>
    .lightblue{
        background-color: #13b5ea;
    }
    .lightblue:hover{
        background-color: #59cbf0;
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
    }
</style>