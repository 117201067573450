<template>
    <div class="mx-auto text-left max-w-screen-xl text-2xl font-bold p-2" id="errorHandler" v-if="errors.length">
        <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
        <ul>
            <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
    </div>
</template>

<script>

import { isValidBusinessName } from '../../assets/constant/validation'

export default {
    data: () => ({
        errors: [],
        didPushErrors: false, 
        PageErrorObject: {
            FbnSearchPage: {
                FbnLookupError: {
                    Error: false,
                    FbnNameTooShort: false,
                    FbnNameInvalidCharacters: false
                }
            },
            SharedPageErrors: {
                CheckboxError: {
                    Set: false,
                    Error: false
                }
            }
        }
    }),
    methods: {
        // Fbn Search Page Validation
        fbnSearchValidation (fbnName) {
            this.PageErrorObject.FbnSearchPage.FbnLookupError.Error = false
            // Field length test
            if (fbnName.length < 3) {
                if(!this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameTooShort) {
                    this.handleErrorMessage(this.$t('Reg.Search.UserInput.Errors.NameShort'), !(fbnName.length < 3))
                    this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameTooShort = true
                }
                this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameTooShort = true
            } else {
                this.handleErrorMessage(this.$t('Reg.Search.UserInput.Errors.NameShort'), !(fbnName.length < 3))
                this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameTooShort = false
            }
            // Valid FBN name test
            if (!isValidBusinessName(fbnName)) {
                if(!this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameInvalidCharacters) {
                    this.handleErrorMessage(this.$t('Reg.Search.UserInput.Errors.NameRestrictions'), isValidBusinessName(fbnName))
                    this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameInvalidCharacters = true
                }
                this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameInvalidCharacters = true
            } else {
                this.handleErrorMessage(this.$t('Reg.Search.UserInput.Errors.NameRestrictions'), isValidBusinessName(fbnName))
                this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameInvalidCharacters = false
            }
            // Set field error status
            if (!this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameTooShort && 
                !this.PageErrorObject.FbnSearchPage.FbnLookupError.FbnNameInvalidCharacters) {
                this.PageErrorObject.FbnSearchPage.FbnLookupError.Error = false
            } else {
                this.PageErrorObject.FbnSearchPage.FbnLookupError.Error = true
            }
            this.sendPageErrorObject()
            return this.PageErrorObject.FbnSearchPage.FbnLookupError.Error
        },
        
        // Used by multiple pages
        checkboxValidation (checkboxSelected) {
            this.PageErrorObject.SharedPageErrors.CheckboxError.Error = false
            if (!checkboxSelected) {
                if(!this.PageErrorObject.SharedPageErrors.CheckboxError.Set) {
                    this.handleErrorMessage(this.$t('Template.ErrorHandling.Checkbox'), checkboxSelected)
                    this.PageErrorObject.SharedPageErrors.CheckboxError.Set = true
                }
                this.PageErrorObject.SharedPageErrors.CheckboxError.Error = true 
            } else {
                this.handleErrorMessage(this.$t('Template.ErrorHandling.Checkbox'), checkboxSelected)
                this.PageErrorObject.SharedPageErrors.CheckboxError.Set = false
            }
            this.sendPageErrorObject()
        },

        //Page proceed validations
        fbnSearchSubmitValidation (payload) {
            this.fbnSearchValidation(payload.fbnName)
            this.checkboxValidation(payload.checkbox)
            this.sendPageErrorObject()
            return this.errors.length > 0
        },

        // Helpers
        sendPageErrorObject () {
            this.$emit('receivePageErrorObject', this.PageErrorObject)
        },
        handleErrorMessage (message, condition) {
            if(!condition) {
                this.errors.push(message)
            } else {
                const messageToRemove = this.errors.indexOf(message)
                if (messageToRemove !== -1) {
                    this.errors.splice(messageToRemove, 1)
                }
            }
        },
        resetErrorData () {
            this.errors = []
            this.pageErrorObject = {}
        }
    }
}
</script>