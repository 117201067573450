<template>
    <div class="m_pad" role="main">
        <div class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{$t('Reg.Signer.Heading')}}</div>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto py-4 text-left text-xl font-bold max-w-screen-xl">{{ $t('Reg.Signer.Intro') }}</div>
        <div v-if="isOnlineFiling" class="mx-auto pb-4 text-left text-m max-w-screen-xl">{{ $t('Reg.Signer.Notice') }}</div>
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="max-w-screen-xl mx-auto">
            <div class="text-center py-2">     
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="signer-fullname">{{ $t('Template.FullNames') }}</div>
                <select aria-labelledby="signer-fullname" aria-label="signer-fullname-select" class="md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" maxlength="400" id="full-name-select" v-bind:class="[ inputErrors.FullName ? 'outlineRed': 'outline-none' ]" v-model="this.signatureInfo.FullName">
                    <option v-for="registeredOwnerName in registeredOwnerNameOptions" :key="registeredOwnerName" :selected="signatureInfo.FullName === registeredOwnerName">{{ registeredOwnerName.name }}</option>
                </select>
                <div v-bind:class="[this.isRequired? 'required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full': 'text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full']" id="signer-signatory-name">{{ $t('Reg.Signer.UserInput.Labels.SignatoryName') }}:</div>
                <input type="text" aria-labelledby="signer-signatory-name" maxlength="100" id="signatory-name" class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" v-bind:class="[ inputErrors.SignatoryName ? 'outlineRed': 'outline-none' ]" v-model="this.signatureInfo.SignatoryName"/>
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="signer-signatory-title">{{ $t('Template.SignatoryTitle') }}</div>
                <select aria-labelledby="signer-signatory-title" aria-label="signer-signatory-title-select" class="md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" maxlength="30" id="signatory-title-select" v-bind:class="[ inputErrors.SignatoryTitle ? 'outlineRed': 'outline-none' ]" v-model="this.signatureInfo.SignatoryTitle">
                    <option v-for="SignatoryTitle in signatoryOptions" :key="SignatoryTitle" :selected="signatureInfo.SignatoryTitle === SignatoryTitle">{{ SignatoryTitle }}</option>
                </select>
                <br> 
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="4"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>        
    </div>
</template>

<script>
import Help from '../common/Help.vue';
import NavigationControls from '../common/NavigationControls.vue'
import { businessTypeInformation, AppHasData } from '../../assets/constants';
import { isValidBusinessName, CompareStrings, verifySignerExists } from '../../assets/constant/validation';
export default {
    name: 'Signer',
    components: {
        Help,
        NavigationControls
    },
    computed: {
        isOnlineFiling() {
            return this.$store.getters.getFilingApproach === 'Online';
        }
    },
    data: () => ({
        fbnFiling: {},
        isRequired: false,
        errors: [],
        nav: '',
        inputErrors: {
            FullName: false,
            SignatoryName: false,
            SignatoryTitle: false,
        },
        signatureInfo: {
            FullName: '',
            SignatoryName: '',
            SignatoryTitle: ''
        },
        businessTypeInformation: businessTypeInformation,
        signatoryOptions : [],
        registeredOwnerNameOptions : [],
        businessTypeIndex: 0,
        signatoryNameField: false,
        isHelpVisible: false,
        currentWorkflow: '',
        deepClone: {},
        language: localStorage.getItem('language') || 'en'
    }),
    methods: {
        validateFullName(name) {
            const re = /^[A-Za-z0-9 '&#,.]*[A-Za-z0-9 '-.][A-Za-z0-9 '#&.]*$/;
            return re.test(name);
        },
        variableNameToLabel (name) {
            const result = name.split(/(?=[A-Z])/).join(' ');        
            return result.charAt(0).toUpperCase() + result.slice(1);
        },
        resetWarnings() {
            const copyInputErrors = {...this.inputErrors};
            for (const [k] of Object.entries(copyInputErrors)) {
                copyInputErrors[k] = false;
            }
            this.inputErrors = copyInputErrors;
        },
        validateFields: function() {
            const currentErrors = [];
            this.resetWarnings();
            const showErrors = {...this.inputErrors};
            for (const [k, v] of Object.entries(this.signatureInfo)) {
                const value = v.trim();
                if(value.length === 0 && k === 'FullName') {
                    currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.variableNameToLabel(k) }));
                    showErrors[k] = true;
                } else if (value.length === 0 && k === 'SignatoryName' && (this.fbnFiling.RegisteredOwnerPage.businessType === 'Corporation' || this.fbnFiling.RegisteredOwnerPage.businessType === 'Limited Liability Company (LLC)')) {
                    currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.variableNameToLabel(k) }));
                    showErrors[k] = true;    
                } else if (value.length === 0 && k === 'SignatoryTitle') {
                    currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.variableNameToLabel(k) }));
                    showErrors[k] = true;
                }                     
                if (value.length > 0) {
                    switch (k) {
                        case 'SignatoryName':
                            if (!this.validateFullName(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }    
                            break;
                        case 'SignatoryTitle':
                            if (!this.validateFullName(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }    
                        break;
                        default:
                            if (!isValidBusinessName(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;                               
                            }      
                    }
                }
            }
            this.inputErrors = showErrors;
            this.errors = currentErrors;
        },
        proceed () {
            this.validateFields();
            if(this.errors === null || this.errors.length === 0) {
                if (this.fbnFiling.reviewPageEdit) {
                    this.fbnFiling.reviewPageEdit = false
                    this.$store.dispatch('setFbnFiling', this.fbnFiling)
                    this.$store.dispatch('setSignatureInformation',this.signatureInfo)
                    this.$router.push('fbnreview')
                } else {
                    switch(this.currentWorkflow){
                        case 'Fictitious Business Name Statement':
                            this.$store.dispatch('setSignatureInformation',this.signatureInfo);
                            this.$router.push('addbusinessname');
                            break;
                        case 'Fictitious Business Name Statement Amendment':
                            this.$store.dispatch('setSignatureInformation',this.signatureInfo);
                            this.$router.push('addbusinessname');
                            break;
                        case 'Fictitious Business Name Statement Abandonment':
                            this.$store.dispatch('setSignatureInformation',this.signatureInfo);
                            this.$router.push('fbnadditionalinformation');
                            break;
                        case 'Fictitious Business Name Statement Renewal':
                            this.$store.dispatch('setSignatureInformation',this.signatureInfo);
                            this.$router.push('fbnadditionalinformation');
                            break;
                    }
                } 
            }
        },
        previousPage() {
            if (this.fbnFiling.reviewPageEdit) {
                this.$router.push('fbnreview')
            } else {  
                switch (this.currentWorkflow) {
                    case 'Fictitious Business Name Statement':
                        this.$router.push('addregisteredowner');
                        break;
                    case 'Fictitious Business Name Statement Amendment':
                        this.$router.push('addregisteredowner');
                        break;
                    case 'Fictitious Business Name Statement Abandonment':
                        if (this.$store.getters.getFilingApproach === 'Online') {
                            this.$router.push('fbnvitalchekinfo');
                        } else {
                            this.$router.push('fbnlookup');
                        }
                        break;
                    case 'Fictitious Business Name Statement Renewal':
                        this.$router.push('addregisteredowner');
                        break;
                }
            }
        },
        openHelp(){
            this.isHelpVisible = true;
        },
        closeHelp(){
            this.isHelpVisible = false;
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.pageTranslated = this.language !== 'en';
        this.businessTypeInformation = businessTypeInformation[this.language];
        this.nav = this.$store.getters.getSubheader;
        this.currentWorkflow = this.$store.getters.getWorkflow;
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        this.fbnFiling.RegisteredOwnerPage.registeredOwners.forEach(owner => {
            if(owner.action !== this.$t('Template.UndoActions.RemoveOwner')) {
                this.registeredOwnerNameOptions.push(owner)
            }
        })
        this.deepClone = JSON.parse(JSON.stringify({...this.$store.getters.getSignatureInformation}));
        Object.assign(this.signatureInfo, this.deepClone);
        const indexSelection = [
            'Corporation', 'General Partnership', 'Joint Venture', 'Limited Liability Company (LLC)', 'Limited Liability Partnership', 
            'Limited Partnership', 'Married Couple', 'State or Local Registered Domestic Partnership',
            'Trust', 'Individual', 'Unincorporated Association', 'Co-partners'
        ];
        for(let i = 0; i < indexSelection.length; i++){
            if(this.fbnFiling.RegisteredOwnerPage.businessType === indexSelection[i]){
                this.businessTypeIndex = i;
            }
        }
        this.signatoryOptions = this.businessTypeInformation[this.businessTypeIndex].EligibleSigner;
        let signatoryTitleFound = false
        for(let i = 0; i < this.signatoryOptions.length; i++) {
            if (CompareStrings(this.signatureInfo.SignatoryTitle,this.signatoryOptions[i])) {
                signatoryTitleFound = true;
                if(signatoryTitleFound) {
                    this.signatureInfo.SignatoryTitle = this.signatoryOptions[i];
                }
                break;
            } 
        }
        if(!signatoryTitleFound) {
            this.signatureInfo.SignatoryTitle = '';
        }
        if(this.fbnFiling.RegisteredOwnerPage.businessType === 'Corporation' || this.fbnFiling.RegisteredOwnerPage.businessType === 'Limited Liability Company (LLC)') {
            this.isRequired = true;
        } else {
            this.isRequired = false;
        }
        // Check if the signer is in the list of registered owners to account for registered owner page edits
        const signerNames = []
        // Get the names of the registered owners
        this.fbnFiling.RegisteredOwnerPage.registeredOwners.forEach(owner => {
            if(owner.action !== this.$t('Template.UndoActions.RemoveOwner')) {
                signerNames.push(owner.name)
            }
        })
        if(!verifySignerExists(signerNames, this.signatureInfo.FullName)) {
            this.signatureInfo.FullName = ''
        }
        window.scrollTo(0,0);
    }
}

</script>
<style>
    .lightblue{
        background-color: #13b5ea;
    }
    .lightblue:hover{
        background-color: #59cbf0;
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
    }
    .outlineRed{
        outline: 2px solid red;
    }
    .required::after {
        content: " *";
        color:red;
    }
</style>