<template>
    <div>
        <div>
            <Signer />
        </div>    
    </div>
    
</template>

<script>
import Signer from '../components/Reg/Signer.vue'
export default {
    components: {
        Signer
    }
}
</script>