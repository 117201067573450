<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" id="registered-owner-page-header">{{ $t('Reg.RegisterOwner.Heading') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div v-if="!hideBusinessTypeSelection">
            <div class="pt-2 mx-auto text-left text-2xl font-bold max-w-screen-xl">{{ $t('Reg.RegisterOwner.UserInput.BusinessTypeLabel') }}:</div>
            <div class="mx-auto max-w-screen-xl">
                <div class="pt-2">
                    <select aria-label="business-type-select" class="mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" id="business-type-select" v-model="selectedBusinessType" @change="onBusinessTypeChangeHandler();">
                        <option v-for="businessType in options" :key="businessType" :selected="selectedBusinessType === businessType">{{ businessType }}</option>
                    </select>
                </div>
            </div>  
            <div class="mx-auto py-2 max-w-screen-xl" v-if="this.renderUploadConditions.includes(selectedBusinessType)">
                <div>
                    {{ $t('Reg.RegisterOwner.Copy1') }}
                </div>
                <upload :cachedFiles="sosFile" :title="$t('Reg.RegisterOwner.UserInput.UploadSOSFile')" :subTitle="$t('Reg.RegisterOwner.UserInput.SOSFileRequirements')" @onErrorHandler="onErrorHandler" @fileRemoveHandler="deleteSOSFile" @fileChangeHandler="setSOSFile"></upload>
            </div>       
            <div class="mx-auto py-2 max-w-screen-xl">
                <div class="py-2 text-xl" v-if="selectedBusinessType">
                    <p id="business-type-number-of-registrants"><strong>{{ $t('Reg.BusinessType.PropertyLabels.NumberOfRegistrants') }}</strong><br>{{ this.businessTypeInformation[index].NumRegistrants }}</p>
                    <p id="business-type-eligible-signer"><strong>{{ $t('Reg.BusinessType.PropertyLabels.EligibleSigner') }}</strong><br>{{ signerString }}</p>
                    <p id="business-type-description"><strong>{{ $t('Reg.BusinessType.PropertyLabels.Description') }}</strong><br>{{ this.businessTypeInformation[index].Description }}</p>
                </div>
            </div>
        </div>      
        <div v-if="fbnFiling.workflow === FilingWorkflow.Renewal">
            <div v-if="showSOSUploadRenewal">
                <div class="mx-auto py-2 max-w-screen-xl" v-if="this.renderUploadConditions.includes(this.selectedBusinessType)">
                    <div>
                        {{ $t('Reg.RegisterOwner.Copy1') }}
                    </div>
                    <upload :cachedFiles="sosFile" :title="$t('Reg.RegisterOwner.UserInput.UploadSOSFile')" :subTitle="$t('Reg.RegisterOwner.UserInput.SOSFileRequirements')" @onErrorHandler="onErrorHandler" @fileRemoveHandler="deleteSOSFile" @fileChangeHandler="setSOSFile"></upload>
                </div>    
            </div>
            <div class="text-lg mx-auto max-w-screen-xl py-2" 
                id="registered-owner-renewal-notice">
                {{ $t('Reg.RegisterOwner.RenewalNotice1') }}
                <a class="text-indigo-600 hover:text-indigo-900 underline" id="to-assembly-bill-878"> {{ $t('Reg.RegisterOwner.RenewalNotice2') }}</a>
                {{ $t('Reg.RegisterOwner.RenewalNotice3') }}
                <br>
                <br>
                {{ $t('Reg.RegisterOwner.RenewalNotice4') }}
                <a class="text-indigo-600">{{ $t('Reg.RegisterOwner.RenewalNotice5') }}</a>
                {{ $t('Reg.RegisterOwner.RenewalNotice6') }}
                <br>
                <br>
                {{ $t('Reg.RegisterOwner.RenewalNotice7') }}
                <router-link id="to-amendment-workflow" to="/fbnlookup" @click="setAmendment()"><a class="text-indigo-600 hover:text-indigo-900 underline"> {{ $t('Reg.RegisterOwner.AmendmentRoute') }}</a></router-link>
            </div>
        </div>
        <div class="mx-auto py-4 text-left text-xl max-w-screen-xl" v-if="fbnFiling.workflow === FilingWorkflow.Standard || fbnFiling.workflow === FilingWorkflow.Amendment">
             <button class="text-white rounded darkblue font-semibold shadow-lg focus:outline-none py-2 px-2 md:mx-autotext-left" @click="addOwnerClickHandler" type="submit" id="add-registered-owner-button">{{ $t('Reg.RegisterOwner.UserInput.AddRegisteredOwnerButton') }}</button>
        </div>
        <div v-if="fbnFiling.workflow === FilingWorkflow.Withdrawal && GetFilingApproach !== FilingApproach.Online">
            <p class="text-lg mx-auto max-w-screen-xl py-1" id="withdrawal-warning-message" v-html="$t('Reg.RegisterOwner.WithdrawNotice')"></p>    
        </div>
        <div v-else-if="fbnFiling.workflow === FilingWorkflow.Withdrawal && GetFilingApproach === FilingApproach.Online">
            <p class="text-lg mx-auto max-w-screen-xl py-1" id="withdrawal-warning-message" v-html="$t('Reg.RegisterOwner.WithdrawNoticeOnline')"></p>
        </div>
        <p v-if="showWarningMessage" class="text-md mx-auto max-w-screen-xl pt-3 pb-4 text-red-600" id="add-warning-message">{{ warningMessage }} </p>
        <p v-if="registeredOwnerCountError.length > 0" 
            class="text-md mx-auto max-w-screen-xl pt-3 pb-4 text-red-600" 
            id="registered-owner-count-error-message">
            {{ registeredOwnerCountError }}
        </p>
        <p v-if="registeredOwnerRowError.length > 0" 
            class="text-md mx-auto max-w-screen-xl pt-3 pb-4 text-red-600" 
            id="registered-owner-row-error-message">
            {{ registeredOwnerRowError }}
        </p>
        <div class="flex flex-col max-w-screen-xl mx-auto py-4">
            <div class="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <!-- Responsive -->
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg md:block hidden">
                        <table class="min-w-full divide-y divide-gray-200" id="table-of-registered-owners">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        #
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.OwnerName') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.Address') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.AddressAdditional') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.City') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.State') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.ZipCode') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.Country') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{ $t('Template.StateOfOrganization') }}
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="fbnFiling.workflow !== FilingWorkflow.Withdrawal">
                                        {{ $t('Template.Edit') }}
                                        <span class="sr-only">{{ $t('Template.Edit') }}</span>
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="fbnFiling.workflow === FilingWorkflow.Withdrawal">
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="fbnFiling.workflow !== FilingWorkflow.Renewal">
                                        {{ $t('Template.Remove') }}
                                        <span class="sr-only">{{ $t('Template.Remove') }}</span>
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="fbnFiling.workflow === FilingWorkflow.Renewal">
                                    </th>
                                    <th scope="col" role="columnheader" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="fbnFiling.workflow === FilingWorkflow.Amendment">
                                        {{ $t('Template.Action') }}
                                        <span class="sr-only">{{ $t('Template.Action') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(owner, index) in registeredOwners" 
                                    :key="index" 
                                    :id="[owner.id]" 
                                    :class="[owner.backgroundColor]"
                                >
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            {{ index + 1}}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.name }} </div>
                                    </td>
                                        <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">{{ owner.address }} </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.addressAdditional }} </div>                                
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.city }} </div>                                
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.state }} </div>                                
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.zipCode }} </div>                                
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.country }} </div>                                
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">{{ owner.stateOfOrganization }} </div>                                
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" v-if="fbnFiling.workflow !== FilingWorkflow.Withdrawal">
                                        <button
                                            v-show="!owner.action" 
                                            id="table-control-edit-button"
                                            class="text-indigo-600 hover:text-indigo-900"
                                            aria-label="edit-registered-owner-button"
                                            @click="editRegisteredOwnerClickHandler(owner)">
                                            {{ $t('Template.Edit') }}
                                        </button>
                                    </td>  
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" v-if="fbnFiling.workflow === FilingWorkflow.Withdrawal">
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" v-if="fbnFiling.workflow !== FilingWorkflow.Renewal">
                                        <button
                                            v-show="!owner.action" 
                                            id="table-control-remove-button"
                                            class="text-indigo-600 hover:text-indigo-900"
                                            aria-label="remove-registered-owner-button" 
                                            @click="removeRegisteredOwner(owner)">
                                            {{ $t('Template.Remove') }}
                                        </button>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" v-if="fbnFiling.workflow === FilingWorkflow.Renewal">
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium" v-if="fbnFiling.workflow !== FilingWorkflow.Standard">
                                        <button
                                            id="table-control-undo-button"
                                            class="text-indigo-600 hover:text-indigo-900"
                                            aria-label="undo-changes-registered-owner-button" 
                                            @click="undoChanges(owner)">
                                            {{ owner.action }}
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="md:hidden block">
                        <div v-for="(owner, index) in registeredOwners" 
                            :key="index" class="m-owner-row bg-gray-50 cursor-pointer table w-full py-1" 
                            :id="[owner.id]"
                        >
                            <div class="w-9/12 py-2">
                                <div class="float-left w-1/12">
                                    {{ index + 1}}
                                </div>
                                <div 
                                    class="float-left w-11/12" 
                                    @click="expandOwner('owner' + (index + 1))"
                                    :class="[owner.backgroundColor]"
                                >
                                    {{ owner.name }}
                                </div>
                            </div>
                            <div class="md:w-3/12 float-right m-owner-row-controls w-auto">
                                <div v-if="fbnFiling.workflow !== FilingWorkflow.Renewal" class="inline-flex">
                                    <button 
                                        v-show="!owner.action" 
                                        id="table-control-remove-button"
                                        class="text-indigo-600 hover:text-indigo-900"
                                        aria-label="remove-changes-registered-owner-button-mobile"  
                                        @click="removeRegisteredOwner(owner)">
                                        <img src="@/assets/img/m/icon_delete.png" alt="Icon - Delete" title="Delete" width="40" height="40"/>
                                    </button>
                                </div>
                                <div v-if="fbnFiling.workflow !== FilingWorkflow.Withdrawal" class="inline-flex">
                                    <button 
                                        v-show="!owner.action" 
                                        id="table-control-edit-button"
                                        class="text-indigo-600 hover:text-indigo-900 float-right px-2"
                                        aria-label="edit-registered-owner-button-mobile"  
                                        @click="editRegisteredOwnerClickHandler(owner)">
                                        <img src="@/assets/img/m/icon_edit.png" alt="Icon - Edit" title="Edit" width="40" height="40"/>
                                    </button>    
                                </div>
                                <!-- TODO: Create Undo Icon For Mobile -->
                                <div class="inline-flex">
                                    <button 
                                        id="table-control-undo-button"
                                        class="text-indigo-600 hover:text-indigo-900 float-right px-2"
                                        aria-label="undo-changes-registered-owner-button-mobile"  
                                        @click="undoChanges(owner)">
                                        <img src="@/assets/img/m/icon_edit.png" alt="Icon - Undo" title="Undo" width="40" height="40"/>
                                    </button>
                                </div>                           
                            </div>
                            <div :id="'owner' + (index + 1)" class="hidden w-full m-owner-row-details py-2 cursor-text">

                                <!-- Address -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.Address') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.address }}</span>
                                    </div>
                                </div>

                                <!-- Address Additional -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.Unit') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.addressAdditional }}</span>
                                    </div>
                                </div>

                                <!-- City -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.City') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.city }}</span>
                                    </div>
                                </div>

                                <!-- State -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.State') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.state }}</span>
                                    </div>
                                </div>

                                <!-- Zip -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.ZipCode') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.zipCode }}</span>
                                    </div>
                                </div>

                                <!-- Country -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.Country') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.country }}</span>
                                    </div>
                                </div>

                                <!-- State of -->
                                <div class="w-full px-4 table">
                                    <div class="float-left">
                                        <b>{{ $t('Template.StateOf') }}:</b>
                                    </div>
                                    <div class="float-left px-2">
                                        <span>{{ owner.stateOfOrganization }}</span>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full text-left py-2" v-if="showModal">
            <div 
                class="z-10 inset-0 overflow-y-auto" 
                aria-label="add-modal" 
                role="dialog" 
                aria-modal="true">
                <div 
                    class="top-0 right-0 left-0 absolute flex justify-center min-h-screen pt-4 px-4 pb-20 
                    text-center sm:block sm:p-0">
                    <div 
                        class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" 
                        aria-hidden="true">
                    </div>
                    <!-- centering the modal contents. -->
                    <span 
                        class="hidden sm:inline-block sm:align-middle sm:h-screen" 
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <div 
                        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform 
                            transition-all modalwidth50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:items-start">
                            <div class="text-center sm:mt-0 sm:text-left md:mt-0 md:ml-6">
                                <div v-if="editRegisteredOwnerFlag" class="text-xl leading-6 text-red-600">{{ $t('Reg.RegisterOwner.UserInput.EditModalTitle') + '' + originalRegisteredOwnerData.name }}</div>
                                <div v-else class="text-2xl leading-6">{{ $t('Reg.RegisterOwner.UserInput.AddModalTitle') }}</div>
                                    <div class="mx-auto py-2 text-left max-w-screen-xl text-lg" v-if="errors.length">
                                        <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
                                        <ul>
                                            <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
                                        </ul>
                                    </div>
                                    <div class="mx-auto max-w-screen-xl py-4">
                                        <label class="inline-flex items-left">
                                            <input 
                                                type="checkbox" 
                                                id="international-address-checkbox" 
                                                class="h-6 w-6" 
                                                @click="internationalMailingAddressToggle" 
                                                v-model="registeredOwner.international">
                                            <span 
                                                class="ml-3 text-sm">
                                                {{ $t('Reg.RegisterOwner.UserInput.InternationalBusinessAddress') }}
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <div class="text-sm" id="add-data-fields">
                                            <div v-if="fbnFiling.workflow !== FilingWorkflow.Renewal">
                                                <div 
                                                    class="required text-md md:w-4/12 w-full py-1 pr-2 md:inline-block block text-left" 
                                                    id="full-name-add">
                                                    {{ $t('Template.FullNames') }}
                                                </div>
                                                <input 
                                                    type="text" 
                                                    aria-labelledby="full-name-add" 
                                                    maxlength="400" id="full-name-add-modal" 
                                                    :class="[ inputFieldErrors.name  ? 'outlineRed': 'outline-none' ]"                                                
                                                    class="appearance-none md:w-8/12 w-full mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 
                                                        rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block" 
                                                    v-model="registeredOwner.name"/>
                                            </div>
                                            <div 
                                                class="required text-md md:w-4/12 w-full py-1 mt-4 md:inline-block block text-left" 
                                                id="street-address-add">
                                                {{ $t('Reg.RegisterOwner.UserInput.StreetAddress') }}
                                            </div>
                                            <input 
                                                type="text" 
                                                aria-labelledby="street-address-add" 
                                                maxlength="40" id="street-address-add-modal" 
                                                v-bind:class="[ inputFieldErrors.address ? 'outlineRed': 'outline-none' ]" 
                                                class="appearance-none md:w-8/12 w-full mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 
                                                    rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block" 
                                                v-model="registeredOwner.address"/>
                                            <div class="content-to-hide text-sm md:w-4/12 w-full py-1 pr-2 md:inline-block text-left"></div>
                                            <div 
                                                class="text-sm md:w-8/12 w-full py-1 pr-2 md:inline-block text-left" 
                                                id="street-address-information-label">
                                                {{ $t('Reg.RegisterOwner.UserInput.StreetAddressInformation') }}
                                            </div>
                                            <div 
                                                class="text-md md:w-4/12 w-full py-1 mt-4 md:inline-block block text-left" 
                                                id="additional-address-add">
                                                {{ $t('Reg.RegisterOwner.UserInput.AdditionalAddress') }}
                                            </div>
                                            <input 
                                                type="text" 
                                                aria-labelledby="additional-address-add" 
                                                maxlength="40" id="additional-address-add-modal" 
                                                v-bind:class="[ inputFieldErrors.addressAdditional ? 'outlineRed': 'outline-none' ]" 
                                                placeholder="Optional" 
                                                class="appearance-none md:w-8/12 w-full mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded 
                                                    shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block" 
                                                v-model="registeredOwner.addressAdditional"/>
                                            <div class="content-to-hide text-sm md:w-4/12 w-full py-1 pr-2 md:inline-block text-left"></div>
                                            <div 
                                                class="text-sm md:w-8/12 w-full py-1 pr-2 md:inline-block text-left" 
                                                id="additional-address-information-label">
                                                {{ $t('Reg.RegisterOwner.UserInput.AdditionalAddressInformation') }}
                                            </div>
                                            <div 
                                                for="city-select-add-modal" 
                                                class="required text-md md:w-4/12 py-1 mt-4 md:inline-block block text-left w-full" 
                                                id="city-add">{{ $t('Reg.RegisterOwner.UserInput.City') }}
                                            </div>
                                            <input 
                                                list="citiesAdd" 
                                                aria-labelledby="city-add" 
                                                maxlength="30" 
                                                class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight 
                                                    focus:outline-none focus:border-lightblue inline-block w-full" 
                                                v-bind:class="[ inputFieldErrors.city ? 'outlineRed': 'outline-none' ]" 
                                                id="city-select-add-modal" 
                                                v-model="registeredOwner.city">
                                            <datalist id="citiesAdd">
                                                <option v-for="city in cities" v-bind:value="city" :key="city"></option>
                                            </datalist>
                                            <div 
                                                v-bind:class="[registeredOwner.international ? 'text-md md:w-4/12 py-2 mt-4 inline-block text-left w-full' : 'required text-md md:w-4/12 py-2 mt-4 inline-block text-left w-full']" 
                                                id="state-add">
                                                {{ $t('Reg.RegisterOwner.UserInput.State') }}
                                            </div>
                                            <select 
                                                aria-label="add-state-select" 
                                                class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight 
                                                    focus:outline-none focus:border-lightblue inline-block w-full" 
                                                maxlength="30" 
                                                v-bind:class="[ inputFieldErrors.state ? 'outlineRed': 'outline-none' ]" 
                                                id="state-select-add-modal" 
                                                v-model="registeredOwner.state">
                                                <option v-for="state in states" :key="state" :selected="registeredOwner.state === state">{{ state }}</option>
                                            </select>
                                            <div 
                                                v-bind:class="[registeredOwner.international ? 'text-md md:w-4/12 py-2 mt-4 inline-block text-left w-full' : 'required text-md md:w-4/12 py-2 mt-4 inline-block text-left w-full']" 
                                                id="zip-add">
                                                {{ $t('Reg.RegisterOwner.UserInput.ZipCode') }}
                                            </div>
                                            <input 
                                                type="text" 
                                                aria-labelledby="zip-add" 
                                                maxlength="10" 
                                                id="zip-code-add-modal" 
                                                v-bind:class="[ inputFieldErrors.zipCode  ? 'outlineRed': 'outline-none' ]" 
                                                class="appearance-none md:w-8/12 w-full mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 
                                                    px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block" 
                                                v-model="registeredOwner.zipCode"/>
                                            <div 
                                                class="required text-md md:w-4/12 w-full py-1 mt-4 md:inline-block block text-left" 
                                                id="country-add">
                                                {{ $t('Reg.RegisterOwner.UserInput.Country') }}
                                            </div>
                                            <select 
                                                aria-label="add-country-select" 
                                                aria-labelledby="country-add" 
                                                class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded 
                                                    shadow leading-tight focus:outline-none focus:border-lightblue inline-block w-full" 
                                                maxlength="25" v-bind:class="[ inputFieldErrors.country ? 'outlineRed': 'outline-none' ]" 
                                                id="country-select-add-modal" 
                                                v-model="registeredOwner.country">
                                                <option v-for="country in countries" :key="country" :selected="registeredOwner.country === country">{{ country }}</option>
                                            </select>
                                            <div 
                                                v-if="displayStateOfOrganization" 
                                                v-bind:class="[displayStateOfOrganization ? 'required text-md md:w-4/12 w-full py-1 mt-4 md:inline-block block text-left' : 
                                                    'text-md md:w-4/12 w-full py-1 mt-4 md:inline-block block text-left>']" 
                                                id="state-of-org-add">
                                                {{ $t('Reg.RegisterOwner.UserInput.StateOfOrganization') }}
                                            </div>
                                            <select 
                                                v-if="displayStateOfOrganization" 
                                                aria-label="add-state-of-org-select" 
                                                aria-labelledby="state-of-org-add" 
                                                class="md:w-8/12 bg-white border border-gray-400 hover:border-gray-500 px-2 py-3 pr-8 rounded shadow leading-tight 
                                                    focus:outline-none focus:border-lightblue inline-block w-full" 
                                                maxlength="30" 
                                                v-bind:class="[ inputFieldErrors.stateOfOrganization  ? 'outlineRed': 'outline-none' ]" 
                                                id="state-of-organization-select-add-modal" 
                                                v-model="registeredOwner.stateOfOrganization">
                                                    <option v-for="orgState in states" :key="orgState" :selected="registeredOwner.stateOfOrganization === orgState">{{ orgState }}</option>
                                            </select>      
                                            <br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-gray-100 px-4 py-3 text-left sm:px-6 sm:flex">
                            <!-- Responsive -->
                            <div class="hidden md:block">
                                <button @click="closeModal" type="button" id="modal-control-cancel-button" class="mt-3 w-full inline-flex justify-center rounded-md border text-white shadow-sm px-4 py-2 text-base font-medium bg-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">{{ $t('Template.Buttons.Cancel') }}</button>
                                <button v-if="!editRegisteredOwnerFlag" @click="addRegisteredOwner(this.registeredOwner)" type="button" id="modal-control-add-button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 darkblue text-base font-medium text-white darkblue:hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm">{{ $t('Template.Buttons.Add') }}</button>
                                <button v-else @click="editRegisteredOwner(this.registeredOwner)" type="button" id="modal-control-edit-button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 darkblue text-base font-medium text-white darkblue:hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm">{{ $t('Template.Buttons.Save') }}</button>
                            </div>
                            <div class="md:hidden">
                                <button v-if="!editRegisteredOwnerFlag" @click="addRegisteredOwner(this.registeredOwner)" type="button" id="modal-control-add-button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 darkblue text-base font-medium text-white darkblue:hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm">{{ $t('Template.Buttons.Add') }}</button>
                                <button v-else @click="editRegisteredOwner(this.registeredOwner)" type="button" id="modal-control-edit-button" class="mt-3 w-full inline-flex justify-center rounded-md border text-white shadow-sm px-4 py-2 text-base font-medium bg-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">{{ $t('Template.Buttons.Save') }}</button>
                                <button @click="closeModal" type="button" id="modal-control-cancel-button" class="mt-3 w-full inline-flex justify-center rounded-md border text-white shadow-sm px-4 py-2 text-base font-medium bg-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">{{ $t('Template.Buttons.Cancel') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            v-if="fbnFiling.workflow !== FilingWorkflow.Standard" 
            class="mx-auto max-w-screen-xl py-2">
                <ColorLegend />
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @undoChanges="undoChanges"
                @openHelp="openHelp"/>
            <Help 
                :page="3"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>        
    </div>
</template>

<script>
import { states, countries, cities, businessTypeInformation, ControlState, AppHasData, FilingApproach, FilingWorkflow, FilingActionType, BusinessType, BusinessTypeName } from '../../assets/constants'
import { isValidAddress, isValidBusinessName, isValidDropdown, isValidInput, isValidZipCode } from '../../assets/constant/validation'
import ColorLegend from '../common/ColorLegend.vue'
import Help from '../common/Help.vue'
import NavigationControls from '../common/NavigationControls.vue'
import Upload from '../common/Upload.vue'
export default {
    name: 'Register Owner',
    components: {
        ColorLegend,
        Help,
        NavigationControls,
        Upload
    },
    computed: {
        GetFilingApproach() {
            return this.$store.getters.getFilingApproach;
        }
    },
    data: () => ({
        activeOwners: 0,
        businessType: '',
        businessTypeInformation: businessTypeInformation,
        businessTypeObject: {},
        cities: cities,
        countries: countries,
        currentWithdrawalCount: 0,
        editRegisteredOwnerFlag: false,
        errors: [],
        errorsModal: [],
        fbnFiling: {},
        FilingApproach: FilingApproach,
        FilingWorkflow: FilingWorkflow,
        inputFieldErrors: {
            name: false,
            address: false,
            addressAdditional: false,
            city: false,
            state: false,
            zipCode: false,
            country: false,
            stateOfOrganization: false
        },
        internationalMailingAddress: false,
        filingActions: [],
        optionalInputs: [],
        originalRegisteredOwners: [],
        originalRegisteredOwnerData: {},
        registeredOwner: {
            name: '',
            address: '',
            addressAdditional: '',
            city: '',
            id: 0,
            international: false,
            state: '',
            zipCode: '',
            country: '',
            stateOfOrganization: '',
            removed: false
        },
        registeredOwners: [],
        registeredOwnerCountError: '',
        registeredOwnerRowError: '',
        sosFile: [],
        showModal: false,
        showWarningMessage: false,
        states: states,
        warningMessage: '',
        // All variables below will be removed as the page is refactored
        nav: '',
        displayStateOfOrganization: false,
        isHelpVisible: false,
        isRequired: false,
        boundsMessage: '',
        showBoundsMessage: false,
        selectedBusinessType: '',
        renderUploadConditions: ['Corporation', 'Limited Liability Partnership', 'Limited Partnership', 'Limited Liability Company (LLC)'],
        index: 0,
        options: [
            'Corporation', 'General Partnership', 'Joint Venture', 'Limited Liability Company (LLC)', 'Limited Liability Partnership', 
            'Limited Partnership', 'Married Couple', 'State or Local Registered Domestic Partnership',
            'Trust', 'Individual', 'Unincorporated Association', 'Co-partners'
        ],
        signerString: '',
        hideBusinessTypeSelection: false,
        language: localStorage.getItem('language') || 'en',
        pageTranslated: false,
        showSOSUploadRenewal: false,
        signerBackup: {},
        originalBusinessType: '',
        originalSosFile: []
    }),
    methods: {
        //#region Interactions
        addRegisteredOwner (owner) {
            if (!this.validateFields(owner)) return
            if (this.addRegisteredOwnerValidation() && this.registeredOwnerTempId < 500) {
                owner.international = this.internationalMailingAddress
                owner.id = this.registeredOwnerTempId
                // Add Owner Filing Action
                if (this.fbnFiling.workflow !== FilingWorkflow.Standard) {
                    owner.action = this.$t('Template.UndoActions.AddOwner')
                    this.createFilingAction(FilingActionType.AddRegisteredOwner, owner)
                }
                // add owner to registered owners array
                this.registeredOwners.push(owner)
                this.updateRegisteredOwnerBackgroundColor(owner)
                // Increment temporary registered owner id and reset local registered owner object
                this.registeredOwnerTempId++
                this.registeredOwner = {}
                this.showModal = false
                this.calculateActiveRegisteredOwners()
                this.validateRegisteredOwnerCount()
                // check if user can press proceed button
                ControlState.Set(this.activeRegisteredOwnerCount >= this.businessTypeObject.minOwnerCount)
            }          
        },
        closeHelp () {
            this.isHelpVisible = false;
        },
        deleteSOSFile () {
            this.sosFile = []
        },
        editRegisteredOwner (owner) {
            if (owner.id > 500) {
                if (!this.validateFields(owner)) return
                this.clearSignerDataCheck(owner)
                owner.action = this.$t('Template.UndoActions.EditOwner')
                // Update registered owner with new data
                this.originalRegisteredOwners.forEach(ro => {
                    if (ro.id === owner.id) {
                        this.originalRegisteredOwner = {...ro}
                    }
                })
                this.registeredOwners = this.registeredOwners.map(owner => owner.id !== this.registeredOwner.id ? owner : this.registeredOwner)
                // Edit Owner Filing Action
                if (this.fbnFiling.workflow !== FilingWorkflow.Standard) {
                    this.createFilingAction(FilingActionType.EditRegisteredOwner, owner)
                }
            } else {
                if (!this.validateFields(owner)) return
                // Owner is a new ro, Update registered owner with new data, don't create a filing action for edit ro
                this.registeredOwners = this.registeredOwners.map(owner => owner.id !== this.registeredOwner.id ? owner : this.registeredOwner)
            }
            this.validateRegisteredOwnerRows()
            this.updateRegisteredOwnerBackgroundColor(owner)
            this.registeredOwner = {}
            this.showModal = false
            this.editRegisteredOwnerFlag = false
        },
        internationalMailingAddressToggle () {
            this.internationalMailingAddress = !this.internationalMailingAddress
        },
        removeRegisteredOwner (owner) {
            if (this.removeRegisteredOwnerValidation()) {
                if (owner.id >= 500) {
                    owner.action = this.$t('Template.UndoActions.RemoveOwner')
                    owner.removed = true
                    // Remove Owner Filing Action
                    if (this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
                        // Create withdrawal filing action
                        this.createFilingAction(FilingActionType.WithdrawOwner, owner)
                        // Increment withdrawal count
                        this.currentWithdrawalCount++
                    } else {
                        if (this.fbnFiling.workflow !== FilingWorkflow.Standard) {
                            this.createFilingAction(FilingActionType.RemoveRegisteredOwner, owner)
                        }
                        this.clearSignerDataCheck(owner)
                    }
                    this.calculateActiveRegisteredOwners()
                } else {
                    // The owner is a new ro that was added, undo the add
                    this.undoChanges(owner)
                }
            }
            this.updateRegisteredOwnerBackgroundColor(owner)
            ControlState.Set(this.activeRegisteredOwnerCount >= this.businessTypeObject.minOwnerCount)         
        },
        openHelp () {
            this.isHelpVisible = true;
        },
        previousPage() {
            if (this.fbnFiling.reviewPageEdit) {
                this.fbnFiling.reviewPageEdit = false
                this.fbnFiling.RegisteredOwnerPage.businessType = this.originalBusinessType
                this.fbnFiling.RegisteredOwnerPage.sosFile = this.originalSosFile
                this.$store.dispatch('setSignatureInformation',this.signerBackup)
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('fbnreview')
            } else {
                switch(this.fbnFiling.workflow){
                    case FilingWorkflow.Standard:
                        if (this.fbnFiling.approach === FilingApproach.Online) {
                            this.$router.push('fbnvitalchekinfo')
                        } else {
                            this.$router.push('fbnsearch')
                        }
                        break
                    case FilingWorkflow.Amendment:
                        if (this.fbnFiling.approach === FilingApproach.Online) {
                            this.$router.push('fbnvitalchekinfo')
                        } else {
                            this.$router.push('fbnlookup')
                        }
                        break;
                    case FilingWorkflow.Renewal:
                        this.$router.push('fbnreviewbusinessnames')
                        break
                    case FilingWorkflow.Withdrawal:
                        if (this.fbnFiling.approach === FilingApproach.Online) {
                            this.$router.push('fbnvitalchekinfo')
                        } else {
                            this.$router.push('fbnlookup')
                        }
                        break 
                }    
            }          
        },
        proceed () {
            if (this.runAllValidationChecks()) {
                this.setFbnFilingData()
                if (this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
                    if (!this.isReviewPageEdit()) {
                        this.$router.push('fbnadditionalinformation')
                    }
                } else {
                    if (!this.isReviewPageEdit()) {
                        this.$router.push('fbnsigner') 
                    }
                }
            }
        },
        setSOSFile (event) {
            this.resetWarnings()
            this.sosFile.push(event.target.files[0])
        },
        undoChanges (owner) {
            // Reset registered owner to it's original state
            owner.removed = false
            if (owner.id < 500) {
                this.registeredOwners = this.registeredOwners.filter(ro => ro.id !== owner.id)
            } else {
                if (this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
                    this.currentWithdrawalCount--
                } 
                this.registeredOwners = this.registeredOwners.filter(ro => ro.id !== owner.id)
                this.originalRegisteredOwners.forEach(ro => {
                    if (ro.id === owner.id) {
                        if (owner.action === this.$t('Template.UndoActions.EditOwner')) {
                            this.filingActions.forEach(fa => {
                                if (fa.nameId === owner.id) {
                                    this.registeredOwners.push({...fa.originalData})
                                }
                            })
                        } else {
                            ro.action = ''
                            this.registeredOwners.push(ro)
                        }
                    }
                })
            }
            // Remove filing action
            this.filingActions = this.filingActions.filter(fa => fa.nameId !== owner.id)
            this.validateRegisteredOwnerRows()
            this.updateRegisteredOwnerBackgroundColor(owner)
            this.calculateActiveRegisteredOwners()
        },
        //#endregion

        //#region Helpers
        addOwnerClickHandler () {
            if (this.addRegisteredOwnerValidation()) {
                this.showModal = true
                window.scrollTo(0,0)
            }
        },
        calculateActiveRegisteredOwners () {
            let amountActive = 0
            this.registeredOwners.forEach(registeredOwner => {
                if(registeredOwner.action !== this.$t('Template.UndoActions.RemoveOwner')) {
                    amountActive++
                }
            })
            this.activeRegisteredOwnerCount = amountActive
        },
        closeModal () {
            // If editing and the modal is closed, update owner with original data
            if (this.editRegisteredOwnerFlag) {
                this.registeredOwners = this.registeredOwners.map(owner => owner.id !== this.registeredOwner.id ? owner : this.originalRegisteredOwnerData)
            }
            this.resetWarnings()
            this.showModal = false
            this.editRegisteredOwnerFlag = false
            this.errorsModal = []
            this.errors = []
            this.registeredOwner = {}
            this.originalRegisteredOwnerData = {}
        },
        clearSignerDataCheck (owner) {
            const signerData = {...this.$store.getters.getSignatureInformation};
            if (owner.name === signerData.FullName) {
                signerData.FullName = '';
                signerData.SignatoryTitle = '';
                this.$store.dispatch('setSignatureInformation', signerData);
            }
        },
        checkOptionalInputs () {
            this.optionalInputs = []
            if (this.selectedBusinessType === BusinessTypeName.Corporation || this.selectedBusinessType === BusinessTypeName.LLC) {
                this.optionalInputs = ['addressAdditional', 'id', 'international', 'action', 'removed', 'hasMissingData', 'backgroundColor']
                this.isStateOfOrganizationRequired = true
            } else {
                if (this.internationalMailingAddress) {
                    this.optionalInputs = ['addressAdditional', 'id', 'state', 'stateOfOrganization', 'international', 'action', 'zipCode', 'removed', 'hasMissingData', 'backgroundColor']
                } else {
                    this.optionalInputs = ['addressAdditional', 'id', 'stateOfOrganization', 'international', 'action', 'removed', 'hasMissingData', 'backgroundColor']
                }
                this.isStateOfOrganizationRequired = false
            }
        },
        createFilingAction (filingAction, owner) {
            this.filingActions.push ({
                delta: this.createFilingActionDelta (owner),
                filingActionTypeCode: filingAction,
                name: owner.name,
                nameId: owner.id,
                originalData: this.originalRegisteredOwnerData
            })
            this.originalRegisteredOwnerData = {}
        },
        createFilingActionDelta (owner) {
            const delta = []
            for (const [key, value] of Object.entries(owner)) {
                let data = {}
                data = {
                    field: key,
                    value: value
                }; 
                delta.push(data);                               
            }
            return delta
        },
        editRegisteredOwnerClickHandler (owner) {
            this.originalRegisteredOwnerData = {...owner}
            this.registeredOwner = {...owner}
            this.internationalMailingAddress = this.registeredOwner.international
            this.editRegisteredOwnerFlag = true
            this.showModal = true
            window.scrollTo(0,0)
        },
        expandOwner: function(element) {
            element = document.getElementById(element)
            element.style.display = element.style.display === 'none' || element.style.display === '' ? 'table' : 'none'
        },
        isReviewPageEdit () {
            // Check for review page flag
            if (this.fbnFiling.reviewPageEdit) {
                // Return to the review page and set flag to false
                this.fbnFiling.reviewPageEdit = false
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('fbnsigner')
                return true
            }
            return false
        },
        mapBusinessTypeObject (businessType) {
            this.businessTypeObject = BusinessType.find(business => business.name === businessType)
            this.checkOptionalInputs()
        },
        onErrorHandler(error) {
            this.resetWarnings()
            this.errors.push(error)
            this.showWarningMessage = true
        },
        resetWarnings() {
            this.inputErrors = {
                name: false,
                address: false,
                addressAdditional: false,
                city: false,
                state: false,
                zipCode: false,
                country: false,
                stateOfOrganization: false
            }
            this.errors = []
            this.registeredOwnerRowError = ''
            this.registeredOwnerCountError = ''
            this.warningMessage = ''
            this.showWarningMessage = false
        },
        runAllValidationChecks () {
            if (this.selectedBusinessType.length <= 0) return
            if (this.registeredOwners.length <= 0) return
            if (this.validatePage()) return
            if (this.validateRegisteredOwnerCount()) return
            if (this.validateRegisteredOwnerRows()) return
            if (this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
                if(this.currentWithdrawalCount === 0) {
                    this.warningMessage = this.$t('Reg.RegisterOwner.Errors.RemovalRequired')
                    this.showWarningMessage = true
                    return
                }
            }  
            return true
        },
        setFbnFilingData () {
            this.fbnFiling.RegisteredOwnerPage.businessType = this.businessTypeObject.name
            this.fbnFiling.RegisteredOwnerPage.filingActions = this.filingActions
            this.fbnFiling.RegisteredOwnerPage.registeredOwners = this.registeredOwners
            this.fbnFiling.RegisteredOwnerPage.registeredOwnerTempId = this.registeredOwnerTempId
            this.fbnFiling.RegisteredOwnerPage.sosFile = this.sosFile
            this.$store.dispatch('setFbnFiling', this.fbnFiling)             
        },
        updateRegisteredOwnerBackgroundColor (owner) {
            if (owner.action === this.$t('Template.UndoActions.AddOwner')) {
                owner.backgroundColor = 'bg-green'
            } else if (owner.action === this.$t('Template.UndoActions.EditOwner')) {
                owner.backgroundColor = 'bg-orange'
            } else if (owner.action === this.$t('Template.UndoActions.RemoveOwner')) {
                owner.backgroundColor = 'bg-gray'
            } else {
                if (owner.hasMissingData) {
                    owner.backgroundColor = 'bg-red'
                } else {
                    owner.backgroundColor = ''
                }
            }
        },
        //#endregion

        //#region Validations
        addRegisteredOwnerValidation () {
            this.showWarningMessage = false
            if (this.activeRegisteredOwnerCount >= this.businessTypeObject.maxOwnerCount) {
                this.warningMessage = this.$t('Reg.RegisterOwner.Errors.MaximumRegisteredOwners')
                this.showWarningMessage = true
                return false
            }
            return true
        },
        removeRegisteredOwnerValidation () {
            this.resetWarnings()
            if (this.activeRegisteredOwnerCount <= this.businessTypeObject.minOwnerCount) {
                this.warningMessage = this.$t('Reg.RegisterOwner.Errors.MinimumRegisteredOwners', { minCount: this.businessTypeObject.minOwnerCount, maxCount: this.businessTypeObject.maxOwnerCount })
                this.showWarningMessage = true
                return false
            } else {
                if (this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
                    if (this.fbnFiling.approach === FilingApproach.Online) {
                        // Can only withdraw one owner in online approach
                        if (this.currentWithdrawalCount >= 1) {
                            this.warningMessage = this.$t('Reg.RegisterOwner.Errors.RegisteredOwnerCapacityOnline')
                            this.showWarningMessage = true
                            return false
                        }
                    } else {
                        // All other approaches can withdraw up to 4 owners
                        if (this.currentWithdrawalCount >= 4) {
                            this.warningMessage = this.$t('Reg.RegisterOwner.Errors.RegisteredOwnerCapacity')
                            this.showWarningMessage = true
                            return false
                        }
                        if (this.activeRegisteredOwnerCount - 4 > this.businessTypeObject.maxOwnerCount || 
                            this.activeRegisteredOwnerCount <= this.businessTypeObject.minOwnerCount) {
                            this.warningMessage = this.$t('Reg.RegisterOwner.Errors.BoundsMessage.Message2', {
                                totalOwners: this.registeredOwners.length,
                                businessType: this.businessTypeObject.name,
                                minCount: this.businessTypeObject.minOwnerCount,
                                maxCount: this.businessTypeObject.maxOwnerCount
                            })
                            this.showWarningMessage = true
                            return false
                        }
                    }
                }
            }
            return true
        },
        fieldNameToLabel (name) {
            const result = name.split(/(?=[A-Z])/).join(' ');        
            return result.charAt(0).toUpperCase() + result.slice(1);
        },
        validateDidUserWithdraw () {
            this.resetWarnings()
            if (this.currentWithdrawalCount < 1) {
                this.warningMessage = this.$t('Reg.RegisterOwner.Errors.RemovalRequired')
                this.showWarningMessage = true
            }
            return this.showWarningMessage
        },
        validateFields (fields) {
            this.resetWarnings()
            this.checkOptionalInputs()
            const currentErrors = []         
            for (const [fieldName, fieldValue] of Object.entries(fields)) {
                if (typeof fieldValue === 'string'){
                    fieldValue.trim()
                }
                if(fieldValue.length === 0 || fieldValue === null || fieldValue === undefined) {
                    if (this.optionalInputs.includes(fieldName)){
                        this.inputErrors[fieldName] = false
                    } else if (fieldName === 'state') {
                        if(this.internationalMailingAddress){
                            this.inputErrors[fieldName] = false
                        } else {
                            currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.fieldNameToLabel(fieldName) }))
                            this.inputErrors[fieldName] = true
                        }  
                    } else if (fieldName === 'stateOfOrganization') {
                        if (this.isStateOfOrganizationRequired) {
                            currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.fieldNameToLabel(fieldName) }))
                            this.inputErrors[fieldName] = true 
                        } else {
                            this.inputErrors[fieldName] = false
                        }
                    } else {
                        currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.fieldNameToLabel(fieldName) }))
                        this.inputErrors[fieldName] = true
                    }
                } else {
                    switch (fieldName) {
                        case 'address':
                        case 'addressAdditional':
                            if (!isValidAddress(fieldValue)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                this.inputErrors[fieldName] = true
                            }
                            break
                        case 'name':
                            if (!isValidBusinessName(fieldValue)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                this.inputErrors[fieldName] = true
                            }
                            break
                        case 'zipCode': 
                            if (this.internationalMailingAddress) {
                                if (!isValidInput(fieldValue)) {
                                    currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                    this.inputErrors[fieldName] = true
                                }
                            } else {
                                if (!isValidZipCode(fieldValue)) {
                                    currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                    this.inputErrors[fieldName] = true
                                }
                            }
                            break
                        case 'country':
                            if (!isValidDropdown(fieldValue)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                this.inputErrors[fieldName] = true
                            }
                            break
                        case 'stateOfOrganization':
                            if(this.isStateOfOrganizationRequired) {
                                if (!isValidDropdown(fieldValue)) {
                                    currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                    this.inputErrors[fieldName] = true
                                }    
                            }
                            break
                        default:
                            if (!isValidInput(fieldValue)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharacters', { field: this.fieldNameToLabel(fieldName) }))
                                this.inputErrors[fieldName] = true
                            }
                    }
                }
            }
            this.errors = currentErrors
            return currentErrors <= 0    
        },
        validatePage() {
            this.resetWarnings()
            if (this.renderUploadConditions.includes(this.selectedBusinessType) && this.fbnFiling.workflow !== FilingWorkflow.Withdrawal) {
                if (this.sosFile.length <= 0) {
                    this.errors.push(this.$t('Template.ErrorHandling.SOSRequired'))
                }
            }
            if (this.selectedBusinessType === null || this.selectedBusinessType.length <= 0) {
                this.errors.push(this.$t('Reg.RegisterOwner.UserInput.Errors.BusinessTypeEmpty'))               
            }
            return this.errors.length > 0
        },
        validateRegisteredOwnerCount () {
            this.registeredOwnerCountError = ''
            if (this.activeRegisteredOwnerCount < this.businessTypeObject.minOwnerCount) {
                if (this.fbnFiling.workflow === FilingWorkflow.Renewal) {
                    this.registeredOwnerCountError = this.$t('Reg.RegisterOwner.Errors.UnableToEdit')
                } else {
                    this.registeredOwnerCountError = this.$t('Reg.RegisterOwner.Errors.MinimumRegisteredOwners', { minCount: this.businessTypeObject.minOwnerCount, maxCount: this.businessTypeObject.maxOwnerCount })
                }
            } else if (this.activeRegisteredOwnerCount > this.businessTypeObject.maxOwnerCount) {
                this.registeredOwnerCountError = this.$t('Reg.RegisterOwner.Errors.MaximumRegisteredOwners', { minCount: this.businessTypeObject.minOwnerCount, maxCount: this.businessTypeObject.maxOwnerCount })
            }         
            return this.registeredOwnerCountError.length > 0
        },
        validateRegisteredOwnerRows () {
            if (this.fbnFiling.workflow === FilingWorkflow.Standard || 
                this.fbnFiling.workflow === FilingWorkflow.Amendment || 
                this.fbnFiling.workflow === FilingWorkflow.Renewal) {
                this.registeredOwnerRowError = ''
                this.checkOptionalInputs()
                let showRowErrorValidationMessage = false
                this.registeredOwners.forEach(registeredOwner => {
                    if (!registeredOwner.removed) {
                        let rowError = false
                        for (const [fieldName, fieldValue] of Object.entries(registeredOwner)) {
                            if (!this.optionalInputs.includes(fieldName)) {
                                if (fieldName === 'stateOfOrganization') {
                                    if (fieldValue === '' || fieldValue === null) {
                                        if(this.isStateOfOrganizationRequired) {
                                            rowError = true
                                        }    
                                    }                               
                                } else if (fieldName === 'state') {
                                    if (fieldValue === '' || fieldValue === null) {
                                        if(!registeredOwner.international && registeredOwner.id < 500) {
                                            rowError = true
                                        }
                                    }
                                } else if (fieldName === 'zipCode') {
                                    if (fieldValue === '' || fieldValue === null) {
                                        if(!registeredOwner.international && registeredOwner.id < 500) {
                                            rowError = true
                                        }
                                    }
                                } else if (fieldValue === '' || fieldValue === null) {
                                    rowError = true
                                }
                            }
                            if (rowError) {
                                showRowErrorValidationMessage = true
                                registeredOwner.hasMissingData = true
                                this.updateRegisteredOwnerBackgroundColor(registeredOwner)
                            } else {
                                registeredOwner.hasMissingData = false
                                this.updateRegisteredOwnerBackgroundColor(registeredOwner)
                            }
                        }
                    }                         
                })
                if (showRowErrorValidationMessage) {
                    this.registeredOwnerRowError = this.$t('Reg.RegisterOwner.UserInput.Errors.RowValidationError')
                }
                return this.registeredOwnerRowError.length > 0  
            } 
        },
        //#endregion

        // To be refactored
        onBusinessTypeChangeHandler () {
            this.deleteSOSFile();
            this.errors = [];
            this.mapBusinessTypeObject(this.selectedBusinessType)
            for(let i = 0; i <= this.options.length; i++){
                if(this.selectedBusinessType === this.options[i]){
                    this.index = i;
                    this.selectedBusinessType = this.options[i];
                    this.businessTypeObject.minOwnerCount = parseInt(this.businessTypeInformation[this.index].numberOfRegisteredOwners[0]);
                    this.businessTypeObject.maxOwnerCount = parseInt(this.businessTypeInformation[this.index].numberOfRegisteredOwners[1]);
                }
            }
            const list = this.businessTypeInformation[this.index].EligibleSigner;
            let listString = ''
            for(let i = 0; i < list.length; i++){
                if(list.length === 1){
                    listString += list[i];
                }
                if(i < list.length && list.length > 1 && i !== list.length - 1){
                    listString += list[i] + ', ';
                }
                if(i === list.length - 1 && list.length !== 1){
                    listString += list[i];
                }
            }
            this.signerString = listString;
            this.fbnFiling.businessType = this.selectedBusinessType;
            const resetSigner = {
                FullName: '',
                SignatoryName: '',
                SignatoryTitle: ''
            }
            if(this.selectedBusinessType === BusinessTypeName.Corporation || this.selectedBusinessType === BusinessTypeName.LLC) {
                this.displayStateOfOrganization = true
            } else {
                this.displayStateOfOrganization = false
            }
            ControlState.Set(this.activeRegisteredOwnerCount >= this.businessTypeObject.minOwnerCount);
            this.$store.dispatch('setSignatureInformation', resetSigner)
            return this.index;
        },
        buildSignerString() {
            for(let i = 0; i <= this.options.length; i++){
                if(this.selectedBusinessType === this.options[i]){
                    this.index = i;
                }
            }
            const list = this.businessTypeInformation[this.index].EligibleSigner;
            let listString = ''
            for(let i = 0; i < list.length; i++){
                if(list.length === 1){
                    listString += list[i];
                }
                if(i < list.length && list.length > 1 && i !== list.length - 1){
                    listString += list[i] + ', ';
                }
                if(i === list.length - 1 && list.length !== 1){
                    listString += list[i];
                }
            }
            return listString;
        },
        setAmendment() {
            const approach = this.$store.getters.getFilingApproach;
            this.nav = approach + ': ' + FilingWorkflow.Amendment;
            this.$store.dispatch('clearAllState');
            this.$store.dispatch('setWorkflow', FilingWorkflow.Amendment);
            this.$store.dispatch('setFilingApproach', approach);
            this.$store.dispatch('setFilingSelectionType', FilingWorkflow.Amendment);
            this.$store.dispatch('setSubheader', this.nav);
        },
    },
    beforeMount() {
        AppHasData(this.$store.getters.getAppHasData, this)
        this.pageTranslated = this.language !== 'en'
        this.nav = this.$store.getters.getSubheader
        // Get Filing
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        // Filing Actions
        this.filingActions = [...this.fbnFiling.RegisteredOwnerPage.filingActions]
        // SOS File
        this.sosFile = this.fbnFiling.RegisteredOwnerPage.sosFile
        // Registered Owners
        this.registeredOwners = [...this.fbnFiling.RegisteredOwnerPage.registeredOwners]
        this.originalRegisteredOwners = [...this.fbnFiling.RegisteredOwnerPage.registeredOwners]
        if (this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
            this.registeredOwners.forEach(ro => {
                if (ro.action === this.$t('Template.UndoActions.RemoveOwner')) {
                    this.currentWithdrawalCount++
                }
            })
        }
        // Registered Owner temp id
        this.registeredOwnerTempId = this.fbnFiling.RegisteredOwnerPage.registeredOwnerTempId
        // Business Type
        this.businessTypeInformation = businessTypeInformation[this.language]
        this.selectedBusinessType = this.fbnFiling.RegisteredOwnerPage.businessType
        if (this.selectedBusinessType.length > 0) {
            this.mapBusinessTypeObject(this.selectedBusinessType)
        }
        if(this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.Corporation || this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLC) {
            this.displayStateOfOrganization = true
        }
        if(this.fbnFiling.workflow === FilingWorkflow.Renewal || this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
            this.hideBusinessTypeSelection = true
        }
        if (this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.Corporation ||
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLC ||
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLP ||
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LimitedPartnership) {
                this.showSOSUploadRenewal = true
        }
        // Refactor when refactoring signer page
        this.signerString = this.buildSignerString();
        this.signerBackup = {...this.$store.getters.getSignatureInformation};
        if (this.fbnFiling.reviewPageEdit) {
            this.originalBusinessType = this.fbnFiling.RegisteredOwnerPage.businessType
            this.originalSosFile = this.fbnFiling.RegisteredOwnerPage.sosFile
        }
        window.scrollTo(0,0);
    },
    mounted () {
        const controlButton = 'proceed-button'
        ControlState.Construct(controlButton, (this.registeredOwners.length >= this.businessTypeObject.minOwnerCount))
        this.calculateActiveRegisteredOwners()
        this.validateRegisteredOwnerCount()
        this.validateRegisteredOwnerRows()
    }
}
</script>
<style>
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
        border-width: 2px;
    }
    .darkblue {
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .modalwidth50 {
        min-width: 48.5%;
    }
    .outlineRed{
        outline: 2px solid red;
    }
    .bgRed{
        background-color: rgb(239, 83, 80, .1);
        border-left: 10px solid red !important;
    }
    .m-owner-row {
        border-bottom: 1px solid #ccc;
    }
    .m-owner-row-details {
        font-size: 16px;
        background-color: #fff;
    }
    .m-owner-row-controls {
        margin-top: -12px;
    }
    .required::after {
        content: "*";
        color: rgb(250,83,80);
    }
    .content-to-hide {
            display: none;
        }
    @media only screen and (min-width: 768px){                  
        .content-to-hide {
            display: block;
        }
    }
    .bg-orange {
        --tw-text-opacity: 1;
        background-color: rgba(253, 186, 116, var(--tw-text-opacity));
    }
    .bg-green {
        --tw-text-opacity: 1;
        background-color: rgba(74, 222, 128, var(--tw-text-opacity));
    }
    .bg-gray {
        --tw-text-opacity: 1;
        background-color: rgba(168, 162, 158, var(--tw-text-opacity));
    }
    .bg-red {
        --tw-text-opacity: 1;
        background-color: rgba(252, 165, 165, var(--tw-text-opacity));
    }
</style>