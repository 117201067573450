<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.PublishingInfo.Heading') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ currentWorkflow }}</div>
        <div class="mx-auto py-4 text-left text-md max-w-screen-xl">
            {{$t('Reg.PublishingInfo.Intro')}}</div>
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{$t('Template.ErrorHandling.Notify')}}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="max-w-screen-xl mx-auto py-2">
            <div class="text-center flex items-end">            
                <div for="publication" class="required text-xl w-4/12 py-1 mt-4 inline-block text-left" id="publication-label">{{ $t('Reg.PublishingInfo.UserInput.Labels.Publication') }}</div>
                <select class="w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded 
                        shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block"
                        aria-label="publication-select"
                        aria-labelledby="publication-label"
                        v-bind:class="[ showInputErrorWarning.publication ? 'outlineRed': 'outline-none' ]" 
                        id="publication-select" 
                        v-model="publishingData.publication">
                    <option v-for="publication in publications" :key="publication">{{ publication }}</option>
                </select>
            </div>  
        </div>
        <div class="max-w-screen-xl mx-auto py-2">
            <div class="text-center flex items-end py-2">
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="start-date-label">{{ $t('Reg.PublishingInfo.UserInput.Labels.PublishStartDate') }}</div>
                <input 
                    aria-label="start-date-field"
                    aria-labelledby="start-date-label"
                    type="text" 
                    maxlength="10" 
                    name="publish-start-date" 
                    id="publish-start-date"
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.publishStartDate ? 'outlineRed': 'outline-none' ]"
                    v-model="publishingData.publishStartDate"
                    placeholder="MM/DD/YYYY">
            </div>
            <div class="text-center py-2 flex items-end">
                <div class="required text-xl md:w-4/12 py-2 mt-4 inline-block text-left w-full" id="end-date-label">{{ $t('Reg.PublishingInfo.UserInput.Labels.PublishEndDate') }}</div>
                <input 
                    aria-label="end-date-field"
                    aria-labelledby="end-date-label"
                    type="text" 
                    maxlength="10" 
                    name="publish-end-date" 
                    id="publish-end-date"
                    class="appearance-none md:w-8/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block w-full" 
                    v-bind:class="[ showInputErrorWarning.publishEndDate ? 'outlineRed': 'outline-none' ]"
                    v-model="publishingData.publishEndDate"
                    placeholder="MM/DD/YYYY">
            </div>
            <div class="mx-auto max-w-screen-xl py-2 main-controls">
                <NavigationControls 
                    :showBackButton="true"
                    :showProceedButton="true"
                    :showHelpButton="true"  
                    @previousPage="previousPage"
                    @proceed="proceed"
                    @openHelp="openHelp"/>
                <Help 
                    :page="1"
                    v-show="isHelpVisible"
                    @closeHelp="closeHelp"/>
            </div>        
        </div>
    </div>
</template>

<script>
import { publications, AppHasData } from '../../assets/constants';
import Help from '../common/Help.vue';
import NavigationControls from '../common/NavigationControls.vue'
export default {
    name: 'Publishing Info',
    components: {
        Help,
        NavigationControls
    },
    data: () => ({
        errors: [],
        nav: '',
        publications: publications,
        publishingData: {
            publication: '',
            publishStartDate: '',
            publishEndDate: ''
        },
        deepClone: {},
        showInputErrorWarning: {
            publication: false,
            publishStartDate: false,
            publishEndDate: false,
        },
        isHelpVisible: false
    }),
    methods: {
        validateData(input){
            const re = /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;
            return re.test(input);
        },
        validateDropdown(name) {
            const re = /^[A-Za-zÅéçô0-9 -/'&#,]*[A-Za-z0-9 )('-][A-Za-z0-9 ()'#&.]*$/;
            return re.test(name);
        },
        variableNameToLabel(name) {
            const result = name.split(/(?=[A-Z])/).join(' ');        
            return result.charAt(0).toUpperCase() + result.slice(1);
        },
        resetInputErrorWarnings() {
            const copyShowInputErrorWarning = {...this.showInputErrorWarning};
            for (const [k] of Object.entries(copyShowInputErrorWarning)) {
                copyShowInputErrorWarning[k] = false;
            }
            this.showInputErrorWarning = copyShowInputErrorWarning;
        },
        doesPageContainErrors: function () {
            const currentErrors = [];
            this.resetInputErrorWarnings();
            var showErrors = {...this.showInputErrorWarning};
            const startDate = Date.parse(this.publishingData.publishStartDate);
            const endDate = Date.parse(this.publishingData.publishEndDate);
            if(endDate <= startDate) {
                currentErrors.push(this.$t('Reg.PublishingInfo.UserInput.Errors.EndDateGreaterThanStartDateMessage'));
            }
            for (const [k, v] of Object.entries(this.publishingData)) {
                const value = v.trim();
                if(value.length === 0) {
                    currentErrors.push(this.$t('Template.ErrorHandling.FieldRequired', { field: this.variableNameToLabel(k) }));
                    showErrors[k] = true; 
                }
                if(value.length !== 0) {
                    switch(k) {
                        case 'publication':
                            if(!this.validateDropdown(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                        case 'publishStartDate':
                            if(!this.validateData(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidDateMessage', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                        case 'publishEndDate':
                            if(!this.validateData(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidDateMessage', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }
                            break;
                        default:
                            if(!this.validateData(value)) {
                                currentErrors.push(this.$t('Template.ErrorHandling.InvalidCharactersWithDefinition', { field: this.variableNameToLabel(k) }));
                                showErrors[k] = true;
                            }   
                    } 
                }
            }
            this.showInputErrorWarning = showErrors;
            this.errors = currentErrors;
            return currentErrors.length > 0;
        },
        openHelp(){
            this.isHelpVisible = true;
        },
        closeHelp(){
            this.isHelpVisible = false;
        },
        proceed () {
            if (this.doesPageContainErrors()) return;
            this.$store.dispatch('setPublications', this.publishingData);
            this.$router.push('fbnuploadproof');
        },
        previousPage() {
            // This is temporary to keep proofs at 1 record
            this.$store.dispatch('resetFilingPublicationObjects');
            this.$router.push('fbnlookup')
        }
    },
    beforeMount() {
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        const storePublishingData = this.$store.getters.getPublications;
        this.deepClone = JSON.parse(JSON.stringify({...this.$store.getters.getPublications}));
        Object.assign(this.publishingData, storePublishingData);
        this.currentWorkflow = this.$store.getters.getWorkflow;
        window.scrollTo(0,0);
    }
}

</script>
<style>
    .lightblue{
        background-color: #13b5ea;
    }
    .lightblue:hover{
        background-color: #59cbf0;
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
    }
    .outlineRed{
        outline: 2px solid red;
    }
     .required::after{
        content: " *";
        color:red;
    }
</style>