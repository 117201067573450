<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Home.FilingWorkflow.Heading') }}</h1>
        <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto pb-2 mb-2" id="breadcrumb">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto py-2 max-w-screen-xl">
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="standard-filing-workflow-button"
                    aria-label="standard-filing-workflow-button"
                    @click="filingWorkflowSelection(FilingWorkflow.Standard)">
                    <div class="button-header">{{ $t(`Home.FilingWorkflow.StandardFilingWorkflowButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingWorkflow.StandardFilingWorkflowButton.Description`) }}</div> 
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="amendment-filing-workflow-button"
                    aria-label="amendment-filing-workflow-button"
                    @click="filingWorkflowSelection(FilingWorkflow.Amendment)">
                    <div class="button-header">{{ $t(`Home.FilingWorkflow.AmendmentFilingWorkflowButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingWorkflow.AmendmentFilingWorkflowButton.Description`) }}</div> 
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="renewal-filing-workflow-button"
                    aria-label="renewal-filing-workflow-button"
                    @click="filingWorkflowSelection(FilingWorkflow.Renewal)">
                    <div class="button-header">{{ $t(`Home.FilingWorkflow.RenewalFilingWorkflowButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingWorkflow.RenewalFilingWorkflowButton.Description`) }}</div> 
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="abandoment-filing-workflow-button"
                    aria-label="abandoment-filing-workflow-button"
                    @click="filingWorkflowSelection(FilingWorkflow.Abandonment)">
                    <div class="button-header">{{ $t(`Home.FilingWorkflow.AbandonmentFilingWorkflowButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingWorkflow.AbandonmentFilingWorkflowButton.Description`) }}</div> 
                </div>
            </div>
            <div class="py-2">
                <div
                    class="text-white rounded darkblue px-6 py-4 font-semibold shadow-lg focus:outline-none modal mr-2 cursor-pointer"
                    id="withdrawal-filing-workflow-button"
                    aria-label="withdrawal-filing-workflow-button"
                    @click="filingWorkflowSelection(FilingWorkflow.Withdrawal)">
                    <div class="button-header">{{ $t(`Home.FilingWorkflow.WithdrawalFilingWorkflowButton.Name`) }}</div>
                    <div class="button-info">{{ $t(`Home.FilingWorkflow.WithdrawalFilingWorkflowButton.Description`) }}</div> 
                </div>
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showHelpButton="true" 
                @openHelp="openHelp()" 
                @previousPage="previousPage()"/>
            <Help 
                :page="0" 
                v-show="isHelpVisible" 
                @closeHelp="closeHelp()"/>
        </div>          
    </div>           
</template>

<script>
import { AppHasData, FilingWorkflow } from '../../assets/constants.js'
import NavigationControls from '../common/NavigationControls.vue'
import Help from '../common/Help.vue'
export default {
    name: 'FilingWorkflow',
    components: {
        NavigationControls,
        Help
    },
    data: () => ({
        nav:'',
        FilingWorkflow: FilingWorkflow,
        fbnFiling: {},
        isHelpVisible: false
    }),
    methods: {
        filingWorkflowSelection (workflow) {
            this.fbnFiling = this.$store.getters.getFbnFiling
            this.fbnFiling.workflow = workflow
            this.$store.dispatch('setFbnFiling', this.fbnFiling)           
            this.$store.dispatch('setWorkflow', workflow);
            this.$store.dispatch('setSubheader', workflow);         
            this.$store.dispatch('setFilingSelectionType', workflow);
            if (workflow === FilingWorkflow.Standard) {
                this.$router.push('fbnsearch');
            } else {
                this.$store.dispatch('setWorkflow', workflow);
                this.$router.push('fbnlookup');
            };
        },
        openHelp () {
            this.isHelpVisible = true
        },
        closeHelp () {
            this.isHelpVisible = false
        },
        previousPage () {
            this.$router.push('fbnfilingapproach')
        }
    },
    beforeMount () {
        this.nav = this.$store.getters.getSubheader;
        AppHasData(this.$store.getters.getAppHasData, this)
        window.scrollTo(0,0)
    }
};
</script>