<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.OrderConfirmation.Heading') }}</h1>
        <div v-if="workflow.Approach === FilingApproach.Online" class="py-4 mx-auto text-left text-xl max-w-screen-xl" id="order-confirm-text" v-html="$t('Reg.OrderConfirmation.ConfirmationNumber', { orderNumber })"></div>
        <div v-if="displayBarcode === true">
            <div class="py-4 mx-auto text-left text-xl max-w-screen-xl" id="order-confirm-text" v-html="$t('Reg.OrderConfirmation.ConfirmationNumber', { orderNumber })"></div>
            <div class="py-4 mx-auto text-left max-w-screen-xl" id="barcode-image">
                <svg id="barcode"></svg>
            </div>
            <div v-if="currentWorkflow === 'Provide Proof of Publication'" class="py-4 mx-auto text-left text-xl max-w-screen-xl" id="proof-of-publications-text">{{ $t('Reg.OrderConfirmation.Proofs') }}</div>
        </div>
        <!-- In-person Text -->
        <div v-if="filingType === 'In-Person'">
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">{{ $t('Reg.OrderConfirmation.InPerson.Copy1') }}</div>
            <div
                v-if="workflow.Type === FilingWorkflow.Standard ||
                workflow.Type === FilingWorkflow.Amendment ||
                workflow.Type === FilingWorkflow.Renewal" 
                class="py-2 mx-auto text-left text-xl max-w-screen-xl">
                {{ $t('Reg.OrderConfirmation.InPerson.Copy2') }}
            </div>
            <div v-else
                class="py-2 mx-auto text-left text-xl max-w-screen-xl">
                {{ $t('Reg.OrderConfirmation.InPerson.Copy4') }}
            </div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl" v-html="$t('Reg.OrderConfirmation.InPerson.Copy3')"></div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">
                <a href="https://www.mytime.com/express_checkout/123989/137830" target="_blank" id="mytime" class="mytime_embedded_btn big inline-block" title="Book On MyTime"><img :src="require('@/assets/img/button_mytime_booking.jpg?v=2')" alt="MyTime Booking" title="MyTime Booking"/></a>
            </div>
        </div>
        <!-- Mail-In Text -->
        <div v-if="filingType === 'Mail-In'">
            <div
                v-if="workflow.Type === FilingWorkflow.Standard || workflow.Type === FilingWorkflow.Amendment || workflow.Type === FilingWorkflow.Renewal"
                class="py-2 mx-auto text-left text-xl max-w-screen-xl">
                {{ $t('Reg.OrderConfirmation.MailIn.Copy3') }}
            </div>
            <div
                v-else
                class="py-2 mx-auto text-left text-xl max-w-screen-xl">
                {{ $t('Reg.OrderConfirmation.MailIn.Copy2') }}
            </div>
            <div class="py-1 mx-auto text-center text-xl max-w-screen-xl"><strong>Business Filing and Registration</strong></div>
            <div class="py-1 mx-auto text-center text-xl max-w-screen-xl"><strong>P.O. Box 1208</strong></div>
            <div class="py-1 mx-auto text-center text-xl max-w-screen-xl"><strong>Norwalk, California 90651-1208</strong></div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>{{ $t('Reg.OrderConfirmation.MailIn.Copy4') }}</strong></div>
            <div class="py-2 mx-auto text-left text-red-500 text-xl max-w-screen-xl mb-4">{{ $t('Reg.OrderConfirmation.MailIn.Copy5') }}</div>
            <!-- PDF Viewer -->
            <div class="max-w-screen-xl mx-auto">
                <a class="mx-auto max-w-screen-xl text-white px-4 py-4 font-semibold darkblue text-center rounded" id="pdf-download" :href="pdfFile" download="Confirmation.pdf">Download File</a>
                <div class="mt-4" v-if="!safari">
                    <div id="pdf-status"></div>
                    <div id='pdf-containere' class="text-center py-2">
                        <iframe id="pdf-viewer" :key="pdfKey" v-if="pdf" :src="pdf" width="100%" height="1000px"></iframe>
                    </div>
                </div>
            </div>           
        </div>
        <!-- Third Party Text -->
        <div v-if="filingType === 'Third Party'">
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">{{ $t('Reg.OrderConfirmation.InPerson.Copy1') }}</div>
            <div v-if="workflow.Type === FilingWorkflow.Standard || 
                workflow.Type === FilingWorkflow.Amendment || 
                workflow.Type === FilingWorkflow.Renewal">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl mb-4">{{ $t('Reg.OrderConfirmation.Copy5') }}</div>
            </div>
            <div v-else>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl mb-4">{{ $t('Reg.OrderConfirmation.Copy6') }}</div>
            </div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl" v-html="$t('Reg.OrderConfirmation.ThirdParty.Copy2')"></div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">
                <a href="https://www.mytime.com/express_checkout/123989/137830" target="_blank" id="mytime" class="mytime_embedded_btn big inline-block" title="Book On MyTime"><img :src="require('@/assets/img/button_mytime_booking.jpg?v=2')" alt="MyTime Booking" title="MyTime Booking"/></a>
            </div>
            <!-- PDF Viewer -->
            <div class="max-w-screen-xl mx-auto py-2">
                <a class="mx-auto max-w-screen-xl text-white px-4 py-4 font-semibold darkblue text-center rounded mb-1 mt-1" id="pdf-download" :href="pdfFile" download="Confirmation.pdf">Download File</a>
                <div class="mt-4" v-if="!safari">
                    <div id="pdf-status"></div>
                    <div id='pdf-containere' class="text-center py-2">
                        <iframe id="pdf-viewer" :key="pdfKey" v-if="pdf" :src="pdf" width="100%" height="1000px"></iframe>
                    </div>
                </div>
            </div>           
        </div>
        <!-- Online Filing Text -->
        <div v-if="filingType === 'Online'">
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">{{ $t('Reg.OrderConfirmation.Online.Copy1') }}</div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>{{ $t('Reg.OrderConfirmation.Online.Copy2') }}</strong></div>
            <div v-if="workflow.Type !== 'Fictitious Business Name Statement Renewal'">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">{{ $t('Reg.OrderConfirmation.Online.Copy3') }}
                    <br>
                    <a href="https://lavote.gov/home/county-clerk/fictitious-business-names/publication" 
                        target="_blank" id="publication" 
                        class="py-2 mx-auto text-left text-xl max-w-screen-xl text-indigo-600 hover:text-indigo-900">
                        https://lavote.gov/home/county-clerk/fictitious-business-names/publication
                    </a>
                </div>
            </div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl" v-html="$t('Reg.OrderConfirmation.Copy3')"></div>
        </div>
        <div v-if="ShowPricing">
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl mt-2"><strong>{{ $t('Reg.OrderConfirmation.Copy1') }}</strong></div>
            <div v-if="workflow.Approach !== 'In-Person'" class="py-2 mx-auto text-left text-xl max-w-screen-xl mt-2"><strong>{{ $t('Reg.OrderConfirmation.Copy4') }}</strong></div>
            <div v-else class="py-2 mx-auto text-left text-xl max-w-screen-xl">{{ $t('Reg.OrderConfirmation.Copy2') }}</div>
            <div class="py-2 mx-auto text-left text-xl max-w-screen-xl" v-html="$t('Reg.OrderConfirmation.Copy3')"></div>
            <div v-if="workflow.Type === 'Fictitious Business Name Statement'">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">Original - <strong>$26.00</strong> (FOR ORIGINAL FILING WITH ONE BUSINESS NAME ON STATEMENT)</div>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>$5.00</strong> - FOR EACH ADDITIONAL BUSINESS NAME FILED ON SAME STATEMENT, DOING BUSINESS AT THE SAME LOCATION</div>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>$5.00</strong> - FOR EACH ADDITIONAL OWNER IN EXCESS OF ONE OWNER</div>
            </div>
            <div v-else-if="workflow.Type === 'Fictitious Business Name Statement Amendment'">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">Amendment - <strong>$26.00</strong> (CHANGES IN FACTS FROM ORIGINAL FILING - REQUIRES PUBLICATION)</div>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>$5.00</strong> - FOR EACH ADDITIONAL BUSINESS NAME FILED ON SAME STATEMENT, DOING BUSINESS AT THE SAME LOCATION</div>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>$5.00</strong> - FOR EACH ADDITIONAL OWNER IN EXCESS OF ONE OWNER</div>
            </div>
            <div v-else-if="workflow.Type === 'Fictitious Business Name Statement Renewal'">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">Renewal - <strong>$26.00</strong> (NO CHANGES IN THE FACTS FROM ORIGINAL FILING)</div>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>$5.00</strong> - FOR EACH ADDITIONAL BUSINESS NAME FILED ON SAME STATEMENT, DOING BUSINESS AT THE SAME LOCATION</div>
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl"><strong>$5.00</strong> - FOR EACH ADDITIONAL OWNER IN EXCESS OF ONE OWNER</div>
            </div>
            <div v-else-if="workflow.Type === 'Fictitious Business Name Statement Abandonment'">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">Abandonment - <strong>$26.00</strong> (ABANDON BUSINESS NAMES FROM A FICTITIOUS BUSINESS NAME STATEMENT - REQUIRES PUBLICATION)</div>
            </div>
            <div v-else-if="workflow.Type === 'Fictitious Business Name Statement Withdrawal'">
                <div class="py-2 mx-auto text-left text-xl max-w-screen-xl">Withdrawal - <strong>$26.00</strong> (WITHDRAW REGISTERED OWNER(S) NAME FROM A FICTITIOUS BUSINESS NAME STATEMENT - REQUIRES PUBLICATION)</div>
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showHomeButton="true"
                :showPrintButton="true" 
                @homePage="homePage"
                @printPage="printPage"/>
        </div>
    </div>
    
</template>

<script>
import JsBarcode from 'jsbarcode';
import ClerkRepository from '../../repositories/ClerkRepository';
import { FilingApproach, FilingWorkflow } from '../../assets/constants'
import NavigationControls from '../common/NavigationControls.vue'
import { Buffer } from 'buffer'
export default {
    name: 'Confirmation Page',
    components: {
        NavigationControls
    },
    computed: {
        ShowPricing() {
            if(this.workflow.Approach === 'In-Person' || this.workflow.Approach === 'Mail-In' || this.workflow.Approach === 'Third Party') {
                return true;
            } else {
                return false;
            }
        }
    },
    data: () => ({
        orderNumber: '',
        confirmationText: '',
        businessTypeText: false,
        filingType:'',
        barcode: '',
        pdf: null,
        pdfFile: null,
        workflow: {},
        FilingApproach: FilingApproach,
        FilingWorkflow: FilingWorkflow,
        displayBarcode: true,
        safari: false
    }),
    methods: {
        printPage() {
            window.print();
        },
        homePage () {
            this.$store.dispatch('clearAllState');
            this.$router.push('/')
        },
        safariCheck () {
            const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
            const isSafari = navigator.userAgent.indexOf('Safari') > -1;
            if ( (isChrome) && (isSafari) ) { 
                this.safari = false; 
            } else {
               this.safari = true; 
            }
        }
    },
    beforeMount(){
        this.filingType = this.$store.getters.getFilingApproach;
        const businessType = this.$store.getters.getBusinessType;
        if(businessType === 'Corporation' || businessType === 'Limited Liability Company (LLC)' || businessType === 'Limited Partnership' || businessType === 'Limited Liability Partnership'){
            this.businessTypeText = true;
        }
        this.orderNumber = this.$store.getters.getOrderConfirmationNumber;
        var fbnFiling = this.$store.getters.getCurrentFiling;
        this.currentWorkflow = this.$store.getters.getWorkflow;
        this.workflow = {
            Approach: this.filingType,
            Type: this.currentWorkflow
        }
        if(this.workflow.Approach === FilingApproach.Online) {
            this.displayBarcode = false;
        }
        // Add PDF to viewer
        if (this.filingType === 'Mail-In' || this.filingType === 'Third Party') {
            if(this.filingType === 'Mail-In') {
                this.displayBarcode = false;
            }
            ClerkRepository.postPDF(fbnFiling, this.workflow, false, this.$store.getters.getFilingNumber)
            .then( response => {
                this.pdfFile = window.URL.createObjectURL(new Blob([response.data]));
                this.pdf = `data:application/pdf;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
            })
            .catch(function(error) {
                document.getElementById('pdf-status').innerHTML = '<span class="text-red-500 text-2xl font-semibold">' + this.$t('Template.ErrorHandling.PDF') + '</span>';
                console.log(`${error}`);
            });
        }
        this.safariCheck();
        window.scrollTo(0,0);
    },
    mounted(){
        if(this.displayBarcode) {
            JsBarcode('#barcode', this.orderNumber, {
                width:4,
                height:40,
                displayValue: true
            });    
        }
        
        this.$store.dispatch('clearAllState');
    }
}
</script>
<style>
</style>