<template>
    <div id="language_selection" class="hide">
        <div class="row_item">
            <ul>
                <li class="row_span"><a id="en" class="btn" @click="$emit('select-language', $event)">English</a></li>
                <li :class="['row_span', ShowTranslation]"><a id="es" class="btn" @click="$emit('select-language', $event)">Spanish</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LanguageSelectionMenu',
    props: {
        enableTranslation: Boolean
    },
    computed: {
        ShowTranslation() {
            return this.enableTranslation ? 'enabled' : 'disabled';
        }
    }
}
</script>