<template>
    <div>
        <div>
            <FbnSearch :standAlone="standAlone"/>
        </div>    
    </div>
</template>

<script>
import FbnSearch from '../components/standardworkflow/FbnSearch.vue'
export default {
    components: {
        FbnSearch
    },
    data: () => ({
        standAlone: false
    }),
    beforeMount() {
        this.standAlone = this.$route.name === 'OnlineSearch'
    }
}
</script>