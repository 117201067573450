<template>
    <div>
        <div>
            <PublishingInfo />
        </div>    
    </div>
    
</template>

<script>
import PublishingInfo from '../components/Reg/PublishingInfo.vue'
export default {
    components: {
        PublishingInfo
    }
}
</script>