function ConvertFileToBase64 (file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}    

function variableNameToLabel(name) {
    const result = name.split(/(?=[A-Z])/).join(' ');        
    return result.charAt(0).toUpperCase() + result.slice(1);
}

module.exports = {
    ConvertFileToBase64: ConvertFileToBase64,
    variableNameToLabel: variableNameToLabel
}
