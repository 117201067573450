<template>
    <div class="m_pad" role="main">
        <div>
            <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl" id="page-header">{{ $t('Reg.RegisterName.Heading') }}</h1>
            <div class="md:mx-auto text-left text-lg font-bold max-w-screen-xl mx-auto">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
            <div class="mx-auto pt-4 text-left text-2xl font-bold max-w-screen-xl">{{ $t('Reg.RegisterName.Intro') }}</div>
            <div class="md:mx-auto text-left text-lg max-w-screen-xl mx-auto text-red-600">{{ $t('Reg.RegisterName.Intro2') }}</div>
            <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
                <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
                <ul>
                    <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
            <div class="max-w-screen-xl mx-auto">
                <div class="text-center py-2">
                    <input type="text" maxlength="400" name="business-name-input" id="business-name-input" class="appearance-none md:w-11/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 w-full" v-model="fbnName" v-on:keyup.enter="addFbnName(fbnName)" :placeholder="$t('Template.UserInput.FBNFieldPlaceHolder')">
                    <div class="w-1/12 pt-2 pb-2 block md:inline-block md:pl-2" id="submit-button-container">
                        <button id="add-business-name-button" class="text-white rounded darkblue font-semibold shadow-lg focus:outline-none pt-2 pb-2 px-7" @click="addFbnName(fbnName)" type="submit">{{ $t('Template.Buttons.Add') }}</button>
                    </div>
                    <div class="w-full text-left py-4" v-if="fbnNames.length > 0">
                        <div class="mx-auto py-4 text-left text-lg font-bold max-w-screen-xl" v-if="fbnFiling.workflow === filingWorkflow.Amendment">{{ $t('Reg.RegisterName.NewFbnNameList') }}</div>
                        <div class="max-w-screen-xl mx-auto">
                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                            #
                                                        </th>
                                                        <th scope="col" class="w-8/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                            {{ $t('Template.FBNName') }}
                                                        </th>
                                                        <th scope="col" class="w-1/12 px-6 py-3 text-right text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                            {{ $t('Template.Edit') }}
                                                        </th>
                                                        <th scope="col" class="w-1/12 px-6 py-3 text-right text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                            {{ $t('Template.Remove') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    <tr v-for="(fbn,index) in fbnNames" :key="index">
                                                        <td class="px-6 py-4 whitespace-nowrap">
                                                            <div class="text-sm text-gray-900">
                                                                {{ index + 1}}
                                                            </div>
                                                        </td>
                                                        <td class="px-6 py-4 whitespace-nowrap">
                                                            <div class="text-base text-gray-900">{{ fbn }}</div>
                                                        </td>
                                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                            <!-- Responsive -->
                                                            <div class="hidden md:block">
                                                                <button @click="editFbnClickHandler(index)" class="text-indigo-600 hover:text-indigo-900">{{ $t('Template.Edit') }}</button>
                                                            </div>
                                                            <div class="md:hidden">
                                                                <button @click="editFbnClickHandler(index)" class="text-indigo-600 hover:text-indigo-900"><img :src="require('../../assets/img/m/icon_edit.png')" alt="Icon - Edit" title="Edit" width="40" height="40"/></button>
                                                            </div>                                                        
                                                        </td>
                                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                            <!-- Responsive -->
                                                            <div class="hidden md:block">
                                                                <button @click="removeFbn(fbn)" class="text-indigo-600 hover:text-indigo-900">{{ $t('Template.Remove') }}</button>
                                                            </div>
                                                            <div class="md:hidden">
                                                                <button @click="removeFbn(fbn)" class="text-indigo-600 hover:text-indigo-900"><img :src="require('../../assets/img/m/icon_delete.png')" alt="Icon - Delete" title="Delete" width="40" height="40"/></button>
                                                            </div>                                                        
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                  
            </div>
        </div>
        <div v-if="fbnFiling.workflow === filingWorkflow.Amendment">
            <div class="mx-auto py-4 text-left text-lg font-bold max-w-screen-xl">{{ $t('Reg.RegisterName.ExistingFbnNameList') }}</div>
            <div class="max-w-screen-xl mx-auto">
                <div class="text-center py-2">
                    <div class="w-full text-left py-4" v-if="fbnFiling.BusinessNamePage.originalFbnNames.length > 0">
                        <div class="max-w-screen-xl mx-auto">
                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                        #
                                                    </th>
                                                    <th scope="col" class="w-8/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                        FBN Name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                                <tr v-for="(fbn,index) in fbnFiling.BusinessNamePage.originalFbnNames" :key="index">
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        <div class="text-sm text-gray-900">
                                                            {{ index + 1}}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4 whitespace-nowrap">
                                                        <div class="text-base text-gray-900">{{ fbn }}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
        <div class="mx-auto pb-4 text-left max-w-screen-xl" v-if="fbnEdit.length > 0">
            <div class="z-10 inset-0 overflow-y-auto" aria-label="edit-modal" role="dialog" aria-modal="true">
                <div class="top-0 right-0 left-0 absolute flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                    <!-- centering the modal contents. -->
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all modalwidth50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="">
                                <div class="text-left">
                                    <div class="text-2xl leading-6">{{ $t('Reg.RegisterName.UserInput.Edit.ModalTitle') }}</div>
                                        <div class="mx-auto py-2 text-left max-w-screen-xl small-text" v-if="errorsModal.length">
                                            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
                                            <ul>
                                                <li class="text-red-600" v-for="error in errorsModal" :key="error">{{ error }}</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <div class="text-sm" id="edit-data-fields">
                                                <div class="text-md w-2/12 py-1 pr-2 inline-block text-left" id="fbn-name-edit">FBN Name: </div>
                                                <input type="text" aria-label="fbn-name-edit-input" aria-labelledby="fbn-name-edit" id="fbn-name-edit-modal" maxlength="400" class="appearance-none w-10/12 mx-auto bg-white border border-gray-400 hover:border-gray-500 py-2 px-2 rounded shadow leading-tight focus:outline-none focus:border-lightblue h-12 inline-block" v-model="fbnEdit"/>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-100 px-4 py-3 text-left sm:px-6 sm:flex">
                                <button @click="cancelFbnEdit()" type="button" id="cancel-fbn-edit-button" class="mt-3 w-full inline-flex justify-center rounded-md border text-white shadow-sm px-4 py-2 text-base font-medium bg-gray-700 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ $t('Template.Buttons.Cancel') }}
                                </button>
                                <button @click="saveFbnEdit()" type="button" id="save-fbn-edit-button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 darkblue text-base font-medium text-white darkblue:hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ $t('Template.Buttons.Save') }}
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showProceedButton="true"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="proceed"
                @openHelp="openHelp"/>
            <Help 
                :page="2"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>
    </div>         
</template>

<script>
import Help from '../common/Help.vue'
import NavigationControls from '../common/NavigationControls.vue'
import { ControlState, AppHasData, FilingActionType, FilingWorkflow, MaxBusinessNameCount } from '../../assets/constants'
import { isValidBusinessName } from '../../assets/constant/validation'
export default {
    name: 'Register Name',
    components: {
        Help,
        NavigationControls
    },
    data: () => ({
        errors: [],
        errorsModal: [],
        fbnEdit: '',
        fbnFiling: {},
        fbnName: '',
        fbnNames: [],
        filingActions: [],
        filingWorkflow: FilingWorkflow,
        isHelpVisible: false,
        maxBusinessNameCount: MaxBusinessNameCount,
        originalBusinessNameData: {
            fbnName: '',
            index: 0,
        }
    }),
    methods: {
        //#region Interactions
        addFbnName (fbnName) {
            if (this.doesBusinessNameContainErrors(fbnName)) return
            if (this.addFbnNameValidation(fbnName)) return
            // Add Business Name Filing Action
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                this.createFilingAction(FilingActionType.AddBusinessName, fbnName)
            }
            // Add fbn to fbn names array
            this.fbnNames.push(fbnName)
            // Reset fbn name
            this.fbnName = ''
            ControlState.Set((this.fbnNames.length > 0 || this.fbnFiling.BusinessNamePage.originalFbnNames.length > 0))
        },
        cancelFbnEdit () {
            // Reset fbn name to original value
            this.fbnNames[this.originalBusinessNameData.index] = this.originalBusinessNameData.fbnName
            this.resetFbnEdit()
        },
        editFbnClickHandler (index) {
            // Populate modal with name to edit
            this.fbnEdit = this.fbnNames[index]
            // Store original name and index
            this.originalBusinessNameData.fbnName = this.fbnNames[index]
            this.originalBusinessNameData.index = index
            window.scrollTo(0,0)
        },
        closeHelp(){
            this.isHelpVisible = false
        },
        openHelp (){
            this.isHelpVisible = true
        },
        removeFbn (fbnToRemove) {
            this.fbnNames = this.fbnNames.filter(existingFbn => existingFbn !== fbnToRemove)
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                // remove name from local filing actions array
                this.filingActions = this.filingActions.filter(filingAction => filingAction.name !== fbnToRemove)
                // remove name from the filing actions array in the store
                this.fbnFiling.BusinessNamePage.filingActions = this.fbnFiling.BusinessNamePage.filingActions.filter(filingAction => filingAction.name !== fbnToRemove)
            }
            ControlState.Set((this.fbnNames.length > 0 || this.fbnFiling.BusinessNamePage.originalFbnNames.length > 0))
        },
        saveFbnEdit () {
            if (this.doesBusinessNameContainErrors(this.fbnEdit)) return
            this.fbnNames[this.originalBusinessNameData.index] = this.fbnEdit
            // replace the original name with the new name in the filing actions
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                // remove name from local filing actions array
                this.filingActions = this.filingActions.filter(filingAction => filingAction.name !== this.originalBusinessNameData.fbnName)
                // remove name from the filing actions array in the store
                this.fbnFiling.BusinessNamePage.filingActions = this.fbnFiling.BusinessNamePage.filingActions.filter(filingAction => filingAction.name !== this.originalBusinessNameData.fbnName)
                // Treat editing the name as a new filing action since there is no edit business name filing action
                this.createFilingAction(FilingActionType.AddBusinessName, this.fbnEdit)
            }
            this.resetFbnEdit()
        },
        //#endregion

        //#region Helpers
        createFilingAction (filingAction, fbnName) {
            this.filingActions.push ({
                delta: [{ field: 'Name', value: fbnName }],
                filingActionTypeCode: filingAction,
                name: fbnName,
                nameId: 0
            })
        },
        isReviewPageEdit () {
            // Check for review page flag
            if (this.fbnFiling.reviewPageEdit === true) {
                // Return to the review page and set flag to false
                this.fbnFiling.reviewPageEdit = false
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('fbnreview')
                return true
            }
            return false
        },
        resetFbnEdit () {
            this.originalBusinessNameData = {
                fbnName: '',
                index: 0
            }
            this.fbnEdit = ''
            this.resetValidationErrors()
        },
        resetValidationErrors () {
            this.errors = []
            this.errorsModal = []
        },
        setFbnFilingData () {
            this.fbnFiling.BusinessNamePage.fbnNames = this.fbnNames
            this.fbnFiling.BusinessNamePage.filingActions = this.fbnFiling.BusinessNamePage.filingActions.concat(this.filingActions)
        },
        previousPage () {
            if(!this.isReviewPageEdit()) {
                this.setFbnFilingData()
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('fbnsigner')  
            }
        },
        proceed () {
            if (this.doesPageContainErrors()) return
            this.setFbnFilingData()
            if (!this.isReviewPageEdit()) {
                this.$store.dispatch('setFbnFiling', this.fbnFiling)
                this.$router.push('registerbusinessaddress')  
            }
        },
        //#endregion

        //#region Validations
        addFbnNameValidation () {
            this.resetValidationErrors()
            const currentErrors = []
            if (this.fbnNames.length >= this.maxBusinessNameCount) {
                currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.MaxBusinessNameCount'))
                this.errors = currentErrors
            }
            return currentErrors.length > 0
        },
        doesBusinessNameContainErrors (fbnName) {
            this.resetValidationErrors()
            const currentErrors = []
            if (fbnName.trim().length < 3) {
                currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.BusinessNameLength'))
            }
            if (!isValidBusinessName(fbnName) && fbnName.trim().length !== 0) {
                currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.InvalidCharacters'))
            }
            if (this.fbnNames.includes(fbnName)) {
                currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.DuplicateBusinessName'))
            }
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                if (this.fbnFiling.BusinessNamePage.originalFbnNames.includes(fbnName)) {
                    currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.DuplicateBusinessNameOriginalFiling'))
                }
            }
            if(this.fbnEdit.length <= 0) {
                this.errors = currentErrors
            } else {
                this.errorsModal = currentErrors
            }
            return currentErrors.length > 0
        },
        doesPageContainErrors () {
            this.resetValidationErrors()
            const currentErrors = []
            if(this.currentWorkflow === FilingWorkflow.Standard) {
                if (this.fbnNames.length === 0) {
                    currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.CountNotice'));                
                }
                if (this.fbnNames.length >= 500) {
                    currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.MaxBusinessNameCount'))
                }    
            } else {
                if (this.fbnNames.length >= 500) {
                    currentErrors.push(this.$t('Reg.RegisterName.UserInput.Errors.MaxBusinessNameCount'))
                }
            }
            this.errors = currentErrors
            return currentErrors.length > 0
        }
        //#endregion
    },
    beforeMount() {
        AppHasData(this.$store.getters.getAppHasData, this)
        this.pageTranslated = this.language !== 'en'
        this.nav = this.$store.getters.getSubheader
        // Get Filing
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        // If Filing is an Amendment, get the original Filing Names
        if(this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                this.originalFbnNames = [...this.fbnFiling.BusinessNamePage.originalFbnNames]
                this.fbnNames = [...this.fbnFiling.BusinessNamePage.fbnNames]
        } else {
            this.fbnNames = [...this.fbnFiling.BusinessNamePage.fbnNames]
        }
        window.scrollTo(0,0)
    },
    mounted() {
        ControlState.Construct('proceed-button', (this.fbnNames.length > 0 || this.fbnFiling.BusinessNamePage.originalFbnNames.length > 0))
    }
}
</script>
<style>
    .large-text {
        color: #6d6d6d;
        font-weight: bold;
        font-size: 1.25em;
    }
    .small-text{
        color: #6d6d6d;
        font-weight: bold;
        font-size: .85em;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
        border-width: 2px;
    }
    .modalwidth50 {
        min-width: 48.5%;
    }
    .m-owner-row {
        border-bottom: 1px solid #ccc;
    }
    .m-owner-row-details {
        font-size: 16px;
        background-color: #fff;
    }
    .m-owner-row-controls {
        margin-top: -12px;
    }
</style>