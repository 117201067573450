import Repository from './Repository';
import RepositoryInternal from './RepositoryInternal';
import { StateMapToAbbreviatedState, ReadBusinessType, MapClerkFilingTypeIntToString } from '../assets/constants';

const resource = '/clerk';

function MapFbnFilingToClerkPdfModel(payload) {
    const businessAddress = payload.BusinessAddress;
    const signatureInfo = payload.signatureInfo;
    const requestor = payload.RequestorInformationPage;
    const businessType = ReadBusinessType(payload.businessType);
    const mailAddressName = requestor.middleName ? `${requestor.firstName} ${requestor.middleName} ${requestor.lastName}`.toUpperCase() : `${requestor.firstName} ${requestor.lastName}`.toUpperCase();
    const mailingAddressState = requestor.state.length === 2 ? requestor.state : StateMapToAbbreviatedState[requestor.state];
    const state = businessAddress.state.length === 2 ? businessAddress.state : StateMapToAbbreviatedState[businessAddress.state];
    const data = {
        returnMailingAddressName: mailAddressName || '',
        returnMailingAddress: requestor.addAddress !== undefined ? `${requestor.address.toUpperCase()} ${requestor.addAddress.toUpperCase()}` : (requestor.address.toUpperCase() || ''),
        returnMailingAddressCity: requestor.city !== undefined ? requestor.city.toUpperCase() : '',
        returnMailingAddressState: mailingAddressState || '',
        returnMailingAddressZipCode: requestor.zipCode || '',
        returnMailingAddressCountry: requestor.country || '',
        fbnStreetAddress: businessAddress.additionalAddress !== undefined ? `${businessAddress.address.toUpperCase()} ${businessAddress.additionalAddress.toUpperCase()}` : (businessAddress.address.toUpperCase() || ''),
        fbnCity: businessAddress.city !== undefined ? businessAddress.city.toUpperCase() : '',
        fbnState: state || '',
        fbnZipCode: businessAddress.zipCode || '',
        fbnCounty: businessAddress.county !== undefined ? businessAddress.county.toUpperCase() : '',
        fbnCountry: businessAddress.country !== undefined ? businessAddress.country.toUpperCase() : '',
        articlesOfIncorpOrOrg: businessAddress.articles !== undefined ? businessAddress.articles : '',
        registrantCorpLlcName: signatureInfo.FullName !== undefined ? signatureInfo.FullName.toUpperCase() : '',
        registrantName: signatureInfo.SignatoryName !== undefined ? signatureInfo.SignatoryName.toUpperCase() : '',
        registrantTitle: signatureInfo.SignatoryTitle !== undefined ? signatureInfo.SignatoryTitle.toUpperCase() : '',
        commencementDate: requestor.dateRegistrantCommenced ? requestor.dateRegistrantCommenced : '',
        businessConductedBy: businessType,
        registeredOwners: [],
        signature: payload.signature,
        businessNames: payload.fbnNames.map(name => name.toUpperCase()),
        workflow: {},
        filingNumber: payload.jediFilingNumber,
        filedTS: payload.filedTS
    }
    payload.registeredOwners.forEach(ro => {
        let roState
        if (ro.state === undefined) {
            roState = ''
        } else if (ro.state.length === 2) {
            roState = ro.state
        } else {
            roState = StateMapToAbbreviatedState[ro.state]
        }
        data.registeredOwners.push({
            FullName: ro.name !== undefined ? ro.name.toUpperCase() : '',
            Address: ro.address !== undefined ? ro.address.toUpperCase() : '',
            AdditionalAddress: ro.addressAdditional !== undefined ? ro.addressAdditional.toUpperCase() : '',
            State: roState || '',
            ZipCode: ro.zipCode || '',
            City: ro.city !== undefined ? ro.city.toUpperCase() : '',
            StateOfIncorp: ro.stateOfOrganization !== undefined ? ro.stateOfOrganization.toUpperCase() : '',
            Country: ro.country !== undefined ? ro.country.toUpperCase() : ''
        })
    })
    return data;
}

async function MapFbnFilingToClerkModel(payload, filingType) {
    const data = [];
    if (payload[0].filingSelectionType === 'FBN Proof of Publication') {
        // The last filing should have the updated Additional Information section 
        var lastFiling = payload[payload.length - 1];
        payload.forEach(f => {
            f.RequestorInformationPage = lastFiling.RequestorInformationPage;
        })
    }
    for (let i = 0; i < payload.length; i++) {
        var filing = {
            fbnGroup: null,
            clerkFiling: null,
            fbnFiling: null,
            fbnRegisteredOwnerName: [],
            fbnBusinessName: [],
            fbnRelatedForm: [],
            clerkFilingType: '',
            numberOfCopies: 0,
            fbnFilingAction: [],
            fbnProofOfPublication: [],
            workflow: {}
        };
        const totalRemovedRegisteredOwners = payload[i].filingActions.reduce((sum, fa) => {
            return (fa.filingActionTypeCode === 7 || fa.filingActionTypeCode === 1 ? sum + 1 : sum)
        }, 0);
        const totalAddedRegisteredOwners = payload[i].filingActions.reduce((sum, fa) => {
            return (fa.filingActionTypeCode === 8 ? sum + 1 : sum)
        }, 0);
        const totalRemovedBusinessNames = payload[i].filingActions.reduce((sum, fa) => {
            return (fa.filingActionTypeCode === 10 || fa.filingActionTypeCode === 2 || fa.filingActionTypeCode === 3 ? sum + 1 : sum)
        }, 0);
        const totalAddedBusinessNames = payload[i].filingActions.reduce((sum, fa) => {
            return (fa.filingActionTypeCode === 9 ? sum + 1 : sum)
        }, 0);
        const businessAddress = payload[i].BusinessAddress;
        const signatureInfo = payload[i].signatureInfo;
        const requestor = payload[i].RequestorInformationPage;
        // CREATE FBN GROUP
        filing.fbnGroup = {
            businessAddress: businessAddress.address,
            businessAddressAdd: businessAddress.additionalAddress,
            businessCity: businessAddress.city,
            businessState: businessAddress.state && StateMapToAbbreviatedState[businessAddress.state] !== undefined ? StateMapToAbbreviatedState[businessAddress.state] : '',
            businessZipCode: businessAddress.zipCode,
            businessCounty: businessAddress.county,
            articlesIncorpNumber: businessAddress.articles,
            businessCountry: businessAddress.country,
        };
        // CREATE FBN FILING
        var fullName = '';
        if (requestor.firstName) {
            fullName += `${requestor.firstName} `;
        }
        if (requestor.middleName) {
            fullName += `${requestor.middleName} `;
        }
        if (requestor.lastName) {
            fullName += `${requestor.lastName}`;
        }
        filing.fbnFiling = {
            name: fullName,
            firstName: requestor.firstName,
            middleName: requestor.middleName,
            lastName: requestor.lastName,
            address: requestor.address,
            addressAdditional: requestor.addAddress,
            city: requestor.city,
            state: StateMapToAbbreviatedState[requestor.state] !== undefined ? StateMapToAbbreviatedState[requestor.state] : '',
            zipCode: requestor.zipCode,
            phone: requestor.phone,
            registrantCorpLlcName: signatureInfo.FullName,
            registrantName: signatureInfo.SignatoryName,
            registrantTitle: signatureInfo.SignatoryTitle,
            commenceTs: requestor.dateRegistrantCommenced.trim().toUpperCase() === 'N/A' || requestor.dateRegistrantCommenced.trim().length === 0 ? null : requestor.dateRegistrantCommenced,
            email: requestor.email,
            country: requestor.country,
            businessTypeCode: ReadBusinessType(payload[i].businessType),
            filingTypeCode: MapClerkFilingTypeIntToString(payload[i].filingSelectionType)
        };
        // CREATE REGISTERED OWNERS
        payload[i].registeredOwners.forEach(ro => {
            filing.fbnRegisteredOwnerName.push({
                registeredOwnerName: ro.name,
                address: ro.address,
                addressAdditional: ro.addressAdditional,
                city: ro.city,
                state: StateMapToAbbreviatedState[ro.state] !== undefined ? StateMapToAbbreviatedState[ro.state] : '',
                zipCode: ro.zipCode,
                phone: ro.phone,
                email: ro.email,
                country: ro.country,
                stateOfOrganization: StateMapToAbbreviatedState[ro.stateOfOrganization] !== undefined ? StateMapToAbbreviatedState[ro.stateOfOrganization] : '',
            })
        });
        // CREATE BUSINESS NAMES
        payload[i].fbnNames.forEach(businessName => {
            filing.fbnBusinessName.push({
                businessName: businessName,
            })
        });
        // CREATE RELATED FORM
        if (payload[i].sosFile !== null && payload[i].sosFile.length > 0) {
            filing.fbnRelatedForm.push({
                sosFile: payload[i].sosFile[i]
            });
        }
        // This will create a stub fbnRelatedForm record 
        filing.fbnRelatedForm.push({})
        // CREATE FBN FILING ACTION
        if (payload[i].filingActions !== undefined && payload[i].filingActions) {
            payload[i].filingActions.forEach(fa => {
                // Convert state to it's abreviation
                for (let i = 0; i < fa.delta.length; i++) {
                    if (fa.delta[i].field === 'state' || fa.delta[i].field === 'stateOfOrganization') {
                        fa.delta[i].value = StateMapToAbbreviatedState[fa.delta[i].value] !== undefined ? StateMapToAbbreviatedState[fa.delta[i].value] : '';
                    }
                }
                // If additional copies or new records, then we don't care about nameId or name
                if (fa.filingActionTypeCode === 8 || fa.FilingActionTypeCode === 9) {
                    filing.fbnFilingAction.push({
                        NameId: 0,
                        FilingActionTypeCode: fa.filingActionTypeCode,
                        Delta: fa.delta,
                        Name: fa.name
                    })
                } else if (fa.FilingActionTypeCode === 14) {
                    filing.fbnFilingAction.push({
                        NameId: 0,
                        FilingActionTypeCode: fa.filingActionTypeCode,
                        Delta: fa.delta,
                        Name: ''
                    })
                } else {
                    filing.fbnFilingAction.push({
                        NameId: fa.nameId,
                        FilingActionTypeCode: fa.filingActionTypeCode,
                        Delta: fa.delta,
                        Name: fa.name
                    })
                }
            })
        }
        // ADDITIONAL SETTINGS FOR NON STANDARD FILINGS
        if (payload[i].filingSelectionType !== 'Fictitious Business Name Statement') {
            // Fbn Filing
            var fbnFiling = {
                ...filing.fbnFiling,
                ownerNameCount: payload[i].ownerNameCount + totalAddedRegisteredOwners - totalRemovedRegisteredOwners,
                businessNameCount: payload[i].businessNameCount + totalAddedBusinessNames - totalRemovedBusinessNames,
            }
            filing.fbnFiling = fbnFiling;
            // Fbn Group
            var fbnGroup = {
                ...filing.fbnGroup,
                originalFbnGroupNumber: payload[i].fbnGroupNumber
            }
            filing.fbnGroup = fbnGroup;
        }
        // ADDITIONAL SETTINGS FOR PROOF
        if (payload[i].filingSelectionType === 'FBN Proof of Publication') {
            payload[i].sosFilePublications.forEach(p => {
                var proof = {
                    firstPublicationTs: payload[i].publishingData.publishStartDate,
                    lastPublicationTs: payload[i].publishingData.publishEndDate,
                    publicationName: payload[i].publishingData.publication,
                    relatedFilingNumber: payload[i].jediFilingNumber
                };
                filing.fbnProofOfPublication.push(proof);
            })
        }
        // SIGNATURE AND EMAIL FOR ONLINE FILINGS
        if (payload[i].filingSelectionChoice === 'Online') {
            filing.onlineSignature = payload[i].onlineSignature;
            filing.fbnFiling.email = payload[i].VitalChekInformation.confirmEmail;
        }
        filing.clerkFilingType = filingType;
        filing.numberOfCopies = requestor.additionalCopies;
        filing.pdfData = MapFbnFilingToClerkPdfModel(payload[i]);
        data.push(filing);
    }
    return data;
}

export default {
    get() {
        return Repository.get(`${resource}/clerkfiling`);
    },
    async post(payload, filingType, workflow) {
        const data = await MapFbnFilingToClerkModel(payload, filingType)
        for (let i = 0; i < data.length; i++) {
            data[i].workflow = workflow;
            data[i].pdfData.workflow = workflow;
        }
        return RepositoryInternal.post(`${resource}/clerkgroup`, data, workflow)
    },
    postPDF(payload, workflow, isPreview, trackingNumber) {
        const data = MapFbnFilingToClerkPdfModel(payload);
        data.workflow = workflow;
        data.isPreview = isPreview;
        data.trackingNumber = trackingNumber;
        return Repository.post(`${resource}/group/pdf`, data, {
            responseType: 'arraybuffer'
        });
    },
    validate(payload) {
        return Repository.post(`${resource}/validate?response=${payload}`, payload)
    },
    uploadFile(payload) {
        return Repository.post(`${resource}/clerkgroup/upload`, payload)
    }
}