<template>
    <div class="m_pad" role="main">
        <h1 class="mx-auto pt-4 text-left text-4xl font-bold max-w-screen-xl">{{ $t('Reg.Review.Heading') }}</h1>
        <div v-if="isMailinWorkflow" class="mx-auto text-left text-md font-bold py-4 max-w-screen-xl">{{ $t('Reg.Review.Intro') }}</div>
        <div v-if="!isMailinWorkflow" id="extra-padding"></div>
        <div class="mx-auto max-w-screen-xl pageBorder py-2">           
            <div class="mx-auto max-w-screen-xl">
                <div id="filing-type" class="ml-2 text-xl md:w-2/12 md:pb-2 pt-4 md:inline-block text-left block w-full"><b>{{ $t('Reg.Review.BusinessTypePage.Properties.FilingType') }}:</b> </div>
                <div id="filing-type-data" class="ml-2 md:w-8/12 mx-auto md:inline-block block w-full">{{nav}} Filing</div>            
            </div>
        </div>  
        <!-- Register Name Page -->
        <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
        <div class="max-w-screen-xl mx-auto py-3">
            <div id="register-name-page-header" class="text-2xl max-w-screen-xl py-2 mt-2 mx-auto md:w-11/12 md:inline-block block w-full"><strong>{{ $t('Reg.Review.RegisteredBusinessNamesPage.Heading') }}</strong></div>
            <router-link v-if="showEditButton" id="register-name-edit-button" class="py-1 md:px-1 text-md darkblue rounded text-white inline-block md:ml-16 px-10" to="/addbusinessname" @click="setEdit();">{{ $t('Template.Buttons.Edit') }}</router-link>    
        </div>
        <div class="mx-auto max-w-screen-xl">
            <div class="flex flex-col py-4 px-2">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table id="fbn-names-table" class="min-w-full divide-y divide-gray-200" v-if="fbnFiling.BusinessNamePage.fbnNames.length > 0 && fbnFiling.workflow === filingWorkflow.Amendment">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                            #
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Reg.Review.RegisteredBusinessNamesPage.Results.Columns.NewFBNNameList') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(fbn,index) in fbnFiling.BusinessNamePage.fbnNames" :key="index">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">
                                                {{ index + 1}}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-base text-gray-900">{{ fbn }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table id="fbn-names-table" class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement'">
                                            #
                                        </th>
                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Amendment'">
                                            #
                                        </th>
                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Renewal'">
                                            #
                                        </th>
                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Abandonment'">
                                            #
                                        </th>
                                        <th scope="col" class="w-1/12 px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Withdrawal'">
                                            #
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement'">
                                            {{ $t('Reg.Review.RegisteredBusinessNamesPage.Results.Columns.FBNNameList') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Amendment'">
                                            {{ $t('Reg.Review.RegisteredBusinessNamesPage.Results.Columns.ExistingFBNNameList') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Renewal'">
                                            {{ $t('Reg.Review.RegisteredBusinessNamesPage.Results.Columns.FBNNameList') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Abandonment'">
                                            {{ $t('Reg.Review.RegisteredBusinessNamesPage.Results.Columns.AbandonFBNNameList') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider" v-if="this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Withdrawal'">
                                            {{ $t('Reg.Review.RegisteredBusinessNamesPage.Results.Columns.FBNNameList') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(fbn,index) in ShowOriginalFilingFbnNames" :key="index">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">
                                                {{ index + 1}}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-base text-gray-900">{{ fbn }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Business and Mailing Address  -->
        <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
        <div class="max-w-screen-xl mx-auto py-3">
            <div id="business-mailing-header" class="text-2xl max-w-screen-xl py-2 mt-2 mx-auto md:w-11/12 md:inline-block block w-full"><strong>{{ $t('Reg.Review.BusinessAndMailingAddressPage.Heading') }}</strong></div>
            <router-link v-if="showEditButton" id="edit-business-address-button" class="py-1 md:px-1 text-md darkblue rounded text-white md:inline-block md:ml-16 px-10" to="/registerbusinessaddress" @click="setEdit();">{{ $t('Template.Buttons.Edit') }}</router-link>    
        </div>
        <div class="mx-auto max-w-screen-xl pageBorder py-2">
            <div class="ml-2 pt-2 mx-auto max-w-screen-xl">
                <div class="text-xl mt-2 text-left"><strong>{{ $t('Template.BusinessAddress') }}</strong></div>
                <div class="text-lg text-left" id="business-address-line1">{{fbnFiling.BusinessAddressPage.BusinessAddress.address.toUpperCase()}} {{fbnFiling.BusinessAddressPage.BusinessAddress.additionalAddress.toUpperCase()}}</div>
                <div class="text-lg text-left" id="business-address-line2">{{fbnFiling.BusinessAddressPage.BusinessAddress.city.toUpperCase()}}, {{fbnFiling.BusinessAddressPage.BusinessAddress.state.toUpperCase()}} {{fbnFiling.BusinessAddressPage.BusinessAddress.zipCode.toUpperCase()}}</div>
                <div class="text-lg text-left" id="business-address-line3">{{fbnFiling.BusinessAddressPage.BusinessAddress.county.toUpperCase()}}, {{fbnFiling.BusinessAddressPage.BusinessAddress.country.toUpperCase()}}</div>
                <div v-if="showArticles && articles.length > 0">
                    <div id="articles" class="text-xl mt-2 inline-block text-left">{{ $t('Reg.Review.BusinessAndMailingAddressPage.Properties.ArticlesOfIncorporation') }}: </div>
                    <div id="articles-data" class="mx-auto ml-4 inline-block">{{articles}}</div>
                </div>
            </div>
        </div>
        
        <!-- Registered Owner Page -->
        <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
        <div class="max-w-screen-xl mx-auto py-3">
            <div id="register-owner-header" class="text-2xl max-w-screen-xl py-2 mt-2 mx-auto md:w-11/12 md:inline-block block w-full"><strong>{{ $t('Reg.Review.RegisteredOwnersAndBusinessTypePage.Heading') }}</strong></div>
            <router-link v-if="showEditButton" id="edit-registered-owner-button" class="py-1 md:px-1 text-md darkblue rounded text-white md:inline-block md:ml-16 px-10" to="/addregisteredowner" @click="setEdit();">Edit</router-link>    
        </div>
        <div class="mx-auto max-w-screen-xl pageBorder py-2">
            <div class="mx-auto max-w-screen-xl">
                <div id="business-type" class="ml-2 text-xl md:w-2/12 md:py-2 md:inline-block text-left block w-full"><b>{{ $t('Reg.Review.BusinessTypePage.Properties.BusinessType') }}:</b> </div>
                <div id="business-type-data" class="ml-2 md:w-9/12 mx-auto md:inline-block block w-full">{{fbnFiling.RegisteredOwnerPage.businessType}}</div>
            </div>
            <div v-if="showSOS">
                <div id="sos-files" class="ml-2 text-xl md:w-2/12 md:py-2 md:inline-block text-left block w-full"><b>{{ $t('Reg.Review.BusinessTypePage.Properties.SOSFile') }}:</b> </div>
                <div v-for="(file) in this.fbnFiling.RegisteredOwnerPage.sosFile" :key="file" class="ml-2 md:w-9/12 mx-auto md:inline-block block w-full">
                    <div>
                        {{ file.name }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentWorkflow !== 'Fictitious Business Name Statement Withdrawal'" class="text-xl mt-2 text-left mx-auto max-w-screen-xl py-2"><strong>{{ $t('Reg.Review.RegisteredOwnersAndBusinessTypePage.Results.Heading') }}</strong></div>
        <div v-if="currentWorkflow === 'Fictitious Business Name Statement Withdrawal'" class="text-xl mt-2 text-left mx-auto max-w-screen-xl py-2"><strong>{{ $t('Reg.Review.RegisteredOwnersAndBusinessTypePage.Results.Heading2') }}</strong></div>
        <div class="max-w-screen-xl mx-auto py-4">
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <!-- Responsive -->
                            <table id="register-owner-table" class="min-w-full divide-y divide-gray-200 md:table hidden">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            #
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.OwnerName') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.Address') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.AddressAdditional') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.City') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.State') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.ZipCode') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Template.Country') }}
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {{ $t('Reg.Review.RegisteredOwnersAndBusinessTypePage.Results.Columns.StateOfOrganization') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(owner, index) in registeredOwnersToShow" :key="index">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">
                                                {{ index + 1}}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.name }} </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="text-sm text-gray-900">{{ owner.address }} </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.addressAdditional }} </div>                                
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.city }} </div>                                
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.state }} </div>                                
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.zipCode }} </div>                                
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.country }} </div>                                
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{{ owner.stateOfOrganization }} </div>                                
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="md:hidden block">
                                <div v-for="(owner, index) in this.state.registeredOwners" :key="index">
                                    <div @click="expandOwner($event, 'owner' + (index + 1))" class="cursor-pointer item-row table w-full">
                                        <div class="float-left">
                                            <b>{{ owner.name }}</b>
                                        </div>
                                        <div class="float-right">
                                            <img class="expand" :src="require('@/assets/img/m/icon_plus.png')" alt="Icon - Expand" title="Expand" width="25" height="25"/>
                                            <img class="collapse hidden" :src="require('@/assets/img/m/icon_minus.png')" alt="Icon - Collapse" title="Collapse" width="25" height="25"/>
                                        </div>
                                    </div>
                                    <div :id="'owner' + (index + 1)" class="hidden item-row-content sub-content w-full">
                                        <p><b>{{ $t('Template.Address') }}:</b> {{ owner.address }}</p>
                                        <p><b>{{ $t('Template.Unit') }}:</b> {{ owner.addressAdditional }}</p>
                                        <p><b>{{ $t('Template.City') }}:</b> {{ owner.city }}</p>
                                        <p><b>{{ $t('Template.State') }}:</b> {{ owner.state }}</p>
                                        <p><b>{{ $t('Template.ZipCode') }}:</b> {{ owner.zipCode }}</p>
                                        <p><b>{{ $t('Template.Country') }}:</b> {{ owner.country }}</p>
                                        <p><b>{{ $t('Template.StateOf') }}:</b> {{ owner.stateOfOrganization }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FBN Signer Page -->
        <div v-if="fbnFiling.workflow !== filingWorkflow.Withdrawal">
            <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
            <div class="max-w-screen-xl mx-auto py-3">
                <div v-if="signerError" class="text-red-600 font-bold"> {{ $t('Reg.Review.FictitiousBusinessNameSignerPage.Errors.SignatoryTitleRequired') }}</div>
                <div id="fbn-signer-header" class="text-2xl max-w-screen-xl py-2 mt-2 mx-auto md:w-11/12 md:inline-block block w-full"><strong>{{ $t('Reg.Review.FictitiousBusinessNameSignerPage.Heading') }}</strong></div>
                <router-link v-if="showEditButton" id="edit-fbn-signer-button" class="py-1 md:px-1 text-md darkblue rounded text-white inline-block md:ml-16 px-10" to="/fbnsigner" @click="setEdit();">{{ $t('Template.Buttons.Edit') }}</router-link>    
            </div>
            <div class="py-4 mb-8 mx-auto max-w-screen-xl pageBorder">
                <div id="full-name" class="ml-2 md:w-3/12 text-lg md:inline-block text-left block w-full"><b>{{ $t('Template.FullNames') }}:</b> </div>
                <div id="full-name-data" class="ml-2 md:w-8/12 mx-auto md:inline-block text-lg block w-full">{{state.signatureInfo.FullName}}</div>
                <div v-if="state.signatureInfo.SignatoryName.length > 0">
                    <div id="signatory-name" class="ml-2 md:w-2/12 text-lg md:inline-block text-left w-full" v-if="state.signatureInfo.SignatoryName.length > 0"><b>{{ $t('Reg.Review.FictitiousBusinessNameSignerPage.Properties.SignatoryName') }}:</b> </div>
                    <div id="signatory-name-data" class="ml-2 md:w-8/12 mx-auto md:inline-block text-lg block w-full">{{state.signatureInfo.SignatoryName}}</div>    
                </div>
                <div id="signatory-title" class="ml-2 md:w-2/12 text-lg md:inline-block text-left block w-full"><b>{{ $t('Template.SignatoryTitle') }}:</b> </div>
                <div id="signatory-title-data" class="ml-2 md:w-8/12 mx-auto md:inline-block block w-full">{{state.signatureInfo.SignatoryTitle}}</div>
            </div>
        </div>
        <!-- Requester Information Page -->
        <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
        <div class="max-w-screen-xl mx-auto py-3">
            <div id="requestor-info-header" class="text-2xl max-w-screen-xl py-2 mt-2 mx-auto md:w-11/12 inline-block w-full"><strong>{{ $t('Reg.Review.AdditionalInformationPage.Heading') }}</strong></div>
            <router-link v-if="showEditButton" id="edit-requestor-info-button" class="py-1 md:px-1 text-md darkblue rounded text-white inline-block md:ml-16 px-10" to="/fbnadditionalInformation" @click="setEdit();">{{ $t('Template.Buttons.Edit') }}</router-link>    
        </div>
        <div class="mx-auto py-4 mb-8 max-w-screen-xl pageBorder">
            <div id="requestor-address" class="ml-2 text-xl mt-2 text-left mx-auto max-w-screen-xl"><strong>{{ $t('Reg.Review.AdditionalInformationPage.Properties.RequestorInformation') }}</strong></div>
            <div class="ml-2 text-lg text-left" id="requestor-data-1">{{fbnFiling.RequestorInformationPage.firstName.toUpperCase()}} {{fbnFiling.RequestorInformationPage.middleName.toUpperCase()}} {{fbnFiling.RequestorInformationPage.lastName.toUpperCase()}}</div>
            <div class="ml-2 text-lg text-left" id="requestor-data-2">{{fbnFiling.RequestorInformationPage.address.toUpperCase()}}, {{fbnFiling.RequestorInformationPage.addAddress.toUpperCase()}}</div>
            <div class="ml-2 text-lg text-left" id="requestor-data-3">{{fbnFiling.RequestorInformationPage.city.toUpperCase()}}, {{fbnFiling.RequestorInformationPage.state.toUpperCase()}} {{fbnFiling.RequestorInformationPage.zipCode.toUpperCase()}}</div>
            <div class="ml-2 text-lg text-left" id="requestor-data-4">{{fbnFiling.RequestorInformationPage.country.toUpperCase()}}</div>
            <div v-if="!isOnlineWorkflow" id="email" class="ml-2 text-lg mt-2 inline-block text-left py-2">{{ $t('Template.Email') }}: </div>
            <div v-if="!isOnlineWorkflow" id="email-data" class="ml-4 mx-auto inline-block text-lg py-1">{{fbnFiling.RequestorInformationPage.email}}</div>
            <br v-if="!isOnlineWorkflow">
            <div v-if="!isOnlineWorkflow" id="phone" class="ml-2 text-lg mt-2 inline-block text-left py-2">{{ $t('Template.Phone') }}: </div>
            <div v-if="!isOnlineWorkflow" id="phone-data" class="ml-4 mx-auto inline-block text-lg py-1">{{fbnFiling.RequestorInformationPage.phone}}</div>
            <br  v-if="!isOnlineWorkflow">
            <div class="ml-2 text-xl mt-2 text-left mx-auto max-w-screen-xl py-1"><strong>{{ $t('Template.AdditionalInformation') }}</strong></div>
            <div id="commenced" class="ml-2 text-lg mt-2 inline-block text-left py-2">{{ $t('Reg.Review.AdditionalInformationPage.Properties.DateRegistrantCommenced') }}: </div>
            <div id="commenced-data" class="ml-4 mx-auto inline-block text-lg py-1">{{fbnFiling.RequestorInformationPage.dateRegistrantCommenced}}</div>
            <br>
            <div id="copies" class="ml-2 text-lg mt-2 inline-block text-left py-2">{{ $t('Reg.Review.AdditionalInformationPage.Properties.NumberOfCopies') }}: </div>
            <div id="copies-data" class="ml-4 mx-auto inline-block text-lg py-1">{{fbnFiling.RequestorInformationPage.additionalCopies}}</div>
            <br>
        </div>
         <!-- VitalChek Information Page -->
        <div v-if="isOnlineWorkflow" class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
        <div v-if="isOnlineWorkflow" class="max-w-screen-xl mx-auto py-3">
            <div id="vc-info-header" class="text-2xl max-w-screen-xl py-2 mt-2 mx-auto md:w-11/12 inline-block w-full"><strong>{{ $t('Reg.Review.VitalChekInformationPage.Heading') }}</strong></div>
            <router-link v-if="showEditButton" id="edit-requestor-info-button" class="py-1 md:px-1 text-md darkblue rounded text-white inline-block md:ml-16 px-10" to="/fbnvitalchekinfo" @click="setEdit();">{{ $t('Template.Buttons.Edit') }}</router-link>    
        </div>
        <div v-if="isOnlineWorkflow" class="mx-auto py-4 mb-8 max-w-screen-xl pageBorder">
            <div id="requestor-address" class="ml-2 text-xl mt-2 text-left mx-auto max-w-screen-xl"><strong>{{ $t('Reg.Review.VitalChekInformationPage.Properties.RequestorInformation') }}</strong></div>
            <div class="ml-2 text-lg text-left" id="requestor-data-1">{{state.VitalChekInformation.firstName.toUpperCase()}} {{state.VitalChekInformation.middleName?.toUpperCase()}} {{state.VitalChekInformation.lastName.toUpperCase()}}</div>
            <div class="ml-2 text-lg text-left" id="requestor-data-2">{{state.VitalChekInformation.address.toUpperCase()}}, {{state.VitalChekInformation.additionalAddress?.toUpperCase()}}</div>
            <div class="ml-2 text-lg text-left" id="requestor-data-3">{{state.VitalChekInformation.city.toUpperCase()}}, {{state.VitalChekInformation.state.toUpperCase()}} {{state.VitalChekInformation.zipCode.toUpperCase()}}</div>
            <div id="email" class="ml-2 text-lg mt-2 inline-block text-left py-2">{{ $t('Template.Email') }}: </div>
            <div id="email-data" class="ml-4 mx-auto inline-block text-lg py-1">{{state.VitalChekInformation.email}}</div>
            <br>
            <div id="phone" class="ml-2 text-lg mt-2 inline-block text-left py-2">{{ $t('Template.Phone') }}: </div>
            <div id="phone-data" class="ml-4 mx-auto inline-block text-lg py-1">{{state.VitalChekInformation.phone}}</div>
            <br>
        </div>
        <!-- Checkbox -->
        <div class="mx-auto py-2 text-left max-w-screen-xl text-lg font-semibold" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-600" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="md:max-w-screen-xl mx-auto w-full mt-2" @click="onCheckBoxClickedHandler">
            <p class="text-red-600">{{ $t('Reg.Preview.Acknowledgement') }}</p>
            <br />
            <div class="flex items-center">
                <input class="h-8 w-8 mr-2" aria-label="agreement-checkbox" type="checkbox" id="agreement" v-bind:class="[ isOutlined ? 'outlineRed' : 'outline-none' ]" v-model="checkBox"/><b>{{ $t('Reg.Preview.UserInput.Checkbox') }}</b>
            </div>
        </div>
        <!-- Signature -->
        <div class="py-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>
        <div class="mx-auto text-left text-xl font-bold pt-4 max-w-screen-xl text-red-600" v-show="showSignMessage">{{ $t('Template.Signature.SignToProceed') }}</div>
        <div v-if="signatureDisplay">
            <div class="text-left text-xl mx-auto max-w-screen-xl">{{ $t('Template.Signature.Intro') }}</div>
            <div class="md:max-w-screen-xl mx-auto py-2 md:pl-80 w-full">
                <div id="signature" class="pt-2">
                    <canvas id="signature-pad" class="outlineBlack" width="600" height="175"></canvas>
                </div><br/>
                <div class="pt-2 m-auto md:m-0 text-center md:text-left">
                    <button class="text-white px-4 font-semibold darkblue text-center rounded inline-block" aria-label="clear-signature-button" aria-labelledby="signature" id="clear-signature-button" @click="clearSignature()">{{ $t('Template.Signature.Clear') }}</button>
                </div>    
            </div>                             
            <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>    
        </div>
        <div v-if="submissionError" class="mx-auto max-w-screen-xl py-4 text-2xl text-red-600">{{ $t('Template.SubmitOrderError')}}</div>
        <div class="mx-auto max-w-screen-xl py-2 main-controls">
            <NavigationControls 
                :showBackButton="true"
                :showSubmitButton="true"
                :isSubmitDisabled="isSubmitButtonDisabled"
                :showHelpButton="true"  
                @previousPage="previousPage"
                @proceed="submitOrder"
                @openHelp="openHelp"/>
            <Help 
                :page="1"
                v-show="isHelpVisible"
                @closeHelp="closeHelp"/>
        </div>
        <FbnFiling 
            ref="fbnFiling"
            v-show="false" />
    </div>
</template>
<script>
import ClerkRepository from '../../repositories/ClerkRepository';
import Help from '../common/Help.vue';
import FbnFiling from '../common/FbnFiling.vue'
import NavigationControls from '../common/NavigationControls.vue'
import { EnableRecaptcha } from '../../assets/config';
import { AppHasData, BusinessTypeName, FilingApproach, FilingWorkflow } from '../../assets/constants';
import { ConvertFileToBase64 } from '../../assets/common';
import $ from 'jquery';
import SignaturePad from 'signature_pad'
export default {
    name: 'Review FBN Page',
    data: () => ({
        fbnFiling: {},
        filingWorkflow: FilingWorkflow,
        registeredOwnersToShow: [],
        nav: '',
        filingIndex: 0,
        signatureDisplay: false,
        showSOS: false,
        sosFile: null,
        showArticles: false,
        articles: 0,
        didSign: false,
        sigValidationOn: false,
        showSignMessage: false,
        oldNames: [],
        newNames: [],
        errors: [],
        state: {},
        signerError: false,
        currentWorkflow: '',
        checkBox: false,
        isOutlined: false,
        FbnFiling: {},
        enableRecaptcha: EnableRecaptcha,
        isHelpVisible: false,
        workflow: {},
        disableSubmitButton: false,
        showEditButton: false,
        signaturePad: null,
        onlineSignature: '',
        submissionError: false
    }),
    computed: {
        isSubmitButtonDisabled() {
            return this.disableSubmitButton;
        },
        isOnlineWorkflow() {
            return this.filingType === 'Online';
        },
        isMailinWorkflow() {
            return this.filingType === 'Mail-In';
        },
        ShowOriginalFilingFbnNames () {
            if (this.fbnFiling.workflow === FilingWorkflow.Amendment) {
                return this.fbnFiling.BusinessNamePage.originalFbnNames
            } else {
                return this.fbnFiling.BusinessNamePage.fbnNames
            }
        }
    },
    components: {
        FbnFiling,
        Help,
        NavigationControls
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('submit')
            await ClerkRepository.validate(token)
            .then( response => {
                if (response.data.success === true) {
                    if(response.data.score >= 0.5){
                        return true;
                    }
                }
            })
            .catch( error => console.log('Recaptcha: ', error))
        },
        setEdit: function (){
            // New edit flag
            this.fbnFiling.reviewPageEdit = true
            this.$store.dispatch('setFbnFiling', this.fbnFiling)
            // Old edit code, to be removed later in refactor
            this.$store.dispatch('setEdit',true);
            // Clicking edit makes the register name page now use the backup list
            if(this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Amendment') {
                if(this.$store.getters.getBackup.length <= 0) {
                    // use the original fbn names list as we havent combined the two lists yet. length > 0 when the backup is created on the nextpage function to preview page
                    this.$store.dispatch('setIsBackup', false);
                } else {
                    // now need to use the backup list
                    this.$store.dispatch('setIsBackup', true);    
                }  
            }
        },
        signerEmptyCheck () {
            const signerData = this.$store.getters.getSignatureInformation;
            if (signerData.FullName.length === 0 || signerData.SignatoryTitle.length === 0) {
                this.signerError = true;
                this.errors.push(this.$t('Reg.Review.FictitiousBusinessNameSignerPage.Errors.SignatoryTitleRequired'));
            } else {
                this.signerError = false;
            }
        },
        submitOrder() {
            this.errors = [];
            if(this.checkBox === false) {
                this.isOutlined = true;
                this.errors.push('Please verify the information entered above and click the agreement checkbox to continue.')
                this.disableSubmitButton = false;
                return;
            }
            if (this.fbnFiling.workflow !== FilingWorkflow.Withdrawal) {
                this.signerEmptyCheck();
                if (this.signerError) return;
            }
            if((this.filingType === FilingApproach.MailIn && this.currentWorkflow !== FilingWorkflow.Withdrawal) || this.filingType === FilingApproach.Online){
                const data = this.signaturePad.toDataURL('image/png');
                this.onlineSignature = data;
                if(!this.signaturePad.isEmpty()) {
                    this.$store.dispatch('setSignature', data);
                    this.didSign = true;
                    this.showSignMessage = false;
                } else {
                    this.didSign = false
                    this.showSignMessage = true;
                    return;
                }    
            }
            if (this.sigValidationOn === true) {
                if (!this.didSign) {
                    this.showSignMessage = true;
                    this.disableSubmitButton = false;
                    return;
                } else {
                    this.showSignMessage = false;
                }
            }  
            if(this.enableRecaptcha) {
                if(!this.recaptcha()) {
                    this.errors.push(this.$t('Template.VerifyRecaptcha'));
                } else {
                    this.disableSubmitButton = true;
                    this.dispatchConfirmation();
                } 
            }
        },
        setBackupList() {
            if(this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Amendment') {
                if(this.$store.getters.getBackup.length <= 0) {
                    this.$store.dispatch('setBackup');
                }
                this.$store.dispatch('setIsBackup', true);     
            }
        },
        expandOwner: function(event, element) {
            element = document.getElementById(element);
            const expandableContentHidden = element.style.display === 'none' || element.style.display === '';
            element.style.display = expandableContentHidden ? 'table' : 'none';
            $(event.target).find('img.expand').css('display', expandableContentHidden ? 'none' : 'block');
            $(event.target).find('img.collapse').css('display', expandableContentHidden ? 'block' : 'none');
        },
        onCheckBoxClickedHandler() {
            this.checkBox = !this.checkBox;
            this.errors = []; 
        },
        async convertSosFileToBase64String() {
            return await ConvertFileToBase64(this.fbnFiling.RegisteredOwnerPage.sosFile[0])
        },
        async dispatchConfirmation() {
            try {
                this.submissionError = false
                const payload = [...this.$store.getters.getFilings]
                // Replace payload with the new filing action and page data
                const filing = this.$store.getters.getFbnFiling
                payload[0].filingActions = filing.fbnFilingActions
                // Add registered owner page filing actions
                filing.RegisteredOwnerPage.filingActions.forEach(fa => {
                    payload[0].filingActions.push(fa)
                })
                // Add registered owner page data
                payload[0].registeredOwners = this.registeredOwnersToShow
                payload[0].businessType = filing.RegisteredOwnerPage.businessType
                if(this.fbnFiling.RegisteredOwnerPage.sosFile.length > 0) {
                    payload[0].sosFile[0] = await this.convertSosFileToBase64String()
                }
                // Add business name page filing actions
                filing.BusinessNamePage.filingActions.forEach(fa => {
                    payload[0].filingActions.push(fa)
                })
                // Add business name page data
                // Amendments combine the original filing names + fbnnames
                if (filing.workflow === FilingWorkflow.Amendment) {
                    payload[0].fbnNames = filing.BusinessNamePage.fbnNames.concat(filing.BusinessNamePage.originalFbnNames)
                } else {
                    payload[0].fbnNames = filing.BusinessNamePage.fbnNames
                }
                // Add business address page data
                payload[0].BusinessAddress = filing.BusinessAddressPage.BusinessAddress
                // Add business address page filing actions
                filing.BusinessAddressPage.filingActions.forEach(fa => {
                    payload[0].filingActions.push(fa)
                })
                // Add Vitalchek page filing actions
                filing.VitalChekPage.filingActions.forEach(fa => {
                    payload[0].filingActions.push(fa)
                })
                payload[0].RequestorInformationPage = filing.RequestorInformationPage
                await ClerkRepository.post(payload, this.$store.getters.getFilingApproach, this.workflow)
                    .then( response => {
                        this.$router.push('fbnorderconfirmation');    
                        this.$store.dispatch('setOrderNumber', response.data.trackingNumber);          
                        this.$store.dispatch('setFilingNumber', response.data.filingNumber);
                    })
                    .catch( error => {
                        console.log(error)
                        this.disableSubmitButton = false
                        this.submissionError = true
                    })    
            } catch (error) {
                console.log(error)
                this.disableSubmitButton = false
                this.submissionError = true
            }            
        },
        openHelp(){
            this.isHelpVisible = true;
        },
        closeHelp(){
            this.isHelpVisible = false;
        },
        clearSignature() {
            this.signaturePad.clear();
            this.didSign = false;
            this.showSignMessage = false;
        },
        previousPage () {
            this.setBackupList()
            if (this.fbnFiling.workflow === FilingWorkflow.Abandonment) {
                this.$router.push('fbnreviewbusinessnames')
            } else {
                this.$router.push('fbnadditionalInformation')
            }
        }
    },
    mounted() {
        if(this.filingType === FilingApproach.MailIn && this.currentWorkflow !== FilingWorkflow.Withdrawal) {
            this.signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
                backgroundColor: 'rgb(255,255,255)',
                velocityFilterWeight: 0.4
            });
        } else if (this.filingType === FilingApproach.Online) {
            this.signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
                backgroundColor: 'rgb(255,255,255)',
                velocityFilterWeight: 0.4
            });
        }
    },
    beforeMount(){
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.fbnFiling = {...this.$store.getters.getFbnFiling}
        this.nav = this.$store.getters.getSubheader;
        this.currentWorkflow = this.$store.getters.getWorkflow;
        this.filingType = this.$store.getters.getFilingApproach;
        this.filingIndex = this.$store.getters.getCurrentFilingIndex - 1;
        this.workflow = {
            Approach: this.filingType,
            Type: this.currentWorkflow
        }
        if(this.$store.getters.getWorkflow === 'Fictitious Business Name Statement Amendment') {
            if(this.$store.getters.getIsBackup === true) {
                // Coming to review page from preview, now use the backup list which contains the original filing names and then new fbn names
                this.state = {...this.$store.state.FbnFilings[this.filingIndex]};
                this.oldNames = this.$store.getters.getBackup;
                this.newNames = this.$store.getters.getNewFbnNames;
            } else {
                // first time entering review page on amendment workflow uses the original fbn names and new fbn names
                this.state = {...this.$store.state.FbnFilings[this.filingIndex]};
                this.oldNames = this.state.fbnNames;
                this.newNames = this.$store.getters.getNewFbnNames;
                this.$store.dispatch('setBackup');
                this.$store.dispatch('setIsBackup', true);
            }
        } else {
            // Original filing workflow uses the fbn names list only
            this.state = {...this.$store.state.FbnFilings[this.filingIndex]};
            this.oldNames = this.$store.getters.getFbnNames;
        }
        if((this.filingType === FilingApproach.MailIn && this.currentWorkflow !== FilingWorkflow.Withdrawal) || this.filingType === FilingApproach.Online) {
            this.signatureDisplay = true;
            this.sigValidationOn = true;
        }
        if (this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.Corporation ||
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LimitedPartnership ||
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLP ||
            this.fbnFiling.RegisteredOwnerPage.businessType === BusinessTypeName.LLC) {
                this.showSOS = true
                this.sosFile = this.fbnFiling.RegisteredOwnerPage.sosFile
                this.showArticles = true
                this.articles = this.fbnFiling.BusinessAddressPage.BusinessAddress.articles
        }
        if (this.workflow.Type === 'Fictitious Business Name Statement' || this.workflow.Type === 'Fictitious Business Name Statement Amendment' ) {
            this.showEditButton = false;
        }
        this.fbnFiling.RegisteredOwnerPage.registeredOwners.forEach(owner => {
            if(this.fbnFiling.workflow === FilingWorkflow.Withdrawal) {
                if (owner.action === this.$t('Template.UndoActions.RemoveOwner')) {
                    this.registeredOwnersToShow.push(owner)
                }
            } else {
                if(owner.action !== this.$t('Template.UndoActions.RemoveOwner')) {
                    this.registeredOwnersToShow.push(owner)
                }
            }
        })
        window.scrollTo(0,0);
    }    
}
</script>
<style>
    .lineBorder{
        border-bottom: solid #13b5ea .15rem;
    }
    .pageBorder{
        border: 1px gray solid;
    }
    #signature {
        height: 90px;
        width: 600px;
        overflow: hidden;
        border: 1px solid #000;
        margin-top: 20px;
    }
    #signature-pad {
        margin-top: -100px;
        outline: none;
    }
    #extra-padding{
        margin-top: 20px;
    }
</style>