<template>
    <div>
        <div>
            <LandingPage />
        </div>    
    </div>
    
</template>

<script>
import LandingPage from '../components/Home/LandingPage.vue'
export default {
    components: {
        LandingPage
    }
}
</script>