<template>
    <div>
        <div>
            <Preview />
        </div>    
    </div>
    
</template>

<script>
import Preview from '../components/Reg/Preview.vue'
export default {
    components: {
        Preview
    }
}
</script>