<template>
    <div class="m_pad">
        <div class="mx-auto pt-4 text-left text-4xl font-bold md:max-w-screen-xl w-full">{{ $t('Reg.Preview.Heading') }}</div>
        <div class="md:mx-auto text-left text-lg font-bold md:max-w-screen-xl mx-auto w-full">{{ $t('Template.Breadcrumb', { breadcrumb: nav }) }}</div>
        <div class="mx-auto py-2 text-left md:max-w-screen-xl text-lg font-semibold w-full" v-if="errors.length">
            <b>{{ $t('Template.ErrorHandling.Notify') }}</b>
            <ul>
                <li class="text-red-400" v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
        <div class="py-4 mx-auto text-left text-xl md:max-w-screen-xl w-full" v-if="currentWorkflow === 'Fictitious Business Name Statement'">
            <span v-html="$t('Reg.Preview.Intro.Standard')"></span>
            <button @click="GoToHandler()" class="ml-2 text-white px-1 py-1 font-semibold darkblue text-center rounded inline-block" id="back-to-review-button">Go To Review</button>
            <br>
            <span class="text-red-500 text-2xl font-semibold" v-html="$t('Reg.Preview.Intro.Warning')"></span>
        </div>
        <div class="py-4 mx-auto text-left text-xl md:max-w-screen-xl w-full" v-if="currentWorkflow === 'Fictitious Business Name Statement Amendment'">
            <span v-html="$t('Reg.Preview.Intro.Standard')"></span>
            <button @click="GoToHandler()" class="ml-2 text-white px-1 py-1 font-semibold darkblue text-center rounded inline-block" id="back-to-review-button">Go To Review</button>
            <br>
            <span class="text-red-500 text-2xl font-semibold" v-html="$t('Reg.Preview.Intro.Warning')"></span>
        </div>
        <div class="py-4 mx-auto text-left text-xl md:max-w-screen-xl w-full" v-else-if="currentWorkflow === 'Fictitious Business Name Statement Renewal'">
            <span v-html="$t('Reg.Preview.Intro.Renewal')"></span>
            <button @click="GoToHandler()" class="ml-2 text-white px-1 py-1 font-semibold darkblue text-center rounded inline-block" id="back-to-review-button">Go To Registered Owner</button>
            <br>
            <span class="text-red-500 text-2xl font-semibold" v-html="$t('Reg.Preview.Intro.Warning')"></span>
        </div>
        <div class="py-4 mx-auto text-left text-xl md:max-w-screen-xl w-full" v-else-if="currentWorkflow === 'Fictitious Business Name Statement Abandonment'">
            <span v-html="$t('Reg.Preview.Intro.Abandonment')"></span>
            <button @click="GoToHandler()" class="ml-2 text-white px-1 py-1 font-semibold darkblue text-center rounded inline-block" id="back-to-review-button">Go To Review Business Names</button>
            <br>
            <span class="text-red-500 text-2xl font-semibold" v-html="$t('Reg.Preview.Intro.Warning')"></span>
        </div>
        <div class="py-4 mx-auto text-left text-xl md:max-w-screen-xl w-full" v-else-if="currentWorkflow === 'Fictitious Business Name Statement Withdrawal'">
            <span v-html="$t('Reg.Preview.Intro.Withdrawal')"></span>
            <button @click="GoToHandler()" class="ml-2 text-white px-1 py-1 font-semibold darkblue text-center rounded inline-block" id="back-to-review-button">Go To Registered Owner</button>
            <br>
            <span class="text-red-500 text-2xl font-semibold" v-html="$t('Reg.Preview.Intro.Warning')"></span>
        </div>
        <div class="md:max-w-screen-xl mx-auto w-full">
            <a class="mx-auto max-w-screen-xl text-white px-4 py-4 mb-1 font-semibold darkblue text-center rounded inline-block" id="pdf-download" :href="pdfFile" download="preview.pdf">Download Preview File</a>
            <div v-if="safari === false">
                <div id="pdf-status"></div>
                <div id='pdf-containere' class="text-center py-2">
                    <iframe id="pdf-viewer" :key="pdfKey" v-if="pdf" :src="pdf" width="100%" height="1000px"></iframe>
                </div>
            </div>
        </div>
        <br />
        <div class="md:max-w-screen-xl mx-auto w-full mt-2" @click="onCheckBoxClickedHandler">
            <p class="text-red-500">{{ $t('Reg.Preview.Acknowledgement', { proceedType: ShouldTextBeProceedOrSubmit }) }}</p>
            <br />
            <div class="flex items-center">
                <input class="h-8 w-8 mr-2" type="checkbox" id="agreement" v-bind:class="[ isOutlined ? 'outlineRed' : 'outline-none' ]" v-model="checkBox"/><b>{{ $t('Reg.Preview.UserInput.Checkbox') }}</b>
            </div>
        </div>
        <br />
        <div class="mx-auto text-left text-xl font-bold pt-4 md:max-w-screen-xl text-red-400 w-full" v-show="showSignMessage">{{ $t('Template.Signature.SignToProceed') }}</div>
        <div v-if="signatureDisplay">
                <div class="text-left text-xl mx-auto md:max-w-screen-xl w-full">{{ $t('Template.Signature.Intro') }}</div>
                <div class="max-w-screen-xl mx-auto py-2 md:pl-80 w-full">
                    <div id="signature" class="pt-2">
                        <canvas id="signature-pad" class="outlineBlack" width="600" height="175"></canvas>
                    </div><br/>
                    <div>
                        <!-- <button class="text-white px-4 font-semibold darkblue text-center rounded inline-block" id="save-signature-button">Save Signature</button> -->
                        <button class="text-white px-4 font-semibold darkblue text-center rounded inline-block" id="clear-signature-button">{{ $t('Template.Signature.Clear') }}</button>
                    </div>    
                </div>                             
            <!-- <div class="mx-auto max-w-screen-xl pt-2" v-if="savedSignature">Signature Saved.</div> -->
            <div class="pt-4 pb-2 mb-2 lineBorder mx-auto max-w-screen-xl"></div>    
        </div>
        <div class="mx-auto md:max-w-screen-xl py-4 w-full" v-if="this.enableRecaptcha && ShouldDisplayRecaptcha">
            <recaptcha ref="recaptcha" @verify="onRecaptchaSubmitClickHandler"></recaptcha>
        </div>
        <div class="mx-auto md:max-w-screen-xl py-4 main-controls w-full">
            <div class="flex">
                <button class="text-white px-4 py-4 font-semibold bg-gray-700 hover:bg-gray-500 text-center rounded inline-block" id="back-button" @click="previousPage();">{{ $t('Template.Buttons.PageNavigation.Back') }}</button>
                <button :disabled="isSubmitButtonDisabled" :class="`text-white px-4 py-4 font-semibold darkblue text-center rounded inline-block ml-2 ${submitButtonStyle}`" id="proceed-button" @click="nextPage()">{{ShouldTextBeProceedOrSubmit}}</button>
                <button class="text-white px-4 py-4 font-semibold darkblue text-center rounded ml-auto" id="open-help-button" @click="openHelp();">{{ $t('Template.Buttons.PageNavigation.Help') }}</button>
            </div>
            <Help :page="1" v-show="isHelpVisible" @closeHelp="closeHelp()"/>
        </div>         
    </div>
</template>

<script>
import ClerkRepository from '../../repositories/ClerkRepository';
import Help from '../common/Help.vue';
import Recaptcha from '../common/Recaptcha.vue';
import { EnableRecaptcha } from '../../assets/config';
import { ControlState, Platform, Sign, AppHasData } from '../../assets/constants';
import $ from 'jquery';
import SignaturePad from 'signature_pad'
export default {
    name: 'Preview',
    computed: {
        ShouldTextBeProceedOrSubmit() {
            return this.filingType === 'In-Person' ? this.$t('Template.Buttons.PageNavigation.Proceed') : this.$t('Template.SubmitOrder');
        },
        ShouldDisplayRecaptcha() {
            return this.filingType !== 'In-Person';
        },
        isSubmitButtonDisabled() {
            return this.disableSubmitButton;
        },
        submitButtonStyle() {
            return this.disableSubmitButton && this.filingType !== 'In-Person' ? 'opacity-50 cursor-not-allowed' : '';
        }
    },
    components: {
        Help,
        Recaptcha
    },
    data: () => ({
        checkBox: false,
        isOutlined: false,
        errors: [],
        nav: '',
        FbnFiling: {},
        pdf: null,
        filingType: null,
        signature: '',
        pdfKey: 0,
        orderNumber: 0,
        currentWorkflow: '',
        passRecaptcha: false,
        enableRecaptcha: EnableRecaptcha,
        isHelpVisible: false,
        workflow: {},
        didSign: false,
        sigValidationOn: false,
        showSignMessage: false,
        disableSubmitButton: false,
        pdfFile: null,
        safari: false
    }),
    methods: {
        onRecaptchaSubmitClickHandler(response) {
            ClerkRepository.validate(response)
                .then( response => {
                    if (response.data.success === true) {
                        this.passRecaptcha = true;
                    }
                })
                .catch( error => console.log('Recaptch: ', error))
        },
        onCheckBoxClickedHandler() {
            this.checkBox = !this.checkBox;
            ControlState.Set(this.checkBox);
        },
        renewalCheckboxHandler () {
            this.checkBoxRenewalEmail = !this.checkBoxRenewalEmail;
            this.checkBoxRenewalMail = !this.checkBoxRenewalMail;
        },
        dispatchConfirmation() {
            const payload = [...this.$store.getters.getFilings]
            ClerkRepository.post(payload, this.$store.getters.getFilingApproach, this.workflow)
                .then( response => {
                    this.$router.push('fbnorderconfirmation');    
                    this.$store.dispatch('setOrderNumber', response.data.trackingNumber);          
                    this.$store.dispatch('setFilingNumber', response.data.filingNumber);
                })
                .catch( error => console.log(error))
        },
        GoToHandler () {
            switch(this.currentWorkflow) {
                case 'Fictitious Business Name Statement':
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Amendment':
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Renewal':
                    this.$router.push('addregisteredowner');
                    break;
                case 'Fictitious Business Name Statement Abandonment':
                    this.$router.push('fbnreviewbusinessnames');
                    break;
                case 'Fictitious Business Name Statement Withdrawal':
                    this.$router.push('addregisteredowner');
                    break;
            }
        },
        nextPage() {
            this.disableSubmitButton = true;
            this.errors = [];
            if(this.checkBox === false) {
                this.isOutlined = true;
                this.errors.push('Please verify the pdf and click the agreement checkbox to continue.')
                this.disableSubmitButton = false;
                return;
            }
            if (this.sigValidationOn === true) {
                if (!this.didSign) {
                    this.showSignMessage = true;
                    this.disableSubmitButton = false;
                    return;
                } else {
                    this.showSignMessage = false;
                }
            }
            if (this.filingType === 'In-Person') {
                this.$router.push('fbnadditionalfiling');
            } else {
                if (this.enableRecaptcha && !this.passRecaptcha) {
                    this.errors.push('Please verify that the recaptcha is passing.');
                    this.disableSubmitButton = false;
                    return;
                }
                this.dispatchConfirmation();
            }
        },
        previousPage() {
            switch(this.currentWorkflow){
                case 'Fictitious Business Name Statement':
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Amendment':
                    this.$router.push('fbnreview');
                    break;
                case 'Fictitious Business Name Statement Renewal':
                    this.$router.push('fbnadditionalinformation');
                    break;
                case 'Fictitious Business Name Statement Abandonment':
                    this.$router.push('fbnreviewbusinessnames');
                    break;
                case 'Fictitious Business Name Statement Withdrawal':
                    this.$router.push('addregisteredowner');
                    break;  
            }
        },
        openHelp(){
            this.isHelpVisible = true;
        },
        closeHelp(){
            this.isHelpVisible = false;
        },
        safariCheck () {
            const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
            const isSafari = navigator.userAgent.indexOf('Safari') > -1;
            if ( (isChrome) && (isSafari) ) { 
                this.safari = false; 
            } else {
               this.safari = true; 
            }
        }
    },
    beforeMount() {
        //test
        const vm = this;
        AppHasData(this.$store.getters.getAppHasData, vm);
        this.FbnFiling = this.$store.getters.getCurrentFiling;
        this.filingType = this.$store.getters.getFilingApproach;
        this.nav = this.$store.getters.getSubheader;
        this.currentWorkflow = this.$store.getters.getWorkflow;
        this.workflow = {
            Approach: this.filingType,
            Type: this.currentWorkflow
        }
        if (this.workflow.Approach === 'Mail-In') {
            if (this.workflow.Type === 'Fictitious Business Name Statement Renewal' || this.workflow.Type === 'Fictitious Business Name Statement Abandonment') {
                this.signatureDisplay = true;
                this.sigValidationOn = true;
            }
        }
        this.pdf = null;
        ClerkRepository.postPDF(this.FbnFiling, this.workflow, true, -1)
            .then( response => {
                this.pdfFile = window.URL.createObjectURL(new Blob([response.data]));
                this.pdf = `data:application/pdf;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
            })
            .catch(function(error) {
                if (!this.pdf) {
                    document.getElementById('pdf-status').innerHTML = '<span class="text-red-500 text-2xl font-semibold">' + this.$t('Template.ErrorHandling.PDF') + '</span>';
                }
                console.log(`${error}`);
            });
        this.safariCheck();
    },
    mounted() {
        ControlState.Construct('proceed-button', this.checkBox);
        if(this.signatureDisplay === true){
            const vm = this;
            var signaturePad = new SignaturePad(document.getElementById('signature-pad'));
            $(document).ready(function() {
                if (!Platform._mobile) {
                    $('#signature-pad').click(function() {
                        Sign(vm, signaturePad);
                    });
                } else {
                    $('#signature-pad').on('touchstart', function() {
                        Sign(vm, signaturePad);
                    });
                }
                $('#clear-signature-button').click(function(){
                    signaturePad.clear();
                    vm.didSign = false;
                    vm.showSignMessage = false;
                    ControlState.Set(false);
                });
            });
        }
        ControlState.Construct('proceed-button', (this.signatureDisplay ? this.didSign : true));
    }
}

</script>
<style>
    .lightblue{
        background-color: #13b5ea;
    }
    .lightblue:hover{
        background-color: #59cbf0;
    }
    .darkblue{
        background-color: #003471;
    }
    .darkblue:hover{
        background-color: #004fac;
    }
    .focus\:border-lightblue:focus {
        --border-opacity: 1;
        border-color: #13b5ea;
        border-color: rgba(19, 181, 234, var(--border-opacity));
    }
    .small-text{
        color: #6d6d6d;
        font-weight: bold;
        font-size: .85em;
    }
    .large-text {
        color: #6d6d6d;
        font-weight: bold;
        font-size: 1.25em;
    }
    .outlineRed{
        outline: 2px solid red;
    }
    .outlineBlack{
        outline: 2px solid black;
    }
</style>